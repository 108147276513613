import { RequestType } from '../../../Service/RequestType';
import { callService } from '../../../Service/ServiceBase';
import { CustomerModuleMetaData, ModuleVariable } from './Model/ModulesModel';

class ModuleService {
  GetCustomerModules(customerId: string): Promise<CustomerModuleMetaData> {
    const customerModuleMetaData = callService(
      'modules',
      `${customerId}`,
      RequestType.GET,
    );
    return customerModuleMetaData;
  }

  GetModuleVariables(
    module: string,
    customerId: string,
  ): Promise<ModuleVariable[]> {
    const modulevariables = callService(
      'modules',
      `ModuleVariables/${module}/${customerId}`,
      RequestType.GET,
    );
    return modulevariables;
  }
  SaveModuleVariable(
    module: string,
    customerId: string,
    variableName: string,
    value: string,
  ): Promise<ModuleVariable> {
    const modulevariable = callService(
      'modules',
      `ModuleVariables/${module}/${customerId}/${variableName}/${value}/save`,
      RequestType.POST,
    );
    return modulevariable;
  }
  SaveModulesForCustomer(
    customerId: string,
    selectedModules: string[],
  ): Promise<ModuleVariable> {
    const modulevariable = callService(
      'modules',
      `CustomerModules/${customerId}/save`,
      RequestType.POST,
      selectedModules,
    );
    return modulevariable;
  }
}
export const moduleService: ModuleService = new ModuleService();
