import React, { SyntheticEvent } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Snackbar, SnackbarCloseReason, Theme, Tooltip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FileShareHomeTooltipTitle } from '../Home/FileShareHome';
import { FileShare } from '../Model/FileShareModel';
import { fileshareService } from '../FileShareService';
import Alert from '@mui/material/Alert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }),
);

export default function FormDialog(props: {
  addItemToState: (item: FileShare[]) => void;
}) {
  const [open, setOpen] = React.useState(false);
  const [create, setOpenCreateAlert] = React.useState(false);

  const handleCreateCloseAlert = (reason: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenCreateAlert(false);
  };

  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (e: SyntheticEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      Id: { value: string };
      CustomerName: { value: string };
      Status: { value: string };
      AdminUserName: { value: string };
      EmailAddress: { value: string };
      CreatedDate: { value: string };
      DeployedDate: { value: string };
    };
    handleClose();
    const fileshareReceived = await fileshareService.postFileshare({
      Id: '',
      CustomerName: target.CustomerName.value,
      Status: target.Status.value,
      AdminUserName: target.AdminUserName.value,
      EmailAddress: target.EmailAddress.value,
      CreatedDate: target.CreatedDate.value,
      DeployedDate: target.DeployedDate.value,
    });

    props.addItemToState(fileshareReceived.Items);
    setOpenCreateAlert(true);
  };

  return (
    <div>
      <Tooltip title={FileShareHomeTooltipTitle}>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          size="small"
          color="primary"
          onClick={handleClickOpen}
        >
          New
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Enter Tenant Details</DialogTitle>
        <form onSubmit={handleSave}>
          <DialogContent className={classes.root}>
            <TextField
              id="CustomerName"
              label="CustomerName"
              variant="standard"
            />
            <TextField id="Status" label="Status" variant="standard" />
            <TextField
              id="AdminUserName"
              label="AdminUserName"
              variant="standard"
            />
            <TextField
              type="email"
              id="EmailAddress"
              label="Email Address"
              variant="standard"
            />
            <TextField
              disabled={true}
              id="CreatedDate"
              label="Created Date"
              variant="standard"
            />
            <TextField
              disabled={true}
              id="DeployedDate"
              label="Deployed Date"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Snackbar
        open={create}
        autoHideDuration={4000}
        onClose={(_, reason) => handleCreateCloseAlert(reason)}
      >
        <Alert severity="success">Tenant Created Successfully!</Alert>
      </Snackbar>
    </div>
  );
}
