import { useParams, useHistory } from 'react-router-dom';
import { CustomerV2 } from '../../Customer/Model/CustomerModel';
import { Farm, Tenant } from '../../Farm/Model/FarmModel';
import React, { useState } from 'react';
import { AssembleBuild } from '../../AzureDevOps/Model/AzureDevOpsModel';
import { azureDevOpsService } from '../../AzureDevOps/AzureDevOpsService';
import { customerService } from '../../Customer/CustomerService';
import { farmService } from '../../Farm/FarmService';
import {
  Card,
  Checkbox,
  LinearProgress,
  Paper,
  Typography,
  Divider,
  Theme,
  Grid,
} from '@mui/material';
import { ReleaseRequest } from '../Model/FarmModel';
import { toast } from 'react-toastify';
import FarmContants from '../Model/FarmConstants';
import {
  DeploymentFooter,
  BaseDeployment,
  DeploymentTypeComponent,
} from '../../AzureDevOps/Common/CommonMethod';
import { useStyles } from '../../../../Common/Styles/WraperStyle';
import { Loader } from '../../../../Common/Components/Loader';
import { useStyles as farmStyles } from '../../../../Common/Styles/FarmStyle';
import makeStyles from '@mui/styles/makeStyles';

const selfStyle = makeStyles((theme: Theme) => ({
  checkBoxContainer: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(1.25),
    textAlign: 'start',
    maxHeight: '400px',
    overflow: 'auto',
  },
  outerContainer: {
    margin: theme.spacing(2.5),
    justifyContent: 'space-around',
  },
  card: {
    maxWidth: '500px',
    padding: theme.spacing(2.5),
    margin: theme.spacing(2.5),
    transition: 'box-shadow 0.3s',
    '&:hover': {
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    borderRadius: 5,
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    borderColor: theme.palette.secondary.main,
  },
}));

interface Param {
  FarmId: string;
}

interface Component {
  name: string;
  checked: boolean;
  label: string;
}
interface TenantCheck extends Component {
  customer: CustomerV2;
}
export default function DeployFarm() {
  const classes = useStyles();
  const styleClass = farmStyles();
  const style = selfStyle();
  const param = useParams<Param>();
  const initialTenants: Tenant[] = [];
  const [farm, setFarm] = React.useState<Farm>();
  const history = useHistory();

  const [components, setComponents] = React.useState<Component[]>([
    { name: 'PA.Web', checked: true, label: 'Web' },
    { name: 'WebApp', checked: true, label: 'WebApp' },
    { name: 'PA.Api', checked: true, label: 'Api' },
    { name: 'PA.Appserver', checked: true, label: 'Appserver' },
    { name: 'PA.Dss', checked: true, label: 'Dss' },
    { name: 'PA.Gateway', checked: true, label: 'Gateway' },
    { name: 'SetupDB', checked: true, label: 'SetupDB' },
  ]);

  const initialassemblebuilds: AssembleBuild[] = [];
  const initialSelectedList: TenantCheck[] = [];

  const [assemblebuilds, setAssemblebuilds] = useState(initialassemblebuilds);
  const [assemblebuildscopy, setAssemblebuildscopy] = useState(
    initialassemblebuilds,
  );
  const [branches, setBranches] = useState<string[]>([]);
  const [selectedBranch, setSelectedBranch] = useState('refs/heads/master');
  const [selectedList, setSelectedList] = useState(initialSelectedList);
  const [tenants, setTenants] = useState(initialTenants);
  const [selectAllTenants, setSelectAllTenants] = useState(false);
  const [selectedassembleBuild, setSelectedAssembleBuild] = useState('');
  const [assembleBuild, setAssembleBuild] = useState('');
  const [disasterrecovery, setDisasterrecovery] = useState(false);
  const [rollback, setRollback] = useState(false);
  const [DeployOnly, setDeployOnly] = useState(false);
  const [selectedlistLoading, setselectedlistLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSearchFilter = (searchFilterText: string) => {
    const filteredAB = assemblebuildscopy.filter(function (AB) {
      return (
        AB.BuildNumber.toLowerCase().indexOf(searchFilterText.toLowerCase()) >=
        0
      );
    });
    setAssemblebuilds(filteredAB);
  };
  function handleFieldChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (event.target.name === 'AssembleBuild') {
      setAssembleBuild(value);
      handleSearchFilter(value);
    } else if (event.target.name === 'SelectedAssemble') {
      setSelectedAssembleBuild(value);
    } else if (event.target.name === 'SelectedBranch') {
      setSelectedBranch(value);
    }
  }
  function handleCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.name === 'SelectAllTenants') {
      setSelectAllTenants(event.target.checked);
      selectedList.forEach((tenant) => {
        tenant.checked = event.target.checked;
      });
    }
    const updatedComponents = components.map((component) =>
      component.name === event.target.name
        ? { ...component, checked: event.target.checked }
        : component,
    );
    const updatedTenants = selectedList.map((tenant) =>
      tenant.name === event.target.name
        ? { ...tenant, checked: event.target.checked }
        : tenant,
    );
    setComponents(updatedComponents);
    setSelectedList(updatedTenants);
  }
  React.useEffect(() => {
    loadInitData();
  }, []);

  React.useEffect(() => {
    if (tenants.length > 0) {
      getselectedList(tenants);
    }
  }, [tenants]);

  const loadInitData = async () => {
    if (tenants.length < 1) {
      loadTenants();
    }
    await loadAssembles();
    await loadBranches();
    await loadFarm();
  };
  const loadAssembles = async () => {
    try {
      setLoading(true);
      const assemblebuilds = await azureDevOpsService.getBuild();
      setAssemblebuilds(assemblebuilds);
      setAssemblebuildscopy(assemblebuilds);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const loadBranches = async () => {
    try {
      setLoading(true);
      const branches = await azureDevOpsService.getBranches();
      setBranches(branches.Items);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const loadFarm = async () => {
    try {
      const farm = await farmService.getFarm(param.FarmId);
      setFarm(farm);
    } catch (error) {
      console.log(error);
    }
  };
  const loadTenants = async () => {
    try {
      await farmService.getTenantsOnFarm(+param.FarmId).then((newtenants) => {
        setTenants(newtenants.Items);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getselectedList = async (tenants: Tenant[]) => {
    try {
      const newSelectedItems: TenantCheck[] = [];

      for (const element of tenants) {
        const data = await customerService.getCustomer(element.Id);

        if (data.Id !== undefined) {
          newSelectedItems.push({
            name: data.Id,
            checked: false,
            label: data.Subdomain ?? data.Id,
            customer: data,
          });
        }
      }

      setSelectedList((prevSelectedList) => [
        ...prevSelectedList,
        ...newSelectedItems,
      ]);
      setselectedlistLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const RenderCheckbox = (props: {
    name: string;
    checked: boolean;
    label: string;
  }) => {
    return (
      <div
        style={{
          display: 'block',
          marginRight: '20px',
        }}
      >
        <Checkbox
          checked={props.checked}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          name={props.name}
          onChange={handleCheckbox}
        />
        <label>{props.label}</label>
      </div>
    );
  };

  const DeployAppServices = () => {
    const Release: ReleaseRequest = {};
    const selectedlist = selectedList
      .filter(function (obj) {
        return obj.checked;
      })
      .map(function (obj) {
        return obj?.customer?.Id;
      })
      .filter((item): item is string => item !== undefined);
    const compos = components
      .filter(function (obj) {
        return obj.checked;
      })
      .map(function (obj) {
        return obj.name;
      });
    Release.components = compos;
    Release.buildID = selectedassembleBuild;
    Release.branch = selectedBranch;
    if (selectedlist.length < 1) {
      toast.info(FarmContants.EmptyTenantInfo);
    } else if (compos.length < 1) {
      toast.info(FarmContants.EmptyComponentInfo);
    } else if (selectedassembleBuild === '') {
      toast.info(FarmContants.EmptyBuildInfo);
    } else {
      Release.tenantIds = selectedlist;
      Release.farmId = farm?.Id;
      Release.deployOnly = DeployOnly;
      Release.disasterRecovery = disasterrecovery;
      CreateRelease(Release);
      history.push('/farm');
    }
  };

  const CreateRelease = async (Release: ReleaseRequest) => {
    try {
      azureDevOpsService.CreateRelease(Release);
      toast.success(FarmContants.DeploymentQueued);
    } catch (error) {
      toast.error(FarmContants.DeploymentQueueFailed);
    }
  };

  return (
    <div>
      <Paper>
        <Typography variant="h5" className={classes.title}>
          Deploy Farm : {farm?.DisplayName} - {farm?.Id}
        </Typography>
        <Divider className={classes.divider} />
        <Loader loading={selectedlistLoading || loading} />
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <BaseDeployment
              assembleBuild={assembleBuild}
              handleFieldChange={handleFieldChange}
              assemblebuilds={assemblebuilds}
              selectedassembleBuild={selectedassembleBuild}
              branches={branches}
              selectedBranch={selectedBranch}
            />
            <div className={styleClass.outerContainer}>
              <Card elevation={0} className={styleClass.card}>
                <h3>Proarc Components</h3>
                <div className={style.checkBoxContainer}>
                  {components.map((component) => (
                    <RenderCheckbox
                      name={component.name}
                      checked={component.checked}
                      label={component.label}
                      key={component.name}
                    />
                  ))}
                </div>
              </Card>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div className={styleClass.outerContainer}>
              <Card elevation={0} className={styleClass.card}>
                <h3>Deployment Type(Leave empty for default)</h3>
                <div className={style.checkBoxContainer}>
                  <DeploymentTypeComponent
                    disasterrecovery={disasterrecovery}
                    rollback={rollback}
                    DeployOnly={DeployOnly}
                    handleCheckbox={handleCheckbox}
                    setDeployOnly={setDeployOnly}
                    setRollback={setRollback}
                    setDisasterrecovery={setDisasterrecovery}
                    displayOptions={{
                      deployOnly: true,
                      rollback: true,
                      disasterRecovery: true,
                    }}
                  />
                </div>
              </Card>
            </div>
            <div className={style.outerContainer}>
              <Card elevation={0} className={styleClass.card}>
                <h3>Tenants to be Deployed</h3>
                <div className={style.checkBoxContainer}>
                  {selectedlistLoading ? (
                    <LinearProgress />
                  ) : (
                    <>
                      <RenderCheckbox
                        checked={selectAllTenants}
                        label={'Select All'}
                        name={'SelectAllTenants'}
                      />
                      {selectedList.map((tenant) => (
                        <RenderCheckbox
                          name={tenant.name}
                          checked={tenant.checked}
                          label={tenant.label}
                          key={tenant.name}
                        />
                      ))}
                    </>
                  )}
                </div>
              </Card>
            </div>
          </Grid>
        </Grid>
        <DeploymentFooter DeployAppServices={DeployAppServices} />
      </Paper>
    </div>
  );
}
