import React, { FC, useEffect, useState } from 'react';
import { useStyles } from '../../../../Common/Styles/FarmStyle';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { commonService } from '../../../Common/CommonService';
import { validDMS, validDTUs } from '../Model/SqlServerData';
import { Loader } from '../../../../Common/Components/Loader';
import { Product, Subscription } from '../../../Common/CommonModel';
import {
  FailoverGroup,
  OperationType,
  SqlServer,
} from '../Model/SqlServerModel';
import { ValidationErrors } from 'fluentvalidation-ts';
import { Add } from '@mui/icons-material';
import { DialogOptions } from '../../Monitoring/Model/MonitoringModel';
import {
  ThemedDeleteIcon,
  ThemedEditIcon,
} from '../../../../Common/Components/Icons';
import { SqlServerValidator } from '../Validation/SqlServerValidator';
import { FailoverGroupForm } from './FailoverGroupForm';
import { valueToText } from '../../../../Common/CommonMethods';
import { sqlServerService } from '../SqlServerService';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface SqlServerFormProps {
  onFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sqlserver: SqlServer;
  setSqlServer: (value: React.SetStateAction<SqlServer>) => void;
  errors: ValidationErrors<SqlServer>;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors<SqlServer>>>;
  sqlServerOperation: OperationType;
}

const SqlServerForm: FC<SqlServerFormProps> = (props) => {
  const classes = useStyles();
  const [regions, setRegions] = useState<string[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [subscriptions, setSuscriptions] = useState<Subscription[]>([]);
  const [failovergroup, setFailovergroup] = useState<FailoverGroup>({
    Name: '',
    ServerName: '',
    Region: '',
    AdminPassword: '',
    UserName: '',
    EnableElasticPool: true,
    DataMaxSize: 50,
    EDtu: 50,
    PerDbDtu: 10,
  });
  const [loading, setLoading] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const {
    onFieldChange,
    sqlserver,
    setSqlServer,
    errors,
    setErrors,
    sqlServerOperation,
  } = props;
  const [failoverOperation, setFailoverOperation] = useState<OperationType>(
    OperationType.Create,
  );
  const [sqlservers, setSqlServers] = useState<SqlServer[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const sqlServerValidator = new SqlServerValidator();

  const validate = () => {
    const sqlserverErrors = sqlServerValidator.validate(sqlserver);
    if (sqlserver.ServerName && sqlServerOperation === OperationType.Create) {
      const exists = checkServerExists(sqlserver.ServerName);
      if (exists) {
        sqlserverErrors.ServerName = 'This server already exists';
      }
    }
    setErrors(sqlserverErrors);
  };

  function checkServerExists(servername: string) {
    return sqlservers.find((server) => server.ServerName == servername)
      ? true
      : false;
  }

  const validateSqlServer = () => {
    validate();
  };

  const handleSliderChange =
    (field: string) => (_: Event, value: number | number[]) => {
      setSqlServer((prev) => ({ ...prev, [field]: value as number }));
    };

  const Init = async () => {
    setLoading((prevLoading) => prevLoading + 1);
    await loadAzureRegions();
    await loadSubscriptions();
    await loadProducts();
    await loadSqlServers();
    await setLoading((prevLoading) => prevLoading - 1);
  };

  const handleDialogue = (value: boolean) => {
    setOpen(value);
    reInitializeDialog();
    setFailoverOperation(OperationType.Create);
  };

  const handleDelete = (group: string) => {
    setSqlServer((prev) => ({
      ...prev,
      FailoverGroups: prev.FailoverGroups.filter(
        (failovergroup) => failovergroup.Name !== group,
      ),
    }));
  };

  const handleEdit = (group: string) => {
    setFailoverOperation(OperationType.Edit);
    setOpen(true);
    setFailovergroup(
      sqlserver.FailoverGroups.find(
        (failovergroup) => failovergroup.Name === group,
      ) as FailoverGroup,
    );
  };

  const reInitializeDialog = () => {
    setFailovergroup({
      Name: '',
      ServerName: '',
      Region: '',
      AdminPassword: '',
      UserName: '',
      EnableElasticPool: true,
      DataMaxSize: 50,
      EDtu: 50,
      PerDbDtu: 10,
    });
  };

  const handleAddFailovergroup = () => {
    setSqlServer((prev) => ({
      ...prev,
      FailoverGroups: [...prev.FailoverGroups, failovergroup],
    }));
  };

  const handleEditFailovergroup = () => {
    setSqlServer((prev) => ({
      ...prev,
      FailoverGroups: prev.FailoverGroups.map((group) =>
        group.ServerName === failovergroup.ServerName ? failovergroup : group,
      ),
    }));
  };

  const handleDialogueOptions = (option: DialogOptions) => {
    if (
      DialogOptions.Ok === option &&
      failoverOperation === OperationType.Create
    ) {
      handleAddFailovergroup();
    } else if (
      DialogOptions.Ok === option &&
      failoverOperation === OperationType.Edit
    ) {
      handleEditFailovergroup();
    }
    reInitializeDialog();
    handleDialogue(false);
  };

  const loadAzureRegions = async () => {
    const azureRegions = await commonService.getAzureRegions();
    setRegions(azureRegions.toSorted());
  };

  const loadSubscriptions = async () => {
    const subscriptions = await commonService.getSubscriptions();
    setSuscriptions(subscriptions.Items);
  };

  const loadSqlServers = async () => {
    const servers = await sqlServerService.getSqlServers();
    setSqlServers(servers.Items);
  };

  const loadProducts = async () => {
    const products = await commonService.getProducts();
    setProducts(products.Items);
  };

  const canShowFailoverTable = () => {
    return sqlserver.FailoverGroups && sqlserver.FailoverGroups.length > 0;
  };

  useEffect(() => {
    Init();
    validate();
  }, []);

  useEffect(() => {
    validate();
  }, [sqlserver]);

  return (
    <>
      {loading > 0 ? (
        <Loader loading={loading > 0} />
      ) : (
        <div className={classes.outerContainer}>
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Card elevation={0} className={classes.card}>
                <h3>Basic information</h3>
                <div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Subscription</span>
                    <TextField
                      className={classes.textField}
                      error={!!errors.Subscription}
                      helperText={errors.Subscription}
                      value={sqlserver.Subscription}
                      placeholder="Subscription"
                      name="Subscription"
                      onChange={onFieldChange}
                      onBlur={validate}
                      select
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 350,
                              maxWidth: 350,
                            },
                          },
                        },
                      }}
                    >
                      {subscriptions.map((subscription) => (
                        <MenuItem
                          key={subscription.Name}
                          value={subscription.Name}
                        >
                          {subscription.Name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Resource Group</span>
                    <TextField
                      className={classes.textField}
                      error={!!errors.ResourceGroup}
                      helperText={errors.ResourceGroup}
                      value={sqlserver.ResourceGroup}
                      placeholder="Resource Group"
                      name="ResourceGroup"
                      onChange={onFieldChange}
                      onBlur={validate}
                    />
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Server Name</span>
                    <TextField
                      className={classes.textField}
                      error={!!errors.ServerName}
                      helperText={errors.ServerName}
                      value={sqlserver.ServerName}
                      placeholder="Server Name"
                      name="ServerName"
                      onChange={(e) => {
                        onFieldChange(e as React.ChangeEvent<HTMLInputElement>);
                        validateSqlServer();
                      }}
                      onBlur={validateSqlServer}
                    />
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>User Name</span>
                    <TextField
                      className={classes.textField}
                      error={!!errors.UserName}
                      helperText={errors.UserName}
                      value={sqlserver.UserName}
                      placeholder="User Name"
                      name="UserName"
                      onChange={onFieldChange}
                      onBlur={validate}
                    />
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Admin Password</span>
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      className={classes.textField}
                      error={!!errors.AdminPassword}
                      helperText={errors.AdminPassword}
                      value={sqlserver.AdminPassword}
                      placeholder="Admin Password"
                      name="AdminPassword"
                      onChange={onFieldChange}
                      onBlur={validate}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Location/Region</span>
                    <TextField
                      className={classes.textField}
                      error={!!errors.Region}
                      helperText={errors.Region}
                      value={sqlserver.Region}
                      placeholder="Region"
                      name="Region"
                      onChange={onFieldChange}
                      onBlur={validate}
                      select
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 350,
                            },
                          },
                        },
                      }}
                    >
                      {regions.map((region) => (
                        <MenuItem key={region} value={region}>
                          {region}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Product</span>
                    <TextField
                      className={classes.textField}
                      error={!!errors.ProductID}
                      helperText={errors.ProductID}
                      value={sqlserver.ProductID}
                      placeholder="Product"
                      name="ProductID"
                      onChange={onFieldChange}
                      onBlur={validate}
                      select
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 350,
                            },
                          },
                        },
                      }}
                    >
                      {products.map((product) => (
                        <MenuItem key={product.Id} value={product.Id}>
                          {product.Id}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Card elevation={0} className={classes.card}>
                <h3>Additional Configuration</h3>
                <div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Enable Elastic Pool</span>
                    <div className={classes.textField}>
                      <Checkbox
                        sx={{ float: 'left' }}
                        id="Enable Elastic Pool"
                        value={sqlserver.EnableElasticPool ?? false}
                        checked={sqlserver.EnableElasticPool ?? false}
                        name="EnableElasticPool"
                        onChange={(e) => onFieldChange(e)}
                        onBlur={validate}
                      />
                    </div>
                  </div>
                  {sqlserver.EnableElasticPool && (
                    <>
                      <div className={classes.spanContainer}>
                        <span className={classes.span}>eDTUs</span>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center' }}
                          className={classes.textField}
                        >
                          <Slider
                            aria-label="Small steps"
                            defaultValue={sqlserver.EDtu}
                            getAriaValueText={valueToText}
                            step={null}
                            marks={validDTUs}
                            min={50}
                            max={3000}
                            onChange={handleSliderChange('EDtu')}
                            valueLabelDisplay="auto"
                          />
                          <TextField
                            sx={{ marginLeft: '20px', width: '80px' }}
                            error={!!errors.EDtu}
                            helperText={errors.EDtu}
                            value={sqlserver.EDtu}
                            onBlur={validate}
                          />
                        </Box>
                      </div>
                      <div className={classes.spanContainer}>
                        <span className={classes.span}>Data max size (GB)</span>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center' }}
                          className={classes.textField}
                        >
                          <Slider
                            aria-label="Small steps"
                            defaultValue={sqlserver.DataMaxSize}
                            getAriaValueText={valueToText}
                            marks={validDMS}
                            step={null}
                            min={50}
                            max={3000}
                            onChange={handleSliderChange('DataMaxSize')}
                            valueLabelDisplay="auto"
                          />
                          <TextField
                            sx={{ marginLeft: '20px', width: '80px' }}
                            error={!!errors.DataMaxSize}
                            helperText={errors.DataMaxSize}
                            value={sqlserver.DataMaxSize}
                            onBlur={validate}
                          />
                        </Box>
                      </div>
                      <div className={classes.spanContainer}>
                        <span className={classes.span}>Per database DTUs</span>
                        <Box
                          sx={{ display: 'flex', alignItems: 'center' }}
                          className={classes.textField}
                        >
                          <Slider
                            aria-label="Small steps"
                            defaultValue={sqlserver.PerDbDtu}
                            getAriaValueText={valueToText}
                            step={null}
                            min={0}
                            max={sqlserver.EDtu}
                            marks={validDTUs}
                            onChange={handleSliderChange('PerDbDtu')}
                            valueLabelDisplay="auto"
                          />
                          <TextField
                            sx={{ marginLeft: '20px', width: '80px' }}
                            error={!!errors.PerDbDtu}
                            helperText={errors.PerDbDtu}
                            value={sqlserver.PerDbDtu}
                            onBlur={validate}
                          />
                        </Box>
                      </div>
                    </>
                  )}
                </div>
              </Card>
              <FailoverGroupForm
                sqlserver={sqlserver}
                failovergroup={failovergroup}
                setFailovergroup={setFailovergroup}
                regions={regions}
                open={open}
                handleDialogueOptions={handleDialogueOptions}
                operationType={failoverOperation}
              />
              <Card elevation={0} className={classes.card}>
                <h3>Configure Failover</h3>
                <div className={classes.toolsHolder}>
                  <Button
                    disabled={sqlserver.FailoverGroups.length == 1}
                    variant="contained"
                    onClick={() => handleDialogue(true)}
                    startIcon={<Add />}
                  >
                    Add{' '}
                  </Button>
                </div>
                {canShowFailoverTable() && (
                  <div className={classes.spanContainer}>
                    <TableContainer style={{ maxHeight: 450 }}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Server</TableCell>
                            <TableCell>Region</TableCell>
                            <TableCell>Delete</TableCell>
                            <TableCell>Edit</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {sqlserver.FailoverGroups.map((group) => {
                            return (
                              <TableRow>
                                <TableCell>{group.Name}</TableCell>
                                <TableCell>{group.ServerName}</TableCell>
                                <TableCell>{group.Region}</TableCell>
                                <TableCell>
                                  <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    onClick={() => handleDelete(group.Name)}
                                  >
                                    <ThemedDeleteIcon />
                                  </IconButton>
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    aria-label="delete"
                                    color="primary"
                                    onClick={() => handleEdit(group.Name)}
                                  >
                                    <ThemedEditIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </Card>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

export default SqlServerForm;
