const win: Window = window;
const appid: string = win.config.appid;

const GRAPH_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  API_TOKEN: appid,
};

export const GRAPH_REQUESTS = {
  LOGIN: {
    scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ],
  },
  API: {
    scopes: [GRAPH_SCOPES.API_TOKEN],
  },
};
