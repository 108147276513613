import React from 'react';
import { Button, Divider, Paper, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router';
import { farmService } from '../FarmService';
import { toast } from 'react-toastify';
import { Loader } from '../../../../Common/Components/Loader';
import { useStyles } from '../../../../Common/Styles/WraperStyle';
import DeleteInfoCard from '../../../../Common/Components/DeleteInfoCard';
import { Check, Clear } from '@mui/icons-material';

interface UriParams {
  FarmId: string;
}

const DeleteForm = () => {
  const classes = useStyles();
  const params = useParams<UriParams>();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  const handleCancel = () => {
    history.push('/farm');
  };

  const handleDelete = async () => {
    setLoading(true);
    const deletedfarm = await farmService.deleteFarm(+params.FarmId);
    toast.success(
      `Running Delete On ${deletedfarm.DisplayName}(${deletedfarm.Id})`,
    );
    setLoading(false);
    handleCancel();
  };

  return (
    <div>
      <Loader loading={loading} />
      <Paper>
        <Typography variant="h5" className={classes.title}>
          Delete Farm
        </Typography>
        <DeleteInfoCard text={`this Farm : ${params.FarmId}`} />
        <Divider className={classes.divider} />
        <Paper style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            onClick={handleDelete}
            className={classes.button}
            startIcon={<Check />}
          >
            Yes{' '}
          </Button>
          <Button
            variant="contained"
            onClick={handleCancel}
            className={classes.button}
            startIcon={<Clear />}
          >
            No{' '}
          </Button>
        </Paper>
      </Paper>
    </div>
  );
};

export default DeleteForm;
