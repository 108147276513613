import {
  Theme,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  CircularProgress,
  Backdrop,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { toast } from 'react-toastify';
import { TrustedClientsRouterProps } from '../Model';
import TrustedClientsMenu from '../Components/TrustedClientsMenu';
import { customerService } from '../../Customer/CustomerService';
import { azureDevOpsService } from '../../AzureDevOps/AzureDevOpsService';
import { CustomerV2 } from '../../Customer/Model/CustomerModel';
import { Tenant } from '../../../../Components/Layout/Farm/Model/FarmModel';
import TrustedClientsHeaderToolbar from './TrustedClientsHeaderToolbar';
import TrustedClientsTableHead from './TrustedClientsTableHead';
import { Order } from '../../../../Helpers/Literals';
import { TrustedClients } from '../Model/TrustedClientsModel';
import { ReleaseRequest } from '../../AzureDevOps/Model/AzureDevOpsModel';
import { getSorting, stableSort } from '../../../../Helpers/UtilityMethods';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    subDomainWidth: {
      width: 421,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

export default function TrustedClientsHome(props: TrustedClientsRouterProps) {
  const initialState: CustomerV2 = {};
  const initialsetReleaseState: ReleaseRequest = {};
  const initialStateTC: TrustedClients[] = [];
  const { customerId } = props.match.params;
  const [customerData, getCustomer] = useState(initialState);
  const [tenantdata, getTenant] = useState<Tenant>({
    Id: '',
    ApplicationUrl: '',
    FarmId: 0,
    AppDBName: '',
    StatusID: 0,
    AppservicePlan: '',
  });
  const [azureDevOpsBuilds, getBuilds] = useState('');
  const Release: ReleaseRequest = initialsetReleaseState;
  const [loading, setLoading] = useState(true);
  const [TrustedClientsc, setFields] = useState(initialStateTC);
  const [TrustedClientscopy, setFieldsCopy] = useState(initialStateTC);
  const classes = useStyles();
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TrustedClients>('ClientID');
  const rowsPerPage = 10;
  const [page, setPage] = useState(0);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, TrustedClientsc.length - page * rowsPerPage);

  useEffect(() => {
    loadInitData();
  }, []);

  const loadInitData = () => {
    loadCustomerData();
    loadAzureBuilds();
    loadTenantData();
  };

  const loadCustomerData = async () => {
    try {
      const customersReceived = await customerService.getCustomer(customerId);
      getTrustedClients(customersReceived);

      getCustomer(customersReceived);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSiblingsNum = (build: string) => getBuilds(build);

  const loadTenantData = async () => {
    try {
      const activeTenant = await customerService.getTenant(customerId, true);
      getTenant(activeTenant.Items[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const loadAzureBuilds = async () => {
    try {
      const Builds = await azureDevOpsService.getBuild();
      handleSiblingsNum(
        Builds.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (a: any) => a.BuildNumber.indexOf('Maintenance') === -1,
        )[0].BuildNumber,
      );
    } catch (error) {
      console.log(error);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getTrustedClients = (customersReceived: any) => {
    if (customersReceived.TrustedClients != null) {
      const data = JSON.parse(customersReceived.TrustedClients);
      setFieldsCopy(data);
      setFields(data);
    }
  };

  const saveTclientCallback = async (a: string | undefined | null) => {
    try {
      const cust = customerData;
      if (a) {
        cust.TrustedClients = a;
        const cus = await customerService.setCustomer(customerId, cust);
        getCustomer(cus);
      }
    } catch (error) {
      console.log(error);
      toast.error('Failed to Update the values');
      return;
    }

    toast.success('Successfully saved the Trusted Clients');
  };

  function handleChangePage(newPage: number) {
    setPage(newPage);
  }

  const onDelete = (event: React.MouseEvent<HTMLLIElement>) => {
    const a = window.confirm('Are you sure you want to delete the client?');
    const target = event.target as HTMLElement;
    if (a === true) {
      const TC = TrustedClientsc.filter((item) => item.ClientID !== target.id);
      if (TC.length === 1 && TC[0].ClientID === target.id) {
        saveTclientCallback(null).then(() => {
          setFieldsCopy(initialStateTC);
          setFields(initialStateTC);
        });
      } else {
        saveTclientCallback(JSON.stringify(TC)).then(() => {
          loadInitData();
        });
      }
    }
  };

  const OnAdd = (val: TrustedClients) => {
    const TC = TrustedClientsc;
    TC.push(val);
    saveTclientCallback(JSON.stringify(TC)).then(() => {
      loadInitData();
    });
  };

  const OnUpdate = (val: TrustedClients) => {
    let TC = TrustedClientsc.filter((item) => item.ClientID !== val.ClientID);
    if (TC.length === 1 && TC[0].ClientID === val.ClientID) {
      TC = initialStateTC;
      TC.push(val);
    } else {
      TC.push(val);
    }
    saveTclientCallback(JSON.stringify(TC)).then(() => {
      loadInitData();
    });
  };

  const handleFilter = (searchFilterText: string) => {
    const filteredTC = TrustedClientscopy.filter(function (TC) {
      return (
        TC.ClientID.toLowerCase().indexOf(searchFilterText.toLowerCase()) >= 0
      );
    });
    setFields(filteredTC);
  };

  function handleSearchFilter(event: React.ChangeEvent<HTMLInputElement>) {
    handleFilter(event.target.value);
  }

  function handleDeployClients() {
    try {
      Release.TrustedClients = true;
      Release.FarmId = tenantdata.FarmId;
      Release.BuildID = azureDevOpsBuilds;
      const cust: Array<string> = [customerId];
      Release.TenantIds = cust;
      azureDevOpsService.triggerRelease(Release);
      toast.success('BAZINGA! release is queued');
    } catch (error) {
      toast.error('Failed to Queue the release');
    }
  }

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof TrustedClients,
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TrustedClientsHeaderToolbar
          handleDeployClients={handleDeployClients}
          onFilterRequest={handleSearchFilter}
          OnAdd={OnAdd}
          customer={customerData || ''}
        />
        {loading ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <TrustedClientsTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              ></TrustedClientsTableHead>
              <TableBody>
                {stableSort(TrustedClientsc, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((TC) => (
                    <TableRow key={TC.ClientID}>
                      <TableCell>{TC.ClientID}</TableCell>
                      <TableCell>{TC.ClientUri}</TableCell>
                      <TableCell>{TC.RedirectUris}</TableCell>
                      <TableCell>{TC.LogoutUri}</TableCell>
                      <TableCell>{TC.AllowedScopes}</TableCell>
                      <TableCell>{TC.AllowedGrantTypes}</TableCell>
                      <TableCell>
                        <TrustedClientsMenu
                          ClientID={TC.ClientID}
                          onDelete={onDelete}
                          onUpdate={OnUpdate}
                          Tclient={TC}
                          customerId={customerId}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    colSpan={7}
                    count={TrustedClientsc.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Rows per page' },
                      native: true,
                    }}
                    onPageChange={(_, page) => handleChangePage(page)}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </Paper>
    </div>
  );
}
