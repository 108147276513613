import { callPublicService, callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { ClientFile } from './Model/ClientsModel';

class ClientsService {
  getClientfiles(): Promise<ClientFile[]> {
    return callPublicService(
      'resources/proarc-clients/blobs',
      '',
      RequestType.GET,
    );
  }
  deleteClientfile(container: string, filename: string): Promise<unknown> {
    return callService(
      `resources/${container}/blobs`,
      `${filename}`,
      RequestType.DELETE,
    );
  }
}

export const clientsService: ClientsService = new ClientsService();
