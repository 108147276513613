import { authProvider } from '../Auth';

export interface IErrorResponse {
  message: string;
  details: string;
}

export const sendFileRequest = (
  file: File,
  serviceName: string,
  methodName: string,
  progress: (filename: string, percentage: number, state: string) => void,
  onComplete: (filename: string) => void,
  onError: (httpStatusCode: number, response: IErrorResponse) => void,
): unknown => {
  const win: Window = window;
  const baseUrl: string = win.config.apibaseurl;
  const completeURL: string = baseUrl + '/' + serviceName + '/' + methodName;

  return authProvider.getWebApiToken().then((token) => {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();

      req.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          progress(file.name, (event.loaded / event.total) * 100, 'pending');
        }
      });

      req.upload.addEventListener('load', () => {
        progress(file.name, 100, 'done');
        resolve(req.response);
      });

      req.upload.addEventListener('error', () => {
        progress(file.name, 0, 'error');
        reject(req.response);
      });

      const formData = new FormData();
      formData.append('file', file, file.name);

      req.open('POST', completeURL);
      req.onload = () => {
        const statusCode = req.status,
          error = statusCode >= 400 && statusCode <= 500 ? true : false;
        if (error) {
          onError(req.status, req.response);
        } else {
          onComplete(file.name);
        }
      };

      req.onerror = () => {
        onError(req.status, req.response);
      };

      req.setRequestHeader('Cache-Control', 'no-cache');
      req.setRequestHeader('Authorization', 'Bearer ' + token);
      req.setRequestHeader(
        'Access-Control-Allow-Methods',
        'POST, PUT, OPTIONS',
      );
      req.setRequestHeader(
        'Access-Control-Allow-Headers',
        'Origin, Content-Type, Authorization',
      );

      req.send(formData);
    });
  });
};
