import { RequestType } from '../../../Service/RequestType';
import { callService } from '../../../Service/ServiceBase';
import { PagedData } from '../../Common/CommonModel';
import {
  GroupAndAvailablePermissions,
  PortalGroup,
  PortalGroupPermission,
  PortalPermission,
  PortalUser,
  UserAndAvailableGroups,
} from './Model/AccessControlModel';

class AccessControlService {
  getPortalUsers(): Promise<PagedData<PortalUser>> {
    const portalusers = callService('accesscontrol', 'users', RequestType.GET);
    return portalusers;
  }

  getPortalGroups(): Promise<PagedData<PortalGroup>> {
    const portalgroups = callService(
      'accesscontrol',
      'groups',
      RequestType.GET,
    );
    return portalgroups;
  }

  getUserAndAvailableGroups(username: string): Promise<UserAndAvailableGroups> {
    const userandavailablegroups = callService(
      'accesscontrol',
      `${username}/userandavailablegroups`,
      RequestType.GET,
    );
    return userandavailablegroups;
  }

  getAvailableGroups(): Promise<UserAndAvailableGroups> {
    const userandavailablegroups = callService(
      'accesscontrol',
      'availablegroups',
      RequestType.GET,
    );
    return userandavailablegroups;
  }

  SaveUser(
    username: string,
    selectedGroups: string[],
  ): Promise<UserAndAvailableGroups> {
    const userandavailablegroups = callService(
      'accesscontrol',
      `saveuser/${username}`,
      RequestType.PUT,
      selectedGroups,
    );
    return userandavailablegroups;
  }

  DeleteUser(username: string) {
    callService('accesscontrol', `deleteuser/${username}`, RequestType.DELETE);
  }

  getGroupAndAvailablePermissions(
    groupname: string,
  ): Promise<GroupAndAvailablePermissions> {
    const groupandavailablepermissions = callService(
      'accesscontrol',
      `${groupname}/groupandavailablepermissions`,
      RequestType.GET,
    );
    return groupandavailablepermissions;
  }

  getAvailablePermissions(): Promise<GroupAndAvailablePermissions> {
    const userandavailablegroups = callService(
      'accesscontrol',
      'availablepermissions',
      RequestType.GET,
    );
    return userandavailablegroups;
  }

  SaveGroup(
    groupname: string,
    group: PortalGroup,
  ): Promise<GroupAndAvailablePermissions> {
    const groupandavailablepermissions = callService(
      'accesscontrol',
      `groups/${groupname}`,
      RequestType.PUT,
      group,
    );
    return groupandavailablepermissions;
  }

  GetPartnerGroupDefaultPermissions(): Promise<PagedData<PortalPermission>> {
    const partnerGroupDefaultPermissions = callService(
      'accesscontrol',
      'partnergroupermissions',
      RequestType.GET,
    );
    return partnerGroupDefaultPermissions;
  }

  setGroupPermissions(
    groupname: string,
    portalgrouppermissions: PortalGroupPermission[],
  ): Promise<GroupAndAvailablePermissions> {
    const groupandavailablepermissions = callService(
      'accesscontrol',
      `groups/${groupname}/permissions`,
      RequestType.PUT,
      portalgrouppermissions,
    );
    return groupandavailablepermissions;
  }

  DeleteGroup(groupname: string, portalgroup: PortalGroup) {
    callService(
      'accesscontrol',
      `deletegroup/${groupname}`,
      RequestType.DELETE,
      portalgroup,
    );
  }
}
export const accessControlService: AccessControlService =
  new AccessControlService();
