import React, { useEffect, useState } from 'react';
import { SqlServer } from './Model/SqlServerModel';
import { sqlServerService } from './SqlServerService';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useTableStyles } from '../../../Common/Styles/WraperStyle';
import SqlServerHeaderToolbar from './Components/SqlServerHeaderToolbar';
import { Loader } from '../../../Common/Components/Loader';
import { Order } from '../../../Helpers/Literals';
import { getSorting, stableSort } from '../../../Helpers/UtilityMethods';
import SqlServerHead from './Components/SqlServerHead';
import SqlServerMenu from './Components/SqlServerMenu';

const SqlServerHome = () => {
  const tableClass = useTableStyles();
  const rowsPerPage = 8;
  const [loading, setLoading] = useState(1);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof SqlServer>('ServerName');
  const [sqlServers, setSqlServers] = useState<SqlServer[]>([]);
  const [sqlServersCopy, setSqlServersCopy] = useState<SqlServer[]>([]);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, sqlServers.length - page * rowsPerPage);

  const handleFilter = (searchFilterText: string) => {
    const filteredResult = sqlServersCopy.filter(function (sqlserver) {
      return (
        sqlserver.ServerName.toLowerCase().indexOf(
          searchFilterText.toLowerCase(),
        ) >= 0
      );
    });
    setSqlServers(filteredResult);
  };

  function handleSearchFilter(event: React.ChangeEvent<HTMLInputElement>) {
    handleFilter(event.target.value);
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof SqlServer,
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  useEffect(() => {
    loadSqlServerData();
  }, []);

  interface RenderContentProps {
    loading: boolean;
  }

  function SqlServerData() {
    return (
      <div className={tableClass.tableWrapper}>
        <Table className={tableClass.table}>
          <SqlServerHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(sqlServers, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((sqlserver) => (
                <TableRow key={sqlserver.ServerName}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={tableClass.column}
                  >
                    {sqlserver.ServerName}
                  </TableCell>
                  <TableCell>{sqlserver.ProductID}</TableCell>
                  <TableCell>{sqlserver.Region}</TableCell>
                  <TableCell>{sqlserver.ResourceGroup}</TableCell>
                  <TableCell>{sqlserver.Role}</TableCell>
                  <TableCell>{sqlserver.Subscription}</TableCell>
                  <TableCell>
                    <SqlServerMenu server={sqlserver.ServerName} />
                  </TableCell>
                  <TableCell style={{ width: 100 }} align="left"></TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[8]}
                colSpan={9}
                count={sqlServers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }

  const RenderContent = ({ loading }: RenderContentProps) => {
    if (loading) {
      return <Loader loading={loading} />;
    } else {
      return <SqlServerData />;
    }
  };

  const loadSqlServerData = async () => {
    try {
      const sqlserver = await sqlServerService.getSqlServers();
      setSqlServers(sqlserver.Items);
      setSqlServersCopy(sqlserver.Items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  return (
    <div className={tableClass.root}>
      <Paper className={tableClass.paper}>
        <SqlServerHeaderToolbar onFilterRequest={handleSearchFilter} />
        <RenderContent loading={loading > 0} />
      </Paper>
    </div>
  );
};

export default SqlServerHome;
