import {
  Card,
  Divider,
  Paper,
  Toolbar,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TextField,
  Button,
  Backdrop,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStyles } from '../../../../Common/Styles/Theme';
import { TableCell } from '../../DashBoard/Common/CommonMethods';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import { emailadminService } from '../EmailAdministrationService';
import { useHistory, useParams } from 'react-router-dom';
import { EmailTemplate } from '../Model/EmailAdminModel';
import { Save } from '@mui/icons-material';
import JoditTemplateEditor from '../../../Common/JodiEditor/JodiEditor';
import { saveEmailTemplate } from '../Common/Common';

interface Param {
  name: string;
  product: string;
}

const TemplateOperation = () => {
  const classes = useToolbarStyles();
  const { name, product } = useParams<Param>();
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>();
  const [loading, setLoadingState] = useState(true);
  const history = useHistory();

  useEffect(() => {
    loadEmailTemplate();
  }, []);

  const loadEmailTemplate = async () => {
    await emailadminService.getEmailTemplate(name, product).then((template) => {
      setEmailTemplate(template);
      setLoadingState(false);
    });
  };

  return (
    <>
      <Card elevation={2} style={{ padding: 15, margin: 15 }}>
        <Typography variant="h6">Basic Information</Typography>
        {loading || emailTemplate === undefined ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <Card sx={{ width: '50%', padding: 2, margin: 2 }}>
              <TableContainer>
                <Table>
                  <TableRow>
                    <TableCell>Template Name :</TableCell>
                    <TableCell>{emailTemplate.Name}</TableCell>
                    <TableCell>Product :</TableCell>
                    <TableCell>{emailTemplate.Product}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>E-mail Subject :</TableCell>
                    <TextField
                      className={classes.textFieldMedium}
                      value={emailTemplate.Subject}
                      onChange={(e) =>
                        setEmailTemplate({
                          ...emailTemplate,
                          Subject: e.target.value,
                        })
                      }
                    />
                  </TableRow>
                </Table>
              </TableContainer>
            </Card>
            <Card sx={{ margin: 2 }}>
              <JoditTemplateEditor
                emailtemplate={emailTemplate}
                setEmailTemplate={setEmailTemplate}
              />
            </Card>
          </>
        )}
      </Card>
      <Divider className={classes.divider} />
      <Paper style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<Save />}
          onClick={() =>
            saveEmailTemplate(
              history,
              emailTemplate,
              setLoadingState,
              setEmailTemplate,
            )
          }
        >
          Save{' '}
        </Button>
      </Paper>
    </>
  );
};

const EditTemplatePage = () => {
  const classes = useStyles();
  return (
    <div>
      <Paper>
        <Toolbar className={classes.Root}>
          <div className={classes.Title}>
            <Typography variant="h6" id="tableTitle">
              Edit Tamplate
            </Typography>
          </div>
        </Toolbar>
        <TemplateOperation />
      </Paper>
    </div>
  );
};

export default EditTemplatePage;
