import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { topologyService } from './TopologyService';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from 'react-router-dom';
import { Farm } from '../ManageFarms/Model/FarmModel';
import { toast, ToastContainer, ToastPosition } from 'react-toastify';
import SimpleDialogDemo from './Components/PlainDialogue';
import {
  RemoveDuplicateAppserviceplans,
  createNewAppServicePlan,
} from '../../../Common/CommonMethods';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      padding: 0,
    },
    button: {
      margin: theme.spacing(1),
    },
    typography: {
      margin: '20px',
    },
    paper: {
      display: 'flex',
    },
    backdrop: {
      color: '#fff',
    },
  }),
);

interface UriParams {
  FarmId: string;
}
interface FarmTopology {
  Topology: Topology[];
}

interface Topology {
  Component: string;
  AppservicePlan: string;
}

interface Props {}

const DynamicTable: React.FC<Props> = () => {
  const classes = useStyles();
  const [rows, setRows] = useState<Topology[]>([]);
  const [save, setSave] = useState<boolean>();
  const [farm, setFarm] = useState<Farm>();
  const [edit, setEdit] = useState<boolean>();
  const [loading, setLoadingState] = React.useState(true);
  const [newRow, setNewRow] = useState<Topology>({
    Component: '',
    AppservicePlan: '',
  });
  const initialAppserviceState: string[] = [];
  const [appserviceplans, SetAppservicePlans] = useState(
    initialAppserviceState,
  );

  const params = useParams<UriParams>();

  const addItemToState = (item: string[]) => {
    const newItem = createNewAppServicePlan(
      appserviceplans,
      item,
      Number(params.FarmId),
    );
    const appAppservicePlans = appserviceplans.concat(appserviceplans, newItem);
    SetAppservicePlans(RemoveDuplicateAppserviceplans(appAppservicePlans));
  };
  const handleAddRow = () => {
    setRows([...rows, newRow]);
    setNewRow({ Component: '', AppservicePlan: '' });
  };

  const handleDeleteRow = (component: string) => {
    const filteredRows = rows.filter((row) => row.Component !== component);
    setRows(filteredRows);
  };
  const handleEditRow = (topology: Topology) => {
    setNewRow({
      Component: topology.Component,
      AppservicePlan: topology.AppservicePlan,
    });
    const filteredRows = rows.filter(
      (row) => row.Component !== topology.Component,
    );
    setRows(filteredRows);
    setEdit(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setNewRow((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target as HTMLInputElement;
    setNewRow((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSave = async () => {
    const json = JSON.stringify(rows);
    const newfarm = farm;
    newfarm!.Topology = '{"Topology" :' + json + '}';
    const farmRecieved = await topologyService.UpdateAppservicePlans(
      +params.FarmId,
      newfarm!,
    );
    parseJsonString(farmRecieved);
    toast.success('Topology Updated Successful!', {
      position: toast.POSITION.BOTTOM_CENTER as ToastPosition,
    });
    setSave(true);
    setEdit(false);
  };

  useEffect(() => {
    loadAppservice();
  }, [save]);
  const parseJsonString = (farmRecieved: Farm) => {
    if (farmRecieved.Topology) {
      const farmTopology: FarmTopology = JSON.parse(farmRecieved.Topology);
      const farmsAppservicesReceived = farmTopology.Topology.map(
        (a) => a.AppservicePlan,
      );
      setRows(farmTopology.Topology);
      SetAppservicePlans(
        RemoveDuplicateAppserviceplans(farmsAppservicesReceived),
      );
    }
  };
  const loadAppservice = async () => {
    try {
      const farmRecieved = await topologyService.getAppservicePlans(
        +params.FarmId,
      );
      setFarm(farmRecieved);
      parseJsonString(farmRecieved);
      setLoadingState(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingState(false);
    }
  };

  return (
    <Paper>
      <Box style={{ padding: '20px' }}>
        <Grid justifyContent="space-between" container>
          <Grid item>
            <Typography
              className={classes.typography}
              variant="h6"
              id="tableTitle"
            >
              Manage Topology For Farm {params.FarmId}
            </Typography>
          </Grid>

          <Grid item>
            <div>
              <SimpleDialogDemo addItemToState={addItemToState} />
            </div>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Component</TableCell>
                <TableCell>AppservicePlan</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.Component}>
                  <TableCell>{row.Component}</TableCell>
                  <TableCell>{row.AppservicePlan}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => handleDeleteRow(row.Component)}
                      className={classes.button}
                      startIcon={<DeleteIcon />}
                    ></Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled={edit}
                      variant="contained"
                      onClick={() => handleEditRow(row)}
                      className={classes.button}
                      startIcon={<EditIcon />}
                    ></Button>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <TextField
                    style={{ width: '200px', fontSize: '20px' }}
                    id="standard-helperText"
                    variant="standard"
                    name="Component"
                    value={newRow.Component}
                    onChange={handleInputChange}
                  />
                </TableCell>
                <TableCell>
                  <Select
                    style={{ width: '200px', fontSize: '20px' }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="AppservicePlan"
                    value={newRow.AppservicePlan}
                    onChange={handleChange}
                  >
                    {appserviceplans.map((appserviceplan) => {
                      return (
                        <MenuItem key={appserviceplan} value={appserviceplan}>
                          {appserviceplan}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    onClick={handleAddRow}
                    className={classes.button}
                    startIcon={<AddCircleIcon />}
                  ></Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box>
            <Grid justifyContent="flex-end" container>
              <div style={{ float: 'right', padding: '20px' }}>
                <Button
                  variant="contained"
                  onClick={handleSave}
                  className={classes.button}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </Box>
        </Paper>
      )}
      <ToastContainer />
    </Paper>
  );
};

export default DynamicTable;
