import { MenuItems } from '../../../Common/CommonModel';

interface BaseServer {
  ServerName: string;
  Region: string;
  UserName: string;
  AdminPassword: string;
  EnableElasticPool: boolean;
  EDtu: number;
  DataMaxSize: number;
  PerDbDtu: number;
}

export interface SqlServer extends BaseServer {
  ProductID: string;
  ResourceGroup: string;
  Subscription: string;
  Role: string;
  TenantCount: number;
  FailoverGroups: FailoverGroup[];
}

export interface FailoverGroup extends BaseServer {
  Name: string;
}

export interface SqlServerMenuItems extends MenuItems {}

export enum OperationType {
  Create,
  Edit,
}
