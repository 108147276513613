import React from 'react';
import { ThemeContext } from '../../../Theme/UseToggleDarkMode';
import { IconButton, Tooltip, useTheme } from '@mui/material';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

const TogglethemeControl = () => {
  const theme = useTheme();
  return (
    <ThemeContext.Consumer>
      {(toggleTheme) => (
        <IconButton onClick={toggleTheme}>
          <Tooltip
            title={theme.palette.mode === 'dark' ? 'Light Theme' : 'Dark Theme'}
          >
            {theme.palette.mode === 'dark' ? (
              <LightModeIcon color="secondary" />
            ) : (
              <DarkModeIcon color="secondary" />
            )}
          </Tooltip>
        </IconButton>
      )}
    </ThemeContext.Consumer>
  );
};

export default TogglethemeControl;
