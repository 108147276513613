import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { PagedData } from '../../Common/CommonModel';
import {
  ClientSecretMetaData,
  ClientSecrete,
  MessageGroup,
  UserMessageGroup,
} from '../ClientSecrete/Model/ClientSecretesModel';

class ClientSecretService {
  async setClientSecret(
    customerId: string,
    description: string,
  ): Promise<ClientSecrete> {
    const route = `${customerId}/clientsecret/${description}`;
    const result = await callService('clientsecret', route, RequestType.POST);
    return result;
  }
  async GetClientSecretMetadata(
    customerId: string,
  ): Promise<PagedData<ClientSecretMetaData>> {
    const route = `${customerId}/clientsecrets`;
    const result = callService('clientsecret', route, RequestType.GET);
    return result;
  }
  async UpdateClientActiveness(
    customerId: string,
    clientId: string,
    isActive: boolean,
  ): Promise<boolean> {
    const route = `${customerId}/clientsecrets/activateorinactivate/${clientId}/${isActive}`;
    const result = callService('clientsecret', route, RequestType.PUT);
    return result;
  }
  async GetRefreshSecret(
    customerId: string,
    clientId: string,
  ): Promise<ClientSecrete> {
    const route = `${customerId}/clientsecret/clientid/${clientId}`;
    const result = await callService('clientsecret', route, RequestType.PUT);
    return result;
  }
  async GetAvailableGroups(
    customerId: string,
    clientId: string,
    pageNo: number,
  ): Promise<PagedData<MessageGroup>> {
    const route = `${customerId}/clientsecret/clientid/${clientId}/Groups/Available?pageNo=${pageNo}`;
    const result = await callService('clientsecret', route, RequestType.GET);
    return result;
  }
  async AddGroupsToClient(
    customerId: string,
    clientId: string,
    clientGroups: MessageGroup[],
  ): Promise<string> {
    const route = `${customerId}/clientsecret/clientid/${clientId}/AddGroups`;
    const result = await callService(
      'clientsecret',
      route,
      RequestType.POST,
      clientGroups,
    );
    return result;
  }
  async GetClientGroups(
    customerId: string,
    clientId: string,
    pageNo: number,
  ): Promise<PagedData<UserMessageGroup>> {
    const route = `${customerId}/clientsecret/clientid/${clientId}/Groups?pageNo=${pageNo}`;
    const result = await callService('clientsecret', route, RequestType.GET);
    return result;
  }

  async GetAvailableGroupsCount(
    customerId: string,
    clientId: string,
  ): Promise<number> {
    const route = `${customerId}/clientsecret/clientid/${clientId}/Groups/Available/Count`;
    const result = await callService('clientsecret', route, RequestType.GET);
    return result;
  }
  async GetClientGroupsCount(
    customerId: string,
    clientId: string,
  ): Promise<number> {
    const route = `${customerId}/clientsecret/clientid/${clientId}/Groups/Count`;
    const result = await callService('clientsecret', route, RequestType.GET);
    return result;
  }
  async RemoveGroupsFromClient(
    customerId: string,
    clientId: string,
    clientGroups: number[],
  ): Promise<PagedData<UserMessageGroup>> {
    const route = `${customerId}/clientsecret/clientid/${clientId}/Groups/Delete`;
    const result = await callService(
      'clientsecret',
      route,
      RequestType.DELETE,
      clientGroups,
    );
    return result;
  }
  async SearchAvailableGroups(
    customerId: string,
    clientId: string,
    searchString: string,
    pageNo: number,
  ): Promise<PagedData<MessageGroup>> {
    const route = `${customerId}/clientsecret/clientid/${clientId}/Groups/Search?searchString=${searchString}&pageNo=${pageNo}`;
    const result = await callService('clientsecret', route, RequestType.GET);
    return result;
  }
}

export const clientsecretService: ClientSecretService =
  new ClientSecretService();
