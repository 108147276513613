/* eslint-disable @typescript-eslint/no-unused-vars */
import { DateObject } from 'react-multi-date-picker';
import { Licenses } from '../LicenseModule/PurchaseOrders/Model/PurchaseOrderModel';
import { Order } from './Literals';
import moment from 'moment';
import { FailoverGroup } from '../Components/Layout/SqlServer/Model/SqlServerModel';

export function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
    return descString(
      String(a[orderBy]).toLowerCase(),
      String(b[orderBy]).toLowerCase(),
    );
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function descString(aValue: string, bValue: string) {
  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

export function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K,
): (
  a: {
    [key in K]:
      | number
      | string
      | Date
      | boolean
      | string[]
      | Licenses[]
      | FailoverGroup[];
  },
  b: {
    [key in K]:
      | number
      | string
      | Date
      | boolean
      | string[]
      | Licenses[]
      | FailoverGroup[];
  },
) => number {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

export const getLocalDate = (serverDate: Date | string | undefined) => {
  if (serverDate) {
    return moment(serverDate).format('DD MMM, YYYY, hA');
  } else {
    return '';
  }
};

const formatBytesToMB = (bytes: number, decimals = 2) => {
  if (bytes === 0) return 0;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  return parseFloat((bytes / Math.pow(k, 2)).toFixed(dm));
};

const formatBytesToString = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const valueConverter: { [value: string]: (value: string) => any } = {
  bytes: (value: string) => formatBytesToMB(Number(value)),
  date: (value: string) => moment(new Date(value)).format('lll'),
  number: (value: string) => (value === '' ? 0 : Number(value)),
};

export const toStringValueConverter: {
  [value: string]: (value: string) => string;
} = {
  bytes: (value: string) => formatBytesToString(Number(value)),
  date: (value: string) => moment(new Date(value)).format('lll'),
  number: (value: string) => (value === '' ? '0' : value),
};

export const capitalize = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const truncate = (stringToTruncate: string, length: number) => {
  return stringToTruncate.length > length
    ? stringToTruncate.substr(0, length - 1) + '...'
    : stringToTruncate;
};

export const capitalizeWords = (sentence: string): string => {
  const words = sentence.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
};

export const getNumberOfWeek = () => {
  const today: Date = new Date();
  const isoWeekNumber: number = moment(today).isoWeek();
  return isoWeekNumber;
};

export function idToColor(id: string) {
  let hash = 0;
  for (let i = 0; i < id.length; i++) {
    hash = id.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += ('00' + value.toString(16)).substr(-2);
  }
  return color;
}

export const getStandardDateFormat = (date: DateObject) => {
  return date.format('YYYY-MM-DD');
};
