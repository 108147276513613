import { RequestType } from '../../../Service/RequestType';
import { callService } from '../../../Service/ServiceBase';
import { PagedData } from '../../Common/CommonModel';
import { Audit, PortalUser } from './Model/AuditModel';

class AuditService {
  async GetAuditLogs(queryString: string): Promise<PagedData<Audit>> {
    const proarcinsight = await callService(
      'audits',
      queryString,
      RequestType.GET,
    );
    return proarcinsight;
  }
  async GetAuditPerformers(): Promise<PagedData<PortalUser>> {
    const proarcinsight = await callService(
      'audits',
      'performer',
      RequestType.GET,
    );
    return proarcinsight;
  }
}
export const auditService: AuditService = new AuditService();
