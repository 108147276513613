import React, { FC, useEffect, useState } from 'react';
import { useStyles } from '../../../../Common/Styles/FarmStyle';
import { useStyles as wrapperStyles } from '../../../../Common/Styles/WraperStyle';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Slider,
  TextField,
} from '@mui/material';
import {
  FailoverGroup,
  OperationType,
  SqlServer,
} from '../Model/SqlServerModel';
import { ValidationErrors } from 'fluentvalidation-ts';
import { Add, Cancel, SaveAs } from '@mui/icons-material';
import { DialogOptions } from '../../Monitoring/Model/MonitoringModel';
import { FailoverGroupValidator } from '../Validation/FailoverGroupValidator';
import { valueToText } from '../../../../Common/CommonMethods';
import { validDMS, validDTUs } from '../Model/SqlServerData';
import failoverValidtionRules from '../Validation/failovergroupValidationRules.json';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface AddFailoverProps {
  sqlserver: SqlServer;
  failovergroup: FailoverGroup;
  setFailovergroup: (value: React.SetStateAction<FailoverGroup>) => void;
  regions: string[];
  open: boolean;
  handleDialogueOptions: (value: DialogOptions) => void;
  operationType: OperationType;
}

export const FailoverGroupForm: FC<AddFailoverProps> = ({
  sqlserver,
  failovergroup,
  setFailovergroup,
  regions,
  open,
  handleDialogueOptions,
  operationType,
}) => {
  const classes = useStyles();
  const wrapper = wrapperStyles();
  const [failoverErrors, setFailoverErrors] = useState<
    ValidationErrors<FailoverGroup>
  >({});
  const [showPassword, setShowPassword] = useState(false);

  const failoverGroupValidator = new FailoverGroupValidator();

  const validateFailoverGroup = () => {
    const failoverErrors = failoverGroupValidator.validate(failovergroup);
    if (sqlserver.ServerName === failovergroup.ServerName) {
      failoverErrors.ServerName = failoverValidtionRules.Server;
    }
    if (sqlserver.Region === failovergroup.Region) {
      failoverErrors.Region = failoverValidtionRules.Region;
    }
    setFailoverErrors(failoverErrors);
  };

  const validate = () => {
    validateFailoverGroup();
  };

  const handleSliderChange =
    (field: string) => (event: Event, value: number | number[]) => {
      setFailovergroup((prev) => ({ ...prev, [field]: value as number }));
    };

  useEffect(() => {
    validate();
  }, [failovergroup]);
  return (
    <>
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth="xl"
        open={open}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Failover Group</DialogTitle>
        <Divider className={wrapper.divider} />
        <DialogContent>
          <div className={classes.outerContainer}>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card elevation={0} className={classes.card}>
                  <h3>Basic information</h3>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}> Failover group name</span>
                    <TextField
                      className={classes.textField}
                      error={!!failoverErrors.Name}
                      helperText={failoverErrors.Name}
                      value={failovergroup.Name}
                      placeholder="failover group name"
                      name="Name"
                      onChange={(e) =>
                        setFailovergroup((failovergroup) => ({
                          ...failovergroup,
                          Name: e.target.value,
                        }))
                      }
                      onBlur={validate}
                    />
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}> Server Name</span>
                    <TextField
                      className={classes.textField}
                      error={!!failoverErrors.ServerName}
                      helperText={failoverErrors.ServerName}
                      value={failovergroup.ServerName}
                      placeholder="Server Name"
                      name="ServerName"
                      onChange={(e) =>
                        setFailovergroup((failovergroup) => ({
                          ...failovergroup,
                          ServerName: e.target.value,
                        }))
                      }
                      onBlur={validate}
                    />
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}> User Name</span>
                    <TextField
                      className={classes.textField}
                      error={!!failoverErrors.UserName}
                      helperText={failoverErrors.UserName}
                      value={failovergroup.UserName}
                      placeholder="User Name"
                      name="UserName"
                      onChange={(e) =>
                        setFailovergroup((failovergroup) => ({
                          ...failovergroup,
                          UserName: e.target.value,
                        }))
                      }
                      onBlur={validate}
                    />
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Admin Password</span>
                    <TextField
                      className={classes.textField}
                      error={!!failoverErrors.AdminPassword}
                      helperText={failoverErrors.AdminPassword}
                      value={failovergroup.AdminPassword}
                      placeholder="Admin Password"
                      name="AdminPassword"
                      onChange={(e) =>
                        setFailovergroup((failovergroup) => ({
                          ...failovergroup,
                          AdminPassword: e.target.value,
                        }))
                      }
                      onBlur={validate}
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}> Region</span>
                    <TextField
                      className={classes.textField}
                      error={!!failoverErrors.Region}
                      helperText={failoverErrors.Region}
                      value={failovergroup.Region}
                      placeholder="Region"
                      name="Region"
                      onChange={(e) =>
                        setFailovergroup((failovergroup) => ({
                          ...failovergroup,
                          Region: e.target.value,
                        }))
                      }
                      onBlur={validate}
                      select
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            style: {
                              maxHeight: 350,
                            },
                          },
                        },
                      }}
                    >
                      {regions.map((region) => (
                        <MenuItem key={region} value={region}>
                          {region}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Card elevation={0} className={classes.card}>
                  <h3>Additional Configuration</h3>
                  <div>
                    <div className={classes.spanContainer}>
                      <span className={classes.span}>Enable Elastic Pool</span>
                      <div className={classes.textField}>
                        <Checkbox
                          sx={{ float: 'left' }}
                          id="Enable Elastic Pool"
                          value={failovergroup.EnableElasticPool ?? false}
                          checked={failovergroup.EnableElasticPool ?? false}
                          name="EnableElasticPool"
                          onChange={(e) =>
                            setFailovergroup((failovergroup) => ({
                              ...failovergroup,
                              EnableElasticPool: e.target.checked,
                            }))
                          }
                          onBlur={validate}
                        />
                      </div>
                    </div>
                    {failovergroup.EnableElasticPool && (
                      <>
                        <div className={classes.spanContainer}>
                          <span className={classes.span}>eDTUs</span>
                          <Box
                            sx={{ display: 'flex', alignItems: 'center' }}
                            className={classes.textField}
                          >
                            <Slider
                              aria-label="Small steps"
                              defaultValue={failovergroup.EDtu}
                              getAriaValueText={valueToText}
                              step={null}
                              marks={validDTUs}
                              min={50}
                              max={3000}
                              onChange={handleSliderChange('EDtu')}
                              valueLabelDisplay="auto"
                            />
                            <TextField
                              sx={{ marginLeft: '20px', width: '80px' }}
                              error={!!failoverErrors.EDtu}
                              helperText={failoverErrors.EDtu}
                              value={failovergroup.EDtu}
                              onBlur={validate}
                            />
                          </Box>
                        </div>
                        <div className={classes.spanContainer}>
                          <span className={classes.span}>
                            Data max size (GB)
                          </span>
                          <Box
                            sx={{ display: 'flex', alignItems: 'center' }}
                            className={classes.textField}
                          >
                            <Slider
                              aria-label="Small steps"
                              defaultValue={failovergroup.DataMaxSize}
                              getAriaValueText={valueToText}
                              marks={validDMS}
                              step={null}
                              min={50}
                              max={3000}
                              onChange={handleSliderChange('DataMaxSize')}
                              valueLabelDisplay="auto"
                            />
                            <TextField
                              sx={{ marginLeft: '20px', width: '80px' }}
                              error={!!failoverErrors.DataMaxSize}
                              helperText={failoverErrors.DataMaxSize}
                              value={failovergroup.DataMaxSize}
                              onBlur={validate}
                            />
                          </Box>
                        </div>
                        <div className={classes.spanContainer}>
                          <span className={classes.span}>
                            Per database DTUs
                          </span>
                          <Box
                            sx={{ display: 'flex', alignItems: 'center' }}
                            className={classes.textField}
                          >
                            <Slider
                              aria-label="Small steps"
                              defaultValue={failovergroup.PerDbDtu}
                              getAriaValueText={valueToText}
                              step={null}
                              min={0}
                              max={failovergroup.EDtu}
                              marks={validDTUs}
                              onChange={handleSliderChange('PerDbDtu')}
                              valueLabelDisplay="auto"
                            />
                            <TextField
                              sx={{ marginLeft: '20px', width: '80px' }}
                              error={!!failoverErrors.PerDbDtu}
                              helperText={failoverErrors.PerDbDtu}
                              value={failovergroup.PerDbDtu}
                              onBlur={validate}
                            />
                          </Box>
                        </div>
                      </>
                    )}
                  </div>
                </Card>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <Divider className={wrapper.divider} />
        <DialogActions>
          {operationType === OperationType.Edit ? (
            <Button
              className={wrapper.smallbutton}
              variant="contained"
              disabled={Object.keys(failoverErrors).length > 0}
              onClick={() => handleDialogueOptions(DialogOptions.Ok)}
              startIcon={<SaveAs />}
            >
              {OperationType[operationType]}
            </Button>
          ) : (
            <Button
              className={wrapper.smallbutton}
              variant="contained"
              disabled={Object.keys(failoverErrors).length > 0}
              onClick={() => handleDialogueOptions(DialogOptions.Ok)}
              startIcon={<Add />}
            >
              {OperationType[operationType]}
            </Button>
          )}

          <Button
            variant="contained"
            onClick={() => handleDialogueOptions(DialogOptions.Cancel)}
            className={wrapper.smallbutton}
            startIcon={<Cancel />}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
