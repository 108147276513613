import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Fab } from '@mui/material';
import { useStyles } from '../../../Common/Styles/Theme';
import { MenuFabProps } from './FabProps';

const MenuFab = (props: MenuFabProps) => {
  const classes = useStyles();
  return (
    <Fab
      color="primary"
      aria-label="add"
      size="small"
      className={classes.fab}
      onClick={() => props.setShowMenus(!props.showMenus)}
    >
      {props.showMenus ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
    </Fab>
  );
};

export default MenuFab;
