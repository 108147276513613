import React, { Component } from 'react';
import { OnCallUser } from '../Model/MonitoringModel';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { Card, CardContent, CircularProgress, Typography } from '@mui/material';
import { getNumberOfWeek } from '../../../../Helpers/UtilityMethods';

interface Props extends WithStyles<typeof styles> {
  onCallUserOfTheWeek: OnCallUser;
  loadingOnCallUser: boolean;
}

interface OnCallOfWeekState {
  loading: boolean;
}

const styles = createStyles({
  card: {
    maxWidth: 300,
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  propgress: {
    marginLeft: '40%',
    marginTop: '15%',
  },
});

class OnCallOfTheWeek extends Component<Props, OnCallOfWeekState> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { onCallUserOfTheWeek, loadingOnCallUser, classes } = this.props;
    const { card, propgress, title, pos } = classes;
    return (
      <Card className={card}>
        <CardContent>
          {loadingOnCallUser ? (
            <CircularProgress className={propgress} />
          ) : (
            <div>
              <Typography className={title} color="textSecondary" gutterBottom>
                On call for the week number {getNumberOfWeek()}:
              </Typography>
              <Typography variant="h5" component="h2">
                {onCallUserOfTheWeek.Name}
              </Typography>
              <Typography className={pos} color="textSecondary">
                {onCallUserOfTheWeek.EmailAddress}
              </Typography>
              <Typography component="p">
                Can be reached at:
                <br />
                {onCallUserOfTheWeek.PhoneNumber}
              </Typography>
            </div>
          )}
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(styles)(OnCallOfTheWeek);
