import { RequestType } from '../../../../Service/RequestType';
import { callService } from '../../../../Service/ServiceBase';
import { Tenant } from '../../Farm/Model/FarmModel';
import { TenantRestartMetaData } from '../Model/Model';

class TenantService {
  async GetTenant(tenantId: string): Promise<Tenant> {
    const route = `${tenantId}`;
    const tenant = await callService('tenant', route, RequestType.GET);
    return tenant;
  }
  RestartTenant(
    tenantId: string,
    RestartMetaData: TenantRestartMetaData,
  ): Promise<boolean> {
    const route = `${tenantId}/restart`;
    return callService('tenant', route, RequestType.POST, RestartMetaData);
  }
  async GetTenantRunningStatus(tenantId: string): Promise<string> {
    const route = `${tenantId}/status`;
    const tenant = await callService('tenant', route, RequestType.GET);
    return tenant;
  }
}
export const tenantService: TenantService = new TenantService();
