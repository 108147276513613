import React, { useState, useEffect, ChangeEvent, useContext } from 'react';
import {
  List,
  ListItemText,
  Icon,
  CircularProgress,
  Paper,
  Typography,
  InputBase,
  alpha,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  IconButton,
  ListItemButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ReactComponent as OkIcon } from '../../../../assets/icons/Ok.svg';
import { ReactComponent as FailedIcon } from '../../../../assets/icons/HighPriority.svg';
import { ReactComponent as NotDeployed } from '../../../../assets/icons/MediumPriority.svg';
import { Dictionary } from '../../../Common/CommonModel';
import { customerService } from '../../Customer/CustomerService';
import { Tenant } from '../../Farm/Model/FarmModel';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import '../../../../Helpers/Extensions';
import { truncate } from '../../../../Helpers/UtilityMethods';
import {
  TriggerDetails,
  TriggerProps,
  TriggerDispatchContext,
  initialTriggerState,
  TriggerConstants,
} from '../Model';
import ConfirmationDialog from '../../../Common/Dialog/ConfirmationDialog';
import PermissionConstants from '../../../Common/Constants/PermissionConstants';
import { PermissionsContext } from '../../UIContainer/PermissionsWrapper';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    maxWidth: 360,
    minWidth: 300,
  },
  progress: {
    marginLeft: '50%',
    marginTop: '30%',
    color: theme.palette.primary.light.toString(),
  },
  imageIcon: {
    height: '100%',
  },
  iconRoot: {
    textAlign: 'center',
  },
  paper: {
    width: '100%',
    height: '80vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  listItem: {
    padding: theme.spacing(1, 1, 1, 2),
  },
  listItemIcon: {
    minWidth: '35px',
  },
  title: {
    padding: theme.spacing(0, 0, 1, 0),
    flex: '0 0 auto',
  },
  search: {
    position: 'relative',
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.05),
    },
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    margin: theme.spacing(1, 1, 1, 7),
    width: '100%',
  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing(),
  },
}));

interface IconComponents {
  [key: number]: JSX.Element;
}

interface DeploymentStates {
  [key: number]: string;
}

export default function TriggerBlade(props: Readonly<TriggerProps>) {
  const stringTruncateLength = 22;
  const {
    customerId,
    triggers,
    contentChanged,
    getQueryCallback,
    dropTriggerCallback,
  } = props;
  const classes = useStyles();

  const [selectedTrigger, setSelectedTrigger] =
    useState<TriggerDetails>(initialTriggerState);
  const [currentTrigger, setCurrentTrigger] =
    useState<TriggerDetails>(initialTriggerState);
  const [triggersCopy, setTriggersCopy] = useState<TriggerDetails[]>([]);
  const [iconsLiteral, setIconsLiteral] = useState<IconComponents>({});
  const [deploymentState, setTriggerDeploymentState] =
    useState<DeploymentStates>({});
  const [triggerBeingDropped, setDroppingTriggerId] = useState(-1);
  const [tenant, setTenant] = useState<Tenant>({
    Id: '',
    ApplicationUrl: '',
    FarmId: 0,
    AppDBName: '',
    StatusID: 0,
    AppservicePlan: '',
  });

  const [showConfirmation, setShowConfirmationDialog] = useState(false);
  const [confirmationMessage, setConfirmationDialogMessage] = useState('');

  const [showTriggerChangeConfirmation, setShowTriggerChangeConfirmation] =
    useState(false);
  const [
    triggerChangeConfirmationMessage,
    setTriggerChangeConfirmationMessage,
  ] = useState('');

  const permissions = useContext(PermissionsContext);
  const dispatch = useContext(TriggerDispatchContext);

  useEffect(() => {
    loadActiveTenant();
    createIconLiteral();
    setDeploymentState();
    setTriggersCopy(triggers);
  }, [triggers]);

  const createIconLiteral = () => {
    const icons: IconComponents = {};
    icons[1] = <OkIcon />;
    icons[2] = <FailedIcon />;
    icons[3] = <NotDeployed />;
    icons[4] = <Icon>delete_forever</Icon>;
    setIconsLiteral(icons);
  };

  const setDeploymentState = () => {
    const deploymentState: Dictionary<string> = {};
    deploymentState[1] = 'Trigger deployed successfully';
    deploymentState[2] = 'Trigger deployment failed';
    deploymentState[3] = 'Trigger is not deployed';
    deploymentState[4] = 'Trigger was dropped';
    setTriggerDeploymentState(deploymentState);
  };

  const loadActiveTenant = async () => {
    const activeTenant = await customerService.getTenant(customerId, true);
    setTenant(activeTenant.Items[0]);
  };

  const getTenantSubDomain = (applicationUrl: string) => {
    if (applicationUrl !== '') {
      const url = new URL(applicationUrl);
      const subDomain = url.host.split('.')[0];
      return subDomain;
    }
    return '';
  };

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    const searchFilterText = event.target.value;
    const filteredTriggers = triggers.filter(
      (trigger) =>
        trigger.Name.toLowerCase().indexOf(searchFilterText.toLowerCase()) >= 0,
    );
    setTriggersCopy(filteredTriggers);
  };

  const getQueryAndDispatch = async (trigger: TriggerDetails) => {
    setSelectedTrigger(trigger);
    if (currentTrigger.Id === 0) {
      setCurrentTrigger(trigger);
    }
    if (currentTrigger.Id !== trigger.Id && contentChanged) {
      setShowTriggerChangeConfirmation(true);
      setTriggerChangeConfirmationMessage(
        TriggerConstants.TriggerContentChangesMessage,
      );
    } else if (currentTrigger.Id === trigger.Id) {
      // if same trigger is selected do nothing
    } else {
      setCurrentTrigger(trigger);
      dispatch({ type: 'triggerData', payload: trigger });
      await getQueryCallback(trigger.Id);
    }
  };

  const dispatchTriggerChange = async () => {
    setTriggerChangeConfirmationMessage('');
    setShowTriggerChangeConfirmation(false);
    setCurrentTrigger(selectedTrigger);
    dispatch({ type: 'triggerData', payload: selectedTrigger });
    await getQueryCallback(selectedTrigger.Id);
  };

  const preventTriggerSelectionChange = () => {
    setSelectedTrigger(currentTrigger);
    setTriggerChangeConfirmationMessage('');
    setShowTriggerChangeConfirmation(false);
  };

  const handleTriggerDrop = (trigger: TriggerDetails) => {
    const deleteMessage =
      TriggerConstants.TriggerDropConfirmationMessage.format(trigger.Name);
    setDroppingTriggerId(trigger.Id);
    setConfirmationDialogMessage(deleteMessage);
    setShowConfirmationDialog(true);
  };

  const setConfirmationValuesToDefault = () => {
    setDroppingTriggerId(-1);
    setShowConfirmationDialog(false);
    setConfirmationDialogMessage('');
  };

  const dropTrigger = async () => {
    await dropTriggerCallback(triggerBeingDropped);
    setConfirmationValuesToDefault();
  };

  const hasTriggeWritePermission = (): boolean => {
    return permissions[PermissionConstants.WriteTrigger] ? true : false;
  };

  return (
    <div className={classes.root}>
      <div>
        <Typography
          className={classes.title}
          variant="subtitle1"
          id="tableTitle"
        >
          Available triggers for {getTenantSubDomain(tenant.ApplicationUrl)}
        </Typography>
        <Paper className={classes.paper}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search for trigger"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'Search' }}
              onChange={handleFilter}
            />
          </div>
          <List component="nav" aria-label="main mailbox folders">
            {triggersCopy.map((trigger) => (
              <ListItemButton
                className={classes.listItem}
                key={trigger.Id}
                onClick={() => getQueryAndDispatch(trigger)}
                selected={selectedTrigger.Id === trigger.Id}
              >
                <ListItemAvatar>
                  <Tooltip title={deploymentState[trigger.DeploymentState]}>
                    <Avatar>
                      <Icon className={classes.iconRoot}>
                        {iconsLiteral[trigger.DeploymentState]}
                      </Icon>
                    </Avatar>
                  </Tooltip>
                </ListItemAvatar>
                <ListItemText>
                  {truncate(trigger.Name, stringTruncateLength)}
                </ListItemText>
                <ListItemSecondaryAction>
                  {triggerBeingDropped === trigger.Id ? (
                    <CircularProgress
                      className={classes.circularProgress}
                      size={20}
                    />
                  ) : (
                    hasTriggeWritePermission() && (
                      <Tooltip title="drop trigger">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => handleTriggerDrop(trigger)}
                          size="large"
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </Tooltip>
                    )
                  )}
                </ListItemSecondaryAction>
              </ListItemButton>
            ))}
          </List>
        </Paper>
        <ConfirmationDialog
          open={showConfirmation}
          message={confirmationMessage}
          onConfirmHandler={dropTrigger}
          onCancelHandler={setConfirmationValuesToDefault}
          onCloseHandler={setConfirmationValuesToDefault}
        />
        <ConfirmationDialog
          open={showTriggerChangeConfirmation}
          message={triggerChangeConfirmationMessage}
          onConfirmHandler={dispatchTriggerChange}
          onCancelHandler={preventTriggerSelectionChange}
          onCloseHandler={preventTriggerSelectionChange}
        />
      </div>
    </div>
  );
}
