import React, { ChangeEvent, useEffect, useState, ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Tooltip,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  CircularProgress,
  Button,
  Backdrop,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { licenseService } from '../../Api/LicenseService';
import { LicenseSummary } from '../Model/LicenseModel';
import TableHeader from '../Views/Components/TableHeader';
import { Refresh, Edit } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Order } from '../../../Helpers/Literals';
import { getSorting, stableSort } from '../../../Helpers/UtilityMethods';
import Link from '@mui/material/Link';
import { toast } from 'react-toastify';
import {
  LicenseWriter,
  LicenseHomeTitleConstant,
  HomeFetchLiveLicenseButtonLabel,
  HomeCurrentUsageTooltip,
  HomeTotalPurchasedTooltip,
  HomeFetchLiveLicenseButtonToolTip,
  HomeLiveLicenseFetchSuccess,
  HomeLiveLicenseFetchError,
  HomeLiveLicenseFetchWarning,
  HomeLiveLicenseSingleTenantSuccessFormat,
  HomeSingleTenantFetchLiveLicenseButtonToolTip,
  ConfirmationForRefreshUsageConstant,
  UnlicensedLabelConstant,
} from '../Model/LicenseConstants';
import { useStyles } from '../../../Common/Styles/Theme';

export default function LicenseHome() {
  const classes = useStyles();
  const initialLicenseState: LicenseSummary[] = [];
  const history = useHistory();

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof LicenseSummary>('Subdomain');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [licenses, setLicenses] = useState(initialLicenseState);
  const [licensesCopy, setLicensesCopy] = useState(initialLicenseState);
  const [loading, setLoadingState] = useState(true);
  const [hasWritePermission, setWritePermissions] = useState(false);
  const [backDropProgressRing, setBackDropProgressRing] = useState(false);
  const [confirmationDialogProps, setConfirmationDialogProps] = useState({
    isOpen: false,
    message: <></>,
  });

  useEffect(() => {
    if (loading) {
      licenseService.getAllLicenseSummaries().then((Licenses) => {
        for (const licenseSummary of Licenses.Items)
          updateConsumptionData(licenseSummary);
        setLicenses(Licenses.Items);
        setLicensesCopy(Licenses.Items);
        const userPermissions = JSON.parse(
          localStorage.getItem('userPermissions') || '{}',
        );
        for (const element of userPermissions) {
          if (element.PermissionName === LicenseWriter) {
            setWritePermissions(true);
            break;
          }
        }
        setLoadingState(false);
      });
    }
  }, [loading]);

  const handleFilter = (searchFilterText: string) => {
    const filteredLC = licensesCopy.filter(function (LC) {
      return (
        LC.CustomerName.toLowerCase().indexOf(searchFilterText.toLowerCase()) >=
        0
      );
    });
    setLicenses(filteredLC);
  };

  const handleSearchFilter = () => (event: ChangeEvent<HTMLInputElement>) => {
    handleFilter(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const baseRowClick = (licenseSummary: LicenseSummary) => {
    const customerName = JSON.stringify(licenseSummary.CustomerName);
    localStorage.setItem('customerName', customerName);
  };

  const handleRowClick = (licenseSummary: LicenseSummary) => {
    baseRowClick(licenseSummary);
    history.push(`/purchaseorders/${licenseSummary.TenantId}`);
  };

  const manageCustomerLicenses = (tenantId: string) => {
    history.push(`/license/manage/${tenantId}`);
  };

  const manageModuleLicenses = (tenantId: string) => {
    history.push(`/license/module/${tenantId}`);
  };

  const totalLicenseCount = (param: LicenseSummary) => {
    return param.StandardUsersLicenses + param.ReadUsersLicenses;
  };

  const totalLicenseUsageCount = (param: LicenseSummary) => {
    return (
      param.StandardUsersLicensesUsage +
      param.AdminUsersLicensesUsage +
      param.ReadUsersLicensesUsage
    );
  };

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof LicenseSummary,
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const createLicenseTableCellWithTooltip = (
    usageCount: number,
    totalLicenseCount: number,
  ): ReactElement => {
    return (
      <Tooltip
        title={
          <div>
            <span>
              {HomeCurrentUsageTooltip}
              {usageCount}
            </span>
            <br />
            <span>
              {HomeTotalPurchasedTooltip}
              {totalLicenseCount}
            </span>
          </div>
        }
      >
        <span>
          {usageCount}/{totalLicenseCount}
        </span>
      </Tooltip>
    );
  };

  const createStorageLicenseTableCellWithTooltip = (
    usageCount: number,
    totalLicenseCount: number,
  ): ReactElement => {
    return (
      <Tooltip
        title={
          <div>
            <span>
              {HomeCurrentUsageTooltip}
              {usageCount}
              {' GB'}
            </span>
            <br />
            <span>
              {HomeTotalPurchasedTooltip}
              {totalLicenseCount}
              {' GB'}
            </span>
          </div>
        }
      >
        <span>
          {usageCount}/{totalLicenseCount}
        </span>
      </Tooltip>
    );
  };

  const fetchLiveLicenseUsage = () => {
    setBackDropProgressRing(true);

    licenseService
      .fetchLiveLicenseUsage()
      .then((response) => {
        let failedCustomerNames: string = '';
        response.Items.forEach((customer) => {
          if (failedCustomerNames.length > 0) failedCustomerNames += ', ';
          failedCustomerNames += `${customer.CustomerName}`;
        });
        if (failedCustomerNames.length > 0)
          toast.error(HomeLiveLicenseFetchWarning);
        else toast.success(HomeLiveLicenseFetchSuccess);

        setLoadingState(true);
      })
      .catch(() => {
        toast.error(HomeLiveLicenseFetchError);
      })
      .finally(() => {
        setBackDropProgressRing(false);
      });
  };

  const updateConsumptionData = (
    licenseSummary: LicenseSummary,
  ): LicenseSummary => {
    const total = totalLicenseCount(licenseSummary);
    const consumption =
      total !== 0
        ? Math.round((totalLicenseUsageCount(licenseSummary) / total) * 100)
        : Number.MAX_VALUE;
    licenseSummary['Consumption'] = consumption;
    return licenseSummary;
  };

  const fetchLiveLicenseUsageForTenant = (
    customerId: string,
    customerName: string,
  ) => {
    setBackDropProgressRing(true);
    licenseService
      .fetchLiveLicenseUsageForTenant(customerId)
      .then((licenseSummary) => {
        toast.success(
          `${HomeLiveLicenseSingleTenantSuccessFormat}${customerName}`,
        );

        const indexToUpdate = licenses.findIndex(
          (x) => x.TenantId === customerId,
        );
        if (indexToUpdate !== -1)
          licenses[indexToUpdate] = updateConsumptionData(licenseSummary);
      })
      .catch(() => {
        toast.error(HomeLiveLicenseFetchError);
      })
      .finally(() => {
        setBackDropProgressRing(false);
      });
  };

  const showProgressBackDropElement = (): ReactElement => {
    return (
      <Backdrop className={classes.Backdrop} open={backDropProgressRing}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const closeConfirmationDialog = () => {
    setConfirmationDialogProps({ isOpen: false, message: <></> });
  };

  const stopDefaultBehaviour = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    licenseSummary: LicenseSummary,
  ) => {
    event.stopPropagation();
    baseRowClick(licenseSummary);
  };

  const handleCustomerLicenseEvent = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    licenseSummary: LicenseSummary,
  ) => {
    stopDefaultBehaviour(event, licenseSummary);
    manageCustomerLicenses(licenseSummary.TenantId);
  };

  const handleModuleLicensesEvent = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    licenseSummary: LicenseSummary,
  ) => {
    stopDefaultBehaviour(event, licenseSummary);
    manageModuleLicenses(licenseSummary.TenantId);
  };

  const handlefetchLiveLicenseUsageForTenantEvent = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    licenseSummary: LicenseSummary,
  ) => {
    stopDefaultBehaviour(event, licenseSummary);
    fetchLiveLicenseUsageForTenant(
      licenseSummary.TenantId,
      licenseSummary.CustomerName,
    );
  };

  const confirmationDialogElement = (): ReactElement => {
    return (
      <Dialog
        disableEscapeKeyDown
        maxWidth="xs"
        open={confirmationDialogProps.isOpen}
        onClose={() => {
          closeConfirmationDialog();
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmationDialogProps.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActionButton}>
          <Button
            onClick={() => {
              closeConfirmationDialog();
              fetchLiveLicenseUsage();
            }}
            color="primary"
          >
            {ConfirmationForRefreshUsageConstant.confirmButtonLabel}
          </Button>
          <Button
            onClick={() => {
              closeConfirmationDialog();
            }}
            color="primary"
            autoFocus
          >
            {ConfirmationForRefreshUsageConstant.cancelButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const createTotalLicenseCountRow = (
    licenseSummary: LicenseSummary,
  ): ReactElement => {
    const totalUsageCount = totalLicenseUsageCount(licenseSummary);
    const totalCount = totalLicenseCount(licenseSummary);

    return (
      <TableCell
        align="center"
        className={totalUsageCount > totalCount ? classes.Cellhighlight : ''}
      >
        {createLicenseTableCellWithTooltip(totalUsageCount, totalCount)}
      </TableCell>
    );
  };

  return (
    <div>
      {loading ? (
        <CircularProgress className={classes.Progressbar} />
      ) : (
        <>
          <Paper>
            <Toolbar disableGutters={true} className={classes.Root}>
              <div className={classes.Title}>
                <Typography variant="h6" id="tableTitle">
                  {LicenseHomeTitleConstant}
                </Typography>
              </div>
              <div className={classes.Space} />
              <div className={classes.SearchOptions}>
                <div className={classes.SearchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.RootInput,
                    input: classes.input,
                  }}
                  inputProps={{ 'aria-label': 'Search' }}
                  onChange={handleSearchFilter()}
                />
              </div>
              <div className={classes.RefreshLicenseButton}>
                <Tooltip title={HomeFetchLiveLicenseButtonToolTip}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setConfirmationDialogProps({
                        isOpen: true,
                        message: (
                          <span>
                            {ConfirmationForRefreshUsageConstant.message}
                          </span>
                        ),
                      });
                    }}
                  >
                    <Refresh /> {HomeFetchLiveLicenseButtonLabel}
                  </Button>
                </Tooltip>
              </div>
            </Toolbar>
            <Paper className={classes.Paper}>
              <div className={classes.TableWrapper}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHeader
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    hasWritePermission={hasWritePermission}
                  />
                  <TableBody>
                    {stableSort(licenses, getSorting(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((licenseSummary, index) => {
                        return (
                          <TableRow
                            hover
                            key={licenseSummary.TenantId.toString()}
                            className={`${classes.TableRows} ${
                              index % 2 === 0 && classes.Zebra
                            }`}
                            onClick={() => handleRowClick(licenseSummary)}
                          >
                            <TableCell>
                              <Link color="inherit">
                                {licenseSummary.CustomerName}
                              </Link>
                            </TableCell>
                            <TableCell>{licenseSummary.Subdomain}</TableCell>
                            <TableCell
                              align="center"
                              className={
                                licenseSummary.StandardUsersLicensesUsage +
                                  licenseSummary.AdminUsersLicensesUsage >
                                licenseSummary.StandardUsersLicenses
                                  ? classes.Cellhighlight
                                  : ''
                              }
                            >
                              {createLicenseTableCellWithTooltip(
                                licenseSummary.StandardUsersLicensesUsage +
                                  licenseSummary.AdminUsersLicensesUsage,
                                licenseSummary.StandardUsersLicenses,
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={
                                licenseSummary.ReadUsersLicensesUsage >
                                licenseSummary.ReadUsersLicenses
                                  ? classes.Cellhighlight
                                  : ''
                              }
                            >
                              {createLicenseTableCellWithTooltip(
                                licenseSummary.ReadUsersLicensesUsage,
                                licenseSummary.ReadUsersLicenses,
                              )}
                            </TableCell>
                            {createTotalLicenseCountRow(licenseSummary)}
                            <TableCell
                              align="center"
                              className={
                                licenseSummary.StorageLicensesUsage >
                                licenseSummary.StorageLicenses
                                  ? classes.Cellhighlight
                                  : ''
                              }
                            >
                              {createStorageLicenseTableCellWithTooltip(
                                licenseSummary.StorageLicensesUsage,
                                licenseSummary.StorageLicenses,
                              )}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={
                                licenseSummary.Consumption > 90
                                  ? classes.Cellhighlight
                                  : ''
                              }
                            >
                              {licenseSummary.Consumption === Number.MAX_VALUE
                                ? UnlicensedLabelConstant
                                : `${licenseSummary.Consumption}%`}
                            </TableCell>
                            <TableCell
                              align="center"
                              className={
                                hasWritePermission
                                  ? classes.ShowCell
                                  : classes.HideCell
                              }
                              onClick={(event) => {
                                handleCustomerLicenseEvent(
                                  event,
                                  licenseSummary,
                                );
                              }}
                            >
                              <Tooltip title="Administer license">
                                <Edit fontSize="small" />
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={
                                hasWritePermission
                                  ? classes.ShowCell
                                  : classes.HideCell
                              }
                              onClick={(event) => {
                                handleModuleLicensesEvent(
                                  event,
                                  licenseSummary,
                                );
                              }}
                            >
                              <Tooltip title="Administer module licenses">
                                <Edit fontSize="small" />
                              </Tooltip>
                            </TableCell>
                            <TableCell align="center">
                              <Tooltip title="License usage last updated on">
                                <span>
                                  {Date.parse(
                                    licenseSummary.LicenseUsageUpdatedOn.toString(),
                                  ) <= 0
                                    ? ''
                                    : new Date(
                                        licenseSummary.LicenseUsageUpdatedOn,
                                      ).toLocaleString()}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.ShowCell}
                              onClick={(event) => {
                                handlefetchLiveLicenseUsageForTenantEvent(
                                  event,
                                  licenseSummary,
                                );
                              }}
                            >
                              <Tooltip
                                title={
                                  HomeSingleTenantFetchLiveLicenseButtonToolTip
                                }
                              >
                                <Refresh fontSize="small"></Refresh>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[10]}
                        count={licenses.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: { 'aria-label': 'Rows per page' },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </div>
            </Paper>
            {showProgressBackDropElement()}
            {confirmationDialogElement()}
          </Paper>
        </>
      )}
    </div>
  );
}
