import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { TriggerDetails } from './Model/TriggerModel';

class TriggerService {
  public getCustomerTriggers(customerId: string): Promise<TriggerDetails[]> {
    const customerTriggers = callService(
      'triggers',
      `customer/${customerId}`,
      RequestType.GET,
    );
    return customerTriggers;
  }

  public getTrigger(triggerId: number): Promise<TriggerDetails> {
    const triggerDetail = callService(
      'triggers',
      triggerId.toString(),
      RequestType.GET,
    );
    return triggerDetail;
  }

  public createTrigger(trigger: TriggerDetails): Promise<TriggerDetails> {
    const triggerDetail = callService(
      'triggers',
      '',
      RequestType.POST,
      trigger,
    );
    return triggerDetail;
  }

  public editTrigger(trigger: TriggerDetails): Promise<TriggerDetails> {
    const triggerDetail = callService('triggers', '', RequestType.PUT, trigger);
    return triggerDetail;
  }
}

export const triggerService: TriggerService = new TriggerService();
