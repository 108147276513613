export default class DevOpsConstants {
  static readonly Indexing_Warn =
    'Select at least one farm to deploy indexing search';
  static readonly Common_Res_Warn =
    'Select at least one farm to deploy common resources';
  static readonly Default_Warn =
    'You need to select atleast one of the Global Services';
  static readonly Monitoring_Warn =
    'Select at least one farm to deploy dashoard and alerts';
}
