import { Dispatch, createContext } from 'react';
import { CustomScript } from '../Model/CustomerModel';

export const CustomerDispatchContext = createContext<
  Dispatch<CustomerReducerAction>
>({} as Dispatch<CustomerReducerAction>);

export const CustomerContext = createContext({
  CustomerId: '',
  ContentChanged: false,
});

export type CustomerReducerAction =
  | { type: 'sql'; payload: string }
  | { type: 'powershell'; payload: string }
  | { type: 'content'; payload: CustomScript };

export const initialCustomScriptState: CustomScript = {
  CustomerId: '',
  Sql: '',
  Powershell: '',
};
