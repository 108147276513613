import React, { useEffect, useState } from 'react';
import { licenseService } from '../../Api/LicenseService';
import { customerService } from '../../Api/CustomerService';
import { ModuleLicenseManagementRouteProps } from '../Model/ModuleLicenseManagementProps';
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  Theme,
  Toolbar,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { CustomerV2, ModuleInfo, ModuleLicense } from '../Model/LicenseModel';
import PublishIcon from '@mui/icons-material/Publish';
import {
  ConfirmationForPublishModuleLicenseConstant,
  ConfirmationForPublishPurchaseOrderConstant,
  ModuleLicenseManagementPageTitleConstant,
  SaveAndPublishButtonLabelConstant,
  SnackBarMessageConstant,
} from '../Model/LicenseConstants';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editableTextBoxPanel: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '4px',
      borderColor: 'rgb(204,204,204)',
      height: 35,
      width: '100px',
      '& input.MuiInputBase-input': {
        fontSize: '14px',
        paddingLeft: 8,
        paddingRight: 8,
        height: '-webkit-fill-available',
      },
      '& input.MuiInputBase-input.Mui-disabled': {
        fontSize: '14px',
      },
      '& .MuiInputBase-root': {
        width: '-webkit-fill-available',
      },
    },

    orderNameTextBox: {
      marginLeft: '20px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '4px',
      borderColor: 'rgb(204,204,204)',
      width: '100px',
      '& input.MuiInputBase-input': {
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '& input.MuiInputBase-input.Mui-disabled': {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.37)',
      },
      '& .MuiInputBase-root': {
        width: '100%',
      },
    },

    purchaseOrdersFieldSet: {
      marginLeft: 15,
    },

    licenseValidityPanel: {
      display: 'flex',
      alignItems: 'center',

      '& .MuiFormControl-root.MuiTextField-root.date-picker-control': {
        width: '-webkit-fill-available',
      },

      '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
        height: 35,
      },

      '& label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined':
        {
          marginTop: '-8px',
        },

      '& span': {
        marginLeft: '0px',
        width: '-webkit-fill-available',
        height: 35,
      },

      '& input.MuiInputBase-input': {
        paddingRight: 8,
        paddingLeft: 8,
      },
    },

    customerEmailPanel: {
      display: 'flex',
      alignItems: 'center',

      '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
        height: 35,
      },

      '& .MuiFormControl-root.MuiTextField-root': {
        width: '-webkit-fill-available',
      },
    },

    root: {
      '& label': {
        width: '150px',
        minWidth: '150px',
        marginRight: '20px',
      },

      '& td.MuiTableCell-root.MuiTableCell-footer': {
        fontSize: '14px',
        color: 'rgb(51, 102, 153)',
        paddingTop: 11,
        paddingBottom: 11,
      },
    },

    progress: {
      marginLeft: '50%',
      marginTop: '15%',
    },

    pageTitle: {
      '& h6#tableTitle': {
        width: 'max-content',
      },
    },

    buttonsPanel: {
      float: 'right',
      marginRight: 16,
      height: '35px',
      width: '100px',
    },

    dialogActionButton: {
      '& .MuiButton-root.MuiButton-textPrimary': {
        color: theme.palette.primary.light,
      },
    },

    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    snackBar: {
      '& span.messagePanel': {
        display: 'block',
        width: '-webkit-fill-available',
        marginRight: '20px',
      },
    },

    mainDiv: {
      display: 'flex',

      '& .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded': {
        width: '450px',
        height: 'fit-content',
      },
      '& .purchaseOrdersFieldSetDiv': {
        width: '50%',
        marginLeft: 100,
        marginTop: 50,
        minWidth: 450,
        '& .heading': {
          padding: 16,
          color: '#336699',
          fontSize: '15px',
          fontWeight: '550',
        },
      },
      '& th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader': {
        border: '0',
      },
      '& td.MuiTableCell-root.MuiTableCell-body': {
        border: '0',
      },
    },
    disableLabel: {
      opacity: '0.5',
      pointerEvents: 'none',
    },

    subDiv: {
      marginTop: 10,
      marginLeft: 50,
    },

    publishButtonDiv: {
      marginTop: 25,
    },
    dialogHeader: {
      marginBottom: 10,
    },
  }),
);

export default function ModuleLicenseManagement(
  routeProps: ModuleLicenseManagementRouteProps,
) {
  const initialModuleInfo: ModuleInfo[] = [
    { Checked: false, Description: '', Id: '', Name: '' },
  ];
  const { tenantId } = routeProps.match.params;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [moduleLicenses, setModuleLicenses] = useState({} as ModuleLicense[]);
  const [moduleInfo, setModuleInfo] = useState(initialModuleInfo);
  const [showProgressRing, setShowProgressRing] = React.useState(false);
  const [customerInfo, setCustomerInfo] = useState({} as CustomerV2);
  const [publishEnabled, setPublishEnabled] = useState(false);
  const [ogininalModuleInfo, setOriginalModuleInfo] =
    useState(initialModuleInfo);

  const showProgressBackDropElement = (): JSX.Element => {
    return (
      <Backdrop className={classes.backdrop} open={showProgressRing}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  };

  const [confirmationDialogProps, setConfirmationDialogProps] = useState({
    isOpen: false,
    dialogAction: '',
    message: <></>,
  });

  useEffect(() => {
    const tempModuleInfo: ModuleInfo[] = [];
    if (isLoading) {
      customerService.getCustomerInfo(tenantId).then((customerInfo) => {
        setCustomerInfo(customerInfo);
        licenseService.getModuleInfo().then((moduleInfo) => {
          setModuleInfo(moduleInfo);
          licenseService.getModuleLicenses(tenantId).then((moduleLicenses) => {
            moduleInfo.forEach((module) => {
              module.Checked = false;

              const tempobj = moduleLicenses.find(
                (obj) => obj.ModuleId.toLowerCase() === module.Id.toLowerCase(),
              );

              if (tempobj === undefined) {
                tempModuleInfo.push({
                  Id: module.Id,
                  Checked: false,
                  Name: '',
                  Description: '',
                });
                moduleLicenses.push({
                  Id: 0,
                  ModuleId: module.Id,
                  TenantId: tenantId,
                  Voided: true,
                });
              } else {
                module.Checked = !tempobj.Voided;
                tempModuleInfo.push({
                  Id: module.Id,
                  Checked: module.Checked,
                  Name: '',
                  Description: '',
                });
              }
            });
            setModuleLicenses(moduleLicenses);
            setOriginalModuleInfo(tempModuleInfo);
            setIsLoading(false);
          });
        });
      });
    }
  }, [isLoading, moduleLicenses, tenantId]);

  const handleModuleLicenseChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    moduleInfo.forEach((module) => {
      if (module.Id.toLowerCase() === event.target.name.toLowerCase()) {
        module.Checked = event.target.checked;
      }
    });
    setModuleInfo(moduleInfo);

    let modified = false;
    moduleInfo.forEach((module) => {
      if (ogininalModuleInfo.length === 0) modified = true;

      ogininalModuleInfo.forEach((originalModule) => {
        if (module.Id === originalModule.Id) {
          if (originalModule.Checked !== module.Checked) {
            modified = true;
          }
        }
      });
    });

    if (modified) {
      setPublishEnabled(true);
    } else {
      setPublishEnabled(false);
    }
  };

  const closeConfirmationForPublishModuleLicense = () => {
    setConfirmationDialogProps({
      isOpen: false,
      dialogAction: '',
      message: <></>,
    });
  };

  const toastPublishModuleLicenseFailed = () => {
    toast.error(SnackBarMessageConstant.moduleLicensePublishError);
  };

  const buildModuleLicenseToPublish = () => {
    const moduleLicenseList: ModuleLicense[] = [];
    for (let i = 0; i < moduleInfo.length; i++) {
      const tempmodule = moduleLicenses.find(
        (obj) => obj.ModuleId === moduleInfo[i].Id,
      )?.Id;
      moduleLicenseList.push({
        ModuleId: moduleInfo[i].Id,
        TenantId: tenantId,
        Voided: !moduleInfo[i].Checked,
        Id: tempmodule === undefined ? 0 : tempmodule,
      });
    }

    return moduleLicenseList;
  };
  const publishModuleLicense = () => {
    setShowProgressRing(true);
    const moduleLicenseForPublish = buildModuleLicenseToPublish();
    licenseService
      .publishModuleLicenses(tenantId, moduleLicenseForPublish)
      .then((response) => {
        if (response)
          toast.success(SnackBarMessageConstant.moduleLicensePublishedMessage);
        else toastPublishModuleLicenseFailed();
      })
      .catch(() => {
        toastPublishModuleLicenseFailed();
      })
      .finally(() => {
        setShowProgressRing(false);
        setTimeout(function () {
          window.location.reload();
        }, 5000);
      });
  };

  const showConfirmationForPublishModuleLicense = () => {
    setConfirmationDialogProps({
      isOpen: true,
      dialogAction: 'QUESTION',
      message: (
        <div>
          <div className={classes.dialogHeader}>
            <span>
              {ConfirmationForPublishModuleLicenseConstant.messagePart}
            </span>
            <br />
          </div>
          {publishLicenseConfirmationMessage()}
        </div>
      ),
    });
  };

  const publishConfirmationDialogElement = (): JSX.Element => {
    return (
      <Dialog
        disableEscapeKeyDown
        maxWidth="xs"
        open={confirmationDialogProps.isOpen}
        onClose={() => {
          closeConfirmationForPublishModuleLicense();
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmationDialogProps.message}
          </DialogContentText>
        </DialogContent>

        {confirmationDialogProps.dialogAction === 'INFORMATION' ? (
          <DialogActions className={classes.dialogActionButton}>
            <Button
              onClick={() => {
                closeConfirmationForPublishModuleLicense();
              }}
              color="primary"
              autoFocus
            >
              {ConfirmationForPublishPurchaseOrderConstant.okButtonLabel}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions className={classes.dialogActionButton}>
            <Button
              onClick={() => {
                closeConfirmationForPublishModuleLicense();
                publishModuleLicense();
              }}
              color="primary"
            >
              {ConfirmationForPublishPurchaseOrderConstant.confirmButtonLabel}
            </Button>
            <Button
              onClick={() => {
                closeConfirmationForPublishModuleLicense();
              }}
              color="primary"
              autoFocus
            >
              {ConfirmationForPublishPurchaseOrderConstant.cancelButtonLabel}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  };

  const formLabelWithCheckboxs = (): React.JSX.Element[] => {
    const elements: React.JSX.Element[] = moduleInfo.map((module) => {
      return (
        <FormControlLabel
          key={module.Id}
          control={
            <Checkbox
              name={module.Id}
              color="primary"
              // checked={module.Checked ? module.Checked : false}
              defaultChecked={module.Checked ? module.Checked : false}
              onChange={handleModuleLicenseChange}
            />
          }
          label={module.Name}
        />
      );
    });
    return elements;
  };

  const publishLicenseConfirmationMessage = (): React.JSX.Element[] => {
    const elements: React.JSX.Element[] = moduleInfo.map((module) => {
      return (
        <div key={module.Name}>
          <span key={module.Id}>
            {module.Name} {':'} {module.Checked === true ? 'Enable' : 'Disable'}{' '}
            <br></br>
          </span>
        </div>
      );
    });
    return elements;
  };

  const pageToolbarElement = (): JSX.Element => {
    return (
      <Toolbar disableGutters={true}>
        <div className={classes.pageTitle}>
          <Typography variant="h6" id="tableTitle">
            {ModuleLicenseManagementPageTitleConstant}
            {customerInfo.CustomerName.toUpperCase()}
          </Typography>
        </div>
      </Toolbar>
    );
  };

  return (
    <div className={classes.mainDiv}>
      {isLoading ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <div className={classes.subDiv}>
          {pageToolbarElement()}
          <div>
            <FormControl component="fieldset">
              <FormGroup>{formLabelWithCheckboxs()}</FormGroup>
              <div className={classes.publishButtonDiv}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    showConfirmationForPublishModuleLicense();
                  }}
                  disabled={!publishEnabled}
                >
                  <PublishIcon /> {SaveAndPublishButtonLabelConstant}
                </Button>
              </div>
            </FormControl>
          </div>
          <div>
            {publishConfirmationDialogElement()}
            {showProgressBackDropElement()}
          </div>
        </div>
      )}
    </div>
  );
}
