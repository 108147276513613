import React, { useEffect } from 'react';
import DashboardHome from '../DashBoard/Home/Home';
import {
  Box,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import { FarmTopologies } from '../ManageFarms/Model/FarmModel';
import { farmService } from '../ManageFarms/FarmService';

const Home = () => {
  const [Type, setType] = React.useState('ProArc Online');
  const initialTopologyState: FarmTopologies[] = [];
  const [farmtopologies, setFarmTopologies] =
    React.useState(initialTopologyState);
  const [dropdown, setDropDown] = React.useState(false);

  const EnableOrDisableDropDown = (flag: boolean) => {
    setDropDown(flag);
  };

  useEffect(() => {
    loadFarmTopologiesData().catch((err) => console.log(err));
  }, []);

  const loadFarmTopologiesData = async () => {
    const farmsTopologiesReceived = await farmService.getFarmTopologies();
    setFarmTopologies(farmsTopologiesReceived.Items);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    setType(event.target.value);
  };
  return (
    <Paper sx={{ padding: 2 }} elevation={0}>
      <Box style={{ padding: '20px' }}>
        <Grid justifyContent="space-between" container>
          <Grid item>
            <Typography>Ascertra Cloud Ops Portal</Typography>
          </Grid>
          {dropdown ? (
            <Grid item>
              <Tooltip title={'Select Product'} placement="right">
                <Select
                  labelId="Subscription"
                  id="Subscription"
                  variant="outlined"
                  value={Type}
                  onChange={handleChange}
                  name="Subscription"
                >
                  {farmtopologies.map((topology) => {
                    return (
                      <MenuItem
                        key={topology.FarmType}
                        value={topology.FarmType}
                      >
                        {topology.FarmType}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Tooltip>
            </Grid>
          ) : null}
        </Grid>
      </Box>
      <DashboardHome Product={Type} setDropDown={EnableOrDisableDropDown} />
    </Paper>
  );
};

export default Home;
