import React from 'react';
import { IconButton } from '@mui/material';
import { emailadminService } from '../EmailAdministrationService';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { ThemedDeleteIcon } from '../../../../Common/Components/Icons';

const DeleteTemplate = (props: {
  templateName: string;
  loadEmailTemplates: () => Promise<void>;
}) => {
  const history = useHistory();
  const handleClick = () => {
    const confirm = window.confirm(
      'Are you sure!, you want to delete the template ' + props.templateName,
    );
    if (confirm) {
      emailadminService
        .deleteEmailTemplate(props.templateName)
        .then((template) => {
          props.loadEmailTemplates();
          toast.success(template.Name + ' deleted successfuly');
          history.push('/emailadministration');
        });
    }
  };

  return (
    <IconButton onClick={handleClick}>
      <ThemedDeleteIcon />
    </IconButton>
  );
};

export default DeleteTemplate;
