export default class DashboardContants {
  static readonly Online = 'Online';
  static readonly Offline = 'Offline';
  static readonly TimedOut = 'TimedOut';
  static readonly Maintenance = 'Maintenance';
  static readonly ServiceIncidents = 'Service Incidents';
}

export const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
