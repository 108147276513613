import { Order } from '../../../Helpers/Literals';

export interface Licenses {
  LicenseTypeId: number;
  Count: number | null;
}

export interface PurchaseOrder {
  Id: number;
  Name: string;
  TenantId: string;
  CustomerName: string;
  StandardUsersLicenses: number;
  AdminUsersLicenses: number;
  ReadUsersLicenses: number;
  StorageLicenses: number;
  IssuedOn: Date;
  ValidFrom: Date;
  ValidTo: Date;
  Licenses: Licenses[];
  ContactEmail: string;
  Published: string;
  Voided: boolean;
}

export interface LicenseUsage {
  StorageLicenses: number;
  AdminUsersLicenses: number;
  StandardUsersLicenses: number;
  ReadUsersLicenses: number;
  StorageLicensesUsage: number;
  AdminUsersLicensesUsage: number;
  StandardUsersLicensesUsage: number;
  ReadUsersLicensesUsage: number;
}

export enum LicenseType {
  FileStorage = 1,
  AdministratorUsers = 2,
  StandardUsers = 3,
  ReadUsers = 4,
}

export interface PurchaseOrderHeadRow {
  id: keyof PurchaseOrder;
  label: string;
  enableSorting: boolean;
}

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof PurchaseOrder,
  ) => void;
  order: Order;
  orderBy: string;
  hasWritePermission: boolean;
}
