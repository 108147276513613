export default class DssSiteConstants {
  static readonly SiteSavedMessage =
    'Dss site for customer saved successfully.';
  static readonly SiteIdRequiredMessage = 'Dss site id is required.';
  static readonly SiteURLRequiredMessage =
    'Dss site URL is required and it should not exceed 73 char';

  static readonly SiteEditorTitle = 'Save dss site for customer.';

  static readonly EditSiteHelperText = 'edit dss site.';
  static readonly PrimaryRegionHelperText =
    'Select primary Azure region where the site will be deployed.';
  static readonly SecondaryRegionHelperText =
    'Select Azure region where the site will be deployed in case primary is down.';
}
