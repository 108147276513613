import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './Components/App';
import * as serviceWorker from './serviceWorker';

import { runWithMsal, authProvider } from './Auth';
import { publicRoutes } from './Routes/PublicRoutes';

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

export const AuthContext = createContext<boolean>(false);
export const PubliRouteContext = createContext<boolean>(false);
const permissions = localStorage.getItem('userPermissions');

const checkIfPublicRoute = () => {
  return publicRoutes.some(
    (route) => window.location.origin + route.path === window.location.href,
  );
};

if (checkIfPublicRoute() && permissions != null) {
  root.render(
    <AuthContext.Provider value={true}>
      <PubliRouteContext.Provider value={true}>
        <App />
      </PubliRouteContext.Provider>
    </AuthContext.Provider>,
  );
  serviceWorker.unregister();
} else if (checkIfPublicRoute()) {
  root.render(
    <AuthContext.Provider value={false}>
      <PubliRouteContext.Provider value={true}>
        <App />
      </PubliRouteContext.Provider>
    </AuthContext.Provider>,
  );
  serviceWorker.unregister();
} else {
  runWithMsal(authProvider, () => {
    root.render(
      <AuthContext.Provider value={true}>
        <App />
      </AuthContext.Provider>,
    );
    serviceWorker.unregister();
  });
}
