import { RequestType } from '../../../Service/RequestType';
import { callService } from '../../../Service/ServiceBase';
import { PagedData } from '../../Common/CommonModel';
import { SqlServer } from './Model/SqlServerModel';

class SqlServerService {
  async getSqlServers(): Promise<PagedData<SqlServer>> {
    const pagedSqlServerResult = await callService(
      'azuresqlservers',
      '',
      RequestType.GET,
    );
    return pagedSqlServerResult;
  }
  async getSqlServer(server: string): Promise<SqlServer> {
    const sqlserverResult = await callService(
      'azuresqlservers',
      `${server}`,
      RequestType.GET,
    );
    return sqlserverResult;
  }
  async deleteSqlServer(server: string): Promise<SqlServer> {
    const sqlserverResult = await callService(
      'azuresqlservers',
      `${server}`,
      RequestType.DELETE,
    );
    return sqlserverResult;
  }
  async addSqlServer(
    servername: string,
    sqlserver: SqlServer,
  ): Promise<SqlServer> {
    const sqlserverResult = await callService(
      'azuresqlservers',
      `${servername}`,
      RequestType.PUT,
      sqlserver,
    );
    return sqlserverResult;
  }
}

export const sqlServerService: SqlServerService = new SqlServerService();
