import {
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { farmService } from '../FarmService';
import { Farm, FarmTopologies } from '../Model/FarmModel';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useHistory, useParams } from 'react-router-dom';
import FarmSkeleton from './Skeleton';
import { farmStateMapping } from '../StateMapper/StateMapper';
import { Save } from '@mui/icons-material';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import { TableCell } from '../../ClientSecrete/CommonMethods/ClientSecretCommonMethods';

interface UriParams {
  FarmId: string;
}
interface Inputfiled {
  name: string;
  value: string;
}

const EditForm = () => {
  const initialTopologyState: FarmTopologies[] = [];
  const initialRegionState: string[] = [];
  const initialInputFieldsState: Inputfiled[] = [];
  const history = useHistory();
  const [topologies, setFarmTopologies] = useState(initialTopologyState);
  const [regions, setFarmRegions] = useState(initialRegionState);
  const [inputFields, setInputFields] = useState(initialInputFieldsState);
  const [newinputFields, setNewInputfields] = useState([
    { name: '', value: '' },
  ]);
  const classes = useToolbarStyles();
  const params = useParams<UriParams>();
  const [formData, setFormData] = useState({
    Id: 0,
    DisplayName: '',
    GroupName: '',
    Location: '',
    Type: '',
    State: 7,
    DeploymentState: -1,
    SetupFilename: '',
    FarmRegion: 'northeurope',
    SecondaryRegion: 'westeurope',
    SubState: '',
    Properties: '',
    Subscription: '',
    IsHighAvailability: false,
  });

  useEffect(() => {
    loadFarmData().catch((error) => console.log(error));
    loadFarmTopologiesData().catch((error) => console.log(error));
    loadFarmRegionsData().catch((error) => console.log(error));
  }, []);

  const loadFarmData = async () => {
    const farmReceived = await farmService.getFarm(+params.FarmId);
    setFormData(farmReceived);

    if (farmReceived.Properties) {
      const properties: { [key: string]: string } = JSON.parse(
        farmReceived.Properties,
      );
      const keys = Object.keys(properties);

      for (const element of keys) {
        inputFields.push({ name: element, value: properties[element] });
      }
    }
  };

  const loadFarmTopologiesData = async () => {
    const farmsTopologiesReceived = await farmService.getFarmTopologies();
    setFarmTopologies(farmsTopologiesReceived.Items);
  };
  const loadFarmRegionsData = async () => {
    const farmsRegionsReceived = await farmService.getFarmRegions();
    setFarmRegions(farmsRegionsReceived);
  };
  const getLocationAndType = () => {
    const topo = topologies.find((x) => x.FarmType === Type);
    return `${topo?.DisplayName} @ ${topo?.FarmType}`;
  };

  const handleChangeInputValue = (
    index: number,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const updatedValue = event.target.value;
    const fieldName = event.target.name as keyof (typeof inputFields)[0];

    // Use the appropriate input fields based on the condition
    const values =
      inputFields.length < 1 ? [...newinputFields] : [...inputFields];

    // Update the specific field
    values[index][fieldName] = updatedValue;

    // Set the state based on the condition
    if (inputFields.length < 1) {
      setNewInputfields(values);
    } else {
      setInputFields(values);
    }
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const handleAddField = () => {
    if (inputFields.length < 1) {
      setNewInputfields([...newinputFields, { name: '', value: '' }]);
    } else {
      setInputFields([...inputFields, { name: '', value: '' }]);
    }
  };

  const handleRemoveField = (index: number) => {
    if (inputFields.length < 1) {
      const values = [...newinputFields];
      values.splice(index, 1);
      setInputFields(values);
    } else {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  const handleSave = async () => {
    const properties: { [key: string]: string } = {};
    if (inputFields.length < 1) {
      for (const element of newinputFields) {
        const prop = element;
        if (!prop.name) continue;

        properties[prop.name] = prop.value;
      }
    } else {
      for (const element of inputFields) {
        const prop = element;
        if (!prop.name) continue;

        properties[prop.name] = prop.value;
      }
    }
    const propertyString = JSON.stringify(properties);

    formData.Properties = propertyString;
    await farmService.postFarm(formData.Id, formData as Farm);
    history.push('/farm');
  };
  const handleValueChange = (event: SelectChangeEvent<string>) => {
    const { name, value, checked } = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [name]: checked !== undefined ? checked : value.trim(),
    });
  };

  const updateFarmData = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) =>
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });

  const {
    DisplayName,
    GroupName,
    State,
    Location,
    Type,
    SetupFilename,
    FarmRegion,
    SecondaryRegion,
    IsHighAvailability,
  } = formData;

  return (
    <div>
      <Paper>
        <Toolbar className={classes.root}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              Edit Farm : {params.FarmId}
            </Typography>
          </div>
        </Toolbar>
        <div
          style={{
            width: '100%',
            justifyContent: 'space-around',
          }}
        >
          {Location ? (
            <Card elevation={2} style={{ padding: 20 }}>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Display Name</TableCell>
                      <TableCell>
                        <TextField
                          className={classes.textfieldlong}
                          value={DisplayName}
                          onChange={(e) => updateFarmData(e)}
                          type="text"
                          name="DisplayName"
                          required
                        ></TextField>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Group Name</TableCell>
                      <TableCell colSpan={2}>{GroupName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>State</TableCell>
                      <TableCell colSpan={2}>
                        <Select
                          className={classes.textfieldlong}
                          name="State"
                          value={State.toString()}
                          onChange={handleValueChange}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                        >
                          {Object.entries(farmStateMapping).map(
                            (StateArray) => {
                              return (
                                <MenuItem
                                  key={StateArray[0]}
                                  value={StateArray[0]}
                                >
                                  {StateArray[1]}
                                </MenuItem>
                              );
                            },
                          )}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Location & farm type{}</TableCell>
                      <TableCell colSpan={2}>{getLocationAndType()}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Assemble Build</TableCell>
                      <TableCell colSpan={2}>{SetupFilename}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Primaty Region</TableCell>
                      <TableCell colSpan={2}>
                        <Select
                          className={classes.textfieldlong}
                          name="FarmRegion"
                          value={FarmRegion}
                          onChange={handleValueChange}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                        >
                          {regions.map((region) => {
                            return (
                              <MenuItem key={region} value={region}>
                                {region}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>High Availability</TableCell>
                      <TableCell colSpan={2}>
                        <FormControl component="fieldset" variant="standard">
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={IsHighAvailability}
                                  onChange={handleValueChange}
                                  name={'IsHighAvailability'}
                                />
                              }
                              label={
                                IsHighAvailability ? 'Enabled' : 'Disabled'
                              }
                            />
                          </FormGroup>
                        </FormControl>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Secondary Region</TableCell>
                      <TableCell colSpan={2}>
                        <Select
                          className={classes.textfieldlong}
                          name="SecondaryRegion"
                          value={SecondaryRegion}
                          onChange={handleValueChange}
                          MenuProps={{ classes: { paper: classes.menuPaper } }}
                        >
                          {regions.map((region) => {
                            return (
                              <MenuItem key={region} value={region}>
                                {region}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </TableCell>
                    </TableRow>
                    {inputFields.length < 1 ? (
                      <TableRow>
                        <TableCell>Properties</TableCell>
                        <TableCell>
                          <form
                            onSubmit={handleSubmit}
                            className={classes.root}
                          >
                            {newinputFields.map((inputField, index) => (
                              <div key={inputField.name}>
                                <TextField
                                  key={inputField.name}
                                  name="name"
                                  label="name"
                                  variant="standard"
                                  value={inputField.name}
                                  onChange={(event) =>
                                    handleChangeInputValue(index, event)
                                  }
                                />
                                <TextField
                                  name="value"
                                  label="value"
                                  variant="standard"
                                  value={inputField.value}
                                  onChange={(event) =>
                                    handleChangeInputValue(index, event)
                                  }
                                />
                                <IconButton
                                  onClick={() => handleRemoveField(index)}
                                  size="large"
                                >
                                  <RemoveIcon />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleAddField()}
                                  size="large"
                                >
                                  <AddIcon />
                                </IconButton>
                              </div>
                            ))}
                          </form>
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell>Properties</TableCell>
                        <TableCell>
                          <div className="App">
                            <Container className="Container">
                              <form
                                onSubmit={handleSubmit}
                                className={classes.root}
                              >
                                {inputFields.map((inputField, index) => (
                                  <div key={inputField.name}>
                                    <TextField
                                      name="name"
                                      label="name"
                                      variant="standard"
                                      value={inputField.name}
                                      onChange={(event) =>
                                        handleChangeInputValue(index, event)
                                      }
                                    />
                                    <TextField
                                      name="value"
                                      label="value"
                                      variant="standard"
                                      value={inputField.value}
                                      onChange={(event) =>
                                        handleChangeInputValue(index, event)
                                      }
                                    />
                                    <IconButton
                                      onClick={() => handleRemoveField(index)}
                                      size="large"
                                    >
                                      <RemoveIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => handleAddField()}
                                      size="large"
                                    >
                                      <AddIcon />
                                    </IconButton>
                                  </div>
                                ))}
                              </form>
                            </Container>
                          </div>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          ) : (
            <FarmSkeleton />
          )}
          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              onClick={handleSave}
              className={classes.button}
              startIcon={<Save />}
            >
              Save{' '}
            </Button>
          </Paper>
        </div>
      </Paper>
    </div>
  );
};

export default EditForm;
