declare global {
  interface String {
    format(...values: string[]): string;
  }
}

String.prototype.format = function (...values: string[]): string {
  let content = this as string;
  for (let i = 0; i < values.length; i++) {
    const replacement = '{' + i + '}';
    content = content.replace(replacement, values[i]);
  }
  return content;
};

export {};
