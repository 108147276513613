import { authProvider } from '../Auth';
import { User } from '../Components/Layout/UIContainer/Global/CommonProps';

class UserHelper {
  GetInitialsFromId = (userId: string) => {
    const names = this.getNameFromId(userId).split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  getNameFromId = (userId: string) => {
    const splits = userId.split('@');
    const name = splits[0].replace('.', ' ');
    return name;
  };

  getUserName = () => {
    const userInfo = authProvider.getAccount();
    return this.getNameFromId(userInfo.userName);
  };

  getUserPfile = async (): Promise<Blob> => {
    const profile = await authProvider.getProfile();
    return profile;
  };

  logOut = (): void => {
    authProvider.logout();
  };

  getUser = (): User => {
    const user = authProvider.getAccount();
    return user;
  };

  getUserInitials = () => {
    const userInfo = authProvider.getAccount();
    return this.GetInitialsFromId(userInfo.userName);
  };
}

const userHelper: UserHelper = new UserHelper();
export default userHelper;
