import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  span: {
    padding: theme.spacing(1, 0),
    fontWeight: theme.typography.fontWeightMedium,
    width: theme.spacing(30.5),
    display: 'inline-flex',
  },
  toolsHolder: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    alignContent: 'flex-end',
    width: '100%',
    paddingRigt: '100px',
  },
  spanContainer: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(1.25),
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  outerContainer: {
    margin: theme.spacing(2.5),
    justifyContent: 'space-around',
  },
  gridContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    minWidth: '600px',
    padding: theme.spacing(2.5),
    margin: theme.spacing(2.5),
    transition: 'box-shadow 0.3s',
    '&:hover': {
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    borderRadius: 5,
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    borderColor: theme.palette.secondary.main,
  },
  textField: {
    width: '50%',
  },
  checkBoxContainer: {
    padding: theme.spacing(1, 0),
    margin: theme.spacing(1.25),
    textAlign: 'start',
    maxHeight: '350px',
    overflow: 'auto',
  },
  responsiveGrid: {
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 50%',
      maxWidth: '50%',
    },
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 100%',
      maxWidth: '100%',
    },
  },
  box: {
    overflow: 'auto',
    padding: 2,
    paddingBottom: 4,
    maxHeight: 500,
  },
  minCard: {
    padding: theme.spacing(2.5),
    margin: theme.spacing(2.5),
    transition: 'box-shadow 0.3s',
    '&:hover': {
      boxShadow:
        '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    },
    borderRadius: 5,
    textAlign: 'center',
    borderStyle: 'solid',
    borderWidth: '0.5px',
    borderColor: theme.palette.secondary.main,
  },
}));
