import { callService } from '../../../../Service/ServiceBase';
import { RequestType } from '../../../../Service/RequestType';
import { PagedPermissions, PortalConfig, Permissions } from './GlobalState';
import { PagedData, Tag, TagDetails } from '../../../Common/CommonModel';

class GlobalService {
  getPermissions(): Promise<PagedPermissions> {
    const pagedPermissions = callService('roles', '', RequestType.GET);
    return pagedPermissions;
  }

  getCurrentUserPermissions(): Promise<Permissions[]> {
    const pagedPermissions = callService(
      'auth',
      'currentuser/permissions',
      RequestType.GET,
    );
    return pagedPermissions;
  }

  getPortalConfig(): Promise<PortalConfig> {
    const portalConfig = callService('config', '', RequestType.GET);
    return portalConfig;
  }

  getTags(): Promise<PagedData<Tag>> {
    return callService('tags', '', RequestType.GET);
  }

  getTagDetailsByObjectType(
    objectType: string,
  ): Promise<PagedData<TagDetails>> {
    return callService(
      'tags',
      `tagdetails?objectType=${objectType}`,
      RequestType.GET,
    );
  }

  getTagDetailsByTagName(tagName: string): Promise<PagedData<TagDetails>> {
    return callService(
      'tags',
      `tagdetails?tagName=${tagName}`,
      RequestType.GET,
    );
  }

  getTagDetailsByObjectId(objectId: string): Promise<PagedData<TagDetails>> {
    return callService('tags', `tagdetails/${objectId}`, RequestType.GET);
  }

  saveTagDetails(
    objectId: string,
    tagsDetails: TagDetails[],
  ): Promise<PagedData<TagDetails>> {
    return callService(
      'tags',
      `tagdetails/${objectId}`,
      RequestType.POST,
      tagsDetails,
    );
  }

  getTagContacts(tagName: string): Promise<string[]> {
    return callService('tags', `${tagName}/contacts`, RequestType.GET);
  }
}

export const globalService: GlobalService = new GlobalService();
