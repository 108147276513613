export default class ClientSecretContants {
  static readonly ClientSecretAlert =
    "Please keep the client secret safe once you receive it, you won't be able to retrieve it again !";
  static readonly GenerateSuccess = 'Successfully Generated Client Secret!!!';
  static readonly DescriptionNullAlert =
    "You can't pass the null value as Description";
  static readonly ClientIdCoppied = 'Api Key Coppied...';
  static readonly SecretCoppied = 'Client Secret Coppied...';
  static readonly ClientSecretHeader = 'Client Secret for customer : ';
  static readonly RefreshAlert =
    'Do you want to refresh client secret for the ApiKey : ';
  static readonly RefreshTitle = 'Refresh Client Secret';
  static readonly RefreshedClientSecret =
    'Successfully Refreshed Client Secret!!!';
  static readonly GenerateClientSecretToolTip = 'Generate Client Secret';
  static readonly ClientSecretMenuToolTip = 'Menu';
  static readonly ActivateClientSecretToolTip = 'Activate Client Secret';
  static readonly DeactivateClientSecretToolTip = 'Deactivate Client Secret';
  static readonly RefreshToolTip = 'Refresh The Client Secret';
  static readonly AddGroupsToolTip = 'View, Add or Remove Groups From User';
  static readonly RemoveGroupToolTip = 'Remove Group From Client';
  static readonly AddGroupsToClientToolTip = 'Add Groups To Client';
  static readonly AutoCompleteToolTip =
    'Search And Select Groups to be Added, Scroll To Load More Groups';
  static readonly LoadMoreToolTip = 'Load More Groups';
}
