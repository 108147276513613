import { Button, Divider, Paper, Typography } from '@mui/material';
import { Cancel, Save } from '@mui/icons-material';
import { useStyles } from '../../../../Common/Styles/WraperStyle';
import SqlServerCreateForm from './SqlServerForm';
import { OperationType, SqlServer } from '../Model/SqlServerModel';
import { useState } from 'react';
import { ValidationErrors } from 'fluentvalidation-ts';
import { toast } from 'react-toastify';
import { sqlServerService } from '../SqlServerService';
import { useHistory } from 'react-router-dom';
import { Loader } from '../../../../Common/Components/Loader';

const SqlServerCreate = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(0);
  const [errors, setErrors] = useState<ValidationErrors<SqlServer>>({});
  const [sqlServer, setSqlServer] = useState<SqlServer>({
    AdminPassword: '',
    UserName: '',
    ServerName: '',
    ResourceGroup: '',
    Subscription: '',
    ProductID: '',
    Role: 'primary',
    Region: 'northeurope',
    EnableElasticPool: true,
    TenantCount: 0,
    DataMaxSize: 50,
    EDtu: 50,
    PerDbDtu: 10,
    FailoverGroups: [],
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      setSqlServer({
        ...sqlServer,
        [name]: checked,
      });
    } else {
      setSqlServer({
        ...sqlServer,
        [name]: value,
      });
    }
  };

  const validate = () => {
    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([_, value]) => {
        toast.error(_ + ' ' + value?.toString());
      });
      return false;
    }
    return true;
  };

  const cancel = () => {
    history.push('/sqlserver');
  };

  const saveSqlServer = () => {
    setLoading((prevState) => prevState + 1);
    if (validate()) {
      sqlServerService
        .addSqlServer(sqlServer.ServerName, sqlServer)
        .then((result) => {
          toast.success(result.ServerName + ' added successfully');
          cancel();
        })
        .catch((err) => {
          toast.error('Failed to add Sql Server');
          toast.error(err.message);
        });
    }
    setLoading((prevState) => prevState - 1);
  };

  return (
    <div>
      <Paper>
        <Loader loading={loading > 0} />
        <Typography variant="h5" className={classes.title}>
          Create Sql Server
        </Typography>
        <Divider className={classes.divider} />
        <SqlServerCreateForm
          errors={errors}
          onFieldChange={handleChange}
          setErrors={setErrors}
          sqlserver={sqlServer}
          setSqlServer={setSqlServer}
          sqlServerOperation={OperationType.Create}
        />
        <Divider className={classes.divider} />
        <Paper style={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            onClick={saveSqlServer}
            className={classes.button}
            startIcon={<Save />}
          >
            Save{' '}
          </Button>
          <Button
            variant="contained"
            onClick={cancel}
            className={classes.button}
            startIcon={<Cancel />}
          >
            Cancel{' '}
          </Button>
        </Paper>
      </Paper>
    </div>
  );
};

export default SqlServerCreate;
