import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { AssembleBuild, ReleaseRequest } from './Model/AzureDevOpsModel';
import { ReleaseRequest as Release } from '../ManageFarms/Model/FarmModel';
import { PagedData } from '../../Common/CommonModel';

class AzureDevOpsService {
  getBuild(): Promise<AssembleBuild[]> {
    return callService('devops', '', RequestType.GET);
  }

  getBranches(): Promise<PagedData<string>> {
    return callService('devops', 'branches', RequestType.GET);
  }
  getDtsBranches(): Promise<PagedData<string>> {
    return callService('devops', 'dtsbranches', RequestType.GET);
  }

  triggerRelease(ReleaseBody: ReleaseRequest): Promise<void> {
    const pagedCustomerResult = callService(
      'devops',
      '',
      RequestType.PUT,
      ReleaseBody,
      true,
    );
    return pagedCustomerResult;
  }

  CreateRelease(release: Release): Promise<void> {
    return callService('devops', '', RequestType.PUT, release, true);
  }
}

export const azureDevOpsService: AzureDevOpsService = new AzureDevOpsService();
