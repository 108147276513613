import React from 'react';
import HttpsIcon from '@mui/icons-material/Https';
import { Card, CardContent, CardMedia, Paper, Typography } from '@mui/material';
import Link from '@mui/material/Link';
export const NoAccess = () => {
  return (
    <Paper>
      <Card
        sx={{
          margin: 10,
          padding: 10,
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <CardContent>
          <CardMedia sx={{ display: 'inline-flex', padding: 2 }}>
            <HttpsIcon color="error" fontSize="large" />
            <Typography color={'red'} fontSize={30}>
              No Access
            </Typography>
          </CardMedia>
          <Typography>
            {"Sorry! you're not allowed to see this page"}
          </Typography>
          <Typography>
            <Link href="/" bgcolor={'secondary'} underline="always">
              {'Home'}
            </Link>
          </Typography>
        </CardContent>
      </Card>
    </Paper>
  );
};
