import { Component } from 'react';

export default class PermissionConstants extends Component {
  static readonly WriteTrigger = 'WriteTrigger';
  static readonly ReadTrigger = 'ReadTrigger';
  static readonly CustomerReader = 'CustomerReader';
  static readonly CustomerWriter = 'CustomerWriter';
  static readonly CustomerDeleter = 'CustomerDeleter';
  static readonly RestartTenant = 'RestartTenant';
  static readonly StorageAccountWriter = 'StorageAccountWriter';
  static readonly StorageAccountReader = 'StorageAccountReader';
  static readonly SqlServerWriter = 'AzureSQLServerWriter';
  static readonly SqlServerReader = 'AzureSQLServerReader';
  static readonly ResourceWriter = 'ResourceWriter';
  static readonly FarmDeleter = 'FarmDeleter';
  static readonly FarmWriter = 'FarmWriter';
}
