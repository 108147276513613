import { RequestType } from '../../../Service/RequestType';
import { callService } from '../../../Service/ServiceBase';
import { PagedData } from '../../Common/CommonModel';
import { SecureKey, KeyValuePairs } from './Model/KeyVaultModel';

class KeyVaultService {
  getSecureKeys(): Promise<PagedData<KeyValuePairs>> {
    const pagedCustomerResult = callService('keystore', '', RequestType.GET);
    return pagedCustomerResult;
  }
  RevealKeyValue(keyId: string): Promise<string> {
    const pagedCustomerResult = callService(
      'keystore',
      `Keys/Reveal/${keyId}`,
      RequestType.GET,
    );
    return pagedCustomerResult;
  }
  EditKeyValue(securekey: SecureKey): Promise<SecureKey> {
    const pagedCustomerResult = callService(
      'keystore',
      `Keys/Edit/${securekey.KeyName}`,
      RequestType.PUT,
      securekey,
    );
    return pagedCustomerResult;
  }
  DeleteSecureKey(KeyName: string): Promise<string> {
    const pagedCustomerResult = callService(
      'keystore',
      `Keys/Delete/${KeyName}`,
      RequestType.DELETE,
    );
    return pagedCustomerResult;
  }
  CreateSecureKey(securekey: SecureKey): Promise<SecureKey> {
    const pagedCustomerResult = callService(
      'keystore',
      `Keys/Create`,
      RequestType.POST,
      securekey,
    );
    return pagedCustomerResult;
  }
}
export const keyvaultrService: KeyVaultService = new KeyVaultService();
