import React, { useState, useEffect, useContext, useMemo } from 'react';
import makeStyles from '@mui/styles/makeStyles';

import { ToastContent, toast } from 'react-toastify';
import { useImmerReducer } from 'use-immer';

import { triggerService } from './TriggerService';
import { TriggerBlade, TriggerEditor, TriggerOptions } from './Components';
import {
  TriggerBladeSkeleton,
  TriggerEditorSkeleton,
} from './Skeleton/TriggerHomeSkeletons';
import {
  TriggerDispatchContext,
  TriggerReducerAction,
  TriggerConstants,
  TriggerRouterProps,
  TriggerDetails,
  TriggerCustomerContext,
  initialTriggerState,
  TriggerDeploymentState,
} from './Model';

import { Error, isValidError } from '../../Common/CommonModel';
import { commonService } from '../../Common/CommonService';
import PermissionConstants from '../../Common/Constants/PermissionConstants';

import NotFound from '../UIContainer/Global/NotFound';
import { customerService } from '../Customer/CustomerService';
import { PermissionsContext } from '../UIContainer/PermissionsWrapper';

const useStyles = makeStyles((theme) => ({
  flexRowContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  bladeFlexRowItem: {
    display: 'flex',
    flexBasis: '22%',
    alignSelf: 'flex-start',
  },
  flexRowItem: {
    display: 'flex',
    flex: '3%',
    padding: theme.spacing(3, 0, 0, 2),
  },
  contentFlexRowItem: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '70%',
    alignSelf: 'flex-start',
    padding: theme.spacing(4.5, 0, 0, 2),
  },
  homeRoot: {
    width: '100%',
    height: '80vh',
    margin: theme.spacing(0, 2, 2, 0),
    padding: theme.spacing(0, 2, 0, 0),
  },
}));

function triggerReducer(
  triggerDraft: TriggerDetails,
  action: TriggerReducerAction,
) {
  switch (action.type) {
    case 'triggerData':
      triggerDraft.Id = action.payload.Id;
      triggerDraft.CustomerId = action.payload.CustomerId;
      triggerDraft.Name = action.payload.Name;
      triggerDraft.DeploymentState = action.payload.DeploymentState;
      triggerDraft.InsertedDate = action.payload.InsertedDate;
      triggerDraft.Query = action.payload.Query;
      return;
    case 'name': //TODO: Simplify property assignment obj[propname]: value
      triggerDraft.Name = action.payload;
      return;
    case 'content':
      triggerDraft.Query = action.payload;
      return;
    case 'reset':
      triggerDraft.Id = 0;
      triggerDraft.Name = '';
      triggerDraft.DeploymentState = 0;
      triggerDraft.InsertedDate = new Date();
      triggerDraft.Query = '';
      return;
    default:
      break;
  }
}

const ShowNoTenantDeployed = () => {
  return (
    <div>
      <h4 style={{ textAlign: 'center' }}>
        Customer does not have any tenant deployed
      </h4>
    </div>
  );
};

export default function TriggerHome(props: TriggerRouterProps) {
  const { customerId } = props.match.params;

  const classes = useStyles();

  const [queryLoading, setQueryLoading] = useState(false);
  const [triggersLoading, setTriggersLoading] = useState(true);
  const [triggers, setTriggers] = useState<TriggerDetails[]>([]);
  const [hasActiveTenant, setHasActiveTenant] = useState(false);
  const [editorContentChanged, setEditorContentChanged] = useState(false);

  const [triggerData, dispatch] = useImmerReducer(
    triggerReducer,
    initialTriggerState,
  );
  const permissions = useContext(PermissionsContext);

  const deployTriggerJobParams =
    '-TriggerId ' +
    triggerData.Id +
    ' -CustomerIdentifier "' +
    customerId +
    '"';

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    await loadActiveTenant();
    await loadCustomerTriggers();
  };

  const loadCustomerTriggers = async () => {
    try {
      const customerTriggers =
        await triggerService.getCustomerTriggers(customerId);
      setTriggers(customerTriggers);
    } catch (error) {
      if (isValidError(error as Error)) {
        toast.error(error as ToastContent);
      } else {
        console.log(error);
      }
    } finally {
      setTriggersLoading(false);
    }
  };

  const loadActiveTenant = async () => {
    try {
      const activeTenant = await customerService.getTenant(customerId, true);
      setHasActiveTenant(activeTenant.Items.length > 0);
    } catch {
      setHasActiveTenant(false);
    }
  };

  const getTriggerQuery = async (triggerId: number): Promise<void> => {
    setQueryLoading(true);
    const trigger: TriggerDetails = await triggerService.getTrigger(triggerId);
    dispatch({ type: 'content', payload: trigger.Query });
    setQueryLoading(false);
  };

  const saveTriggerCallback = async (): Promise<void> => {
    try {
      if (isNewTrigger()) {
        const savedTrigger = await triggerService.createTrigger({
          ...triggerData,
          CustomerId: customerId,
        });
        updateTriggerState(savedTrigger, true);
      } else {
        const savedTrigger = await triggerService.editTrigger({
          ...triggerData,
          DeploymentState: TriggerDeploymentState.NotDeployed,
          CustomerId: customerId,
        });
        updateTriggerState(savedTrigger, false);
      }
      toast.success(TriggerConstants.TriggerSavedMessage);
    } catch (error) {
      if (isValidError(error as Error)) {
        toast.error(error as ToastContent);
      } else {
        console.log(error);
      }
    }
  };

  const deployTriggerCallback = async (): Promise<void> => {
    const job = {
      Module: 'Trigger',
      Location: 'WindowsAzure',
      JobType: 'Invoke-TriggerDeployment',
      Scheduled: new Date().toISOString(),
      JobParameters: deployTriggerJobParams,
    };
    if (isNewTrigger()) {
      toast.error(TriggerConstants.ErrorDeployNewTriggerMessage);
    } else {
      await commonService.deployJob(job);
      toast.info(TriggerConstants.TriggerDeployedMessage);
    }
  };

  const dropTriggerCallback = async (triggerId: number): Promise<void> => {
    const dropTriggerJobParams =
      '-TriggerId ' +
      triggerId +
      ' -CustomerIdentifier "' +
      customerId +
      '" -DropTrigger';
    const job = {
      Module: 'Trigger',
      Location: 'WindowsAzure',
      JobType: 'Invoke-TriggerDeployment',
      Scheduled: new Date().toISOString(),
      JobParameters: dropTriggerJobParams,
    };
    if (triggerId < 1) {
      toast.error(TriggerConstants.ErrorDropNewTriggerMessage);
    } else {
      await commonService.deployJob(job);
      toast.info(TriggerConstants.TriggerDroppedMessage);
    }
  };

  const updateTriggerState = (trigger: TriggerDetails, newTrigger: boolean) => {
    if (newTrigger) {
      triggers.push(trigger);
    } else {
      const index = triggers.findIndex(({ Id }) => Id === trigger.Id);
      triggers[index] = trigger;
    }
    dispatch({ type: 'triggerData', payload: initialTriggerState });
    dispatch({ type: 'triggerData', payload: trigger });

    // to force reload trigger editor again
    setQueryLoading(true);
    setQueryLoading(false);
  };

  const isNewTrigger = (): boolean => {
    return triggerData.Id === 0;
  };

  const canViewTriggerManagementPage = (): boolean => {
    return permissions[PermissionConstants.WriteTrigger] ||
      permissions[PermissionConstants.ReadTrigger]
      ? true
      : false;
  };

  const hasTriggeWritePermission = (): boolean => {
    return permissions[PermissionConstants.WriteTrigger] ? true : false;
  };

  const reloadTriggers = async () => {
    await loadCustomerTriggers();
    dispatch({ type: 'triggerData', payload: initialTriggerState });
  };

  const editorContentChangedCallback = (contentChanged: boolean) => {
    setEditorContentChanged(contentChanged);
  };

  const resetTriggerSelection = async () => {
    setQueryLoading(true);
    await sleep(10);
    dispatch({ type: 'reset' });
    setQueryLoading(false);
  };

  function sleep(millisecond: number) {
    return new Promise<void>((resolve) => setTimeout(resolve, millisecond));
  }

  const triggerCustomerValue = useMemo(
    () => ({
      CustomerId: customerId,
      ContentChanged: editorContentChanged,
    }),
    [customerId, editorContentChanged],
  );

  const ShowTriggerContext = () => {
    return (
      <TriggerDispatchContext.Provider value={dispatch}>
        <TriggerCustomerContext.Provider value={triggerCustomerValue}>
          <div className={classes.flexRowContainer}>
            <div className={classes.bladeFlexRowItem}>
              {triggersLoading ? (
                <TriggerBladeSkeleton />
              ) : (
                <TriggerBlade
                  customerId={customerId}
                  triggers={triggers}
                  contentChanged={editorContentChanged}
                  getQueryCallback={getTriggerQuery}
                  dropTriggerCallback={dropTriggerCallback}
                />
              )}
            </div>
            <div className={classes.contentFlexRowItem}>
              {triggersLoading || queryLoading ? (
                <TriggerEditorSkeleton />
              ) : (
                <TriggerEditor
                  content={triggerData.Query}
                  editorContentChangedCallback={editorContentChangedCallback}
                />
              )}
            </div>
            <div className={classes.flexRowItem}>
              {hasTriggeWritePermission() && !triggersLoading && (
                <TriggerOptions
                  saveTriggerCallback={saveTriggerCallback}
                  deployTriggerCallback={deployTriggerCallback}
                  reloadTriggersCallback={reloadTriggers}
                  resetTriggerCallback={resetTriggerSelection}
                />
              )}
            </div>
          </div>
        </TriggerCustomerContext.Provider>
      </TriggerDispatchContext.Provider>
    );
  };
  function renderContent() {
    if (canViewTriggerManagementPage() && hasActiveTenant) {
      return ShowTriggerContext();
    } else if (triggersLoading || queryLoading) {
      return <div />;
    } else if (!hasActiveTenant) {
      return ShowNoTenantDeployed();
    } else {
      return <NotFound />;
    }
  }
  return <div className={classes.homeRoot}>{renderContent()}</div>;
}
