import React, { Component } from 'react';
import { monitoringService } from '../MonitoringService';
import { OnCallUser, DialogOptions } from '../Model/MonitoringModel';

import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  CircularProgress,
  Button,
  Snackbar,
} from '@mui/material';

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  onClose: (options: DialogOptions, addedUser: OnCallUser) => void;
}

interface AddUserState extends OnCallUser {
  loading: boolean;
  showSnackbar: boolean;
  snackbarMessage: string;
}

const styles = createStyles({
  pos: {
    marginBottom: 12,
    marginRight: 20,
  },
});

class AddOnCallUser extends Component<Props, AddUserState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      Id: '00000000-0000-0000-0000-000000000000',
      Name: '',
      EmailAddress: '',
      PhoneNumber: '',
      Sequence: 0,
      loading: false,
      showSnackbar: false,
      snackbarMessage: '',
    };
  }

  getEmptyUser = () => {
    return { Id: '', Name: '', EmailAddress: '', PhoneNumber: '', Sequence: 0 };
  };

  handleCancel = () => {
    this.props.onClose(DialogOptions.Cancel, this.getEmptyUser());
  };

  handleOk = () => {
    this.setState({ loading: true });
    const onCallUser = {
      Id: this.state.Id,
      Name: this.state.Name,
      EmailAddress: this.state.EmailAddress,
      PhoneNumber: this.state.PhoneNumber,
      Sequence: this.state.Sequence,
    };

    monitoringService
      .addOnCallUser(onCallUser)
      .then((addedUser) => {
        this.setState({
          Id: '',
          Name: '',
          EmailAddress: '',
          PhoneNumber: '',
          Sequence: 0,
          loading: false,
        });
        this.setState({
          snackbarMessage:
            addedUser.Name + ' is successfully added to on call users list',
        });
        this.setState({ showSnackbar: true });
        this.props.onClose(DialogOptions.Ok, addedUser);
      })
      .catch((err) => {
        console.log(err.message);
        this.setState({
          snackbarMessage: 'Failed to add user to on call users list',
        });
        this.setState({ showSnackbar: true });
        this.setState({ loading: false });
        this.props.onClose(DialogOptions.Cancel, this.getEmptyUser());
      });
  };

  handleInputChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      [event.target.id]: event.target.value,
    } as unknown as Pick<OnCallUser, keyof OnCallUser>);
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };

  render() {
    const { open, classes, onClose, ...other } = this.props;
    return (
      <div>
        <Dialog
          disableEscapeKeyDown
          open={open}
          aria-labelledby="form-dialog-title"
          {...other}
          onClose={() => onClose(DialogOptions.Cancel, this.getEmptyUser())}
        >
          <DialogTitle id="form-dialog-title">Add user</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter user details to add a new on call user.
            </DialogContentText>
            <br />
            <TextField
              autoFocus
              margin="dense"
              id="Name"
              label="Name"
              fullWidth
              value={this.state.Name}
              onChange={this.handleInputChange()}
            />
            <TextField
              margin="dense"
              id="EmailAddress"
              label="Email Address"
              type="email"
              fullWidth
              value={this.state.EmailAddress}
              onChange={this.handleInputChange()}
            />
            <TextField
              margin="dense"
              id="PhoneNumber"
              label="Phone number"
              fullWidth
              value={this.state.PhoneNumber}
              onChange={this.handleInputChange()}
            />
          </DialogContent>
          <DialogActions>
            {this.state.loading ? (
              <CircularProgress size={20} className={classes.pos} />
            ) : (
              <div>
                <Button onClick={this.handleCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleOk} color="primary">
                  Create
                </Button>
              </div>
            )}
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.showSnackbar}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackbarMessage}</span>}
        />
      </div>
    );
  }
}

export default withStyles(styles)(AddOnCallUser);
