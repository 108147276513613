import { RequestType } from '../../../Service/RequestType';
import { callService } from '../../../Service/ServiceBase';
import { PagedData } from '../../Common/CommonModel';
import {
  EmailTemplate,
  SendEmailTemplateMessage,
} from './Model/EmailAdminModel';

class EmailAdminService {
  getEmailTemplates(): Promise<PagedData<EmailTemplate>> {
    return callService('emailtemplates', '', RequestType.GET);
  }

  getEmailTemplate(
    templateType: string,
    Product: string,
  ): Promise<EmailTemplate> {
    return callService(
      'emailtemplates',
      `${templateType}/${Product}`,
      RequestType.GET,
    );
  }

  saveEmailTemplate(
    templateName: string,
    template: EmailTemplate,
  ): Promise<EmailTemplate> {
    return callService(
      'emailtemplates',
      `save/${templateName}`,
      RequestType.PUT,
      template,
    );
  }

  createEmailTemplate(
    templateName: string,
    template: EmailTemplate,
  ): Promise<EmailTemplate> {
    return callService(
      'emailtemplates',
      `create/${templateName}`,
      RequestType.POST,
      template,
    );
  }

  deleteEmailTemplate(templateName: string): Promise<EmailTemplate> {
    return callService(
      'emailtemplates',
      `delete/${templateName}`,
      RequestType.DELETE,
    );
  }

  sendEmailWithTemplate(templatemessage: SendEmailTemplateMessage) {
    return callService(
      'sendemail',
      'template',
      RequestType.POST,
      templatemessage,
    );
  }
}
export const emailadminService = new EmailAdminService();
