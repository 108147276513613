import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { PagedData } from '../../Common/CommonModel';
import { Farm, FarmTopologies, Resources, Tenant } from './Model/FarmModel';

class FarmService {
  getFarms(): Promise<PagedData<Farm>> {
    const pagedFarmResult = callService('farms', '', RequestType.GET);
    return pagedFarmResult;
  }
  getFarmTopologies(): Promise<PagedData<FarmTopologies>> {
    const pagedFarmTopologiesResult = callService(
      'farmtopologies',
      '',
      RequestType.GET,
    );
    return pagedFarmTopologiesResult;
  }
  getAvailableGroups(): Promise<string[]> {
    const availablegroups = callService(
      'accesscontrol',
      'partnergroups',
      RequestType.GET,
    );
    return availablegroups;
  }
  getFarmRegions(): Promise<string[]> {
    const farmregions = callService('farms', 'azureregions', RequestType.GET);
    return farmregions;
  }
  getResources(): Promise<Resources[]> {
    const farmregions = callService(
      'resources',
      '360setups/blobs',
      RequestType.GET,
    );
    return farmregions;
  }
  postFarm(farmId: number, newFarm: Farm): Promise<Farm> {
    const farm = callService('farms', `${farmId}`, RequestType.PUT, newFarm);
    return farm;
  }
  getFarm(farmId: number): Promise<Farm> {
    const farm = callService('farms', `${farmId}`, RequestType.GET);
    return farm;
  }
  deleteFarm(farmId: number): Promise<Farm> {
    const farm = callService('farms', `Delete/${farmId}`, RequestType.DELETE);
    return farm;
  }
  async updateSSlBindings(): Promise<void> {
    await callService(
      'farms',
      'updatesslbindings',
      RequestType.PUT,
      null,
      true,
    );
  }
  getTenantsOnFarm(farmId: number): Promise<PagedData<Tenant>> {
    const tenants = callService('farms', farmId + '/tenants', RequestType.GET);
    return tenants;
  }
  getAppservicePlans(farmId: number): Promise<string[]> {
    const appserviceplans = callService(
      'farms',
      farmId + '/appserviceplans',
      RequestType.GET,
    );
    return appserviceplans;
  }
  setTenant(farmId: number, tenantId: string, tenant: Tenant): Promise<Tenant> {
    const tenantresponse = callService(
      'farms',
      `${farmId}/tenants/${tenantId}`,
      RequestType.PUT,
      tenant,
    );
    return tenantresponse;
  }
  getPassword(farmId: number): Promise<string> {
    const farm = callService(
      'farms',
      `${farmId}/adminpassword`,
      RequestType.GET,
    );
    return farm;
  }
}

export const farmService: FarmService = new FarmService();
