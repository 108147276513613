import React, { ReactElement } from 'react';
import PropTypes from 'prop-types';
import {
  LicenseSummary,
  LicenseSummaryHeadRow,
  EnhancedTableProps,
} from '../../Model/LicenseModel';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const LicenseheadRows: LicenseSummaryHeadRow[] = [
  { id: 'CustomerName', label: 'Customer Name', enableSorting: true },
  { id: 'Subdomain', label: 'Tenant Name', enableSorting: true },
  {
    id: 'StandardUsersLicenses',
    label: 'Std + Adm Users',
    enableSorting: false,
  },
  { id: 'ReadUsersLicenses', label: 'Read Users', enableSorting: false },
  { id: 'UserLicensesUsage', label: 'Total Licenses', enableSorting: false },
  { id: 'StorageLicenses', label: 'Storage (in GB)', enableSorting: false },
  { id: 'Consumption', label: 'Consumption', enableSorting: true },
];

const AdministrationCellLabel = 'Administration';
const ModulesCellLabel = 'Modules';

const LicenseUsageUpdatedOnHeadRow: LicenseSummaryHeadRow = {
  id: 'LicenseUsageUpdatedOn',
  label: 'Last Updated',
  enableSorting: true,
};
const LicenseUsageCellLabel = 'Licenthemese Usage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: '#336699 !important',
    },
    hideCell: {
      display: 'none',
    },
    showCell: {
      display: 'table-cell',
    },
  }),
);

const CreateSortColumnTableCell = (
  row: LicenseSummaryHeadRow,
  rowIndex: number,
  props: EnhancedTableProps,
): ReactElement => {
  const { order, orderBy, onRequestSort } = props;
  const classes = useStyles();
  const createSortHandler =
    (property: keyof LicenseSummary) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableCell
      key={row.id}
      sortDirection={orderBy === row.id ? order : false}
      align={rowIndex === 0 || rowIndex === 1 ? 'left' : 'center'}
    >
      {row.enableSorting ? (
        <TableSortLabel
          className={classes.root}
          active={orderBy === row.id}
          direction={order}
          onClick={createSortHandler(row.id)}
        >
          {row.label}
        </TableSortLabel>
      ) : (
        <div>{row.label}</div>
      )}
    </TableCell>
  );
};
export default function TableHeader(props: Readonly<EnhancedTableProps>) {
  const { hasWritePermission } = props;
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {LicenseheadRows.map((row, index) =>
          CreateSortColumnTableCell(row, index, props),
        )}
        <TableCell
          align="center"
          className={hasWritePermission ? classes.showCell : classes.hideCell}
        >
          {AdministrationCellLabel}
        </TableCell>
        <TableCell
          align="center"
          className={hasWritePermission ? classes.showCell : classes.hideCell}
        >
          {ModulesCellLabel}
        </TableCell>
        {CreateSortColumnTableCell(LicenseUsageUpdatedOnHeadRow, -1, props)}
        <TableCell align="center">{LicenseUsageCellLabel}</TableCell>
      </TableRow>
    </TableHead>
  );
}

CreateSortColumnTableCell.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  onRequestSort: PropTypes.func.isRequired,
};
