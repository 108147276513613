import React, { useState, useContext } from 'react';
import { Icon, CircularProgress, Tooltip, Fab } from '@mui/material';
import { useFabStyles } from '../../../../Common/Styles/FabStyle';
import { CustomizationOptionProps } from '../Model/CustomerModel';
import { CustomerContext } from '../Reducer/CustomizationState';

export default function CustomizationOptions(
  props: Readonly<CustomizationOptionProps>,
) {
  const classes = useFabStyles();
  const { saveCustomizationCallback } = props;
  const [saving, setSaving] = useState(false);

  const { ContentChanged } = useContext(CustomerContext);

  const saveCustomization = async () => {
    setSaving(true);
    await saveCustomizationCallback();
    setSaving(false);
  };

  return (
    <div className={classes.flexRowContainer}>
      <Tooltip title={'Save Scripts'}>
        <Fab
          className={classes.firstFab}
          aria-label="save"
          disabled={!ContentChanged || saving}
          color="primary"
          onClick={saveCustomization}
        >
          <Icon>save</Icon>
        </Fab>
      </Tooltip>
      {saving && (
        <CircularProgress size={68} className={classes.firstProgress} />
      )}
    </div>
  );
}
