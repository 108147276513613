import { callService } from '../../Service/ServiceBase';
import { RequestType } from '../../Service/RequestType';
import { DeploymentJob } from './DeploymentJob';
import { PagedData, Product, Subscription } from './CommonModel';

class CommonService {
  deployJob(deploymentJob: DeploymentJob): Promise<DeploymentJob> {
    const addedUser = callService('jobs', '', RequestType.POST, deploymentJob);
    return addedUser;
  }

  async getAzureRegions(): Promise<string[]> {
    const azureRegions = await callService(
      'farms',
      'azureregions',
      RequestType.GET,
    );
    return azureRegions;
  }

  async getSubscriptions(): Promise<PagedData<Subscription>> {
    const subscriptions = await callService(
      'subscriptions',
      '',
      RequestType.GET,
    );
    return subscriptions;
  }

  async getProducts(): Promise<PagedData<Product>> {
    const products = await callService('Products', '', RequestType.GET);
    return products;
  }

  async getAzureResourceGroups(): Promise<string[]> {
    const products = await callService(
      'azureresource',
      'resourcegroups',
      RequestType.GET,
    );
    return products;
  }
}

export const commonService: CommonService = new CommonService();
