export enum PermissionKeys {
  LicenseReader = 'LicenseReader',
  LicenseWriter = 'LicenseWriter',
  RestartTenant = 'RestartTenant',
  SecureKeyReader = 'SecureKeyReader',
  SecureKeyWriter = 'SecureKeyWriter',
  StorageAccountReader = 'StorageAccountReader',
  StorageAccountWriter = 'StorageAccountWriter',
  SqlServerReader = 'AzureSQLServerReader',
  SqlServerWriter = 'AzureSQLServerWriter',
  PermissionReader = 'PermissionReader',
  PermissionWriter = 'PermissionWriter',
  ModuleWriter = 'ModuleWriter',
  ModuleVariableWriter = 'ModuleVariableWriter',
  EmailTemplateWriter = 'EmailTemplateWriter',
  EmailTemplateReader = 'EmailTemplateReader',
  FarmReader = 'FarmReader',
  FarmWriter = 'FarmWriter',
  AuditReader = 'AuditReader',
}
