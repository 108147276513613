export default class StorageAccountConstants {
  static readonly StorageAccount = 'Storage Account';
  static readonly StorageAccountPermissionWarn =
    'You do not have permission to view StorageAccounts.';
  static readonly StorageAccountDeleteWarn =
    'Are you sure you want to delete the storage account?';
  static readonly StorageAccountDeleteSuc =
    'Storage Account Entry Deleted Successfuly !';
  static readonly StorageAccountSaveSuc = 'Storage Account Saved Successfuly !';
  static readonly StorageAccountSaveFailed =
    'failed to load Storage Account details';
  static readonly StorageAccoutChangeWarn =
    'Please manually move the document store if you are changing the storage account. Are you sure you want to make this change?';
}
