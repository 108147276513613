import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { BreadcrumbProps } from './RouteProps';
import { makeStyles } from '@mui/styles';
import { Chip } from '@mui/material';

const useStyles = makeStyles(() => ({
  link: {
    fontSize: 'inherit',
    color: '#003130',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  typography: {
    fontSize: 'inherit',
    color: 'white',
    textDecoration: 'none',
  },
}));

const CustomBreadcrumbs = ({ routes }: BreadcrumbProps) => {
  const location = useLocation();
  const classes = useStyles();
  let shouldRenderNextBreadcrumb = true;
  const pathSegments = decodeURIComponent(location.pathname.toLowerCase())
    .split('/')
    .filter((segment) => segment);

  const regex = /\/keyvault\/(.+)/;
  const shouldRenderSecureKeyBreadcrumb = regex.test(
    location.pathname.toLowerCase(),
  );

  return (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link className={classes.link} to="/">
        <Chip color="secondary" label={'Home'}></Chip>
      </Link>
      {pathSegments.map((segment, index) => {
        const isLastSegment = index === pathSegments.length - 1;
        const url = `/${pathSegments.slice(0, index + 1).join('/')}`;

        const route = routes.find((route) => {
          const routePath = route.path.toLowerCase();
          if (routePath.includes(':')) {
            const pathWithParamReplaced = routePath.replace(
              /:\w+/g,
              (param) => {
                const paramIndex = routePath.split('/').indexOf(param);
                return pathSegments[paramIndex - 1];
              },
            );
            return pathWithParamReplaced === url;
          }
          return routePath === url;
        });

        if (!route) {
          return null;
        }
        let breadcrumbLabel = route.name;
        if (
          route.path.includes(':Operation') &&
          !route.path.includes(':KeyName')
        ) {
          shouldRenderNextBreadcrumb = false;
          breadcrumbLabel = 'Secure Key Operation';
        }

        if (shouldRenderNextBreadcrumb) {
          if (isLastSegment) {
            return (
              <Typography className={classes.typography} key={url}>
                {breadcrumbLabel}
              </Typography>
            );
          } else {
            return (
              <Link className={classes.link} to={url} key={url}>
                <Chip color="secondary" label={breadcrumbLabel}></Chip>
              </Link>
            );
          }
        }

        return null;
      })}
      {shouldRenderSecureKeyBreadcrumb ? (
        <Typography className={classes.typography} key="secure-key-operation">
          {'Secure Key Operation'}
        </Typography>
      ) : null}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;
