import React, { useEffect } from 'react';
import { Card, Checkbox, Grid, MenuItem, TextField } from '@mui/material';
import {
  AvailableIdentityProviders,
  NewCustomer,
} from '../Model/CustomerModel';
import { useStyles } from '../../../../Common/Styles/FarmStyle';
import { ValidationErrors, Validator } from 'fluentvalidation-ts';
import { customerService } from '../CustomerService';
import { ValidateEmails } from '../../EmailAdministration/Common/Common';

enum Ops {
  Create = 'Create',
  Edit = 'Edit',
}

type OpsType = 'Create' | 'Edit';

interface CustomerFormProps {
  groupNames: string[];
  onFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  customer: NewCustomer;
  errors: ValidationErrors<NewCustomer>;
  setErrors: React.Dispatch<
    React.SetStateAction<ValidationErrors<NewCustomer>>
  >;
  ops: OpsType;
}

class CustomerValidator extends Validator<NewCustomer> {
  constructor() {
    super();
    this.ruleFor('Subdomain')
      .notEmpty()
      .withMessage('Subdomain cannot be empty')
      .notNull()
      .withMessage('Subdomain cannot be empty');

    this.ruleFor('CustomerName')
      .notEmpty()
      .withMessage('Customer Name cannot be empty')
      .notNull()
      .withMessage('Customer Name cannot be empty');

    this.ruleFor('GroupName')
      .notEmpty()
      .withMessage('Group Name cannot be empty')
      .notNull()
      .withMessage('Group Name cannot be empty');

    this.ruleFor('AdminUser')
      .notEmpty()
      .withMessage('Admin User Email cannot be empty')
      .emailAddress()
      .withMessage('Should be Email Addess')
      .notNull()
      .withMessage('Admin User Name cannot be empty');

    this.ruleFor('AzureADAppClientID')
      .notEmpty()
      .withMessage('Azure AD App Client ID cannot be empty')
      .notNull()
      .withMessage('Azure AD App Client ID cannot be empty');

    this.ruleFor('AuthProviderName')
      .notEmpty()
      .withMessage('Auth Provider Name cannot be empty')
      .notNull()
      .withMessage('Auth Provider Name cannot be empty');

    this.ruleFor('WSFederationUrl')
      .notEmpty()
      .withMessage('Auth Metadata URL cannot be empty')
      .notNull()
      .withMessage('Auth Metadata URL cannot be empty');

    this.ruleFor('InputClaimType')
      .notEmpty()
      .withMessage('Input Claim Type cannot be empty')
      .notNull()
      .withMessage('Input Claim Type cannot be empty');

    this.ruleFor('IdentityProviders')
      .notEmpty()
      .withMessage('Identity Providers cannot be empty')
      .notNull()
      .withMessage('Identity Providers cannot be empty');

    this.ruleFor('ContactPersons')
      .notEmpty()
      .withMessage('Contact Email cannot be empty')
      .must((value: unknown) => ValidateEmails(value))
      .withMessage('Each email should be a valid email address');

    this.ruleFor('AdminUserName')
      .notEmpty()
      .withMessage('Admin User Name cannot be empty')
      .notNull()
      .withMessage('Admin User Name cannot be empty');
  }
}

function checkSubdomainExists(subdomain: string) {
  return customerService.validateSubdomain(subdomain);
}

const CustomerForm: React.FC<CustomerFormProps> = (props) => {
  const { groupNames, customer, errors, onFieldChange, setErrors, ops } = props;
  const classes = useStyles();
  const disableControls = ops === Ops.Edit;

  const customerValidator = new CustomerValidator();

  const validate = () => {
    const customerErrors = customerValidator.validate(customer);
    setErrors(customerErrors);
  };

  const validateSubdomain = () => {
    if (customer.Subdomain) {
      const exists = checkSubdomainExists(customer.Subdomain);
      if (exists) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          Subdomain: 'This subdomain already exists',
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, Subdomain: '' }));
      }
    }
    validate();
  };

  useEffect(() => {
    validateSubdomain();
  }, [customer]);

  return (
    <div className={classes.outerContainer}>
      <Grid container>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card elevation={0} className={classes.card}>
            <h3>Basic information</h3>
            <div>
              <div className={classes.spanContainer}>
                <span className={classes.span}>Subdomain</span>
                <TextField
                  disabled={disableControls}
                  className={classes.textField}
                  error={!!errors.Subdomain}
                  helperText={errors.Subdomain}
                  value={customer.Subdomain}
                  placeholder="Subdomain"
                  name="Subdomain"
                  onChange={onFieldChange}
                  onBlur={validateSubdomain}
                />
              </div>
              <div className={classes.spanContainer}>
                <span className={classes.span}>Customer Name</span>
                <TextField
                  className={classes.textField}
                  error={!!errors.CustomerName}
                  helperText={errors.CustomerName}
                  value={customer.CustomerName}
                  placeholder="Customer Name"
                  name="CustomerName"
                  onChange={onFieldChange}
                  onBlur={validate}
                />
              </div>
              <div className={classes.spanContainer}>
                <span className={classes.span}>Installation type</span>
                <TextField
                  disabled
                  className={classes.textField}
                  value={customer.SetupType}
                />
              </div>
              <div className={classes.spanContainer}>
                <span className={classes.span}>Enterprise Number</span>
                <TextField
                  className={classes.textField}
                  value={customer.EnterpriseNumber}
                  placeholder="Enterprise Number"
                  name="EnterpriseNumber"
                  onChange={onFieldChange}
                />
              </div>
              <div className={classes.spanContainer}>
                <span className={classes.span}>Contact Email</span>
                <TextField
                  className={classes.textField}
                  error={!!errors.ContactPersons}
                  helperText={errors.ContactPersons}
                  value={customer.ContactPersons}
                  placeholder="Contact Email"
                  name="ContactPersons"
                  onChange={onFieldChange}
                  onBlur={validate}
                />
              </div>
            </div>
          </Card>
          <Card elevation={0} className={classes.card}>
            <h3>Group</h3>
            <div>
              <div className={classes.spanContainer}>
                <span className={classes.span}>Group Name</span>
                <TextField
                  className={classes.textField}
                  error={!!errors.GroupName}
                  helperText={errors.GroupName}
                  value={customer.GroupName ?? ''}
                  placeholder="Group Name"
                  name="GroupName"
                  onChange={onFieldChange}
                  onBlur={validate}
                  select
                >
                  {groupNames.map((group) => {
                    return (
                      <MenuItem key={group} value={group}>
                        {group}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            </div>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Card elevation={0} className={classes.card}>
            <h3>Authentication</h3>
            <span>Enable ProArc Login</span>
            <Checkbox
              id="Enable ProArc Login"
              value={customer.EnableProArcLogin ?? false}
              checked={customer.EnableProArcLogin ?? false}
              name="EnableProArcLogin"
              onChange={(e) => onFieldChange(e)}
              onBlur={validate}
            />

            <span>Enable External Identity Provider</span>
            <Checkbox
              id="ExternalIdentityProvider"
              value={customer.ExternalIdentityProvider ?? false}
              checked={customer.ExternalIdentityProvider ?? false}
              name="ExternalIdentityProvider"
              onChange={(e) => onFieldChange(e)}
              onBlur={validate}
            />
            <div>
              <div className={classes.spanContainer}>
                <span className={classes.span}>Admin Email Id</span>
                <TextField
                  className={classes.textField}
                  error={!!errors.AdminUser}
                  helperText={errors.AdminUser}
                  value={customer.AdminUser}
                  placeholder="Admin User"
                  name="AdminUser"
                  onChange={onFieldChange}
                  onBlur={validate}
                />
              </div>
              <div className={classes.spanContainer}>
                <span className={classes.span}>Admin User Name</span>
                <TextField
                  className={classes.textField}
                  error={!!errors.AdminUserName}
                  helperText={errors.AdminUserName}
                  value={customer.AdminUserName}
                  placeholder="Admin User Name"
                  name="AdminUserName"
                  onChange={onFieldChange}
                  onBlur={validate}
                />
              </div>
              {customer.ExternalIdentityProvider && (
                <>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Identity Provider</span>
                    <TextField
                      className={classes.textField}
                      error={!!errors.IdentityProviders}
                      helperText={errors.IdentityProviders}
                      value={customer.IdentityProviders ?? ''}
                      placeholder="Identity Provider"
                      name="IdentityProviders"
                      onChange={onFieldChange}
                      onBlur={validate}
                      select
                    >
                      {Object.entries(AvailableIdentityProviders).map(
                        ([key, value]) => {
                          return (
                            <MenuItem key={key} value={value}>
                              {value}
                            </MenuItem>
                          );
                        },
                      )}
                    </TextField>
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Input Claim Type</span>
                    <TextField
                      className={classes.textField}
                      error={!!errors.InputClaimType}
                      helperText={errors.InputClaimType}
                      value={customer.InputClaimType}
                      placeholder="Input Claim Type"
                      name="InputClaimType"
                      onChange={onFieldChange}
                      onBlur={validate}
                    />
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Auth Client ID</span>
                    <TextField
                      className={classes.textField}
                      error={!!errors.AzureADAppClientID}
                      helperText={errors.AzureADAppClientID}
                      value={customer.AzureADAppClientID}
                      placeholder="Auth Client ID"
                      name="AzureADAppClientID"
                      onChange={onFieldChange}
                      onBlur={validate}
                    />
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Auth Metadata URL</span>
                    <TextField
                      className={classes.textField}
                      error={!!errors.WSFederationUrl}
                      helperText={errors.WSFederationUrl}
                      value={customer.WSFederationUrl}
                      placeholder="Auth Metadata URL"
                      name="WSFederationUrl"
                      onChange={onFieldChange}
                      onBlur={validate}
                    />
                  </div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Auth Provider Name</span>
                    <TextField
                      className={classes.textField}
                      error={!!errors.AuthProviderName}
                      helperText={errors.AuthProviderName}
                      value={customer.AuthProviderName}
                      placeholder="Auth Provider Name"
                      name="AuthProviderName"
                      onChange={onFieldChange}
                      onBlur={validate}
                    />
                  </div>
                </>
              )}
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomerForm;
