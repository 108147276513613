import React, { useContext, useMemo, useState } from 'react';
import PermissionsWrapper from './Layout/UIContainer/PermissionsWrapper';

import {
  ThemeProvider,
  Theme,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import {
  ThemeContext,
  PreferredThemeContext,
  useToggleDarkMode,
} from '../Theme/UseToggleDarkMode';
import PublicPageWrapper from './Layout/UIContainer/PublicPageWrapper';
import { AuthContext, PubliRouteContext } from '..';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const App = () => {
  const [prefersDarkMode, , setColorMode] = useToggleDarkMode();
  const [disablePermissionWrapper, setDisablePermissionWrapper] =
    useState(false);

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
          primary: {
            main: '#0078d4',
          },
          secondary: {
            main: '#d5d5d5',
          },
          success: {
            main: '#4caf50',
          },
          error: {
            main: '#f44336',
          },
          warning: {
            main: '#ff9800',
          },
          info: {
            main: '#2196f3',
          },
        },
      }),
    [prefersDarkMode],
  );

  const isAuthenticated = useContext(AuthContext);
  const ispublicRoute = useContext(PubliRouteContext);

  useMemo(() => {
    if (!isAuthenticated) {
      setDisablePermissionWrapper(true);
    } else if (ispublicRoute) {
      setDisablePermissionWrapper(false);
    }
  }, []);

  const toggleTheme = React.useCallback(() => {
    setColorMode();
  }, [setColorMode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ThemeContext.Provider value={toggleTheme}>
          <PreferredThemeContext.Provider value={prefersDarkMode}>
            {disablePermissionWrapper ? (
              <PublicPageWrapper />
            ) : (
              <PermissionsWrapper />
            )}
          </PreferredThemeContext.Provider>
        </ThemeContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
