import React, { useState, useEffect, useContext } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import { EditorProps, EditorOption } from '../Model/EditorModel';
import { TriggerDispatchContext } from '../Model/TriggerState';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PermissionsContext } from '../../UIContainer/PermissionsWrapper';
import PermissionConstants from '../../../Common/Constants/PermissionConstants';
import { PreferredThemeContext } from '../../../../Theme/UseToggleDarkMode';

require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');
require('codemirror/theme/xq-light.css');
require('codemirror/theme/neat.css');
require('codemirror/mode/sql/sql.js');

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: '1030px',
  },
  codeMirror: {
    height: '80vh',
  },
}));

export default function TriggerEditor(props: Readonly<EditorProps>) {
  const { content, editorContentChangedCallback } = props;
  const classes = useStyles();

  const [editorValue, setEditorValue] = useState('');
  const [initialVaule, setInitialVaule] = useState('');

  const permissions = useContext(PermissionsContext);
  const prefersDarkMode = useContext(PreferredThemeContext);

  useEffect(() => {
    setEditorValue(content);
    setInitialVaule(content);
  }, []);

  const onEditorChange = () => {
    const editorContent = JSON.stringify(editorValue);
    const initialContent = JSON.stringify(initialVaule);
    const contentChanged =
      initialContent
        .toLowerCase()
        .localeCompare(editorContent.toLowerCase()) !== 0;

    editorContentChangedCallback(contentChanged);
  };

  const options: EditorOption = {
    mode: 'sql',
    theme: prefersDarkMode ? 'material' : 'xq-light',
    lineNumbers: true,
    readOnly: permissions[PermissionConstants.WriteTrigger]
      ? false
      : 'nocursor',
  };

  return (
    <Paper className={classes.root}>
      <TriggerDispatchContext.Consumer>
        {(dispatch) => (
          <CodeMirror
            className={classes.codeMirror}
            value={editorValue}
            options={options}
            editorDidMount={(editor) => {
              editor.setSize('', '80vh');
            }}
            onBeforeChange={(_editor, _data, value) => {
              dispatch({ type: 'content', payload: value });
              setEditorValue(value);
            }}
            onChange={() => {
              onEditorChange();
            }}
          />
        )}
      </TriggerDispatchContext.Consumer>
    </Paper>
  );
}
