import { useState } from 'react';

export const useLocalStorage = (key: string, initialValue: string) => {
  // We pass useState a function that handles initial state
  // creation. That way, the function is executed only once.
  const [storedValue, setStoredValue] = useState(() => {
    if (window.localStorage) {
      const item = localStorage[key];
      if (!item) localStorage[key] = JSON.stringify(initialValue);
      return item ? JSON.parse(item) : initialValue;
    } else return initialValue;
  });

  const setValue = <T>(value: T | ((val: T) => T)) => {
    // allow value to be a function which takes the current value
    // to conform to useState API
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    setStoredValue(valueToStore);
    localStorage[key] = JSON.stringify(valueToStore);
  };

  return [storedValue, setValue];
};
