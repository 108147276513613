import { callService } from '../../Service/ServiceBase';
import { RequestType } from '../../Service/RequestType';
import { CustomerV2 } from '../Dashboard/Model/LicenseModel';

class CustomerService {
  getCustomerInfo(tenantId: string): Promise<CustomerV2> {
    const customerInfo = callService(
      'customers',
      `${tenantId}`,
      RequestType.GET,
    );
    return customerInfo;
  }
}

export const customerService: CustomerService = new CustomerService();
