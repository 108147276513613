import React, { useRef, useEffect, useState } from 'react';
import JoditEditor from 'jodit-react';
import { EmailTemplate } from '../../Layout/EmailAdministration/Model/EmailAdminModel';
import { useTheme } from '@mui/material';

interface JodiEditorProps {
  emailtemplate: EmailTemplate;
  setEmailTemplate: React.Dispatch<
    React.SetStateAction<EmailTemplate | undefined>
  >;
}

const JoditTemplateEditor = (props: JodiEditorProps) => {
  const editor = useRef<null>(null);
  const theme = useTheme();
  const [config, setConfig] = useState({});

  useEffect(() => {
    setConfig({
      readonly: false,
      placeholder: 'Start typing...',
      theme: theme.palette.mode,
      minHeight: 500,
      padding: 2,
      margin: 2,
      buttons: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'ul',
        'ol',
        '|',
        'font',
        'fontsize',
        'paragraph',
        'lineHeight',
        '|',
        'superscript',
        'subscript',
        '|',
        'hr',
        'table',
        'link',
        '|',
        'indent',
        'outdent',
        'left',
        '|',
        'brush',
        '|',
        'undo',
        'redo',
        '|',
        'preview',
      ],
    });
  }, [theme.palette.mode]);

  return (
    <JoditEditor
      className="email-editor"
      ref={editor}
      value={props.emailtemplate.Content}
      config={config}
      onBlur={(newContent: string) =>
        props.setEmailTemplate({
          ...props.emailtemplate,
          Content: newContent,
        })
      }
    />
  );
};

export default JoditTemplateEditor;
