import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { OnCallSubstituteUser, OnCallUser } from './Model/MonitoringModel';
import { PagedData } from '../../Common/CommonModel';

class MonitoringService {
  public getOnCallOfTheWeek(): Promise<OnCallUser> {
    const OnCallOfTheWeek = callService(
      'monitoring',
      'oncall',
      RequestType.GET,
    );
    return OnCallOfTheWeek;
  }

  public getUsers(): Promise<OnCallUser[]> {
    const OnCallOfTheWeek = callService('monitoring', 'users', RequestType.GET);
    return OnCallOfTheWeek;
  }

  public getUserByWeek(
    week: number,
    oncallUser: OnCallUser,
  ): Promise<OnCallUser> {
    const OnCallUserOfTheWeek = callService(
      'monitoring',
      `user/week/${week}`,
      RequestType.POST,
      oncallUser,
      false,
    );
    return OnCallUserOfTheWeek;
  }

  public addOnCallUser(oncall: OnCallUser): Promise<OnCallUser> {
    const addedUser = callService(
      'monitoring',
      'adduser',
      RequestType.POST,
      oncall,
    );
    return addedUser;
  }

  public deleteOnCallUser(userId: string): Promise<Response> {
    const response = callService(
      'monitoring',
      'remove/' + userId,
      RequestType.DELETE,
    );
    return response;
  }

  public substituteOnCallUser(
    user: OnCallSubstituteUser,
  ): Promise<OnCallSubstituteUser> {
    return callService(
      'monitoring',
      'substitute',
      RequestType.PUT,
      user,
      false,
    );
  }

  public substituteUsers(): Promise<PagedData<OnCallSubstituteUser>> {
    return callService('monitoring', 'substitute/users', RequestType.GET);
  }
}

export const monitoringService: MonitoringService = new MonitoringService();
