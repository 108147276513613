import React from 'react';
import ContentLoader from 'react-content-loader';

const TableLoader = () => (
  <ContentLoader
    speed={2}
    width={1000}
    height={550}
    viewBox="40 20 1000 550"
    backgroundColor="#c0c0c0"
    foregroundColor="#ffffff"
    backgroundOpacity={0}
  >
    <rect x="51" y="10" rx="3" ry="3" width="906" height="17" />
    <rect x="51" y="25" rx="3" ry="3" width="80" height="21" />
    <rect x="551" y="25" rx="3" ry="3" width="155" height="21" />
    <rect x="896" y="25" rx="3" ry="3" width="60" height="21" />
    <rect x="200" y="20" rx="3" ry="3" width="149" height="33" />

    <rect x="51" y="45" rx="3" ry="3" width="906" height="17" />

    <rect x="104" y="115" rx="3" ry="3" width="141" height="15" />
    <rect x="305" y="114" rx="3" ry="3" width="299" height="15" />
    <rect x="661" y="114" rx="3" ry="3" width="141" height="15" />
    <rect x="850" y="114" rx="3" ry="3" width="100" height="15" />
    <rect x="55" y="155" rx="3" ry="3" width="897" height="2" />

    <rect x="105" y="176" rx="3" ry="3" width="141" height="15" />
    <rect x="306" y="175" rx="3" ry="3" width="299" height="15" />
    <rect x="662" y="175" rx="3" ry="3" width="141" height="15" />
    <rect x="850" y="175" rx="3" ry="3" width="100" height="15" />
    <rect x="56" y="216" rx="3" ry="3" width="897" height="2" />

    <rect x="106" y="234" rx="3" ry="3" width="141" height="15" />
    <rect x="307" y="233" rx="3" ry="3" width="299" height="15" />
    <rect x="663" y="233" rx="3" ry="3" width="141" height="15" />
    <rect x="850" y="233" rx="3" ry="3" width="100" height="15" />
    <rect x="57" y="274" rx="3" ry="3" width="897" height="2" />

    <rect x="107" y="295" rx="3" ry="3" width="141" height="15" />
    <rect x="308" y="294" rx="3" ry="3" width="299" height="15" />
    <rect x="664" y="294" rx="3" ry="3" width="141" height="15" />
    <rect x="850" y="294" rx="3" ry="3" width="100" height="15" />

    <rect x="51" y="10" rx="3" ry="3" width="2" height="327" />
    <rect x="956" y="10" rx="3" ry="3" width="2" height="327" />

    <rect x="52" y="335" rx="3" ry="3" width="906" height="2" />

    <rect x="52" y="80" rx="3" ry="3" width="906" height="17" />
    <rect x="53" y="57" rx="3" ry="3" width="68" height="33" />
    <rect x="222" y="54" rx="3" ry="3" width="149" height="33" />
    <rect x="544" y="55" rx="3" ry="3" width="137" height="33" />
    <rect x="782" y="56" rx="3" ry="3" width="72" height="33" />
    <rect x="933" y="54" rx="3" ry="3" width="24" height="33" />

    <rect x="52" y="335" rx="3" ry="3" width="906" height="2" />
  </ContentLoader>
);

export default TableLoader;
