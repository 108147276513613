import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  TableRow,
  TableFooter,
  InputBase,
  TextField,
  CircularProgress,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
  Theme,
  Backdrop,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import PublishIcon from '@mui/icons-material/Publish';
import { LicenseManagementRouteProps } from '../Model/LicenseManagementProps';
import { licenseService } from '../../Api/LicenseService';
import { LicenseSummary, LicenseTypeProps, Order } from '../Model/LicenseModel';
import { toast } from 'react-toastify';
import {
  StandardLicenseTypeConstant,
  ReadLicenseTypeConstant,
  LicenseUserTypeHeaderConstant,
  LicenseExistingCountHeaderConstant,
  LicenseUpdateCountHeaderConstant,
  TotalCountFooterConstant,
  SaveAndPublishButtonLabelConstant,
  PurchaseOrderValidFromDateConstant,
  PurchaseOrderValidToDateConstant,
  PurchaseOrderDetailsLegendConstant,
  PurchaseOrderNameConstant,
  PurchaseOrderCustomerEmailConstant,
  PurchaseOrderValidityDateFormatConstant,
  ErrorMessagesConstant,
  StorageLicenseTypeConstant,
  ConfirmationForPublishPurchaseOrderConstant,
  LicenseManagementPageTitleConstant,
  SnackBarMessageConstant,
  EmailIDConstant,
  LicenseCountReducedMessage,
  LicenseCountIncreasedMessage,
  StorageCountReducedMessage,
  StorageCountIncreasedMessage,
  LicenseAddDeleteErrorMessage,
} from '../Model/LicenseConstants';
import { LicenseType } from '../../PurchaseOrders/Model/PurchaseOrderModel';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    editableTextBoxPanel: {
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '4px',
      borderColor: 'rgb(204,204,204)',
      height: 35,
      width: '100px',
      '& input.MuiInputBase-input': {
        fontSize: '14px',
        paddingLeft: 8,
        paddingRight: 8,
        height: '-webkit-fill-available',
      },
      '& input.MuiInputBase-input.Mui-disabled': {
        fontSize: '14px',
      },
      '& .MuiInputBase-root': {
        width: '-webkit-fill-available',
      },
    },

    orderNameTextBox: {
      marginLeft: '20px',
      borderStyle: 'solid',
      borderWidth: '1px',
      borderRadius: '4px',
      borderColor: 'rgb(204,204,204)',
      width: '100px',
      '& input.MuiInputBase-input': {
        fontSize: '16px',
        color: 'rgba(0, 0, 0, 0.87)',
      },
      '& input.MuiInputBase-input.Mui-disabled': {
        fontSize: '14px',
        color: 'rgba(0, 0, 0, 0.37)',
      },
      '& .MuiInputBase-root': {
        width: '100%',
      },
    },

    purchaseOrdersFieldSet: {
      marginLeft: 15,
    },

    licenseValidityPanel: {
      display: 'flex',
      alignItems: 'center',

      '& .MuiFormControl-root.MuiTextField-root.date-picker-control': {
        width: '-webkit-fill-available',
      },

      '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
        height: 35,
      },

      '& label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined':
        {
          marginTop: '-8px',
        },

      '& span': {
        marginLeft: '0px',
        width: '-webkit-fill-available',
        height: 35,
      },

      '& input.MuiInputBase-input': {
        paddingRight: 8,
        paddingLeft: 8,
      },
    },

    customerEmailPanel: {
      display: 'flex',
      alignItems: 'center',

      '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl': {
        height: 35,
      },

      '& .MuiFormControl-root.MuiTextField-root': {
        width: '-webkit-fill-available',
      },
    },

    root: {
      '& label': {
        width: '150px',
        minWidth: '150px',
        marginRight: '20px',
      },

      '& td.MuiTableCell-root.MuiTableCell-footer': {
        fontSize: '14px',
        color: 'rgb(51, 102, 153)',
        paddingTop: 11,
        paddingBottom: 11,
      },
    },

    progress: {
      marginLeft: '50%',
      marginTop: '15%',
    },

    pageTitle: {
      '& h6#tableTitle': {
        width: 'max-content',
      },
    },

    buttonsPanel: {
      float: 'right',
      marginRight: 16,
      height: '35px',
      width: '100px',
    },

    dialogActionButton: {
      '& .MuiButton-root.MuiButton-textPrimary': {
        color: theme.palette.primary.light,
      },
    },

    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },

    snackBar: {
      '& span.messagePanel': {
        display: 'block',
        width: '-webkit-fill-available',
        marginRight: '20px',
      },
    },

    mainDiv: {
      display: 'flex',

      '& .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded': {
        width: '450px',
        height: 'fit-content',
      },
      '& .purchaseOrdersFieldSetDiv': {
        width: '50%',
        marginLeft: 20,
        marginTop: 2,
        minWidth: 450,
        '& .heading': {
          padding: 16,
          color: '#336699',
          fontSize: '15px',
          fontWeight: '550',
        },
      },
      '& th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-stickyHeader': {
        border: '0',
      },
      '& td.MuiTableCell-root.MuiTableCell-body': {
        border: '0',
      },
    },
    disableLabel: {
      opacity: '0.5',
      pointerEvents: 'none',
    },
  }),
);

const ShowStorageCountReducing = (updatedStorageLicenseCount: number) => {
  return (
    <tr>
      <td>
        {StorageLicenseTypeConstant.id} {StorageCountReducedMessage}
      </td>
      <td>:</td>
      <td>{updatedStorageLicenseCount} GB</td>
    </tr>
  );
};

const ShowStorageCountIncreasing = (updatedStorageLicenseCount: number) => {
  return (
    <tr>
      <td>
        {StorageLicenseTypeConstant.id} {StorageCountIncreasedMessage}
      </td>
      <td>:</td>
      <td>{updatedStorageLicenseCount} GB</td>
    </tr>
  );
};

const ShowReadLicenseCountLessThanZero = (updatedReadLicenseCount: number) => {
  return (
    <tr>
      <td>
        {ReadLicenseTypeConstant.description} {LicenseCountReducedMessage}
      </td>
      <td>:</td>
      <td>{updatedReadLicenseCount}</td>
    </tr>
  );
};

const ShowReadLicenseCountGreaterThanZero = (
  updatedReadLicenseCount: number,
) => {
  return (
    <tr>
      <td>
        {ReadLicenseTypeConstant.description} {LicenseCountIncreasedMessage}
      </td>
      <td>:</td>
      <td>{updatedReadLicenseCount}</td>
    </tr>
  );
};

const ShowStandardLicenseCountLessThanZero = (
  updatedStandardLicenseCount: number,
) => {
  return (
    <tr>
      <td>
        {StandardLicenseTypeConstant.description} {LicenseCountReducedMessage}
      </td>
      <td>:</td>
      <td>{updatedStandardLicenseCount}</td>
    </tr>
  );
};

const ShowStandardLicenseCountGreaterThanZero = (
  updatedStandardLicenseCount: number,
) => {
  return (
    <tr>
      <td>
        {StandardLicenseTypeConstant.description} {LicenseCountIncreasedMessage}
      </td>
      <td>:</td>
      <td>{updatedStandardLicenseCount}</td>
    </tr>
  );
};
const showProgressBackDropElement = (
  showProgressRing: boolean,
  classes: Record<string, string>,
): ReactElement => {
  return (
    <Backdrop className={classes.backdrop} open={showProgressRing}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default function ManageLicenses(
  routeProps: Readonly<LicenseManagementRouteProps>,
) {
  const { tenantId } = routeProps.match.params;
  const classes = useStyles();
  const history = useHistory();
  const maxLicenseCount = 99999;

  // License info object
  const [licenseInfo, setLicenseInfo] = useState({} as LicenseSummary);

  // Confirmation dialog
  const [confirmationDialogProps, setConfirmationDialogProps] = useState({
    isOpen: false,
    dialogAction: '',
    message: <></>,
  });

  // Update To - Standard License
  const [updatedStandardLicenseCount, setUpdatedStandardLicenseCount] =
    useState(0);

  // Update To - Read License
  const [updatedReadLicenseCount, setUpdatedReadLicenseCount] = useState(0);

  // Update To - Storage License
  const [updatedStorageLicenseCount, setUpdatedStorageLicenseCount] =
    useState(0);

  // PurchaseOrderValidFrom
  const [purchaseOrderValidFrom, setPurchaseOrderValidFrom] = useState('');
  const getPurchaseOrderValidFrom = () => {
    return purchaseOrderValidFrom === ''
      ? null
      : new Date(purchaseOrderValidFrom);
  };
  const onPurchaseOrderValidFromChanged = (selectedDate: Date | null) => {
    setPurchaseOrderValidFrom(selectedDate ? selectedDate.toISOString() : '');
  };

  const [showProgressRing, setShowProgressRing] = React.useState(false);

  //PurchaseOrderValidTo
  const [purchaseOrderValidTo, setpurchaseOrderValidTo] = useState('');
  const getPurchaseOrderValidTo = () => {
    return purchaseOrderValidTo === '' ? null : new Date(purchaseOrderValidTo);
  };
  const onPurchaseOrderValidToChanged = (selectedDate: Date | null) => {
    setpurchaseOrderValidTo(selectedDate ? selectedDate.toISOString() : '');
  };

  // ValidFrom - error fields
  const [poValidFromErrorMessage, setpoValidFromErrorMessage] = useState('');
  const [poValidFromError, setpoValidFromError] = useState(false);

  // ValidTo - error fields
  const [poValidToErrorMessage, setpoValidToErrorMessage] = useState('');
  const [poValidToError, setpoValidToError] = useState(false);

  const [purchaseOrderName, setPurchaseOrderName] = useState('');
  const [purchaseOrderCustomerEmail, setPurchaseOrderCustomerEmail] =
    useState('');
  const [poCustomerEmailError, setpoCustomerEmailError] = useState({
    hasError: false,
    errorMessage: '',
  });

  // IsLoading
  const [isLoading, setIsLoading] = useState(true);

  //ToolTip Message
  const [standardTooltipMessage, setStandardTooltipMessage] = useState(
    StandardLicenseTypeConstant.description,
  );
  const [readTooltipMessage, setReadTooltipMessage] = useState(
    ReadLicenseTypeConstant.description,
  );
  const [storageTooltipMessage, setStorageTooltipMessage] = useState(
    StorageLicenseTypeConstant.description,
  );
  const [licenseTypes, setLicenseTypes] = useState({} as LicenseTypeProps[]);

  useEffect(() => {
    if (isLoading) {
      licenseService
        .getLicenseSummary(tenantId)
        .then((license) => {
          setLicenseInfo(license);
          setLicenseTypes([
            {
              id: StandardLicenseTypeConstant.id,
              description: StandardLicenseTypeConstant.description,
              existingCount: license.StandardUsersLicenses,
            },
            {
              id: ReadLicenseTypeConstant.id,
              description: ReadLicenseTypeConstant.description,
              existingCount: license.ReadUsersLicenses,
            },
            {
              id: StorageLicenseTypeConstant.id,
              description: StorageLicenseTypeConstant.description,
              existingCount: license.StorageLicenses,
            },
          ]);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [isLoading]);

  const handleLicenseCountChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: string,
    existingCount: number,
  ) => {
    const selectedElement = document.getElementById(event.target.id);
    let inputValue = Number(event.target.value);

    if (Number.isInteger(inputValue) === false) {
      inputValue =
        inputValue > 0 ? Math.floor(inputValue) : Math.ceil(inputValue);
      (selectedElement as HTMLInputElement).value = inputValue.toString();
    }

    if (inputValue < -existingCount)
      (selectedElement as HTMLInputElement).value = (-existingCount).toString();

    setUpdateLicenseCountById(id, Number(event.target.value));
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
    existingCount: number,
  ) => {
    const inputValue = (event.target as HTMLInputElement).value;
    if (
      Number(inputValue) < -existingCount ||
      Number(inputValue) > Number(maxLicenseCount.toString().slice(0, -1))
    ) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    }
  };

  const getLicenseCountToolTipMessage = (id: string): string => {
    switch (id) {
      case StandardLicenseTypeConstant.id:
        return standardTooltipMessage;
      case ReadLicenseTypeConstant.id:
        return readTooltipMessage;
      case StorageLicenseTypeConstant.id:
        return storageTooltipMessage;
      default:
        return '';
    }
  };

  const setLicenseCountToolTipMessage = (id: string, updatedCount: number) => {
    switch (id) {
      case StandardLicenseTypeConstant.id:
        updatedCount < 0
          ? setStandardTooltipMessage(
              `${
                StandardLicenseTypeConstant.description
              } ${LicenseCountReducedMessage} ${-updatedCount}`,
            )
          : updatedCount > 0
            ? setStandardTooltipMessage(
                `${StandardLicenseTypeConstant.description} ${LicenseCountIncreasedMessage} ${updatedCount}`,
              )
            : setStandardTooltipMessage(
                StandardLicenseTypeConstant.description,
              );
        break;
      case ReadLicenseTypeConstant.id:
        updatedCount < 0
          ? setReadTooltipMessage(
              `${
                ReadLicenseTypeConstant.description
              } ${LicenseCountReducedMessage} ${-updatedCount}`,
            )
          : updatedCount > 0
            ? setReadTooltipMessage(
                `${ReadLicenseTypeConstant.description} ${LicenseCountIncreasedMessage} ${updatedCount}`,
              )
            : setReadTooltipMessage(ReadLicenseTypeConstant.description);
        break;
      case StorageLicenseTypeConstant.id:
        updatedCount < 0
          ? setStorageTooltipMessage(
              `${StorageLicenseTypeConstant.id}
              ${StorageCountReducedMessage}
              ${-updatedCount} GB`,
            )
          : updatedCount > 0
            ? setStorageTooltipMessage(
                `${StorageLicenseTypeConstant.id} 
              ${StorageCountIncreasedMessage} 
              ${updatedCount} GB`,
              )
            : setStorageTooltipMessage(StorageLicenseTypeConstant.description);
        break;
      default:
        break;
    }
  };

  const setUpdateLicenseCountById = (id: string, updatedCount: number) => {
    switch (id) {
      case StandardLicenseTypeConstant.id:
        setLicenseCountToolTipMessage(id, updatedCount);
        setUpdatedStandardLicenseCount(updatedCount);
        break;
      case ReadLicenseTypeConstant.id:
        setLicenseCountToolTipMessage(id, updatedCount);
        setUpdatedReadLicenseCount(updatedCount);
        break;
      case StorageLicenseTypeConstant.id:
        setLicenseCountToolTipMessage(id, updatedCount);
        setUpdatedStorageLicenseCount(updatedCount);
        break;
      default:
        break;
    }
  };

  const getUpdatedLicenseTotalCount = () => {
    return updatedStandardLicenseCount + updatedReadLicenseCount;
  };

  const setValidityError = (
    forValidFrom: boolean,
    forValidTo: boolean,
    error: boolean,
    errorMessage: string = '',
  ) => {
    if (forValidFrom) {
      setpoValidFromError(error);
      setpoValidFromErrorMessage(errorMessage);
    }

    if (forValidTo) {
      setpoValidToError(error);
      setpoValidToErrorMessage(errorMessage);
    }
  };

  const onPurchaseOrderValidFromChange = (date: Date | null) => {
    if (!date) {
      setValidityError(true, false, true, ErrorMessagesConstant.emptyValue);
    } else {
      if (purchaseOrderValidTo !== '') {
        if (new Date(purchaseOrderValidTo) < date) {
          setValidityError(
            false,
            true,
            true,
            ErrorMessagesConstant.validToDateLessThanValidFrom,
          );
        } else {
          setValidityError(false, true, false);
        }
      }
      setValidityError(true, false, false);
    }
    onPurchaseOrderValidFromChanged(date);
  };

  const onPurchaseOrderValidToChange = (date: Date | null) => {
    if (!date) {
      setValidityError(false, true, true, ErrorMessagesConstant.emptyValue);
    } else {
      if (date < new Date(purchaseOrderValidFrom)) {
        setValidityError(
          false,
          true,
          true,
          ErrorMessagesConstant.validToDateLessThanValidFrom,
        );
      } else {
        setValidityError(false, true, false);
      }
    }
    onPurchaseOrderValidToChanged(date);
  };

  const buildPublishOrderData = () => {
    return {
      Name: purchaseOrderName,
      TenantId: tenantId,
      CustomerName: licenseInfo.CustomerName,
      ValidFrom: purchaseOrderValidFrom,
      ValidTo: purchaseOrderValidTo,
      ContactEmail: purchaseOrderCustomerEmail,
      Licenses: [
        {
          LicenseTypeId: Number(LicenseType.FileStorage),
          Count: updatedStorageLicenseCount,
        },
        {
          LicenseTypeId: Number(LicenseType.StandardUsers),
          Count: updatedStandardLicenseCount,
        },
        {
          LicenseTypeId: Number(LicenseType.ReadUsers),
          Count: updatedReadLicenseCount,
        },
      ],
    } as Order;
  };

  const publishPurchaseOrder = () => {
    const purchaseOrder = buildPublishOrderData();
    setShowProgressRing(true);
    licenseService
      .savePurchaseOrder(tenantId, purchaseOrder)
      .then(() => {
        // initiate license publish
        licenseService
          .publishLicense(tenantId)
          .then((response) => {
            if (response)
              toast.success(SnackBarMessageConstant.purchaseOrderSavedMessage);
            else toastPublishPurchaseOrderFailed();
          })
          .catch(() => {
            toastPublishPurchaseOrderFailed();
          })
          .finally(() => {
            setShowProgressRing(false);
            history.push(`/purchaseorders/${tenantId}`);
          });
      })
      .catch(() => {
        toast.error(SnackBarMessageConstant.purchaseOrderSaveError);
        setShowProgressRing(false);
      });
  };

  const toastPublishPurchaseOrderFailed = () => {
    toast.error(SnackBarMessageConstant.purchaseOrderPublishFailedMessage);
  };

  const closeConfirmationForPublishPurchaseOrder = () => {
    setConfirmationDialogProps({
      isOpen: false,
      dialogAction: '',
      message: <></>,
    });
  };

  const showConfirmationForPublishPurchaseOrder = () => {
    setConfirmationDialogProps({
      isOpen: true,
      dialogAction: 'QUESTION',
      message: (
        <>
          <span>{ConfirmationForPublishPurchaseOrderConstant.messagePart}</span>
          <br />
          <br />
          <table>
            {(() => {
              if (updatedStandardLicenseCount > 0) {
                return ShowStandardLicenseCountGreaterThanZero(
                  updatedStandardLicenseCount,
                );
              } else if (updatedStandardLicenseCount < 0) {
                return ShowStandardLicenseCountLessThanZero(
                  updatedStandardLicenseCount,
                );
              }
            })()}
            {(() => {
              if (updatedReadLicenseCount > 0) {
                return ShowReadLicenseCountGreaterThanZero(
                  updatedReadLicenseCount,
                );
              } else if (updatedReadLicenseCount < 0) {
                return ShowReadLicenseCountLessThanZero(
                  updatedReadLicenseCount,
                );
              }
            })()}
            {(() => {
              if (updatedStorageLicenseCount > 0) {
                return ShowStorageCountIncreasing(updatedStorageLicenseCount);
              } else if (updatedStorageLicenseCount < 0) {
                return ShowStorageCountReducing(updatedStorageLicenseCount);
              }
            })()}
          </table>
        </>
      ),
    });
  };

  // UI Elements and methods

  const canPublishPurchaseOrder = (): boolean => {
    const licenseCountContainsAnyPositiveValue =
      updatedStandardLicenseCount > 0 ||
      updatedReadLicenseCount > 0 ||
      updatedStorageLicenseCount > 0;
    const licenseCountContainsAnyNegativeValue =
      updatedStandardLicenseCount < 0 ||
      updatedReadLicenseCount < 0 ||
      updatedStorageLicenseCount < 0;

    if (purchaseOrderValidFrom === '') {
      setValidityError(true, false, true, ErrorMessagesConstant.emptyValue);
      return false;
    }

    //Do not validate Valid To - if count is < 0 because ValidTo will be computed
    if (
      !licenseCountContainsAnyNegativeValue &&
      (getUpdatedLicenseTotalCount() > 0 || updatedStorageLicenseCount > 0) &&
      purchaseOrderValidTo === ''
    ) {
      setValidityError(false, true, true, ErrorMessagesConstant.emptyValue);
      return false;
    }

    if (poValidFromError || poValidToError || poCustomerEmailError.hasError) {
      return false; //if there is an error in the form, do not continue
    }

    if (
      licenseCountContainsAnyPositiveValue &&
      licenseCountContainsAnyNegativeValue
    ) {
      setConfirmationDialogProps({
        isOpen: true,
        dialogAction: 'INFORMATION',
        message: <>{LicenseAddDeleteErrorMessage}</>,
      });
      return false;
    }

    return true;
  };

  const validateCustomerEmailId = () => {
    if (purchaseOrderCustomerEmail === '') return;

    if (!EmailIDConstant.regex.test(purchaseOrderCustomerEmail)) {
      setpoCustomerEmailError({
        hasError: true,
        errorMessage: EmailIDConstant.invalidMessage,
      });
      return false;
    } else {
      setpoCustomerEmailError({ hasError: false, errorMessage: '' });
    }
  };

  const createTableFooterRow = (): ReactElement => {
    return (
      <TableHead>
        <TableRow>
          <TableCell>{TotalCountFooterConstant}</TableCell>
          <TableCell align="center">
            {licenseInfo.StandardUsersLicenses + licenseInfo.ReadUsersLicenses}
          </TableCell>
          <TableCell>{getUpdatedLicenseTotalCount()}</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const createTableHeaderRow = (): ReactElement => {
    return (
      <TableRow>
        <TableCell>{LicenseUserTypeHeaderConstant}</TableCell>
        <TableCell align="center">
          {LicenseExistingCountHeaderConstant}
        </TableCell>
        <TableCell align="center">{LicenseUpdateCountHeaderConstant}</TableCell>
      </TableRow>
    );
  };

  const manageLicenseTableElement = (): ReactElement => {
    return (
      <Paper>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>{createTableHeaderRow()}</TableHead>
            <TableBody>
              {licenseTypes.map((licenseType) => {
                return (
                  <TableRow key={licenseType.id}>
                    <TableCell>{licenseType.description}</TableCell>
                    <TableCell align="center">
                      {licenseType.existingCount}
                    </TableCell>
                    <TableCell>
                      <div className={classes.editableTextBoxPanel}>
                        <Tooltip
                          arrow
                          title={getLicenseCountToolTipMessage(licenseType.id)}
                        >
                          <InputBase
                            type="number"
                            id={licenseType.id}
                            defaultValue={0}
                            inputProps={{
                              min: -licenseType.existingCount,
                              max: maxLicenseCount,
                            }}
                            onKeyDown={(e) => {
                              handleKeyPress(e, licenseType.existingCount);
                            }}
                            onChange={(e) => {
                              handleLicenseCountChange(
                                e,
                                licenseType.id,
                                licenseType.existingCount,
                              );
                            }}
                          />
                        </Tooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            {createTableFooterRow()}
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  const purchaseOrderDetailsTableElement = (): ReactElement => {
    const validToElementDisabled = (() => {
      const disabled =
        updatedReadLicenseCount < 0 ||
        updatedStandardLicenseCount < 0 ||
        updatedStorageLicenseCount < 0;

      // when disabling ValidTo field ensure, it's cleared
      if (disabled && purchaseOrderValidTo !== '') setpurchaseOrderValidTo('');

      return disabled;
    })();

    return (
      <Paper className={classes.purchaseOrdersFieldSet}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={3}>
                  {PurchaseOrderDetailsLegendConstant}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{PurchaseOrderNameConstant.label}</TableCell>
                <TableCell colSpan={2}>{purchaseOrderNameElement()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {PurchaseOrderValidFromDateConstant.label}
                </TableCell>
                <TableCell colSpan={2}>
                  <div className={classes.licenseValidityPanel}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        slotProps={{
                          textField: {
                            error: poValidFromError,
                            helperText: poValidFromErrorMessage,
                            required: true,
                            placeholder:
                              PurchaseOrderValidFromDateConstant.placeholderText,
                          },
                        }}
                        value={getPurchaseOrderValidFrom()}
                        format={PurchaseOrderValidityDateFormatConstant}
                        onChange={(date) => {
                          onPurchaseOrderValidFromChange(date);
                        }}
                        className="date-picker-control"
                      />
                    </LocalizationProvider>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  className={validToElementDisabled ? classes.disableLabel : ''}
                >
                  {PurchaseOrderValidToDateConstant.label}
                </TableCell>
                <TableCell colSpan={2}>
                  <div className={classes.licenseValidityPanel}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        slotProps={{
                          textField: {
                            error: poValidToError,
                            helperText: poValidToErrorMessage,
                            required: true,
                            placeholder: validToElementDisabled
                              ? PurchaseOrderValidToDateConstant.placeholderComputedText
                              : PurchaseOrderValidToDateConstant.placeholderText,
                          },
                        }}
                        value={getPurchaseOrderValidTo()}
                        format={PurchaseOrderValidityDateFormatConstant}
                        onChange={(date: Date | null) => {
                          onPurchaseOrderValidToChange(date);
                        }}
                        className="date-picker-control"
                      />
                    </LocalizationProvider>
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {PurchaseOrderCustomerEmailConstant.label}
                </TableCell>
                <TableCell colSpan={2}>
                  {purchaseOrderCustomerContactElement()}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableFooter>{buttonsPanelElement()}</TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    );
  };

  const purchaseOrderCustomerContactElement = (): ReactElement => {
    return (
      <div className={classes.customerEmailPanel}>
        <TextField
          variant="outlined"
          type="string"
          value={purchaseOrderCustomerEmail}
          error={poCustomerEmailError.hasError}
          helperText={poCustomerEmailError.errorMessage}
          placeholder={PurchaseOrderCustomerEmailConstant.placeholderText}
          onChange={(e) => {
            setPurchaseOrderCustomerEmail(e.target.value);
            validateCustomerEmailId();
          }}
        />
      </div>
    );
  };

  const purchaseOrderNameElement = (): ReactElement => {
    return (
      <div className={classes.customerEmailPanel}>
        <TextField
          variant="outlined"
          type="string"
          placeholder={PurchaseOrderNameConstant.placeholderText}
          value={purchaseOrderName}
          onChange={(e) => {
            setPurchaseOrderName(e.target.value);
          }}
        />
      </div>
    );
  };

  const buttonsPanelElement = (): ReactElement => {
    return (
      <TableRow>
        <TableCell />
        <TableCell />
        <TableCell>
          <div className={classes.buttonsPanel}>
            <Button
              variant="contained"
              color="primary"
              disabled={
                getUpdatedLicenseTotalCount() === 0 &&
                updatedStorageLicenseCount === 0
              }
              onClick={() => {
                if (canPublishPurchaseOrder())
                  showConfirmationForPublishPurchaseOrder();
              }}
            >
              <PublishIcon /> {SaveAndPublishButtonLabelConstant}
            </Button>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  const pageToolbarElement = (): ReactElement => {
    return (
      <Toolbar disableGutters={true}>
        <div className={classes.pageTitle}>
          <Typography variant="h6" id="tableTitle">
            {LicenseManagementPageTitleConstant}
            {licenseInfo.CustomerName.toUpperCase()}
          </Typography>
        </div>
      </Toolbar>
    );
  };

  const publishConfirmationDialogElement = (): ReactElement => {
    return (
      <Dialog
        disableEscapeKeyDown
        maxWidth="xs"
        open={confirmationDialogProps.isOpen}
        onClose={() => {
          closeConfirmationForPublishPurchaseOrder();
        }}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {confirmationDialogProps.message}
          </DialogContentText>
        </DialogContent>

        {confirmationDialogProps.dialogAction === 'INFORMATION' ? (
          <DialogActions className={classes.dialogActionButton}>
            <Button
              onClick={() => {
                closeConfirmationForPublishPurchaseOrder();
              }}
              color="primary"
              autoFocus
            >
              {ConfirmationForPublishPurchaseOrderConstant.okButtonLabel}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions className={classes.dialogActionButton}>
            <Button
              onClick={() => {
                closeConfirmationForPublishPurchaseOrder();
                publishPurchaseOrder();
              }}
              color="primary"
            >
              {ConfirmationForPublishPurchaseOrderConstant.confirmButtonLabel}
            </Button>
            <Button
              onClick={() => {
                closeConfirmationForPublishPurchaseOrder();
              }}
              color="primary"
              autoFocus
            >
              {ConfirmationForPublishPurchaseOrderConstant.cancelButtonLabel}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  };

  return (
    <div className={classes.root}>
      {isLoading ? (
        <>
          <CircularProgress className={classes.progress} />
        </>
      ) : (
        <>
          {pageToolbarElement()}
          <div className={classes.mainDiv}>
            {manageLicenseTableElement()}
            {purchaseOrderDetailsTableElement()}
          </div>
          {showProgressBackDropElement(showProgressRing, classes)}
          {publishConfirmationDialogElement()}
        </>
      )}
    </div>
  );
}
