import React, { createContext, useEffect, useState } from 'react';

import Wrapper from './Wrapper';
import { globalService } from './Global/GlobalService';
import { Permissions } from './Global/GlobalState';
import { Dictionary } from '../../Common/CommonModel';

export const PermissionsContext = createContext<Dictionary<Permissions>>({});

export default function PermissionsWrapper() {
  const [userPermissions, setUserPermissions] = useState<
    Dictionary<Permissions>
  >({});
  const [permissionsLoading, setPermissionsLoading] = useState(true);

  useEffect(() => {
    const permissionsLiteral: Dictionary<Permissions> = {};
    globalService.getCurrentUserPermissions().then((permissions) => {
      permissions.forEach((permission) => {
        permissionsLiteral[permission.PermissionName] = permission;
      });
      setUserPermissions(permissionsLiteral);
      setPermissionsLoading(false);
    });
  }, []);

  return (
    <div>
      {!permissionsLoading && (
        <PermissionsContext.Provider value={userPermissions}>
          <Wrapper />
        </PermissionsContext.Provider>
      )}
    </div>
  );
}
