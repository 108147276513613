import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors';

export const useFabStyles = makeStyles((theme) => ({
  flexRowContainer: {
    width: '100%',
    display: 'flex',
    maxWidth: '230px',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  firstFab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(3),
    zIndex: 2,
  },
  firstProgress: {
    color: green[500],
    position: 'absolute',
    bottom: theme.spacing(3.2),
    right: theme.spacing(2.2),
    zIndex: 2,
  },
  secondFab: {
    position: 'absolute',
    bottom: theme.spacing(13),
    right: theme.spacing(3),
    zIndex: 2,
  },
  secondProgress: {
    color: green[500],
    position: 'absolute',
    bottom: theme.spacing(12.2),
    right: theme.spacing(2.2),
    zIndex: 2,
  },
  fourthFab: {
    position: 'absolute',
    bottom: theme.spacing(31),
    right: theme.spacing(3),
    zIndex: 2,
  },
  thirdFab: {
    position: 'absolute',
    bottom: theme.spacing(22),
    right: theme.spacing(3),
    zIndex: 2,
  },
}));
