import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { TableHeader } from '../../../Common/CommonModel';
import { Audit } from '../Model/AuditModel';
import { EnhancedTableProps } from '../Model/AuditProps';

const AuditheadRows: TableHeader<Audit>[] = [
  {
    id: 'DateAdded',
    numeric: false,
    disablePadding: false,
    label: 'Date Added',
    enableSorting: true,
  },
  {
    id: 'Entity',
    numeric: false,
    disablePadding: false,
    label: 'Entity',
    enableSorting: true,
  },
  {
    id: 'Action',
    numeric: false,
    disablePadding: false,
    label: 'Action',
    enableSorting: true,
  },
  {
    id: 'PerformedBy',
    numeric: false,
    disablePadding: false,
    label: 'Performed By',
    enableSorting: true,
  },
];

export default function AuditTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Audit) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {AuditheadRows.map((AuditRow) => (
          <TableCell
            key={AuditRow.id}
            align={AuditRow.numeric ? 'right' : 'left'}
            padding={AuditRow.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === AuditRow.id ? order : false}
          >
            {AuditRow.enableSorting ? (
              <TableSortLabel
                active={orderBy === AuditRow.id}
                direction={order}
                onClick={createSortHandler(AuditRow.id)}
              >
                {AuditRow.label}
              </TableSortLabel>
            ) : (
              <div>{AuditRow.label}</div>
            )}
          </TableCell>
        ))}
        <TableCell></TableCell>
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
}
