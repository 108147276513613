import React, { useState, useEffect, ReactElement } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, Theme, TableRow, Tooltip } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { Dictionary } from '../../../Common/CommonModel';
import { ClientSecretMenuItems } from '../Model/ClientSecretesModel';
import RefreshSecretComponent from './RefreshSecretComponent';
import RefreshIcon from '@mui/icons-material/Refresh';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import MuiTableCell from '@mui/material/TableCell';
import AddEditGroup from './AddEditGoupComponent';
import ClientSecretContants from '../Model/ClientSecretConstants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      padding: 0,
    },
    button: {
      margin: theme.spacing(1),
      width: 200,
    },
    tableRow: {
      height: 'fit-content',
    },
  }),
);

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    whiteSpace: 'nowrap',
    padding: '2px',
  },
})(MuiTableCell);

export default function ClientsSecreteMenu(
  props: Readonly<{
    customerId: string;
    clientId: string;
  }>,
) {
  const classes = useStyles();
  const [openRefreshPage, setOpenRefreshPage] = useState(false);
  const [openGoupPage, setOpenGoupPage] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [menuState, setMenuState] = useState(false);
  React.useState(false);

  const handleRefresh = () => {
    closeClientSecretMenu();
    setOpenRefreshPage(true);
  };
  const handleAddGroups = () => {
    closeClientSecretMenu();
    setOpenGoupPage(true);
  };
  const menuItems: ClientSecretMenuItems[] = [
    {
      id: 'Refresh',
      label: 'Refresh Secret',
      icon: <RefreshIcon />,
      validationFunc: 'Refresh',
      ToolTip: ClientSecretContants.RefreshToolTip,
      handleFunc: handleRefresh,
    },
    {
      id: 'AddGroups',
      label: 'Add Groups',
      icon: <GroupAddIcon />,
      validationFunc: 'AddGroups',
      ToolTip: ClientSecretContants.AddGroupsToolTip,
      handleFunc: handleAddGroups,
    },
  ];

  const showRefreshPage = (open: boolean): ReactElement | null => {
    if (open) {
      return (
        <RefreshSecretComponent
          setOpen={setOpenRefreshPage}
          clientId={props.clientId}
          customerId={props.customerId}
        />
      );
    }
    return null;
  };

  const showGroup = (open: boolean): ReactElement | null => {
    if (open) {
      return (
        <AddEditGroup
          setOpen={setOpenGoupPage}
          clientId={props.clientId}
          customerId={props.customerId}
        />
      );
    }
    return null;
  };

  useEffect(() => {}, []);
  const closeClientSecretMenu = () => {
    setMenuState(false);
    setAnchor(null);
  };

  const openClientSecretMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuState(true);
    setAnchor(event.currentTarget);
  };

  function executeValidationMethod(type: string) {
    let fn;
    const validations: Dictionary<() => boolean> = {};
    validations['Refresh'] = () => true;

    if (validations[type]) {
      fn = validations[type];
    } else {
      fn = validations['default'];
    }
    return fn();
  }

  return (
    <div>
      <Tooltip title={ClientSecretContants.ClientSecretMenuToolTip}>
        <IconButton
          aria-label="More"
          aria-owns="long-menu"
          aria-haspopup="true"
          onClick={openClientSecretMenu}
          className={classes.menu}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      {menuItems.some((menu) =>
        executeValidationMethod(menu.validationFunc),
      ) && (
        <Menu
          id="long-menu"
          anchorEl={anchor}
          open={menuState}
          onClose={closeClientSecretMenu}
        >
          {menuItems.map((menu) => (
            <MenuItem
              onClick={menu.handleFunc}
              id={props.clientId}
              value={menu.label}
              key={menu.id}
            >
              <Tooltip title={menu.ToolTip}>
                <TableRow className={classes.tableRow}>
                  <TableCell>{menu.icon}</TableCell>
                  <TableCell> {menu.label}</TableCell>
                </TableRow>
              </Tooltip>
            </MenuItem>
          ))}
        </Menu>
      )}
      {showRefreshPage(openRefreshPage)}
      {showGroup(openGoupPage)}
    </div>
  );
}
