import {
  Toolbar,
  Autocomplete,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import React, { useState } from 'react';
import { AuditHeaderToolbarProps } from '../Model/AuditProps';
import { auditService } from '../AuditService';
import { PortalUser } from '../Model/AuditModel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { FilterAltOff, Search } from '@mui/icons-material';

export default function AuditHeaderToolbar(props: AuditHeaderToolbarProps) {
  const {
    loadAuditLogs,
    excludeAutomationEnties,
    setExcludeAutomationEnties,
    Entity,
    selectedUsers,
    setEntity,
    setSelectedUsers,
  } = props;
  const [open, setOpen] = useState(false);
  const classes = useToolbarStyles();
  const initialAvailableUsers: PortalUser[] = [];
  const [availableUsers, setAvailableUsers] = React.useState(
    initialAvailableUsers,
  );
  const createFilterHandler = () => {
    loadAuditLogs();
  };

  const handleFilterClick = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    loadAuditPerformers();
  }, []);
  const loadAuditPerformers = async () => {
    try {
      await auditService
        .GetAuditPerformers()
        .then((response) => setAvailableUsers(response.Items));
    } catch (error) {
      console.log(error);
    }
  };
  const handleValueChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: PortalUser[],
  ) => {
    setSelectedUsers(newValue);
  };

  return (
    <Toolbar className={classes.root}>
      <div className={classes.spacer} />
      {open ? (
        <div className={classes.container}>
          <FormControlLabel
            className={classes.CheckBoxFarmControl}
            control={
              <Checkbox
                checked={excludeAutomationEnties}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                onChange={(e) => {
                  setExcludeAutomationEnties(e.target.checked);
                }}
              />
            }
            label="Exclude Automation Entries"
          />
          <TextField
            placeholder="Entity"
            className={classes.autoComplete}
            value={Entity}
            size="small"
            onChange={(e) => setEntity(e.target.value)}
          />
          <Autocomplete
            className={classes.autoComplete}
            multiple
            value={selectedUsers}
            options={availableUsers}
            onChange={(event, newValue) => {
              handleValueChange(event, newValue);
            }}
            getOptionLabel={(option) => option.Username}
            renderInput={(params) => (
              <div className={classes.root}>
                <TextField
                  placeholder="Performed by …"
                  {...params}
                  size="small"
                />
              </div>
            )}
          />
          <IconButton onClick={createFilterHandler} color="primary">
            <Search fontSize="medium" />
          </IconButton>
        </div>
      ) : null}

      <IconButton onClick={handleFilterClick} color="primary">
        {open ? <FilterAltOff /> : <FilterAltIcon />}
      </IconButton>
    </Toolbar>
  );
}
