import { RequestType } from '../../../../Service/RequestType';
import { callService } from '../../../../Service/ServiceBase';
import { PagedData } from '../../../Common/CommonModel';
import { Tenant } from '../../Farm/Model/FarmModel';
import {
  GlobalSiteHealthStatus,
  MonitoringStatistics,
  TenantHealth,
  TenantHealthStatusCollection,
} from '../Model/HealthModels';

class HealthService {
  async GetProarcInsight(): Promise<TenantHealthStatusCollection> {
    const proarcinsight = await callService(
      'Health',
      'proarcinsight',
      RequestType.GET,
    );
    return proarcinsight;
  }
  async GetTenantInsight(
    tenantId: string,
  ): Promise<PagedData<MonitoringStatistics>> {
    const pagedMonitoringStatistics = await callService(
      'Health',
      'tenant/insight/' + tenantId,
      RequestType.GET,
    );
    return pagedMonitoringStatistics;
  }
  async GetGlobalsitesHealth(
    productId: string,
  ): Promise<PagedData<GlobalSiteHealthStatus>> {
    const pagedglobalhealth = await callService(
      'Health',
      `globalsites/${productId}`,
      RequestType.GET,
    );
    return pagedglobalhealth;
  }
  async GetTenantHealth(
    tenantId: string,
  ): Promise<TenantHealthStatusCollection> {
    const tenantsHealth = await callService(
      'Health',
      `tenant/${tenantId}`,
      RequestType.GET,
    );
    return tenantsHealth;
  }
  async GetActiveTenants(product: string): Promise<PagedData<Tenant>> {
    const tenants = await callService(
      'Health',
      `activetenants/product/${product}`,
      RequestType.GET,
    );
    return tenants;
  }
  async GetTenantHealthStatus(tenantId: string): Promise<TenantHealth> {
    const tenants = await callService(
      'Health',
      `status/tenant/${tenantId}`,
      RequestType.GET,
    );
    return tenants;
  }
}

export const healthService: HealthService = new HealthService();
