import React, { useState, useContext } from 'react';
import {
  Icon,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Paper,
  Backdrop,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ToastContent, toast } from 'react-toastify';

import { sendFileRequest } from '../../../../Service/SendFileRequest';
import { FileUploadDialog } from '../../../Common/Dialog';

import {
  UploadTriggersProps,
  TriggerConstants,
  TriggerCustomerContext,
} from '../Model';
import {
  Error,
  isValidError,
  isValidErrorMessage,
} from '../../../Common/CommonModel';
import { truncate } from '../../../../Helpers/UtilityMethods';

const useStyles = makeStyles((theme) => ({
  triggerUpload: {
    display: 'flex',
    flex: '1 50%',
  },
  filelist: {
    width: '300px',
    height: '200px',
    overflowY: 'auto',
  },
  uploadActions: {
    marginTop: '10px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function UploadTriggers(props: Readonly<UploadTriggersProps>) {
  const stringTruncateLength = 22;
  const { open, onClose, onUploaded } = props;

  const classes = useStyles();

  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploadingStatus] = useState(false);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);

  const { CustomerId } = useContext(TriggerCustomerContext);

  const removeFromList = (filename: string) => {
    const filteredFiles = files.filter((file) => file.name !== filename);
    setFiles(filteredFiles);
  };

  const addFiles = async (files: File[]) => {
    setFiles(files);
    setShowFileUploadDialog(false);
  };

  const uploadFiles = async () => {
    if (files.length > 0) {
      await saveTrigger();
      setFiles([]);
    }
  };

  const onClosed = () => {
    setFiles([]);
    onClose();
  };

  const progress = (
    filename: string,
    uploaded: number,
    uploadStatus: string,
  ) => {
    console.log(
      `file ${filename} has progress ${uploaded} and status ${uploadStatus}`,
    );
  };

  const fileUploadComplete = (filename: string) => {
    onUploaded();
    const triggerSavedMessages =
      TriggerConstants.UploadedTriggerSavedMessage.format(filename);
    toast.success(triggerSavedMessages);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUploadFailed = (stausCode: number, response: any) => {
    const failuremessage = JSON.parse(response);
    if (isValidErrorMessage(failuremessage)) {
      toast.error(failuremessage.Message);
    } else {
      toast.error(
        TriggerConstants.GenericUploadErrorMessage.format(stausCode.toString()),
      );
    }
    onClosed();
  };

  const saveTrigger = async () => {
    try {
      setUploadingStatus(true);
      const promises: unknown[] = [];
      files.forEach((file) => {
        promises.push(
          sendFileRequest(
            file,
            'triggers',
            `files/${CustomerId}`,
            progress,
            fileUploadComplete,
            fileUploadFailed,
          ),
        );
      });
      await Promise.all(promises);
      setUploadingStatus(false);
      toast.success(TriggerConstants.TriggersUploadedMessage);
    } catch (error) {
      if (isValidError(error as Error)) {
        setUploadingStatus(false);
        toast.error(error as ToastContent);
      } else {
        console.log(error);
      }
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth={'md'}
      open={open}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title">Upload Files</DialogTitle>
      <DialogContent dividers>
        <div className={classes.triggerUpload}>
          <Paper>
            <div className={classes.filelist}>
              <List component="nav" aria-label="main mailbox folders">
                {files.map((file) => (
                  <div key={file.name}>
                    <ListItem button>
                      <ListItemText>
                        {truncate(file.name, stringTruncateLength)}
                      </ListItemText>
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => removeFromList(file.name)}
                          size="large"
                        >
                          <Icon>delete</Icon>
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                  </div>
                ))}
              </List>
            </div>
          </Paper>
          <div>
            <FileUploadDialog
              open={showFileUploadDialog}
              dropzoneText={TriggerConstants.TriggerDropzoneText}
              acceptedFileExtensions={['sql']}
              onFilesAdded={addFiles}
            />
          </div>
        </div>
        <div className={classes.uploadActions}>
          <Button
            onClick={uploadFiles}
            variant="contained"
            color="primary"
            disabled={files.length < 1}
          >
            Upload
          </Button>
          {uploading && (
            <Backdrop className={classes.backdrop} open={open}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClosed} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
