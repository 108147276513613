import {
  Theme,
  Paper,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Box,
  IconButton,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { clientsService } from './ClientsService';
import { ClientFile } from './Model/ClientsModel';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { makeStyles, createStyles } from '@mui/styles';
import ClientOptions from './Components/ClientOptions';
import DeleteClientPackage from './Components/DeleteClientPackage';
import { Loader } from '../../../Common/Components/Loader';
import { AuthContext } from '../../..';
import { PermissionsContext } from '../UIContainer/PermissionsWrapper';
import PermissionConstants from '../../Common/Constants/PermissionConstants';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    subDomainWidth: {
      width: 421,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

export default function ClientsHome() {
  const [Clientsfiles, setclientsData] = React.useState<ClientFile[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const isAuthenticated = useContext(AuthContext);
  const permissions = useContext(PermissionsContext);

  useEffect(() => {
    loadClientsData();
  }, []);

  const hasResourceWriteAccess = () => {
    return isAuthenticated && permissions[PermissionConstants.ResourceWriter]
      ? true
      : false;
  };

  const loadClientsData = async () => {
    try {
      setLoading(true);
      const clientsData = await clientsService.getClientfiles();
      setclientsData(clientsData);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Paper>
        <Loader loading={loading} />
        {hasResourceWriteAccess() && (
          <Box style={{ padding: '20px', float: 'right' }}>
            <ClientOptions loadClientsData={loadClientsData} />
          </Box>
        )}

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Client Version</TableCell>
              <TableCell>Download Link</TableCell>
              {hasResourceWriteAccess() && <TableCell>Delete</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {Clientsfiles.map((item) => {
              return (
                <>
                  <TableRow>
                    <TableCell>
                      {item.Filename.toUpperCase().replace('.ZIP', '')}
                    </TableCell>
                    <TableCell>
                      <IconButton>
                        <a
                          href={item.Uri}
                          download
                          target="_blank"
                          color="green"
                        >
                          <FileDownloadRoundedIcon color="primary" />
                        </a>
                      </IconButton>
                    </TableCell>

                    {hasResourceWriteAccess() && (
                      <TableCell>
                        <DeleteClientPackage
                          container={'proarc-clients'}
                          filename={item.Filename}
                          loadClientsData={loadClientsData}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </>
  );
}
