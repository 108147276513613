import React from 'react';
import { Chip, Paper, Theme } from '@mui/material';
import MuiTableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import { experimentalStyled as styled } from '@mui/material/styles';
import { GlobalSiteHealthStatus } from '../Model/HealthModels';
import CommonConstants from './CommonConstants';

export const StatusIcon = (status: string, responseTime: string) => {
  const statusColors: {
    [key: string]: 'default' | 'success' | 'info' | 'warning' | 'error';
  } = {
    [CommonConstants.Online]: 'success',
    [CommonConstants.TimedOut]: 'info',
    [CommonConstants.Maintenance]: 'warning',
    default: 'error',
  };

  return (
    <Chip
      color={statusColors[status] || statusColors.default}
      label={responseTime}
      size="small"
      style={{ width: '60px', cursor: 'pointer' }}
    />
  );
};

export const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    whiteSpace: 'nowrap',
  },
})(MuiTableCell);

export const Item = styled(Paper)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#091f2c' : '#fff',
  color: theme.palette.mode === 'dark' ? '#fff' : '#000',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  justifyContent: 'space-between',
  display: 'flex',
}));

export function customSort(
  a: GlobalSiteHealthStatus,
  b: GlobalSiteHealthStatus,
): number {
  if (a.Status === 'Online' && b.Status !== 'Online') {
    return 1;
  } else if (a.Status !== 'Online' && b.Status === 'Online') {
    return -1;
  } else {
    return 0;
  }
}
