import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { clientsecretService } from '../ClientSecretService';
import { ClientSecrete } from '../Model/ClientSecretesModel';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import {
  Backdrop,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableRow,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Cancel } from '@mui/icons-material';
import ClientSecretContants from '../Model/ClientSecretConstants';
import { toast } from 'react-toastify';
import {
  TableCell,
  toastforClientIdCopy,
  toastforSecretCopy,
  useToolbarStyles,
} from '../CommonMethods/ClientSecretCommonMethods';
import { ThemedCopyIcon } from '../../../../Common/Components/Icons';

export default function RefreshSecretComponent(
  props: Readonly<{
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    clientId: string;
    customerId: string;
  }>,
) {
  const [open, setOpen] = React.useState(true);
  const [refreshField, setField] = useState<ClientSecrete>();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const classes = useToolbarStyles();

  useEffect(() => {
    async function RefreshSecret() {
      await clientsecretService
        .GetRefreshSecret(props.customerId, props.clientId)
        .then((data) => {
          setField(data);
          toast.success(ClientSecretContants.RefreshedClientSecret);
        })
        .then(() => setIsRefreshing(false));
    }
    isRefreshing && RefreshSecret();
  }, [isRefreshing]);

  const handleClose = () => {
    props.setOpen(false);
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={refreshField?.Secret ? 'lg' : 'md'}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {ClientSecretContants.RefreshTitle}
        </DialogTitle>
        <DialogContent>
          <Table style={{ width: 800, textAlign: 'left' }}>
            <TableBody>
              <TableRow>
                {refreshField ? (
                  <TableCell>Api Key</TableCell>
                ) : (
                  <TableCell>
                    {ClientSecretContants.RefreshAlert} {props.clientId} ?
                  </TableCell>
                )}
                {refreshField ? (
                  <TableCell>
                    {': ' + refreshField.ClientId}
                    <CopyToClipboard
                      text={refreshField.ClientId}
                      onCopy={() => {
                        toastforClientIdCopy();
                      }}
                    >
                      <IconButton size="large">
                        <ThemedCopyIcon fontSize="small" />
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                ) : null}
              </TableRow>
              {refreshField ? (
                <TableRow>
                  <TableCell>Secret</TableCell>
                  <TableCell>
                    {': ' + refreshField.Secret}
                    <CopyToClipboard
                      text={refreshField.Secret}
                      onCopy={() => {
                        toastforSecretCopy();
                      }}
                    >
                      <IconButton size="large">
                        <ThemedCopyIcon fontSize="small" />
                      </IconButton>
                    </CopyToClipboard>
                  </TableCell>
                </TableRow>
              ) : (
                <Backdrop className={classes.backdrop} open={isRefreshing}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          {!refreshField ? (
            <Button
              variant="contained"
              onClick={() => setIsRefreshing(true)}
              className={classes.button}
              startIcon={<ThumbUpIcon />}
            >
              Yes
            </Button>
          ) : null}
          <Button
            variant="contained"
            onClick={handleClose}
            className={classes.button}
            startIcon={refreshField ? <Cancel /> : <ThumbDownIcon />}
          >
            {refreshField ? 'Cancel' : 'No'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
