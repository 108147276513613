import React, { useEffect } from 'react';
import {
  Theme,
  Paper,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  Button,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { PortalConfig } from '../../UIContainer/Global/GlobalState';
import { CollaborationRelayInfo } from '../Model/CustomerModel';
import { customerService } from '../CustomerService';
import { RelayRouterProps } from '../Model/CustomerProps';
import { globalService } from '../../UIContainer/Global/GlobalService';
import { commonService } from '../../../Common/CommonService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
    },
    divider: {
      marginTop: 5,
      marginBottom: 10,
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    relayButton: {
      display: 'flex',
      marginLeft: 'auto',
      marginTop: 20,
    },
  }),
);

export default function CollaborationRelay(props: RelayRouterProps) {
  const { customerId } = props.match.params;

  const wcfRelayUrl = 'https://rly-' + customerId + '.servicebus.windows.net/';
  const jobParams = '-CustomerIdentifier "' + customerId + '"';

  const classes = useStyles();

  const emptyStateData: CollaborationRelayInfo = {
    OwnerID: '',
    Username: '',
    EncryptedPassword: '',
    AzureRelayKey: '',
    HybridConnectionKey: '',
    Created: new Date(),
  };

  const emptyConfigData: PortalConfig = {
    Branch: '',
    Environment: 'Prod',
    LastUpdated: new Date(),
  };

  const [hasCollaborationModule, setHasCollaborationModule] =
    React.useState(false);
  const [relayInfo, setRelayInfo] = React.useState(emptyStateData);
  const [portalConfig, setPortalConfig] = React.useState(emptyConfigData);

  const [dialogOpenState, setDialogOpenState] = React.useState(false);
  const [dialogContent, setDialogContent] = React.useState('');
  const [dialogHeader, setDialogHeader] = React.useState('');

  useEffect(() => {
    customerService.getCustomerModules(customerId).then((customerModules) => {
      customerModules.Items.forEach((module) => {
        if (module.ModuleID === 'collaboration') {
          setHasCollaborationModule(true);
        }
      });
    });
    customerService.getCollaborationRelayInfo(customerId).then((relay) => {
      if (relay) {
        setRelayInfo(relay);
      }
    });
    globalService.getPortalConfig().then((config) => {
      setPortalConfig(config);
    });
  }, [customerId]);

  const getRelayPassword = () => {
    customerService
      .getRelayPassword(customerId)
      .then((decryptedRelayUserPass) => {
        setDialogHeader('decrypted password for tenant');
        setDialogContent(decryptedRelayUserPass);
        setDialogOpenState(true);
      });
  };

  const handleDialogClose = () => {
    setDialogOpenState(false);
    setDialogHeader('');
    setDialogContent('');
  };

  const deployAzureRelay = () => {
    const job = {
      Scheduled: new Date().toISOString(),
      JobType: 'New-DeployAzureRelay',
      Module: 'ProArcOnline',
      Location: 'WindowsAzure',
      JobParameters: jobParams,
    };
    commonService.deployJob(job).then(() => {
      setDialogHeader('Job has been queued');
      setDialogContent(
        'You can check the job status by visiting the old cloud ops portal',
      );
      setDialogOpenState(true);
    });
  };

  return (
    <div>
      {hasCollaborationModule ? (
        <Paper className={classes.root}>
          <Typography variant="h6" component="sub">
            Relay information
          </Typography>
          <List>
            <ListItem>
              <ListItemText primary="WCF relay url" secondary={wcfRelayUrl} />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="WCF relay key"
                secondary={relayInfo.AzureRelayKey}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Hybrid connection url"
                secondary={
                  'https://' +
                  (portalConfig.Environment === 'Dev' ? 'dev-rly' : 'rly') +
                  '-collaboration.servicebus.windows.net/hybrid-' +
                  customerId
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Hybrid connection key"
                secondary={relayInfo.HybridConnectionKey}
              />
            </ListItem>
          </List>
          <Divider className={classes.divider} />
          <Typography variant="h6" component="sub">
            User information
          </Typography>
          <List>
            <ListItem>
              <ListItemText
                primary="Azure AD user"
                secondary={relayInfo.Username}
              />
            </ListItem>
            <ListItem>
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={getRelayPassword}
              >
                Show password
                <Icon className={classes.rightIcon}>fingerprint</Icon>
              </Button>
            </ListItem>
          </List>
          <Divider className={classes.divider} />
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={classes.relayButton}
            onClick={deployAzureRelay}
          >
            Deploy relay
            <Icon className={classes.rightIcon}>backup</Icon>
          </Button>
        </Paper>
      ) : (
        <div></div>
      )}
      <Dialog
        open={dialogOpenState}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogHeader}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
