import React from 'react';
import { useStyles } from '../Styles/FarmStyle';
import { Card, Grid, Typography } from '@mui/material';

interface Props {
  text: string;
}

const DeleteInfoCard = ({ text }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.outerContainer}>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Card elevation={0} className={classes.card}>
            <div>
              <div className={classes.spanContainer}>
                <Typography variant="h6">
                  Are you sure you want to delete {text}
                </Typography>
              </div>
            </div>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default DeleteInfoCard;
