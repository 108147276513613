export const LicenseReader = 'LicenseReader';
export const LicenseWriter = 'LicenseWriter';

// HomePage.tsx
export const LicenseHomeTitleConstant = 'License status for all customers';
export const HomeFetchLiveLicenseButtonLabel = 'License Usage';
export const HomeFetchLiveLicenseButtonToolTip =
  'Fetch license usage data for all customers';
export const HomeSingleTenantFetchLiveLicenseButtonToolTip =
  'Fetch license usage data for customer';
export const HomeCurrentUsageTooltip = 'Current usage: ';
export const HomeTotalPurchasedTooltip = 'Total purchased: ';

export const HomeLiveLicenseFetchSuccess =
  'License usage updated for all customers.';
export const HomeLiveLicenseFetchWarning =
  'Failed to update license usage for few customers. Please try again later.';
export const HomeLiveLicenseFetchError =
  'Failed to update license usage. Please try again later.';
export const HomeLiveLicenseSingleTenantSuccessFormat =
  'License usage updated for ';

export const ConfirmationForRefreshUsageConstant = {
  message:
    'Refreshing usage data for all customers may take a while. Are you sure to proceed?',
  confirmButtonLabel: 'Confirm',
  cancelButtonLabel: 'Cancel',
};
export const UnlicensedLabelConstant = 'UNLICENSED';

//PurchaseOrdersPage.tsx
export const OrdersHeaderLabel = 'Orders';
export const StandardUserHeaderLabel = 'Std + Adm Users';
export const AdminUserHeaderLabel = 'Admin User';
export const ReadUserHeaderLabel = 'Read Users';
export const StorageHeaderLabel = 'Storage (in GB)';
export const IssueDateHeaderLabel = 'Issue Date';
export const StartDateHeaderLabel = 'Start Date';
export const ExpiryDateHeaderLabel = 'Expiry Date';
export const PurchaseOrderTitle = 'Purchase orders of ';
export const PurchaseOrderDeleteTootltipDisabled =
  'Purchase order has already been deleted';
export const PurchaseOrderDeleteTootltip = 'Delete';
export const LicenseUsageFooterConstant = 'Licenses Used';
export const RetryPublishButtonTooltip =
  'Publish of license policies for all failed purchase orders will be retried.';
export const PublishLicenseErrorMessage =
  'License policy could not be published. Please try again later';
export const PublishLicenseSuccessMessage =
  'License policy published successfully';
export const DeletePurchaseOrderErrorMessage = 'Failed to delete ';
export const DeletePurchaseOrderSuccessMessage = ' is deleted from the list';
export const DeletePurchaseOrderWarningMessage =
  ' will be deleted from the system, do you want to continue?';
export const ExpiredPurchaseOrdersSwitchLabel = 'Expired Orders';
export const ExpiredPurchaseOrdersSwitchShowToolTip =
  'Toggle switch to show expired purchase orders';
export const ExpiredPurchaseOrdersSwitchHideToolTip =
  'Toggle switch to hide expired purchase orders';
export const LicenseLogViewTooltip = 'View logs';
export const LicenseLogLabel = 'Logs';
export const ExpiredPurchaseOrderTooltip = 'Purchase order has expired';

// LicenseManagementPage.tsx
export const LicenseUserTypeHeaderConstant = 'License Type';
export const LicenseExistingCountHeaderConstant = 'Existing Count';
export const LicenseUpdateCountHeaderConstant = 'Add/Delete';
export const TotalCountFooterConstant = 'Total License Count';
export const SaveAndPublishButtonLabelConstant = 'Publish';
export const StandardLicenseTypeConstant = {
  id: 'Standard',
  description: 'Std + Adm Users',
};
export const ReadLicenseTypeConstant = {
  id: 'Read',
  description: 'Read Users',
};
export const StorageLicenseTypeConstant = {
  id: 'Storage',
  description: 'Storage (in GB)',
};
export const PurchaseOrderDetailsLegendConstant = 'Purchase Order';
export const PurchaseOrderNameConstant = {
  label: 'Order Number',
  placeholderText: 'Purchase order number',
};
export const PurchaseOrderCustomerEmailConstant = {
  label: 'Customer Contact',
  placeholderText: 'Email ID',
};
export const PurchaseOrderValidFromDateConstant = {
  label: 'Valid From*',
  placeholderText: 'Select from date',
};
export const PurchaseOrderValidToDateConstant = {
  label: 'Valid To*',
  placeholderText: 'Select to date',
  placeholderComputedText: 'Computed automatically',
};
// TODO: instead of hard coded format, try using current system settings
export const PurchaseOrderValidityDateFormatConstant = 'MM/dd/yyyy';
export const License = 'license';
export const ErrorMessagesConstant = {
  emptyValue: 'This is a required field',
  validToDateLessThanValidFrom: 'Cannot be lesser than valid from date',
};

export const ConfirmationForPublishModuleLicenseConstant = {
  messagePart: 'Confirm to change the license status as below',
  confirmButtonLabel: 'Confirm',
  cancelButtonLabel: 'Cancel',
  okButtonLabel: 'OK',
};

export const ConfirmationForPublishPurchaseOrderConstant = {
  messagePart: 'Are you sure you want to issue the following licenses, ',
  confirmButtonLabel: 'Confirm',
  cancelButtonLabel: 'Cancel',
  okButtonLabel: 'OK',
};
export const SnackBarMessageConstant = {
  purchaseOrderSavedMessage: 'Purchase order saved successfully.',
  purchaseOrderPublishFailedMessage:
    'Purchase order created but failed to publish license. Please retry publishing.',
  purchaseOrderSaveError:
    'Error occurred while trying to save the purchase order. Please try again later.',
  moduleLicensePublishedMessage: 'Module license saved successfully.',
  moduleLicensePublishError:
    'Error occurred while trying to publish the module license. Please try again later.',
};
export const LicenseManagementPageTitleConstant = 'Administer license for ';

export const ModuleLicenseManagementPageTitleConstant =
  'Administer module licenses for ';

export const EmailIDConstant = {
  regex: new RegExp(/^[a-zA-Z0-9_.+-]+@[a-zA-Z]+\.[a-zA-Z.]+$/),
  invalidMessage: 'Please provide a valid email id',
};

export const LicenseCountReducedMessage = 'Licenses will be reduced by';
export const LicenseCountIncreasedMessage = 'Licenses will be increased by';
export const StorageCountReducedMessage = 'will be reduced by';
export const StorageCountIncreasedMessage = 'will be increased by';
export const LicenseAddDeleteErrorMessage =
  'Addition and Deletion of licenses cannot be done together in a single purchase order. Please consider creating separate orders for the same.';

//LicenseLogsPage.tsx
export const LicenseLogsTitle = 'License Management Logs of ';
export const filterNote = 'Select Operation';
export const OperationHeaderLabel = 'Operation';
export const StatusHeaderLabel = 'Status';
export const DateAddedHeaderLabel = 'Performed Date';
export const MessageHeaderLabel = 'Details';
export const PerformedByHeaderLabel = 'Performed By';
export const OperationType = ['publish', 'livelicenseusage'];
