import React from 'react';
import Icon from '@mui/material/Icon';
import { Tooltip, Theme, Fab } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  DownloadMetricsProps,
  AllTenantMetricsData,
  HeadRow,
} from '../../Model/MetricsModel';

const useStyles = makeStyles((theme: Theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(7),
    right: theme.spacing(3),
  },
}));

export default function DownloadMetrics(props: Readonly<DownloadMetricsProps>) {
  const classes = useStyles();
  const { headerRows, typeHeaderRows, tabledata } = props;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getCellValue = (cell: any): string => {
    return cell.toString();
  };

  const objToArray = (row: AllTenantMetricsData | []): string[] =>
    row instanceof Array ? row : Object.keys(row).map((key) => row[key]);

  const processRow = (row: object): string => {
    const finalVal = objToArray(row).reduce(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (prev: string, value: any, i: number) => {
        let result = getCellValue(value).replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0) {
          result = `"${result}"`;
        }
        return `${prev}${i > 0 ? ',' : ''}${result}`;
      },
      '',
    );

    return `${finalVal}\n`;
  };

  const getTypeHeaders = (): string => {
    let startIndex = 0;
    const typeHeader: string[] = new Array<string>(headerRows.length).fill('');
    typeHeaderRows.forEach((headTypeItem) => {
      typeHeader.fill(
        headTypeItem.label,
        startIndex,
        startIndex + headTypeItem.colSpan,
      );
      startIndex += headTypeItem.colSpan;
    });
    return processRow(typeHeader);
  };

  const getCsv = (): string =>
    tabledata.reduce(
      (prev: string, row: AllTenantMetricsData) => prev + processRow(row),
      getTypeHeaders() + processRow(headerRows.map((x: HeadRow) => x.label)),
    );

  const exportFile = () => {
    const csvFile = getCsv();
    const fileURL = URL.createObjectURL(
      new Blob([`\uFEFF${csvFile}`], {
        type: 'text/csv;charset=utf-8;',
      }),
    );

    const downloadLink = document.createElement('a');

    downloadLink.setAttribute('href', fileURL);
    downloadLink.setAttribute('id', 'download');
    downloadLink.setAttribute('download', 'metrics.csv');
    document.body.appendChild(downloadLink);

    downloadLink.click();
    URL.revokeObjectURL(fileURL);
  };

  return (
    <Tooltip title="download metrics">
      <Fab className={classes.fab} onClick={exportFile}>
        <Icon>cloud_download</Icon>
      </Fab>
    </Tooltip>
  );
}
