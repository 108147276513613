import React from 'react';
import { EnhancedTableProps } from '../Model/KeyVaultProps';
import { KeyValuePairs } from '../Model/KeyVaultModel';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { TableHeader } from '../../../Common/CommonModel';

const SecureKeyheadRows: TableHeader<KeyValuePairs>[] = [
  {
    id: 'Key',
    numeric: false,
    disablePadding: false,
    label: 'Key',
    enableSorting: true,
  },
  {
    id: 'Value',
    numeric: false,
    disablePadding: false,
    label: 'Value',
    enableSorting: false,
  },
];

export default function KeyVaultTableHead(props: Readonly<EnhancedTableProps>) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof KeyValuePairs) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {SecureKeyheadRows.map((SecureKeyRow) => (
          <TableCell
            key={SecureKeyRow.id}
            align={'left'}
            padding={SecureKeyRow.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === SecureKeyRow.id ? order : false}
          >
            {SecureKeyRow.enableSorting ? (
              <TableSortLabel
                active={orderBy === SecureKeyRow.id}
                direction={order}
                onClick={createSortHandler(SecureKeyRow.id)}
              >
                {SecureKeyRow.label}
              </TableSortLabel>
            ) : (
              <div>{SecureKeyRow.label}</div>
            )}
          </TableCell>
        ))}
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
}
