import React from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import { ProfileProps } from './CommonProps';

const useStyles = makeStyles(() => ({
  box: {
    padding: '10px',
    display: 'block',
  },
  mainPaper: {
    padding: '20px',
    display: 'flex',
  },
  link: {
    marginTop: '20px',
  },
}));

const ProfileCard = (props: Readonly<ProfileProps>) => {
  const classes = useStyles();

  return (
    <Paper elevation={0}>
      <Paper className={classes.mainPaper} elevation={0}>
        <Box className={classes.box}>
          <Avatar
            src={props.profileimageurl}
            style={{
              width: '88px',
              height: '88px',
            }}
          />
        </Box>
        <Box className={classes.box}>
          <Typography variant="h6">{props.username}</Typography>
          <Typography variant="body1" align="center">
            {props.email}
          </Typography>
          <Link
            href={props.accounturl}
            className={classes.link}
            underline="hover"
          >
            View Account
          </Link>
        </Box>
      </Paper>
      <Divider />
      <Button
        onClick={props.logOut}
        startIcon={<PersonAddAltOutlinedIcon />}
        fullWidth
      >
        Sign in with a different account
      </Button>
    </Paper>
  );
};

export default ProfileCard;
