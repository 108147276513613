import ClientsHome from '../Components/Layout/Clients/ClientsHome';
import { CustomRoute } from '../Components/Layout/UIContainer/Global/RouteProps';

export const publicRoutes: CustomRoute[] = [
  {
    path: '/clients',
    name: 'Clients',
    component: ClientsHome,
  },
];
