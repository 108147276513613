import React from 'react';
import { useLocalStorage } from '../Theme/UseLocalStorage';

import { useMediaQuery } from '@mui/material';

export const useToggleDarkMode = () => {
  const [colorMode, setColorMode] = useLocalStorage('colorMode', '');
  const setter = () => {
    document.body.style.transition = 'color 0.5s, background 0.5s';
    const themeMode = colorMode === 'dark' ? 'light' : 'dark';
    setColorMode(themeMode);
  };
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const darkModeEnabled =
    colorMode === 'dark' || (prefersDarkMode && colorMode !== 'light');
  return [darkModeEnabled, colorMode, setter];
};

export const ThemeContext = React.createContext(() => {});
export const PreferredThemeContext = React.createContext('');
