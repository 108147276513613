import React, { useState, useEffect, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { Farm, FarmMenuItems, FarmMenuProps } from '../Model/FarmModel';
import { farmService } from '../FarmService';
import { Dictionary } from '../../../Common/CommonModel';
import { PermissionsContext } from '../../UIContainer/PermissionsWrapper';
import PermissionConstants from '../../../Common/Constants/PermissionConstants';
import { farmStateMapping } from '../StateMapper/StateMapper';

const useStyles = makeStyles(() =>
  createStyles({
    menu: {
      padding: 0,
    },
  }),
);

const menuItems: FarmMenuItems[] = [
  {
    id: 'topology',
    label: 'Manage Topology',
    linkTo: '/Farm/{0}/topology',
    validationFunc: 'topology',
  },
  {
    id: 'edit',
    label: 'Edit Farm',
    linkTo: '/Farm/{0}/edit',
    validationFunc: 'edit',
  },
  {
    id: 'delete',
    label: 'Delete Farm',
    linkTo: '/Farm/delete/{0}',
    validationFunc: 'delete',
  },
  {
    id: 'deploy',
    label: 'Deploy',
    linkTo: '/Farm/deploy/{0}',
    validationFunc: 'deploy',
  },
];

export default function FarmMenu(props: FarmMenuProps) {
  const { FarmId } = props;
  const classes = useStyles();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [menuState, setMenuState] = useState(false);
  const [farm, setFarm] = useState<Farm>();
  const permissions = useContext(PermissionsContext);

  useEffect(() => {
    loadData();
  }, []);

  const openFarmMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuState(true);
    setAnchor(event.currentTarget);
  };
  const closeFarmMenu = () => {
    setMenuState(false);
    setAnchor(null);
  };

  const loadData = async () => {
    await GetFarm();
  };
  const GetFarm = async () => {
    const farm = await farmService.getFarm(Number(FarmId));
    setFarm(farm);
  };

  const hasFarmDeleterPermission = () =>
    !!permissions[PermissionConstants.FarmDeleter];
  const hasFarmWriterPermission = () =>
    !!permissions[PermissionConstants.FarmWriter];

  function executeValidationFunction(type: string) {
    let fn;
    const validations: Dictionary<() => boolean> = {};
    validations['delete'] = () =>
      hasFarmDeleterPermission() &&
      farmStateMapping[farm?.State as number] === 'Delete';
    validations['edit'] = () => hasFarmWriterPermission();
    validations['topology'] = () => hasFarmWriterPermission();
    validations['deploy'] = () => hasFarmWriterPermission();
    validations['default'] = () => false;

    if (validations[type]) {
      fn = validations[type];
    } else {
      fn = validations['default'];
    }
    return fn();
  }

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns="long-menu"
        aria-haspopup="true"
        onClick={openFarmMenu}
        className={classes.menu}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchor}
        open={menuState}
        onClose={closeFarmMenu}
      >
        {menuItems
          .filter((menu) => executeValidationFunction(menu.validationFunc))
          .map((menu) => (
            <MenuItem
              key={menu.id}
              component={Link}
              to={menu.linkTo.format(FarmId)}
            >
              {menu.label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
