import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { OnCallUser } from '.././Monitoring/Model/MonitoringModel';

class OncalldrillService {
  public getOnCallOfTheWeek(): Promise<OnCallUser> {
    const OnCallOfTheWeek = callService(
      'monitoring',
      'oncall',
      RequestType.GET,
    );
    return OnCallOfTheWeek;
  }

  public getUsers(): Promise<OnCallUser[]> {
    const OnCallOfTheWeek = callService('monitoring', 'users', RequestType.GET);
    return OnCallOfTheWeek;
  }
}

export const oncalldrillService: OncalldrillService = new OncalldrillService();
