import { callService } from '../../../../Service/ServiceBase';
import { RequestType } from '../../../../Service/RequestType';
import { FileShare } from './Model/FileShareModel';
import { PagedData } from '../../../Common/CommonModel';

class FileShareService {
  getFileshare(): Promise<PagedData<FileShare>> {
    const pagedFileshareResult = callService(
      'fileshare',
      '/tenants',
      RequestType.GET,
    );
    return pagedFileshareResult;
  }
  postFileshare(tenant: FileShare): Promise<PagedData<FileShare>> {
    const pagedFileshareResult = callService(
      'fileshare',
      '/addtenant',
      RequestType.POST,
      tenant,
    );
    return pagedFileshareResult;
  }
  deleteFileshare(tenantId: number): Promise<PagedData<FileShare>> {
    const pagedFileshareResult = callService(
      'fileshare',
      '/tenant/' + tenantId,
      RequestType.DELETE,
    );
    return pagedFileshareResult;
  }
  editFileshare(
    tenantId: number,
    tenant: FileShare,
  ): Promise<PagedData<FileShare>> {
    const pagedFileshareResult = callService(
      'fileshare',
      '/tenant/' + tenantId,
      RequestType.PUT,
      tenant,
    );
    return pagedFileshareResult;
  }
}

export const fileshareService: FileShareService = new FileShareService();
