export interface OnCallUser {
  Id: string;
  Name: string;
  EmailAddress: string;
  PhoneNumber: string;
  Sequence: number;
}

export interface OnCallSubstituteUser {
  Id: number;
  ActualUserId: string;
  SubstituteUserId: string;
  WeekNumber: number;
  Valid: boolean;
}

export enum DialogOptions {
  Cancel,
  Ok,
}

export interface UserSelection {
  startDate: Date;
  endDate: Date;
  key: string;
  showDateDisplay: boolean;
  autoFocus: boolean;
  color: string;
  name: string | undefined;
}
