import { Validator } from 'fluentvalidation-ts';
import { FailoverGroup } from '../Model/SqlServerModel';
import Validation from './FailoverValidationConstants.json';

export class FailoverGroupValidator extends Validator<FailoverGroup> {
  constructor() {
    super();
    this.ruleFor('Name')
      .notEmpty()
      .withMessage(Validation.failoverGroupName.notEmpty)
      .notNull()
      .withMessage(Validation.failoverGroupName.notEmpty)
      .matches(/^[a-z0-9-]+$/)
      .withMessage(Validation.failoverGroupName.matches)
      .maxLength(63)
      .withMessage(Validation.failoverGroupName.maxLength)
      .must(this.noStartOrEndHyphen)
      .withMessage(Validation.failoverGroupName.startEndHyphen)
      .must(this.noDoubleHyphen)
      .withMessage(Validation.failoverGroupName.doubleHyphen);

    this.ruleFor('ServerName')
      .notEmpty()
      .withMessage(Validation.server.notEmpty)
      .notNull()
      .withMessage(Validation.server.notEmpty)
      .matches(/^[a-z0-9-]+$/)
      .withMessage(Validation.server.matches)
      .maxLength(63)
      .withMessage(Validation.server.maxLength)
      .must(this.noStartOrEndHyphen)
      .withMessage(Validation.server.startEndHyphen)
      .must(this.noDoubleHyphen)
      .withMessage(Validation.server.doubleHyphen);

    this.ruleFor('UserName')
      .notEmpty()
      .withMessage(Validation.userName.notEmpty)
      .notNull()
      .withMessage(Validation.userName.notEmpty)
      .minLength(3)
      .withMessage(Validation.userName.minLength)
      .maxLength(128)
      .withMessage(Validation.userName.maxLength)
      .matches(/^[a-zA-Z0-9._]+$/)
      .withMessage(Validation.userName.matches);

    this.ruleFor('AdminPassword')
      .notEmpty()
      .withMessage(Validation.adminPassword.notEmpty)
      .notNull()
      .withMessage(Validation.adminPassword.notEmpty)
      .minLength(8)
      .withMessage(Validation.adminPassword.minLength)
      .must((password) => /[A-Z]/.test(password))
      .withMessage(Validation.adminPassword.upperCase)
      .must((password) => /[a-z]/.test(password))
      .withMessage(Validation.adminPassword.lowerCase)
      .must((password) => /[0-9]/.test(password))
      .withMessage(Validation.adminPassword.numeric);

    this.ruleFor('Region')
      .notEmpty()
      .withMessage(Validation.region.notEmpty)
      .notNull()
      .withMessage(Validation.region.notEmpty);
  }
  private noStartOrEndHyphen = (serverName: string) => {
    return !serverName.startsWith('-') && !serverName.endsWith('-');
  };

  private noDoubleHyphen = (serverName: string) => {
    return !(
      serverName.length > 3 &&
      serverName[2] === '-' &&
      serverName[3] === '-'
    );
  };
}
