export interface Farm {
  Id: number;
  DisplayName: string;
  State: number;
  DeploymentState: number;
  Type: string;
  FarmRegion: string;
  SecondaryRegion: string;
}

export interface Tenant {
  Id: string;
  FarmId: number;
  ApplicationUrl: string;
  StatusID: number;
  AppDBName: string;
  AppservicePlan: string;
}

export enum FarmState {
  Inactive = 1,
  Production,
  Staging,
  'Staging - Passed Autotests',
  Provisioning,
  Maintenance,
  New,
  'Test environment',
}
