import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { SvgIconProps } from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DoneIcon from '@mui/icons-material/Done';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import WarningIcon from '@mui/icons-material/Warning';

export const ThemedDeleteIcon: React.FC<SvgIconProps> = (props) => {
  return <DeleteIcon color="error" {...props} />;
};

export const ThemedDoneIcon: React.FC<SvgIconProps> = (props) => {
  return <DoneIcon color="success" {...props} />;
};

export const ThemedEditNoteIcon: React.FC<SvgIconProps> = (props) => {
  return <EditNoteIcon color="success" {...props} />;
};

export const ThemedCopyIcon: React.FC<SvgIconProps> = (props) => {
  return <FileCopyIcon color="success" {...props} />;
};

export const ThemedEditIcon: React.FC<SvgIconProps> = (props) => {
  return <EditIcon color="info" {...props} />;
};

export const ThemedWarningIcon: React.FC<SvgIconProps> = (props) => {
  return <WarningIcon color="warning" {...props} />;
};
