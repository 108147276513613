import * as React from 'react';
import { DataGrid, GridColDef, GridRowModel } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
  TextField,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { FileShare } from '../Model/FileShareModel';
import { fileshareService } from '../FileShareService';
import Alert from '@mui/material/Alert';

type props = {
  addItemToState: (item: FileShare[]) => void;
  filesharelist: FileShare[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    button: {
      margin: theme.spacing(1),
    },
  }),
);

export default function DataTable({ addItemToState, filesharelist }: props) {
  function onDeleteClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: GridRowModel,
  ): void {
    const confirmDelete = window.confirm('Do You want to delete the Tenant?');
    if (confirmDelete) {
      fileshareService
        .deleteFileshare(row.id)
        .then((response) => {
          addItemToState(response.Items);
        })
        .catch((err) => {
          console.log(err.message);
        });
      setOpenDeleteAlert(true);
    }
  }
  const [edit, setEdit] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { [key: string]: any } | FileShare
  >();
  const [prevSelectedState, setPrevSelectedState] = React.useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { [key: string]: any } | FileShare
  >();

  const [success, setOpenSuccessAlert] = React.useState(false);
  const [dele, setOpenDeleteAlert] = React.useState(false);

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccessAlert(false);
  };
  const handledeleteCloseAlert = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenDeleteAlert(false);
  };

  function onEditClick(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: GridRowModel,
  ): void {
    setEdit(true);
    setSelectedValue(fileshareList.find((i) => i.Id === row.id));
    setPrevSelectedState(fileshareList.find((i) => i.Id === row.id));
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Tenant ID', width: 250, resizable: true },
    {
      field: 'CustomerName',
      headerName: 'Customer Name',
      width: 200,
      resizable: true,
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 150,
      resizable: true,
    },
    {
      field: 'AdminUserName',
      headerName: 'Admin UserName',
      width: 200,
      resizable: true,
    },
    {
      field: 'EmailAddress',
      headerName: 'Email Address',
      width: 200,
      resizable: true,
    },
    {
      field: 'CreatedDate',
      headerName: 'Created Date',
      width: 180,
      resizable: true,
    },
    {
      field: 'DeployedDate',
      headerName: 'Deployed Date',
      width: 180,
      resizable: true,
    },

    {
      field: 'actions',
      headerName: 'Actions',
      width: 350,
      renderCell: (params) => {
        return (
          <div>
            <Button
              className={classes.button}
              onClick={(e) => onEditClick(e, params.row)}
              variant="contained"
              startIcon={<EditIcon style={{ color: '#327825' }} />}
              size="small"
            >
              Edit
            </Button>
            <Button
              className={classes.button}
              onClick={(e) => onDeleteClick(e, params.row)}
              variant="contained"
              startIcon={<DeleteIcon style={{ color: '#850101' }} />}
              size="small"
            >
              Delete
            </Button>
            <Button
              className={classes.button}
              onClick={(e) => onDeleteClick(e, params.row)}
              variant="contained"
              startIcon={<FlightTakeoffIcon style={{ color: '#2c6899' }} />}
              size="small"
            >
              Deploy
            </Button>
          </div>
        );
      },
    },
  ];

  const initialState: FileShare[] = [];
  const [fileshareList, setfileshareList] = React.useState(initialState);

  const handleClose = () => {
    setEdit(false);
  };
  const classes = useStyles();
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue({
      ...selectedValue,
      [event.target.id]: event.target.value,
    });
  };

  const handleSave = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const fileshareReceived = await fileshareService.editFileshare(
      selectedValue?.Id,
      selectedValue as FileShare,
    );
    addItemToState(fileshareReceived.Items);
    setSelectedValue(
      fileshareReceived.Items.find((i) => i.Id === selectedValue?.Id),
    );
    setPrevSelectedState(
      fileshareReceived.Items.find((i) => i.Id === selectedValue?.Id),
    );
    handleClose();
    setOpenSuccessAlert(true);
  };

  React.useEffect(() => {
    loadFileShareData();
  }, [selectedValue]);

  const loadFileShareData = async () => {
    try {
      const fileshareReceived = await fileshareService.getFileshare();
      setfileshareList(fileshareReceived.Items);
    } catch (error) {
      console.log(error);
    }
  };
  if (!Object.values(filesharelist)) {
    return <h1>loading...</h1>;
  } else {
    const rows = Object.values(filesharelist).map((row) => ({
      id: row.Id,
      CustomerName: row.CustomerName,
      Status: row.Status,
      AdminUserName: row.AdminUserName,
      EmailAddress: row.EmailAddress,
      CreatedDate: row.CreatedDate,
      DeployedDate: row.DeployedDate,
    }));

    return (
      <div style={{ height: 400, width: '100%' }}>
        {selectedValue ? (
          <Dialog
            open={edit}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Edit Tenant Details
            </DialogTitle>
            <form onSubmit={handleSave}>
              <DialogContent className={classes.root}>
                <DialogContentText></DialogContentText>
                <TextField
                  id="TenantId"
                  label="Tenant Id"
                  variant="standard"
                  disabled={true}
                  value={selectedValue.Id === null ? '' : selectedValue.Id}
                  contentEditable={false}
                  inputProps={{ readOnly: true }}
                />
                <TextField
                  id="CustomerName"
                  label="Customer Name"
                  variant="standard"
                  onChange={onChange}
                  value={
                    selectedValue.CustomerName === null
                      ? ''
                      : selectedValue.CustomerName
                  }
                />
                <TextField
                  id="Status"
                  label="Status"
                  variant="standard"
                  onChange={onChange}
                  value={
                    selectedValue.Status === null ? '' : selectedValue.Status
                  }
                />
                <TextField
                  id="AdminUserName"
                  label="Admin UserName"
                  variant="standard"
                  onChange={onChange}
                  value={
                    selectedValue.AdminUserName === null
                      ? ''
                      : selectedValue.AdminUserName
                  }
                />
                <TextField
                  type="email"
                  id="EmailAddress"
                  label="Email Address"
                  variant="standard"
                  onChange={onChange}
                  value={
                    selectedValue.EmailAddress === null
                      ? ''
                      : selectedValue.EmailAddress
                  }
                />
                <TextField
                  id="CreatedDate"
                  label="Created Date"
                  variant="standard"
                  disabled={true}
                  onChange={onChange}
                  value={
                    selectedValue.CreatedDate === null
                      ? ''
                      : selectedValue.CreatedDate
                  }
                />
                <TextField
                  id="DeployedDate"
                  label="Deployed Date"
                  variant="standard"
                  disabled={true}
                  onChange={onChange}
                  value={
                    selectedValue.DeployedDate === null
                      ? ''
                      : selectedValue.DeployedDate
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  disabled={prevSelectedState === selectedValue}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        ) : null}
        {rows.length > 0 ? (
          <DataGrid
            getRowId={(row) => row.id}
            rows={rows}
            columns={columns}
            checkboxSelection
            disableRowSelectionOnClick={true}
          />
        ) : (
          <>No Rows</>
        )}

        <Snackbar open={success} autoHideDuration={4000}>
          <Alert onClose={handleCloseAlert} severity="info">
            Tenant Updated Successfully!
          </Alert>
        </Snackbar>
        <Snackbar open={dele} autoHideDuration={4000}>
          <Alert onClose={handledeleteCloseAlert} severity="error">
            Tenant Delete Successfully!
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
