import { RequestType } from '../../../Service/RequestType';
import { callService } from '../../../Service/ServiceBase';
import { PagedData } from '../../Common/CommonModel';
import { StorageAccount } from './Model/StorageAccountModel';

class StorageAccountService {
  async getstorageAccounts(): Promise<PagedData<StorageAccount>> {
    const pagedStorageAccountResult = await callService(
      'storageaccounts',
      '',
      RequestType.GET,
    );
    return pagedStorageAccountResult;
  }
  async setstorageAccount(
    storageaccount: StorageAccount,
  ): Promise<StorageAccount> {
    const storageAccount = await callService(
      'storageaccounts',
      `${storageaccount.Name}`,
      RequestType.PUT,
      storageaccount,
    );
    return storageAccount;
  }

  async getstorageAccount(name: string): Promise<StorageAccount> {
    const storageAccount = await callService(
      'storageaccounts',
      `${name}`,
      RequestType.GET,
    );
    return storageAccount;
  }

  async deletestorageAccount(name: string): Promise<StorageAccount> {
    const storageAccount = await callService(
      'storageaccounts',
      `${name}`,
      RequestType.DELETE,
    );
    return storageAccount;
  }
}

export const storageAccountService: StorageAccountService =
  new StorageAccountService();
