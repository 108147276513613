import {
  Box,
  Card,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import React from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import {
  PermissionState,
  PermissionListProps,
  PortalPermission,
} from '../Model/AccessControlModel';

const BasePermissionDragable: React.FC<PermissionListProps> = ({
  listId,
  listType,
  items,
}) => (
  <Droppable droppableId={listId} type={listType}>
    {(provided) => (
      <List
        ref={provided.innerRef}
        {...provided.droppableProps}
        style={{ minHeight: '50px', minWidth: '100px' }}
      >
        {items.map((item, index) => (
          <Draggable
            key={listId + index}
            draggableId={listId + index}
            index={index}
          >
            {(provided) => (
              <ListItem
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                <ListItemText primary={item.PermissionDisplayName} />
              </ListItem>
            )}
          </Draggable>
        ))}
        {provided.placeholder}
      </List>
    )}
  </Droppable>
);

export default BasePermissionDragable;

export function GetDragEndList(
  liststate: PermissionState,
  setListState: React.Dispatch<React.SetStateAction<PermissionState>>,
  setselectedGroupPermissions: React.Dispatch<
    React.SetStateAction<PortalPermission[]>
  >,
) {
  return (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const start = source.droppableId;
    const finish = destination.droppableId;

    if (start === finish) {
      const newList = Array.from<PortalPermission>(liststate[start]);
      const [removed] = newList.splice(source.index, 1);
      newList.splice(destination.index, 0, removed);

      setListState((prevState) => ({
        ...prevState,
        [start]: newList,
      }));
    } else {
      const startList = Array.from(liststate[start]);
      const finishList = Array.from(liststate[finish]);
      const [removed] = startList.splice(source.index, 1);

      finishList.splice(destination.index, 0, removed);

      const updatedState = {
        ...liststate,
        [start]: startList,
        [finish]: finishList,
      };

      setListState(updatedState);
      setselectedGroupPermissions(updatedState.selectedlist);
    }
  };
}

export function DragableUI(
  onDragEnd: (result: DropResult) => void,
  liststate: PermissionState,
  sourceList: string,
  destListTitle: string,
) {
  return (
    <Paper sx={{ padding: 1, display: 'flex', height: 570 }} elevation={1}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box margin={2}>
          <Typography variant="h6" padding={2}>
            {sourceList}
          </Typography>
          <Card
            elevation={0}
            sx={{
              padding: 2,
              overflow: 'auto',
              height: 450,
              '&::-webkit-scrollbar': { display: 'none' },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            }}
          >
            <BasePermissionDragable
              listId="unselectedlist"
              items={liststate.unselectedlist}
            />
          </Card>
        </Box>
        <Box margin={2}>
          <Typography variant="h6" padding={2}>
            {destListTitle}
          </Typography>
          <Card
            elevation={0}
            sx={{
              padding: 2,
              overflow: 'auto',
              height: 450,
              '&::-webkit-scrollbar': { display: 'none' },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
            }}
          >
            <BasePermissionDragable
              listId="selectedlist"
              items={liststate.selectedlist}
            />
          </Card>
        </Box>
      </DragDropContext>
    </Paper>
  );
}
