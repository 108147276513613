import React, { useState, useEffect } from 'react';

import {
  Theme,
  Paper,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import TableLoader from '../../Skeleton/AllTenantMetricSkeleton';
import {
  AllTenantMetricsProps,
  TenantMetricsData,
  AllTenantMetricsData,
  HeadRow,
} from '../../Model/MetricsModel';
import { farmService } from '../../../Farm/FarmService';
import { Tenant } from '../../../Farm/Model/FarmModel';
import { metricsService } from '../../MetricsService';
import { getTenantSubDomain, convertValue } from '../../Helpers/MetricsHelpers';
import {
  stableSort,
  getSorting,
  capitalize,
} from '../../../../../Helpers/UtilityMethods';
import MetricsTableHead from './MetricsTableHead';
import DownloadMetrics from './DownloadMetrics';
import { Order } from '../../../../../Helpers/Literals';
import { Dictionary } from '../../../../Common/CommonModel';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '5px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    paddingLeft: 16,
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
}));

export default function AllTenantMetrics(
  props: Readonly<AllTenantMetricsProps>,
) {
  const { farmId } = props;
  const rowsPerPage = 10;
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [headerRows, setHeaderRows] = useState<HeadRow[]>([]);
  const [typeHeaderRows, setTypeHeaderRows] = useState<HeadRow[]>([]);
  const [allTenantMetrics, setAllTenantMetrics] = useState<
    AllTenantMetricsData[]
  >([]);

  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] =
    React.useState<keyof AllTenantMetricsData>('Id');

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, allTenantMetrics.length - page * rowsPerPage);

  useEffect(() => {
    if (farmId > 0) {
      loadTenants();
    }
  }, [farmId]);

  const loadTenants = async () => {
    setLoading(true);
    let tenantMetrics: TenantMetricsData[] = [];
    const tenantMetricsDataCollection: AllTenantMetricsData[] = [];

    const deployedTenants = await farmService.getDeployedTenantsOnFarm(farmId);
    for (const tenant of deployedTenants) {
      tenantMetrics = await loadTenantMetrics(tenant);
      const tenantMetricsData = buildDataForTabularRepresentation(
        tenant,
        tenantMetrics,
      );
      tenantMetricsDataCollection.push(tenantMetricsData);
    }
    setAllTenantMetrics(tenantMetricsDataCollection);
    createHeadRows(tenantMetrics);
    setLoading(false);
  };

  const loadTenantMetrics = async (tenant: Tenant) => {
    const tenantMetrics = await metricsService.getLatestTenantMetrics(
      tenant.Id,
    );
    return tenantMetrics;
  };

  const buildDataForTabularRepresentation = (
    tenant: Tenant,
    tenantMetrics: TenantMetricsData[],
  ) => {
    const tenantMetricsData: AllTenantMetricsData = {
      SubDomain: getTenantSubDomain(tenant.ApplicationUrl),
    };
    tenantMetrics.forEach((metrics) => {
      tenantMetricsData[metrics.Name] = convertValue(metrics);
    });
    return tenantMetricsData;
  };

  const createHeadRows = (tenantMetrics: TenantMetricsData[]) => {
    let primaryRows: HeadRow[] = [];
    let typeDisplayRows: HeadRow[] = [];
    const typeHeaderData: Dictionary<number> = {};

    if (tenantMetrics && tenantMetrics.length > 0) {
      primaryRows = [
        {
          id: 'SubDomain',
          numeric: false,
          disablePadding: false,
          label: 'SubDomain',
          enableSorting: true,
          colSpan: 1,
        },
      ];

      tenantMetrics.forEach((metrics) => {
        typeHeaderData[metrics.Type] = typeHeaderData[metrics.Type]
          ? typeHeaderData[metrics.Type] + 1
          : 1;

        const enableSorting = metrics.Unit !== 'date';
        const label =
          metrics.Unit === 'bytes'
            ? metrics.DisplayName + ' (MB)'
            : metrics.DisplayName;
        const row: HeadRow = {
          id: metrics.Name,
          numeric: false,
          disablePadding: false,
          label: label,
          enableSorting: enableSorting,
          colSpan: 1,
        };
        primaryRows.push(row);
      });
      typeDisplayRows = getTypeDisplayHeaders(typeHeaderData);
    }
    setHeaderRows(primaryRows);
    setTypeHeaderRows(typeDisplayRows);
  };

  const getTypeDisplayHeaders = (typeHeaderData: Dictionary<number>) => {
    const typeDisplayRows: HeadRow[] = [
      {
        id: 'SubDomain',
        numeric: false,
        disablePadding: false,
        label: 'Sub domain',
        enableSorting: false,
        colSpan: 1,
      },
    ];
    Object.keys(typeHeaderData).forEach((key) => {
      const displayRow: HeadRow = {
        id: key,
        numeric: false,
        disablePadding: false,
        label: capitalize(key),
        enableSorting: false,
        colSpan: typeHeaderData[key],
      };
      typeDisplayRows.push(displayRow);
    });
    return typeDisplayRows;
  };

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof AllTenantMetricsData,
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) {
    setPage(newPage);
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar disableGutters={true} className={classes.toolbar}>
          <Typography variant="h6" id="tableTitle">
            Tenant metrics for farm {farmId}
          </Typography>
        </Toolbar>
        {loading ? (
          <TableLoader />
        ) : (
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <MetricsTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                headerRows={headerRows}
                typeHeaderRows={typeHeaderRows}
              />
              <TableBody>
                {stableSort(allTenantMetrics, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((metrics) => (
                    <TableRow key={metrics.SubDomain.toString()}>
                      {headerRows.map((headerRow) => (
                        <TableCell
                          key={
                            metrics.SubDomain.toString() +
                            headerRow.id.toString()
                          }
                        >
                          {metrics[headerRow.id]
                            ? metrics[headerRow.id].toString()
                            : 'N/A'}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={headerRows.length} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    colSpan={headerRows.length}
                    count={allTenantMetrics.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            </Table>

            <DownloadMetrics
              headerRows={headerRows}
              tabledata={allTenantMetrics}
              typeHeaderRows={typeHeaderRows}
            />
          </div>
        )}
      </Paper>
    </div>
  );
}
