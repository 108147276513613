import { Button, Paper, Typography, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { customerService } from '../CustomerService';
import { InputClaimTypes, NewCustomer } from '../Model/CustomerModel';
import SaveIcon from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import { ToastContent, toast } from 'react-toastify';
import CustomerForm from './CustomerForm';
import CustomerConstants from '../Model/CustomerConstants';
import { Loader } from '../../../../Common/Components/Loader';
import { useStyles } from '../../../../Common/Styles/WraperStyle';
import { useHistory } from 'react-router-dom';
import { ValidationErrors } from 'fluentvalidation-ts';

export default function CreateCustomerPage() {
  const initialgroupnames: string[] = [];
  const [groupNames, setGroupNames] = useState(initialgroupnames);
  const [customer, setCustomer] = useState<NewCustomer>({});
  const [loading, setLoading] = useState(0);
  const history = useHistory();
  const [customerErrors, setCustomerErros] = useState<
    ValidationErrors<NewCustomer>
  >({});

  const classes = useStyles();

  useEffect(() => {
    InitData();
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      setCustomer({
        ...customer,
        [name]: checked,
      });
    } else if (name === 'IdentityProviders') {
      setCustomer({
        ...customer,
        IdentityProviders: value,
        InputClaimType: InputClaimTypes[value],
      });
    } else {
      setCustomer({
        ...customer,
        [name]: value,
      });
    }
  };

  const validateAuthenticationTypes = () => {
    if (!customer.EnableProArcLogin && !customer.ExternalIdentityProvider) {
      toast.error(
        'You need to select at least one of the authentication types.',
      );
      return false;
    }
    return true;
  };

  const validateIdentityProviderSection = () => {
    if (
      customer.ExternalIdentityProvider &&
      (customerErrors.AzureADAppClientID ||
        customerErrors.WSFederationUrl ||
        customerErrors.IdentityProviders ||
        customerErrors.InputClaimType ||
        customerErrors.AuthProviderName)
    ) {
      toast.error(
        'Some fields need to be filled in the create customer section',
      );
      return false;
    }
    return true;
  };

  const validateCustomerSection = () => {
    if (
      customerErrors.Subdomain ||
      customerErrors.CustomerName ||
      customerErrors.ContactPersons ||
      customerErrors.GroupName ||
      customerErrors.AdminUserName ||
      customerErrors.AdminUser
    ) {
      toast.error(
        'Some fields need to be filled in the create customer section',
      );
      return false;
    }
    return true;
  };

  useEffect(() => {
    InitData();
  }, []);

  const InitData = async () => {
    loadGroupNames();
    loadDefaultValues();
  };
  const loadGroupNames = async () => {
    try {
      const groupNames = await customerService.getGroupNames();
      setGroupNames(groupNames.Items);
    } catch (error) {
      toast.error(error as ToastContent);
    }
  };

  const loadDefaultValues = async () => {
    try {
      const defaults = await customerService.getDefaultValues();
      setCustomer({
        AdminUser: defaults.AdminUser,
        SetupType: 'proarc_default',
        AzureDBServiceObjective: defaults.AzureDBServiceObjective,
      });
    } catch (error) {
      toast.error(error as ToastContent);
    }
  };

  const createCustomer = () => {
    customerService
      .CreateCustomer(customer)
      .then(() => {
        toast.success(CustomerConstants.CustomerCreated);
        setLoading(loading - 1);
        handleClose();
      })
      .catch((error) => toast.error(error as ToastContent));
  };

  const handleSave = async () => {
    if (
      validateAuthenticationTypes() &&
      validateCustomerSection() &&
      validateIdentityProviderSection()
    ) {
      setLoading(loading + 1);
      createCustomer();
    }
  };

  const handleClose = () => {
    history.push('/customer');
  };

  return (
    <>
      <Loader loading={loading > 0} />
      <div>
        <Paper>
          <Typography variant="h5" className={classes.title}>
            Create Customer
          </Typography>
          <Divider className={classes.divider} />
          <CustomerForm
            groupNames={groupNames}
            onFieldChange={handleChange}
            customer={customer}
            errors={customerErrors}
            setErrors={setCustomerErros}
            ops="Create"
          />
          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              onClick={handleSave}
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Save{' '}
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.button}
              startIcon={<Cancel />}
            >
              Cancel{' '}
            </Button>
          </Paper>
        </Paper>
      </div>
    </>
  );
}
