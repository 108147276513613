import { TenantMetricsData } from '../Model/MetricsModel';
import {
  valueConverter,
  toStringValueConverter,
} from '../../../../Helpers/UtilityMethods';

export const getTenantSubDomain = (applicationUrl: string) => {
  const url = new URL(applicationUrl);
  const subDomain = url.host.split('.')[0];
  return subDomain;
};

export const convertValue = (metrics: TenantMetricsData) => {
  const converter = valueConverter[metrics.Unit];
  return converter ? converter(metrics.Value) : metrics.Value;
};

export const convertValueToString = (metrics: TenantMetricsData) => {
  const converter = toStringValueConverter[metrics.Unit];
  return converter ? converter(metrics.Value) : metrics.Value;
};
