import React, { useEffect } from 'react';
import TenantList from '../Components/TenantList';
import TenantForm from '../Components/TenantForm';
import { Toolbar, Typography } from '@mui/material';
import { FileShare } from '../Model/FileShareModel';
import { fileshareService } from '../FileShareService';
import { useStyles } from '../../../../../Common/Styles/Theme';

export const FileShareHomeTitleConstant = 'Tenant Management';
export const FileShareHomeTooltipTitle = 'Add New FileShare Tenants';

export default function SimpleTabs() {
  const classes = useStyles();
  const initialState: FileShare[] = [];
  const [fileshareList, setfileshareList] = React.useState(initialState);

  const loadFileShareData = async () => {
    try {
      const fileshareReceived = await fileshareService.getFileshare();
      setfileshareList(fileshareReceived.Items);
    } catch (error) {
      console.log(error);
    }
  };

  const addItemToState = (item: FileShare[]) => {
    setfileshareList(item);
  };

  useEffect(() => {
    loadFileShareData();
  }, []);
  return (
    <div className={classes.Root}>
      <Toolbar disableGutters={true}>
        <div className={classes.PageTitle}>
          <Typography variant="h6" id="tableTitle">
            {FileShareHomeTitleConstant}
          </Typography>
        </div>
        <div className={classes.SpaceBetween}></div>
        <div className={classes.RefreshLicenseButton}>
          <TenantForm addItemToState={addItemToState} />
        </div>
      </Toolbar>
      <TenantList
        filesharelist={fileshareList}
        addItemToState={addItemToState}
      />
    </div>
  );
}
