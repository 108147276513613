import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Backdrop,
  Button,
  CircularProgress,
  LinearProgress,
  TextField,
  Theme,
  Typography,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { clientsecretService } from '../ClientSecretService';
import { MessageGroup } from '../Model/ClientSecretesModel';
import { ToastContent, toast } from 'react-toastify';
import ClientSecretContants from '../Model/ClientSecretConstants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
    button: {
      margin: theme.spacing(1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    typo: {
      padding: '10px',
      fontSize: 12,
    },
  }),
);
export default function AddEditGroup(
  props: Readonly<{
    onclickAdd: Dispatch<SetStateAction<boolean>>;
    addclicked: boolean;
    onClose: () => void;
    clientId: string;
    customerId: string;
  }>,
) {
  const classes = useStyles();
  const [groupPageNo, setGroupPageNo] = React.useState(1);
  const [searchgroupPageNo, setSearchgroupPageNo] = React.useState(1);
  const initialavailablegroups: MessageGroup[] = [];
  const [loading, setLoading] = React.useState(false);
  const initialselectedgroups: MessageGroup[] = [];
  const [canAdd, setCanAdd] = React.useState(false);
  const [searchString, setSearchString] = React.useState<string>();
  const [getavailablegorupsflag, setGetAvaialbleGroupsFlag] =
    React.useState(false);
  const [availablegroupscount, setAvailabeGroupsCount] = React.useState(0);

  const [availablegroups, setAvailableGroups] = React.useState(
    initialavailablegroups,
  );
  const [selectedgroups, setSelectedGroups] = React.useState(
    initialselectedgroups,
  );
  const [isTooltipOpen, setTooltipOpen] = useState(false);

  const handleMouseEnter = () => {
    setTooltipOpen(true);
    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000);
  };

  const getGroupPageNumber = () => {
    setGroupPageNo(groupPageNo + 1);
    return groupPageNo;
  };
  const getSearchGroupPageNumber = () => {
    setSearchgroupPageNo(searchgroupPageNo + 1);
    return searchgroupPageNo;
  };
  const handleaddGroup = async () => {
    setLoading(true);
    try {
      await clientsecretService
        .AddGroupsToClient(props.customerId, props.clientId, selectedgroups)
        .then((result) => {
          setLoading(false);
          toast.success(`Groups add ${result}`);
          props.onClose();
        });
    } catch (error) {
      toast.error('Failed to add group/groups to client : ' + { error });
    }
  };
  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    SearchGroups(event.target.value);
  };
  const loadMoreItems = async () => {
    if (undefined !== searchString) {
      SearchGroups(searchString, getSearchGroupPageNumber());
    }
    setGetAvaialbleGroupsFlag(true);
    await clientsecretService
      .GetAvailableGroups(
        props.customerId,
        props.clientId,
        getGroupPageNumber(),
      )
      .then((data) => {
        setAvailableGroups((availablegroups) => [
          ...availablegroups,
          ...data.Items,
        ]);
      })
      .then(() => setGetAvaialbleGroupsFlag(false));
  };
  const handleValueChange = (newValue: MessageGroup[]) => {
    setSelectedGroups(newValue);
    if (newValue.length > 0) {
      setCanAdd(true);
    } else {
      setCanAdd(false);
    }
  };
  const SearchGroups = async (searchString: string, pageNo = 1) => {
    await clientsecretService
      .SearchAvailableGroups(
        props.customerId,
        props.clientId,
        searchString,
        pageNo,
      )
      .then((data) => {
        setAvailableGroups((availablegroups) => [
          ...availablegroups,
          ...data.Items,
        ]);
      });
  };

  async function GetAvailableGroups() {
    setLoading(true);
    setGetAvaialbleGroupsFlag(true);
    try {
      await clientsecretService
        .GetAvailableGroupsCount(props.customerId, props.clientId)
        .then((availablegroups) => {
          setAvailabeGroupsCount(availablegroups);
        });
    } catch (error) {
      toast.error(error as ToastContent);
    }

    clientsecretService
      .GetAvailableGroups(
        props.customerId,
        props.clientId,
        getGroupPageNumber(),
      )
      .then((data) => {
        setAvailableGroups(data.Items);
      })
      .then(() => setLoading(false))
      .then(() => setGetAvaialbleGroupsFlag(false));
  }
  useEffect(() => {
    GetAvailableGroups();
  }, []);
  return (
    <div>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      {props.addclicked ? (
        <>
          <Typography className={classes.typo}>
            Listing {availablegroups.length} of {availablegroupscount}
          </Typography>
          <Autocomplete
            multiple
            value={selectedgroups}
            options={availablegroups}
            onChange={(_, newValue) => {
              handleValueChange(newValue);
            }}
            getOptionLabel={(option) => option.Name + ` (${option.Id})`}
            renderInput={(params) => (
              <div className={classes.root}>
                {getavailablegorupsflag ? <LinearProgress /> : null}
                <Tooltip
                  open={isTooltipOpen}
                  title={ClientSecretContants.AutoCompleteToolTip}
                  arrow
                >
                  <TextField
                    {...params}
                    variant="outlined"
                    onChange={onInputChange}
                    onMouseEnter={handleMouseEnter}
                  />
                </Tooltip>
              </div>
            )}
            ListboxProps={{
              onScroll: (event: React.SyntheticEvent) => {
                const listboxNode = event.currentTarget;
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight ===
                  listboxNode.scrollHeight
                ) {
                  loadMoreItems();
                }
              },
            }}
          />
          {canAdd ? (
            <Button
              variant="contained"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={handleaddGroup}
            >
              Groups
            </Button>
          ) : null}
        </>
      ) : null}
    </div>
  );
}
