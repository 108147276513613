import React from 'react';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { SimpleDialog } from '../../../Common/Dialog/SimpleDialog';
import { plans } from '../../../Common/Constants/ValueConstants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      padding: 0,
    },
    button: {
      margin: theme.spacing(1),
    },
  }),
);

export default function SimpleDialogDemo(props: {
  addItemToState: (item: string[]) => void;
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(plans[0]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    if ('Not Selected' !== value) {
      setSelectedValue(value);
      const appserviceplan: string[] = [];
      appserviceplan.push(value);
      props.addItemToState(appserviceplan);
    }
  };

  return (
    <div>
      <br />
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className={classes.button}
        startIcon={<AddCircleIcon />}
      >
        New AppService
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
