import React, { useState } from 'react';
import {
  Icon,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  List,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Paper,
  Tooltip,
  ListItemButton,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ToastContent, toast } from 'react-toastify';

import { sendFileRequest } from '../../../../Service/SendFileRequest';
import { FileUploadDialog } from '../../../Common/Dialog';
import {
  Error,
  isValidError,
  isValidErrorMessage,
} from '../../../Common/CommonModel';
import { truncate } from '../../../../Helpers/UtilityMethods';
import { UploadPackageProps } from '../Model/ClientsModel';
import ClientConstants from '../Model/ClientConstants';
import { Loader } from '../../../../Common/Components/Loader';
import CircularProgressWithLabel from '../../../../Common/Components/CircularProgressWithLabel';

const useStyles = makeStyles((theme) => ({
  triggerUpload: {
    display: 'flex',
    flex: '1 50%',
  },
  filelist: {
    width: '300px',
    height: '200px',
    overflowY: 'auto',
  },
  uploadActions: {
    marginTop: '10px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

export default function UploadClientPackage(
  props: Readonly<UploadPackageProps>,
) {
  const stringTruncateLength = 22;
  const { open, onClose, onUploaded } = props;

  const classes = useStyles();

  const [files, setFiles] = useState<File[]>([]);
  const [uploading, setUploading] = useState(false);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState(false);
  const [uploadingFile, setUploadingFile] = useState<{
    filename: string;
    progress: number;
  }>({ filename: '', progress: 0 });

  const removeFromList = (filename: string) => {
    const filteredFiles = files.filter((file) => file.name !== filename);
    setFiles(filteredFiles);
  };

  const addFiles = async (files: File[]) => {
    setFiles(files);
    setShowFileUploadDialog(false);
  };

  const uploadFiles = async () => {
    if (files.length > 0) {
      await savePackages();
      setFiles([]);
      setShowFileUploadDialog(false);
    }
  };

  const onClosed = () => {
    setFiles([]);
    onClose();
  };

  const progress = (filename: string, uploaded: number) => {
    setUploadingFile((prevState) => ({
      ...prevState,
      progress: uploaded,
      filename: filename,
    }));
  };

  const fileUploadComplete = (filename: string) => {
    onUploaded();
    const packageSavedMessage =
      ClientConstants.PackageSavedMessage.format(filename);
    toast.success(packageSavedMessage);
    setUploading(false);
  };

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fileUploadFailed = (stausCode: number, response: any) => {
    const failuremessage = JSON.parse(response);
    if (isValidErrorMessage(failuremessage)) {
      toast.error(failuremessage.Message);
    } else {
      toast.error(
        ClientConstants.GenericUploadErrorMessage.format(stausCode.toString()),
      );
    }
    onClosed();
  };

  const savePackages = async () => {
    try {
      setUploading(true);
      const promises: unknown[] = [];
      files.forEach((file) => {
        promises.push(
          sendFileRequest(
            file,
            'resources',
            `${'proarc-clients'}/blobs`,
            progress,
            fileUploadComplete,
            fileUploadFailed,
          ),
        );
      });
      await Promise.all(promises);
    } catch (error) {
      if (isValidError(error as Error)) {
        setUploading(false);
        toast.error(error as ToastContent);
      } else {
        console.log(error);
      }
    }
  };

  return (
    <>
      <Dialog
        disableEscapeKeyDown
        maxWidth={'xl'}
        open={open}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title">Upload Files</DialogTitle>
        <DialogContent dividers>
          <div className={classes.triggerUpload}>
            <Paper>
              <div className={classes.filelist}>
                <List component="nav" aria-label="main mailbox folders">
                  {files.map((file) => (
                    <Tooltip title={file.name} key={file.name}>
                      <div key={file.name}>
                        <ListItemButton key={file.name}>
                          <ListItemText>
                            {truncate(file.name, stringTruncateLength)}
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => removeFromList(file.name)}
                              size="large"
                            >
                              <Icon>delete</Icon>
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                      </div>
                    </Tooltip>
                  ))}
                </List>
              </div>
            </Paper>
            <div>
              <FileUploadDialog
                open={showFileUploadDialog}
                dropzoneText={ClientConstants.PackageDropzoneText}
                acceptedFileExtensions={['zip']}
                onFilesAdded={addFiles}
              />
            </div>
          </div>
          <div className={classes.uploadActions}>
            <Button
              onClick={uploadFiles}
              variant="contained"
              color="primary"
              disabled={files.length < 1}
            >
              Upload
            </Button>
            <Loader loading={uploading} />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClosed} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={uploading}>
        <DialogTitle>
          <strong>Files Uploading</strong>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <CircularProgressWithLabel
            progress={uploadingFile.progress}
            filename={uploadingFile.filename}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
