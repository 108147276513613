import { Order } from '../../../../Helpers/Literals';

export interface TenantMetricsData {
  Type: string;
  Id: string;
  TenantId: string;
  TimeStamp: Date;
  Value: string;
  Unit: string;
  Name: string;
  DisplayName: string;
  ResourceName: string;
}

export interface TenantMetricsProps {
  farmId: number;
  tenantId: string;
}

export interface AllTenantMetricsProps {
  farmId: number;
}

export enum MetricEntity {
  Tenant,
  Farm,
}

export interface AllTenantMetricsData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface HeadRow {
  disablePadding: boolean;
  id: keyof AllTenantMetricsData;
  label: string;
  numeric: boolean;
  enableSorting: boolean;
  colSpan: number;
}

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AllTenantMetricsData,
  ) => void;
  order: Order;
  orderBy: string | number;
  headerRows: HeadRow[];
  typeHeaderRows: HeadRow[];
}

export interface DownloadMetricsProps {
  headerRows: HeadRow[];
  typeHeaderRows: HeadRow[];
  tabledata: AllTenantMetricsData[];
}
