import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  PurchaseOrderHeadRow,
  PurchaseOrder,
  EnhancedTableProps,
} from '../../Model/PurchaseOrderModel';
import {
  OrdersHeaderLabel,
  StandardUserHeaderLabel,
  ReadUserHeaderLabel,
  StorageHeaderLabel,
  IssueDateHeaderLabel,
  StartDateHeaderLabel,
  ExpiryDateHeaderLabel,
} from '../../../Dashboard/Model/LicenseConstants';

const PurchaseOrderheadRows: PurchaseOrderHeadRow[] = [
  { id: 'Name', label: OrdersHeaderLabel, enableSorting: true },
  {
    id: 'StandardUsersLicenses',
    label: StandardUserHeaderLabel,
    enableSorting: false,
  },
  { id: 'ReadUsersLicenses', label: ReadUserHeaderLabel, enableSorting: false },
  { id: 'StorageLicenses', label: StorageHeaderLabel, enableSorting: false },
  { id: 'IssuedOn', label: IssueDateHeaderLabel, enableSorting: true },
  { id: 'ValidFrom', label: StartDateHeaderLabel, enableSorting: true },
  { id: 'ValidTo', label: ExpiryDateHeaderLabel, enableSorting: true },
];

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: '#336699 !important',
    },
    hideCell: {
      display: 'none',
    },
    showCell: {
      display: 'table-cell',
    },
  }),
);

export default function PurchaseTableHeader(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, hasWritePermission } = props;
  const classes = useStyles();

  const createSortHandler =
    (property: keyof PurchaseOrder) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {PurchaseOrderheadRows.map((row, index) => (
          <TableCell
            className="theadcell"
            key={row.id}
            sortDirection={orderBy === row.id ? order : false}
            align={index === 0 ? 'left' : 'center'}
            style={{ minWidth: index === 0 ? '132px' : '' }}
          >
            {row.enableSorting ? (
              <TableSortLabel
                className={classes.root}
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            ) : (
              <div>{row.label}</div>
            )}
          </TableCell>
        ))}
        <TableCell align="center">Published</TableCell>
        <TableCell
          align="center"
          className={hasWritePermission ? classes.showCell : classes.hideCell}
        >
          Adminstration
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
