import React, { useEffect, useContext } from 'react';
import {
  Theme,
  Paper,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TableFooter,
  TablePagination,
  CircularProgress,
  Backdrop,
  IconButton,
} from '@mui/material';
import SellIcon from '@mui/icons-material/Sell';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CustomerTableHead from './CustomerTableHead';
import CustomerHeaderToolbar from './CustomerHeaderToolbar';
import { Customer, CustomerModule } from '../Model/CustomerModel';
import { customerService } from '../CustomerService';
import {
  getSorting,
  stableSort,
  getLocalDate,
} from '../../../../Helpers/UtilityMethods';
import { Order } from '../../../../Helpers/Literals';
import { Dictionary, TagDetails } from '../../../Common/CommonModel';
import CustomerMenu from '../Components/CustomerMenu';
import { PermissionsContext } from '../../UIContainer/PermissionsWrapper';
import PermissionConstants from '../../../Common/Constants/PermissionConstants';
import CustomerConstants from '../Model/CustomerConstants';
import { globalService } from '../../UIContainer/Global/GlobalService';
import { toast } from 'react-toastify';
import CommonTag from '../../../Common/Tag/CommonTag';
import MenuFab from '../../../Common/Fab/MenuFab';
import { tenantService } from '../../Tenant/TenantService';
import { Tenant } from '../../ManageFarms/Model/FarmModel';
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    subDomainWidth: {
      width: 421,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);
const stateMapping: Dictionary<string> = {};
stateMapping[1] = 'Production';
stateMapping[2] = 'Trial';
stateMapping[3] = 'Test';
stateMapping[4] = 'Demo';
const deploymentStateMapping: Dictionary<string> = {};
deploymentStateMapping[1] = 'Not deployed';
deploymentStateMapping[2] = 'Deployed';

interface RenderContentProps {
  classes: Record<string, string>;
  loading: boolean;
}

const ShowBackdropOnloading = ({ classes, loading }: RenderContentProps) => {
  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const ShowCannotViewCustomerWarning = () => {
  return (
    <div>
      <h4 style={{ textAlign: 'center' }}>
        {CustomerConstants.CanNotViewCustomers}
      </h4>
    </div>
  );
};

export default function CustomerHomePage() {
  const rowsPerPage = 10;
  const initialState: Customer[] = [];
  const initialModulesState: Dictionary<string[]> = {};
  const classes = useStyles();
  const [showMenus, setShowMenus] = React.useState(true);
  const [proarcCustomers, setCustomers] = React.useState(initialState);
  const [tenants, setTenants] = React.useState<Tenant[]>([]);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Customer>('Subdomain');
  const [loading, setLoadingState] = React.useState(true);
  const [customerModules, setCustomerModules] =
    React.useState(initialModulesState);
  const OnAdd = () => {
    loadCustomerData();
  };
  const [showTags, setShowtags] = React.useState(false);
  const [options, setOptions] = React.useState<string[]>([]);
  const [customerTags, setCustomerTags] = React.useState<string[]>([]);
  const [customerTagDetails, setCustomertagDetails] = React.useState<
    TagDetails[]
  >([]);
  const initialselectedcustomerTags: string[] = [];
  const [selectedCustomerTags, setSelectedCustomerTags] = React.useState(
    initialselectedcustomerTags,
  );
  const [selectedCustomer, setSelectedCustomer] = React.useState('');
  const [proarcCustomersCopy, setCustomersCopy] = React.useState(initialState);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, proarcCustomers.length - page * rowsPerPage);
  const permissions = useContext(PermissionsContext);
  useEffect(() => {
    loadCustomerData();
    loadTenants();
    loadTags();
    loadCuastomerTags();
  }, []);
  const loadCustomerData = async () => {
    try {
      const customersReceived = await customerService.getCustomers();
      setCustomers(customersReceived.Items);
      setCustomersCopy(customersReceived.Items);
      const modulesReceived = await customerService.getAllCustomerModules();
      buildCustomerModulesMapping(modulesReceived.Items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingState(false);
    }
  };
  const loadTenants = async () => {
    try {
      const tenants = await tenantService.getTenants();
      setTenants(tenants.Items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingState(false);
    }
  };

  const loadCuastomerTags = async () => {
    await globalService.getTagDetailsByObjectType('customer').then((tags) => {
      setCustomertagDetails(tags.Items);
      const uniqueTagNames = [...new Set(tags.Items.map((x) => x.TagName))];
      setCustomerTags(uniqueTagNames);
    });
  };

  const onSelectedCustomerTag = (tags: string[]) => {
    setSelectedCustomerTags(tags);
    const customerIds = customerTagDetails
      .filter((x) => tags.includes(x.TagName))
      .map((tagdetails) => tagdetails.ObjectId);
    let filteredCustomers: Customer[] = [];
    if (customerIds !== null && customerIds.length > 0) {
      filteredCustomers = proarcCustomersCopy.filter((customer) => {
        return customerIds.includes(customer.Id);
      });
      setCustomers(filteredCustomers);
    } else {
      setCustomers(proarcCustomersCopy);
    }
  };

  const buildCustomerModulesMapping = (modules: CustomerModule[]) => {
    const modulesMapping: Dictionary<string[]> = {};
    modules.forEach((module) => {
      if (modulesMapping[module.CustomerID]) {
        modulesMapping[module.CustomerID].push(module.ModuleID);
      } else {
        const emptyArry: string[] = [];
        modulesMapping[module.CustomerID] = emptyArry;
        modulesMapping[module.CustomerID].push(module.ModuleID);
      }
    });
    setCustomerModules(modulesMapping);
  };

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) {
    setPage(newPage);
  }

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof Customer,
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const loadTags = async () => {
    const tags = await globalService.getTags();
    setOptions(tags.Items.map((x) => x.TagName));
  };

  const handleFilter = (
    searchFilterText: string,
    showCollaborationOnlyCustomer: boolean,
  ) => {
    let filteredCustomers: Customer[] = [];
    filteredCustomers = proarcCustomersCopy.filter(function (customer) {
      const customerNameMatch = customer.CustomerName.toLowerCase().includes(
        searchFilterText.toLowerCase(),
      );
      const subdomainMatch = customer.Subdomain.toLowerCase().includes(
        searchFilterText.toLowerCase(),
      );
      const idMatch = customer.Id.toLowerCase().includes(
        searchFilterText.toLowerCase(),
      );
      return customerNameMatch || subdomainMatch || idMatch;
    });

    if (showCollaborationOnlyCustomer) {
      filteredCustomers = filteredCustomers.filter(function (customer) {
        const moduleIds = customerModules[customer.Id];
        if (moduleIds) {
          return moduleIds.indexOf('collaboration') >= 0;
        }
        return 0;
      });
    }

    setCustomers(filteredCustomers);
  };

  function handleSearchFilter(
    collaborationFilterApplied: boolean,
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    handleFilter(event.target.value, collaborationFilterApplied);
  }

  function showCollaborationOnlyCustomer(
    searchFilterText: string,
    collaborationFilterApplied: boolean,
  ) {
    handleFilter(searchFilterText, collaborationFilterApplied);
  }

  const getCustomerState = (stateId: number, deploymentStateId: number) => {
    if (deploymentStateId === 1) return deploymentStateMapping[1];
    return stateMapping[stateId];
  };

  const SaveTags = async (selected: string[]) => {
    setLoadingState(true);
    const tagsDetails: TagDetails[] = [];
    selected.forEach((tag: string) => {
      const tagdetails: TagDetails = {
        ObjectId: selectedCustomer,
        ObjectType: 'customer',
        TagName: tag,
      };

      tagsDetails.push(tagdetails);
    });
    await globalService
      .saveTagDetails(selectedCustomer, tagsDetails)
      .then((tagdetails) => {
        setOptions(tagdetails.Items.map((x) => x.TagName));
        toast.success(
          'Tags for customer ' +
            selectedCustomer.toString() +
            ' Saved Successfully',
        );
        loadTags();
        loadCuastomerTags();
        setShowtags(false);
        setLoadingState(false);
      });
  };

  function canViewCustomers(): boolean {
    return !!(
      permissions[PermissionConstants.CustomerReader] ||
      permissions[PermissionConstants.CustomerWriter]
    );
  }

  const RenderContent = ({ classes, loading }: RenderContentProps) => {
    if (loading) {
      return <ShowBackdropOnloading classes={classes} loading={loading} />;
    } else if (canViewCustomers()) {
      return <ShowCustomerData />;
    } else {
      return <ShowCannotViewCustomerWarning />;
    }
  };

  function ShowCustomerData() {
    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <CustomerTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {showTags ? (
              <CommonTag
                objectType="Customer"
                saveTags={SaveTags}
                objectId={selectedCustomer}
                options={options}
                setShowTags={setShowtags}
              />
            ) : null}
            {stableSort(proarcCustomers, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((customer) => (
                <TableRow key={customer.Id}>
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle2">
                      {customer.CustomerName}
                    </Typography>
                  </TableCell>
                  <TableCell>{customer.Id}</TableCell>
                  <TableCell>{customer.Subdomain}</TableCell>
                  <TableCell>
                    {getCustomerState(
                      customer.StateID,
                      customer.DeploymentStateID,
                    )}
                  </TableCell>
                  <TableCell>
                    {getLocalDate(
                      tenants.find((tenant) => tenant.Id === customer.Id)
                        ?.OnboardedDate,
                    )}
                  </TableCell>
                  <TableCell>{getLocalDate(customer.DeploymentDate)}</TableCell>
                  <TableCell>{getLocalDate(customer.InsertDate)}</TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setShowtags(true);
                        setSelectedCustomer(customer.Id);
                      }}
                    >
                      <SellIcon color="primary" />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <CustomerMenu
                      moduleIds={customerModules[customer.Id]}
                      customerId={customer.Id}
                      stateId={customer.StateID}
                    ></CustomerMenu>
                  </TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={9} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                colSpan={9}
                count={proarcCustomers.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        {showMenus ? (
          <CustomerHeaderToolbar
            onFilterRequest={handleSearchFilter}
            onCollaborationOnlyFilter={showCollaborationOnlyCustomer}
            OnAdd={OnAdd}
            customerTags={customerTags}
            onSelectedCustomerTag={onSelectedCustomerTag}
            selectedCustomerTags={selectedCustomerTags}
          />
        ) : null}

        <RenderContent classes={classes} loading={loading} />
        <MenuFab setShowMenus={setShowMenus} showMenus={showMenus} />
      </Paper>
    </div>
  );
}
