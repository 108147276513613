import React, { useContext, useEffect, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  Icon,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Theme,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { dssService } from './DssService';
import { CustomerDssSite, DssSitesRouterProps } from './Model';
import { SiteTableHeader, SitesHeader, SiteEditor } from './Components';
import { PermissionsContext } from '../UIContainer/PermissionsWrapper';
import PermissionConstants from '../../Common/Constants/PermissionConstants';
import CustomerConstants from '../Customer/Model/CustomerConstants';
import DssSiteConstants from './Model/DssSiteConstants';
import { truncate } from '../../../Helpers/UtilityMethods';
import { customerService } from '../Customer/CustomerService';
import { farmService } from '../Farm/FarmService';
import { CustomerV2 } from '../Customer/Model/CustomerModel';
import { globalService } from '../UIContainer/Global/GlobalService';
import { PortalConfig } from '../UIContainer/Global/GlobalState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    subDomainWidth: {
      width: 421,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    title: {
      flex: '0 0 auto',
    },
  }),
);
const emptyConfigData: PortalConfig = {
  Branch: '',
  Environment: 'Prod',
  LastUpdated: new Date(),
};

interface RenderContentProps {
  classes: Record<string, string>;
  loading: boolean;
}

const ShowBackdrop = ({ classes, loading }: RenderContentProps) => {
  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
const ShowCannotViewCustomer = () => {
  return (
    <div>
      <h4 style={{ textAlign: 'center' }}>
        {CustomerConstants.CanNotViewCustomers}
      </h4>
    </div>
  );
};

export default function DssSiteHome(props: DssSitesRouterProps) {
  const descriptionLength = 25;
  const { customerId } = props.match.params;

  const classes = useStyles();
  const [portalConfig, setPortalConfig] = useState(emptyConfigData);
  const permissions = useContext(PermissionsContext);

  const [loading, setLoadingState] = useState(true);
  const [selectedId, setSelectedId] = useState(-1);
  const [siteEditDrawerState, setSiteEditDrawerState] = useState(false);
  const [sites, setSites] = useState<CustomerDssSite[]>([]);
  const [customerInfo, setCustomerInfo] = useState({} as CustomerV2);

  useEffect(() => {
    if (canViewCustomers()) {
      globalService.getPortalConfig().then((info) => {
        customerService.getCustomer(customerId).then((cusInfo) => {
          dssService.getDssSites(customerId).then((Cusdsssites) => {
            customerService.getTenant(customerId, true).then((tenant) => {
              farmService
                .getFarm(tenant.Items[0].FarmId.toString())
                .then((farm) => {
                  Cusdsssites.push({
                    AzureRegion: farm.FarmRegion,
                    Id: 1,
                    CustomerId: customerId,
                    IsActive: true,
                    SecondaryRegion: farm.SecondaryRegion,
                    SiteCount: 1,
                    SiteDescription: 'default',
                    SiteId: 'default',
                    SiteUrl:
                      'https://' +
                      cusInfo.Subdomain +
                      '-dss' +
                      (info.Environment === 'Dev' ? '.dev' : '') +
                      '.proarconline.com',
                  });
                  setCustomerInfo(cusInfo);
                  setPortalConfig(info);
                  setSites(Cusdsssites);
                });
            });
          });
        });
      });
      setTimeout(function () {
        setLoadingState(false);
      }, 7000);
    }
  }, []);

  const setSiteDrawerOpenState = () => {
    setSelectedId(-1);
    setSiteEditDrawerState(true);
  };

  const canViewCustomers = (): boolean => {
    return !!(
      permissions[PermissionConstants.CustomerReader] ||
      permissions[PermissionConstants.CustomerWriter]
    );
  };

  const closeEditorDrawer = (newSite: boolean, dssSite: CustomerDssSite) => {
    setSelectedId(0);
    setSiteEditDrawerState(false);

    if (dssSite.Id > 0) {
      if (newSite) {
        setSites((prev) => [...prev, dssSite]);
      } else {
        const index = sites.findIndex(({ Id }) => Id === dssSite.Id);
        sites[index] = dssSite;
        setSites([...sites]);
      }
    }
  };

  const handleRowClick = (id: number) => {
    setSelectedId(id);
    setSiteEditDrawerState(true);
  };

  const ShowDssSiteData = () => {
    return (
      <Paper className={classes.paper}>
        <SitesHeader
          open={setSiteDrawerOpenState}
          customerName={customerInfo.CustomerName}
        />
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <SiteTableHeader />
            <TableBody>
              {sites.map((site) => (
                <TableRow key={site.Id}>
                  <TableCell component="th" scope="row">
                    {site.SiteId}
                  </TableCell>
                  <TableCell>{site.SiteCount}</TableCell>
                  <TableCell>{site.SiteUrl}</TableCell>
                  <TableCell>{site.AzureRegion}</TableCell>
                  <TableCell>{site.SecondaryRegion}</TableCell>
                  <TableCell>
                    {truncate(site.SiteDescription, descriptionLength)}
                  </TableCell>
                  <TableCell>{site.IsActive ? 'Active' : 'Inactive'}</TableCell>
                  <TableCell hidden={site.SiteCount === 1 ? true : false}>
                    <IconButton
                      disabled={site.SiteCount === 1 ? true : false}
                      edge="end"
                      aria-label="edit"
                      onClick={() => handleRowClick(site.Id)}
                      size="large"
                    >
                      <Tooltip title={DssSiteConstants.EditSiteHelperText}>
                        <Icon>edit</Icon>
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SiteEditor
          SiteCount={sites.length + 1}
          subDomain={customerInfo.Subdomain}
          subscription={portalConfig.Environment}
          customerId={customerId}
          openEditor={siteEditDrawerState}
          setDrawerClose={closeEditorDrawer}
          id={selectedId}
        />
      </Paper>
    );
  };
  const renderContent = () => {
    if (canViewCustomers()) {
      if (loading) {
        return <ShowBackdrop classes={classes} loading={loading} />;
      } else {
        return <ShowDssSiteData />;
      }
    } else {
      return <ShowCannotViewCustomer />;
    }
  };

  return <div className={classes.root}>{renderContent()}</div>;
}
