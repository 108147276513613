import React from 'react';
import { TableHead, TableRow, TableCell } from '@mui/material';

import { CustomerDssSite } from '../Model/DssModel';
import { TableHeader } from '../../../Common/CommonModel';
import { generateTableHeader } from '../../../../Common/CommonMethods';

const SiteTableheadRows: TableHeader<CustomerDssSite>[] = [
  generateTableHeader('SiteId', false, false, 'site id', false),
  generateTableHeader('SiteCount', false, false, 'Site Count', false),
  generateTableHeader('SiteUrl', false, false, 'site url', false),
  generateTableHeader('AzureRegion', false, false, 'site region', false),
  generateTableHeader(
    'SecondaryRegion',
    false,
    false,
    'secondary region',
    false,
  ),
  generateTableHeader('SiteDescription', false, false, 'description', false),
  generateTableHeader('IsActive', false, false, 'active', false),
];

export default function SiteTableHeader() {
  return (
    <TableHead>
      <TableRow>
        {SiteTableheadRows.map((Siterow) => (
          <TableCell
            key={Siterow.id}
            align={Siterow.numeric ? 'right' : 'left'}
            padding={Siterow.disablePadding ? 'none' : 'normal'}
          >
            <div>{Siterow.label}</div>
          </TableCell>
        ))}
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
}
