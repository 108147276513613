import React, { useEffect, useState } from 'react';
import {
  Theme,
  Typography,
  Backdrop,
  Input,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  CircularProgress,
  Toolbar,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SelectChangeEvent,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import { licenseService } from '../../Api/LicenseService';
import LicenseLogTableHeader from './Components/LicenseLogTableHeader';
import { LicenseLog } from '../Model/Log';
import { LicenseRouterProps } from '../../Dashboard/Model/LicenseModel';
import {
  filterNote,
  OperationType,
  LicenseLogsTitle,
} from '../../Dashboard/Model/LicenseConstants';
import { Order } from '../../../Helpers/Literals';
import { getSorting, stableSort } from '../../../Helpers/UtilityMethods';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
    },
    container: {
      maxHeight: '65vh !important',
    },
    title: {
      '& h6#tableTitle': {
        width: 'max-content',
      },
    },
    spaceBetween: {
      flex: '1 1 100%',
    },
    filterLogs: {
      marginTop: '-17px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 175,
      maxWidth: 300,
    },
    progress: {
      marginLeft: '50%',
      marginTop: '15%',
    },
    tableRow: {
      '& .MuiTableCell-root': {
        padding: '12px',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.10) !important',
      },
    },
    zebra: {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    actionButton: {
      '& .MuiButton-root.MuiButton-textPrimary': {
        color: theme.palette.primary.light,
      },
      '& .MuiButton-root.MuiButton-outlinedPrimary': {
        color: theme.palette.info.light,
        border: `1px solid ${theme.palette.info.light}`,
      },
    },
  }),
);

export default function LicenseLogPage(props: LicenseRouterProps) {
  const { tenantId } = props.match.params;
  const classes = useStyles();
  const initialLog: LicenseLog[] = [];
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof LicenseLog>('CreatedDate');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(20);
  const [licenselogs, setLicenseLogs] = useState(initialLog);
  const [licenselogcount, setLicenseLogCount] = useState(0);
  const [customerName, setCustomerName] = useState('');
  const [loading, setLoadingState] = useState(true);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const [operationParam, setOperationParam] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (loading) {
      loadLicenseLogs(page, operationParam);
      const customername = localStorage.getItem('customerName') || '';
      setCustomerName(JSON.parse(customername));
    }
  }, [loading]);

  const loadLicenseLogs = (page: number, operation: string) => {
    licenseService
      .getLicenseLogs(page, rowsPerPage, tenantId, operation)
      .then((licenselogs) => {
        setLicenseLogs(licenselogs.Items);
        setLicenseLogCount(licenselogs.TotalCount);
        setLoadingState(false);
        setOpenBackDrop(false);
      });
  };

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof LicenseLog,
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newpage: number,
  ) => {
    setPage(newpage);
    setOpenBackDrop(true);
    loadLicenseLogs(newpage, operationParam);
  };

  const handleOpenMessage =
    (scrollType: DialogProps['scroll'], message: string) => () => {
      setOpenDialog(true);
      setScroll(scrollType);
      setMessage(message);
    };

  const filterLicenseLogs = (event: SelectChangeEvent<string[]>) => {
    setOpenBackDrop(true);
    const filterValue = event.target.value.toString();
    setOperationParam(filterValue);
    loadLicenseLogs(page, filterValue);
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <>
          <Toolbar disableGutters={true}>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                {LicenseLogsTitle} {customerName.toUpperCase()}
              </Typography>
            </div>
            <div className={classes.spaceBetween}></div>
            <div className={classes.filterLogs}>
              <FormControl className={classes.formControl}>
                <InputLabel id="operation">{filterNote}</InputLabel>
                <Select
                  labelId="operation"
                  onChange={filterLicenseLogs}
                  input={<Input />}
                  renderValue={(selected) => {
                    if ((selected as string[]).length === 0) {
                      return filterNote; // Assuming filterNote is a string
                    }
                    return (selected as string[]).join(', '); // Join the selected strings
                  }}
                >
                  <MenuItem value="">All</MenuItem>
                  {OperationType.map((operation) => (
                    <MenuItem key={operation} value={operation}>
                      {operation}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Toolbar>
          <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <LicenseLogTableHeader
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {stableSort(licenselogs, getSorting(order, orderBy)).map(
                    (licenseInfo, index) => {
                      return (
                        <TableRow
                          hover
                          key={licenseInfo.Id.toString()}
                          className={`${classes.tableRow} ${
                            index % 2 === 0 && classes.zebra
                          }`}
                        >
                          <TableCell>{licenseInfo.PerformedBy}</TableCell>
                          <TableCell align="center">
                            {licenseInfo.Operation}
                          </TableCell>
                          <TableCell align="center">
                            {licenseInfo.Status ? 'Passed' : 'Failed'}
                          </TableCell>
                          <TableCell align="center">
                            {licenseInfo.CreatedDate.toString().slice(
                              0,
                              licenseInfo.CreatedDate.toString().indexOf('T'),
                            )}
                          </TableCell>
                          <TableCell
                            align="center"
                            className={classes.actionButton}
                          >
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              onClick={handleOpenMessage(
                                'paper',
                                licenseInfo.Message.toString(),
                              )}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    },
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[20]}
              component="div"
              count={licenselogcount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
            <Backdrop className={classes.backdrop} open={openBackDrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Dialog
              open={openDialog}
              onClose={handleCloseDialog}
              scroll={scroll}
              maxWidth={'md'}
            >
              <DialogTitle id="dialog-title">Message</DialogTitle>
              <DialogContent dividers={scroll === 'paper'}>
                <DialogContentText id="dialog-description" tabIndex={-1}>
                  {message}
                </DialogContentText>
              </DialogContent>
              <DialogActions className={classes.actionButton}>
                <Button onClick={handleCloseDialog} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </>
      )}
    </div>
  );
}
