import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { useToolbarStyles } from '../CommonMethods/ClientSecretCommonMethods';
import { UserMessageGroup } from '../Model/ClientSecretesModel';
import { clientsecretService } from '../ClientSecretService';
import AddEditGroupToolbar from './AddEditGroupToolbar';
import Cancel from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
import LoopIcon from '@mui/icons-material/Loop';
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRowSelectionModel,
} from '@mui/x-data-grid';
import { ToastContent, toast } from 'react-toastify';
import ClientSecretContants from '../Model/ClientSecretConstants';
export default function AddEditGroup(
  props: Readonly<{
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    clientId: string;
    customerId: string;
  }>,
) {
  const [open, setOpen] = useState(true);
  const classes = useToolbarStyles();
  const [add, setAdd] = React.useState(false);
  const initialclientgroups: UserMessageGroup[] = [];
  const [clientgroups, setClientGroups] = React.useState(initialclientgroups);
  const initialselectedRowsIds: number[] = [];
  const [clientgroupscount, setClientGroupsCount] = React.useState(0);
  const [selectedRowRnos, setSelectedRowRnos] = React.useState(
    initialselectedRowsIds,
  );
  const [clientgroupspageno, setClientGroupsPageNo] = React.useState(1);
  const [loading, setIsLoading] = React.useState(true);
  const [loadingmore, setIsLoadingMore] = React.useState(false);

  const AddEditheadRows: GridColDef[] = [
    {
      field: 'Name',
      headerName: 'Group Name',
      description: 'Group Name',
      editable: false,
      width: 500,
      resizable: true,
    },
    {
      field: 'Id',
      headerName: 'Group ID',
      description: 'Group ID',
      editable: false,
      width: 200,
      resizable: true,
    },
    {
      field: 'deleteButton',
      headerName: 'Actions',
      description: 'Actions',
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return (
          <Tooltip title={ClientSecretContants.RemoveGroupToolTip}>
            <IconButton
              onClick={(e) => onDeleteButtonClick(e, params.row)}
              color="primary"
              size="large"
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const loadMoreItems = () => {
    setIsLoadingMore(true);
    clientsecretService
      .GetClientGroups(props.customerId, props.clientId, getGroupPageNumber())
      .then((data) => {
        setClientGroups((clientgroups) => [...clientgroups, ...data.Items]);
      })
      .then(() => setIsLoadingMore(false));
  };
  const handleAdd = () => {
    setAdd(true);
  };
  const massdelete = () => {
    RemoveGroups(selectedRowRnos);
  };

  function RemoveGroups(groupsrnos: number[]) {
    setIsLoading(true);
    clientsecretService
      .RemoveGroupsFromClient(props.customerId, props.clientId, groupsrnos)
      .then((result) => {
        setClientGroups(result.Items);
        setIsLoading(false);
        toast.success(`Groups removed succeed`);
      });
  }
  const onDeleteButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    row: GridRowModel,
  ) => {
    e.stopPropagation();
    const grouprnos: number[] = [row.GroupRno];
    RemoveGroups(grouprnos);
  };

  const onRowsSelectionHandler = (ids: GridRowSelectionModel) => {
    const selectedRowsData = ids.map((id) => {
      const row = clientgroups.find((row) => row.Id === id);
      if (row !== undefined) return row.GroupRno;
      return null;
    });
    setSelectedRowRnos(
      selectedRowsData.filter((value) => value !== null) as number[],
    );
  };
  const handleClose = () => {
    props.setOpen(false);
    setOpen(false);
  };
  const getGroupPageNumber = () => {
    setClientGroupsPageNo(clientgroupspageno + 1);
    return clientgroupspageno;
  };
  async function GetClientGroups() {
    try {
      await clientsecretService
        .GetClientGroupsCount(props.customerId, props.clientId)
        .then((clientgroups) => {
          setClientGroupsCount(clientgroups);
        });
    } catch (error) {
      toast.error(error as ToastContent);
    }
    clientsecretService
      .GetClientGroups(props.customerId, props.clientId, getGroupPageNumber())
      .then((data) => {
        setClientGroups(data.Items);
      })
      .then(() => setIsLoading(false));
  }
  useEffect(() => {
    GetClientGroups();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Groups</DialogTitle>
        <DialogContent className={classes.DialogContent}>
          <div>
            {loading ? (
              <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <>
                {!add ? (
                  <div>
                    <div style={{ float: 'left' }}>
                      <Typography className={classes.typo}>
                        Listing {clientgroups.length} of {clientgroupscount}
                      </Typography>
                    </div>
                    <div style={{ float: 'right', display: 'inline-flex' }}>
                      {selectedRowRnos.length > 0 ? (
                        <Button
                          variant="contained"
                          className={classes.SmallButton}
                          startIcon={<DeleteIcon />}
                          onClick={massdelete}
                        >
                          Groups
                        </Button>
                      ) : null}
                      <Tooltip
                        title={ClientSecretContants.AddGroupsToClientToolTip}
                      >
                        <Button
                          variant="contained"
                          className={classes.SmallButton}
                          startIcon={<AddIcon />}
                          onClick={handleAdd}
                        >
                          Groups
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                ) : null}
                <AddEditGroupToolbar
                  onclickAdd={setAdd}
                  addclicked={add}
                  onClose={handleClose}
                  clientId={props.clientId}
                  customerId={props.customerId}
                />
              </>
            )}
            {!add && clientgroups.length > 0 ? (
              <div
                style={{ height: 400, width: '100%', display: 'inline-block' }}
              >
                <div className={classes.root}>
                  {loadingmore ? <LinearProgress /> : null}
                </div>
                <DataGrid
                  getRowId={(row) => row.Id}
                  className={classes.DataGridCell}
                  rows={clientgroups}
                  columns={AddEditheadRows}
                  checkboxSelection
                  disableRowSelectionOnClick
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  onRowSelectionModelChange={(ids: GridRowSelectionModel) =>
                    onRowsSelectionHandler(ids)
                  }
                />
                {clientgroups.length !== clientgroupscount ? (
                  <Tooltip title={ClientSecretContants.LoadMoreToolTip}>
                    <Button
                      variant="contained"
                      className={classes.SmallButton}
                      startIcon={<LoopIcon />}
                      onClick={loadMoreItems}
                    >
                      Load More
                    </Button>
                  </Tooltip>
                ) : null}
              </div>
            ) : null}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleClose}
            className={classes.button}
            startIcon={<Cancel />}
          >
            Cancel{' '}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
