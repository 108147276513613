import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, Theme, Divider } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Dictionary } from '../../../Common/CommonModel';
import { CustomerMenuItems } from '../../Customer/Model/CustomerModel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import UriApp from '../Home/TrustedClientsRedirectUri';
import isURL from 'is-url';
import { Link } from 'react-router-dom';
import { TrustedClients } from '../Model';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      padding: 0,
    },
    button: {
      margin: theme.spacing(1),
      width: 200,
    },
  }),
);

const menuItems: CustomerMenuItems[] = [
  {
    id: 'Edit',
    label: 'Edit',
    linkTo: '/customer/trustedclients',
    validationFunc: 'Edit',
  },
  {
    id: 'Delete',
    label: 'Delete',
    linkTo: '/customer/trustedclients',
    validationFunc: 'Delete',
  },
  {
    id: 'ManageClientSecret',
    label: 'Manage ApiKey Secret',
    linkTo: '/customer/{0}/manageclientsecret',
    validationFunc: 'ManageClientSecret',
  },
];

interface TrustedClientsMenuProps {
  ClientID: string;
  onDelete: (event: React.MouseEvent<HTMLLIElement>) => void;
  onUpdate: (val: TrustedClients) => void;
  Tclient: TrustedClients;
  customerId: string;
}

export default function TrustedClientsMenu(
  props: Readonly<TrustedClientsMenuProps>,
) {
  const classes = useStyles();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [menuState, setMenuState] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [clients] = React.useState({
    ClientID: '',
    ClientUri: '',
    RedirectUris: [''],
    LogoutUri: '',
    AllowedScopes: [''],
    AllowedGrantTypes: [''],
  });

  const [ClientID, setFieldClientID] = React.useState('');
  const [ClientUri, setFieldClientUri] = React.useState('');
  const [LogoutUri, setFieldLogoutUri] = React.useState('');
  const [RedirectUris, setFieldRedirectUris] = React.useState(['']);
  const [AllowedScopes, setFieldAllowedScopes] = React.useState(['']);
  const [AllowedGrantTypes, setFieldAllowedGrantTypes] = React.useState(['']);

  const [ClientIDE, setClientID] = React.useState(false);
  const [ClientUriE, setClientUri] = React.useState(false);
  const [LogoutUriE, setLogoutUri] = React.useState(false);
  const [, setAllowedScopes] = React.useState(false);
  const [, setAllowedGrantTypes] = React.useState(false);
  const [RedirectUrisE, setRedirectUris] = React.useState(false);

  useEffect(() => {
    loadCustomerData();
  }, []);

  const validRadio = (str: string[]) => {
    if (str.length >= 2) {
      return true;
    }
    return false;
  };

  const validateClientID = (str: string) => {
    if (str === '') {
      return false;
    }
    return true;
  };

  const validateRedirectUris = (str: string[]) => {
    if (str.length === 0 || RedirectUris.filter((i) => i === '').length) {
      return false;
    } else {
      let res = true;
      for (const i of str) {
        res = res && isURL(i);
      }
      return res;
    }
  };

  const IsClientValid = (res: boolean) => {
    if (validateClientID(ClientID) && res) {
      setClientID(true);
      return true;
    }
    return false;
  };

  const IsRedirectUriValid = (res: boolean) => {
    if (validateRedirectUris(RedirectUris) && res) {
      setRedirectUris(true);
      return true;
    }
    return false;
  };

  const IsUriValid = (res: boolean) => {
    if (isURL(LogoutUri) && res) {
      setLogoutUri(true);
      return true;
    }
    return false;
  };
  const IsUriValidC = (res: boolean) => {
    if (isURL(ClientUri) && res) {
      setClientUri(true);
      return true;
    }
    return false;
  };

  const IsRadioValid = (res: boolean) => {
    if (validRadio(AllowedScopes) && res) {
      setAllowedScopes(true);
      return true;
    }
    return false;
  };

  const IsRadioValidGT = (res: boolean) => {
    if (validRadio(AllowedGrantTypes) && res) {
      setAllowedGrantTypes(true);
      return true;
    }
    return false;
  };

  const Validate = () => {
    let res = true;
    res = IsClientValid(res);
    res = IsRedirectUriValid(res);
    res = IsUriValid(res);
    res = IsUriValidC(res);
    res = IsRadioValid(res);
    res = IsRadioValidGT(res);
    return res;
  };

  const loadCustomerData = () => {
    setFieldClientID(props.Tclient.ClientID);
    setFieldClientUri(props.Tclient.ClientUri);
    setFieldLogoutUri(props.Tclient.LogoutUri);
    setFieldRedirectUris(props.Tclient.RedirectUris);
    setFieldAllowedScopes(props.Tclient.AllowedScopes);
    setFieldAllowedGrantTypes(props.Tclient.AllowedGrantTypes);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    closeCustomerMenu();
  };

  const closeCustomerMenu = () => {
    setMenuState(false);
    setAnchor(null);
  };

  const openCustomerMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuState(true);
    setAnchor(event.currentTarget);
  };

  const executection = (event: React.MouseEvent<HTMLLIElement>) => {
    const target = event.target as HTMLElement;
    if (target.innerText === 'Edit') {
      handleClickOpen();
    } else if (target.innerText === 'Delete') {
      props.onDelete(event);
      closeCustomerMenu();
    }
  };

  function executeValidationMethod(type: string) {
    let fn;
    const validations: Dictionary<() => boolean> = {};
    validations['Delete'] = () => true;
    validations['Edit'] = () => true;
    validations['ManageClientSecret'] = () => true;
    validations['default'] = () => false;

    if (validations[type]) {
      fn = validations[type];
    } else {
      fn = validations['default'];
    }
    return fn();
  }

  const handleSave = () => {
    if (Validate() === true) {
      clients.ClientID = ClientID;
      clients.ClientUri = ClientUri;
      clients.LogoutUri = LogoutUri;
      clients.RedirectUris = RedirectUris;
      clients.AllowedScopes = AllowedScopes;
      clients.AllowedGrantTypes = AllowedGrantTypes;
      props.onUpdate(clients);
      handleClose();
      closeCustomerMenu();
      setFieldClientID('');
      setFieldClientUri('');
      setFieldLogoutUri('');
      setFieldRedirectUris(['']);
      setFieldAllowedScopes(['']);
      setFieldAllowedGrantTypes(['']);
      loadCustomerData();
    }
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    switch (event.target.name) {
      case 'ClientUri':
        setFieldClientUri(event.target.value);
        break;

      case 'RedirectUris': {
        const values = RedirectUris.filter((item: string) => item !== '');
        if (RedirectUris.indexOf(event.target.value) === -1) {
          if (event.target.checked) {
            RedirectUris.push(event.target.value);
          } else {
            const redirectUris = RedirectUris.filter(
              (item) => item !== event.target.value,
            );
            setFieldRedirectUris(redirectUris);
          }
        }
        setFieldRedirectUris(values);
        break;
      }

      case 'LogoutUri':
        setFieldLogoutUri(event.target.value);
        break;

      case 'AllowedScopes': {
        const valuess = [...AllowedScopes];
        if (event.target.checked) {
          valuess.push(event.target.value);
          setFieldAllowedScopes(valuess);
        } else {
          setFieldAllowedScopes(
            valuess.filter((item: string) => item !== event.target.value),
          );
        }
        break;
      }

      case 'AllowedGrantTypes': {
        const val = [...AllowedGrantTypes];
        if (event.target.checked) {
          val.push(event.target.value);
          setFieldAllowedGrantTypes(val);
        } else {
          setFieldAllowedGrantTypes(
            val.filter((item: string) => item !== event.target.value),
          );
        }
        break;
      }
    }
  }

  function handleCheckAS(Name: string) {
    if (AllowedScopes.indexOf(Name) === -1) {
      return false;
    } else {
      return true;
    }
  }

  function handleCheck(Name: string) {
    if (AllowedGrantTypes.indexOf(Name) === -1) {
      return false;
    } else {
      return true;
    }
  }

  const onUpdate = (val: string[]) => {
    const arr = [];
    for (const j in val) {
      arr.push(val[j]);
    }
    setFieldRedirectUris(arr);
  };

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns="long-menu"
        aria-haspopup="true"
        onClick={openCustomerMenu}
        className={classes.menu}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      {menuItems.some((menu) =>
        executeValidationMethod(menu.validationFunc),
      ) && (
        <Menu
          id="long-menu"
          anchorEl={anchor}
          open={menuState}
          onClose={closeCustomerMenu}
          PaperProps={{
            style: {
              width: 200,
            },
          }}
        >
          {menuItems.map((menu) => (
            <div key={menu.id}>
              {menu.id === 'ManageClientSecret' ? (
                <MenuItem
                  id={props.ClientID}
                  key={menu.id}
                  component={Link}
                  to={menu.linkTo.format(props.customerId)}
                >
                  {menu.label}
                </MenuItem>
              ) : (
                <MenuItem
                  onClick={executection}
                  id={props.ClientID}
                  value={menu.label}
                  key={menu.id}
                >
                  {menu.label}
                </MenuItem>
              )}

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">Trusted Client</DialogTitle>
                <DialogContent>
                  <Table style={{ width: 550, textAlign: 'left' }}>
                    <tr>
                      <th>Client ID\Name:</th>
                      <td>
                        <TextField
                          error={ClientIDE}
                          required
                          placeholder="Client ID"
                          name="ClientID"
                          value={ClientID}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Client Uri: </th>
                      <td>
                        {' '}
                        <TextField
                          required
                          error={ClientUriE}
                          placeholder="Client Uri"
                          name="ClientUri"
                          value={ClientUri}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>{"Redirect Uri's: "}</th>
                      <td>
                        <UriApp
                          onUpdate={(e: string[]) => onUpdate(e)}
                          LUri={RedirectUris}
                          RedirectUrisE={RedirectUrisE}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Logout Uri: </th>
                      <td>
                        <TextField
                          error={LogoutUriE}
                          required
                          placeholder="Logout Uri"
                          name="LogoutUri"
                          value={LogoutUri}
                          onChange={handleChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>Scopes: </th>
                      <td>
                        <tr>
                          <td>
                            <input
                              name="AllowedScopes"
                              value="openid"
                              type="checkbox"
                              checked={handleCheckAS('openid')}
                              onChange={handleChange}
                            />
                            openid
                          </td>
                          <td>
                            {' '}
                            <input
                              name="AllowedScopes"
                              value="profile"
                              type="checkbox"
                              checked={handleCheckAS('profile')}
                              onChange={handleChange}
                            />
                            profile
                          </td>
                          <td>
                            <input
                              name="AllowedScopes"
                              value="Email"
                              type="checkbox"
                              checked={handleCheckAS('Email')}
                              onChange={handleChange}
                            />
                            Email
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              name="AllowedScopes"
                              value="Address"
                              type="checkbox"
                              checked={handleCheckAS('Address')}
                              onChange={handleChange}
                            />
                            Address{' '}
                          </td>
                          <td>
                            <input
                              name="AllowedScopes"
                              value="Phone"
                              type="checkbox"
                              checked={handleCheckAS('Phone')}
                              onChange={handleChange}
                            />
                            Phone
                          </td>
                          <td>
                            <input
                              name="AllowedScopes"
                              value="OfflineAccess"
                              type="checkbox"
                              checked={handleCheckAS('OfflineAccess')}
                              onChange={handleChange}
                            />
                            OfflineAccess
                          </td>
                        </tr>
                        <Divider />
                        <br />
                      </td>
                    </tr>
                    <tr>
                      <th>Grant Types: </th>
                      <td>
                        <tr>
                          <td>
                            <input
                              name="AllowedGrantTypes"
                              value="hybrid"
                              type="checkbox"
                              checked={handleCheck('hybrid')}
                              onChange={handleChange}
                            />
                            Hybrid
                          </td>
                          <td>
                            <input
                              name="AllowedGrantTypes"
                              value="client_credentials"
                              type="checkbox"
                              checked={handleCheck('client_credentials')}
                              onChange={handleChange}
                            />
                            ClientCredentials
                          </td>
                          <td>
                            <input
                              name="AllowedGrantTypes"
                              value="urn:ietf:params:oauth:grant-type:device_code"
                              type="checkbox"
                              checked={handleCheck(
                                'urn:ietf:params:oauth:grant-type:device_code',
                              )}
                              onChange={handleChange}
                            />
                            DeviceFlow
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <input
                              name="AllowedGrantTypes"
                              value="implicit"
                              type="checkbox"
                              checked={handleCheck('implicit')}
                              onChange={handleChange}
                            />
                            Implicit
                          </td>
                          <td>
                            <input
                              name="AllowedGrantTypes"
                              value="authorization_code"
                              type="checkbox"
                              checked={handleCheck('authorization_code')}
                              onChange={handleChange}
                            />
                            AuthorizationCode
                          </td>
                          <td>
                            <input
                              name="AllowedGrantTypes"
                              value="password"
                              type="checkbox"
                              checked={handleCheck('password')}
                              onChange={handleChange}
                            />
                            ResourceOwnerPassword
                          </td>
                        </tr>
                      </td>
                    </tr>
                  </Table>
                  <Divider />
                  <br />
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={handleSave}
                    className={classes.button}
                    startIcon={<SaveIcon />}
                  >
                    Save{' '}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    className={classes.button}
                    startIcon={<Cancel />}
                  >
                    Cancel{' '}
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          ))}
        </Menu>
      )}
    </div>
  );
}
