export interface PagedData<T> {
  TotalCount: number;
  Items: T[];
}

export interface Dictionary<T> {
  [Key: string]: T;
}

export interface ErrorMessage {
  Message: string;
}

export interface Error {
  status: number;
  statusText: string;
  url: string;
  errorMessage: ErrorMessage;
}

export interface TableHeader<T> {
  disablePadding: boolean;
  id: keyof T;
  label: string;
  numeric: boolean;
  enableSorting: boolean;
}

export interface RunbookPayload {
  Name: string;
  Exception: string | null;
  JobId: string;
  LastStatusModifiedTime: string;
  StartTime: string;
  Status: string;
  ConnectionId: string;
}

/**
 * type guard to check if the error can be handled
 */
export function isValidError(error: Error): error is Error {
  return (error as Error).errorMessage !== undefined;
}

/**
 * type guard to check if the error message can be handled
 */
export function isValidErrorMessage(
  error: ErrorMessage,
): error is ErrorMessage {
  return (error as ErrorMessage).Message !== undefined;
}

export interface Subscription {
  Name: string;
  SubscriptionID: string;
  ClientID: string;
  ClientSecret: string;
  TenantID: string;
  Environment: string;
  DefaultLocation: string;
  SecondaryLocation: string;
}

export interface Product {
  Id: string;
  Variant: string;
  Domain: string;
  HealthPagePath: string;
  LogsPath: string;
}

export interface Tag {
  TagName: string;
}

export interface TagDetails {
  Id?: number;
  TagName: string;
  ObjectType: string;
  ObjectId: string;
}

export interface SelectListItem {
  Text: string;
  Value: string;
}

export interface CheckBoxItem {
  Text: string;
  Value: string;
  Check: boolean;
}

export interface MenuItems {
  id: string;
  label: string;
  linkTo: string;
  validationFunc: string;
}

export const sendToList: SelectListItem[] = [
  {
    Text: 'Farm',
    Value: 'farm',
  },
  {
    Text: 'Customer',
    Value: 'customer',
  },
  {
    Text: 'E-mail Address',
    Value: 'email',
  },
  {
    Text: 'All Customers',
    Value: 'allcustomers',
  },
  {
    Text: 'Tags',
    Value: 'tags',
  },
];
