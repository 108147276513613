import {
  Backdrop,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, { useContext, useEffect } from 'react';
import StorageAccountHeaderToolbar from './Components/StorageAccountHeaderToolbar';
import { useStyles } from '../Customer/Home/CustomerHomePage';
import PermissionConstants from '../../Common/Constants/PermissionConstants';
import { PermissionsContext } from '../UIContainer/PermissionsWrapper';
import { storageAccountService } from './StorageAccountService';
import StorageAccountsTableHead from './Components/StorageAccountsHead';
import { getSorting, stableSort } from '../../../Helpers/UtilityMethods';
import { Order } from '../../../Helpers/Literals';
import { StorageAccount } from './Model/StorageAccountModel';
import StorageAccountMenu from './Components/StorageAccountMenu';
import { ToastContent, toast } from 'react-toastify';
import StorageAccountConstants from './Common/Constants';

interface RenderContentProps {
  classes: Record<string, string>;
  loading: boolean;
}

const ShowBackdropOnloading = ({ classes, loading }: RenderContentProps) => {
  return (
    <Backdrop className={classes.backdrop} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

const ShowCannotViewStorageAccountWarning = () => {
  return (
    <div>
      <h4 style={{ textAlign: 'center' }}>
        {StorageAccountConstants.StorageAccountPermissionWarn}
      </h4>
    </div>
  );
};

const StorageAccountHome = () => {
  const rowsPerPage = 8;
  const classes = useStyles();
  const permissions = useContext(PermissionsContext);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof StorageAccount>('Name');

  const initialState: StorageAccount[] = [];
  const [StorageAccounts, setStorageAccounts] = React.useState(initialState);
  const [StorageAccountsCopy, setStorageAccountsCopy] =
    React.useState(initialState);
  const [loading, setLoadingState] = React.useState(true);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, StorageAccounts.length - page * rowsPerPage);

  useEffect(() => {
    loadStorageAccountData();
  }, []);
  const loadStorageAccountData = async () => {
    try {
      const storageAccountsRevieved =
        await storageAccountService.getstorageAccounts();
      setStorageAccounts(storageAccountsRevieved.Items);
      setStorageAccountsCopy(storageAccountsRevieved.Items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingState(false);
    }
  };

  const DeleteStorageAccount = async (Name: string) => {
    if (window.confirm(StorageAccountConstants.StorageAccountDeleteWarn)) {
      try {
        setLoadingState(true);
        await storageAccountService.deletestorageAccount(Name);
        setStorageAccounts(StorageAccounts.filter((sa) => sa.Name !== Name));
        toast.success(StorageAccountConstants.StorageAccountDeleteSuc);
        setLoadingState(false);
      } catch (error) {
        console.error(error);
        toast.error(error as ToastContent);
      }
    }
  };

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof StorageAccount,
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) {
    setPage(newPage);
  }

  const handleFilter = (searchFilterText: string) => {
    const filteredStorageAccounts = StorageAccountsCopy.filter(
      function (storageAccount) {
        return (
          storageAccount.Name.toLowerCase().indexOf(
            searchFilterText.toLowerCase(),
          ) >= 0
        );
      },
    );
    setStorageAccounts(filteredStorageAccounts);
  };

  function handleSearchFilter(event: React.ChangeEvent<HTMLInputElement>) {
    handleFilter(event.target.value);
  }

  function canViewStorageAccounts(): boolean {
    return !!(
      permissions[PermissionConstants.StorageAccountReader] ||
      permissions[PermissionConstants.StorageAccountWriter]
    );
  }
  function ShowStorageAccountData() {
    return (
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <StorageAccountsTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(StorageAccounts, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((sa) => (
                <TableRow key={sa.Name}>
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.subDomainWidth}
                  >
                    {sa.Name}
                  </TableCell>
                  <TableCell>{sa.ResourceGroup}</TableCell>
                  <TableCell>{sa.Subscription}</TableCell>
                  <TableCell>{sa.ProductID}</TableCell>
                  <TableCell>{sa.Purpose}</TableCell>
                  <TableCell>{sa.AzureRegion}</TableCell>
                  <TableCell style={{ width: 100 }} align="left">
                    <StorageAccountMenu
                      Name={sa.Name}
                      DeleteFunction={DeleteStorageAccount}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[8]}
                colSpan={8}
                count={StorageAccounts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'Rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </div>
    );
  }

  const RenderContent = ({ classes, loading }: RenderContentProps) => {
    if (loading) {
      return <ShowBackdropOnloading classes={classes} loading={loading} />;
    } else if (canViewStorageAccounts()) {
      return <ShowStorageAccountData />;
    } else {
      return <ShowCannotViewStorageAccountWarning />;
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <StorageAccountHeaderToolbar onFilterRequest={handleSearchFilter} />
        <RenderContent classes={classes} loading={loading} />
      </Paper>
    </div>
  );
};

export default StorageAccountHome;
