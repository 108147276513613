import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  PortalGroup,
  PortalGroupPermission,
  PortalPermission,
  PortalUser,
} from './Model/AccessControlModel';
import { accessControlService } from './AccessControlService';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import TextField from '@mui/material/TextField/TextField';
import { Save } from '@mui/icons-material';
import { ToastContent, toast } from 'react-toastify';
import { useToolbarStyles } from '../Customer/Home/CustomerHeaderToolbar';
import DeleteIcon from '@mui/icons-material/Delete';
import UserGroupDragable from './Components/UserGroupDragable';
import GroupPermissionsDragable from './Components/GroupPermissionsDragable';

interface ListComponentProps {
  items: Array<PortalUser | PortalGroup>;
  title: string;
  icon: React.ReactElement;
  setCurrentUser: React.Dispatch<React.SetStateAction<string>>;
  setCurrentGroup: React.Dispatch<React.SetStateAction<string>>;
  addIcon: React.ReactElement;
  addClickHandler: (
    event: React.MouseEvent<HTMLElement>,
    setAddPortalUser: React.Dispatch<React.SetStateAction<boolean>>,
    setEditPortalUser: React.Dispatch<React.SetStateAction<boolean>>,
    setAddPortalGroup: React.Dispatch<React.SetStateAction<boolean>>,
    setEditPortalGroup: React.Dispatch<React.SetStateAction<boolean>>,
  ) => void;
  setAddPortalUser: React.Dispatch<React.SetStateAction<boolean>>;
  setEditPortalUser: React.Dispatch<React.SetStateAction<boolean>>;
  setAddPortalGroup: React.Dispatch<React.SetStateAction<boolean>>;
  setEditPortalGroup: React.Dispatch<React.SetStateAction<boolean>>;
  resetAddstate: () => void;
  setGroupType: React.Dispatch<React.SetStateAction<string>>;
}

const isPortalUser = (item: PortalUser | PortalGroup): item is PortalUser => {
  return (item as PortalUser).Username !== undefined;
};

const handleClick = (
  event: React.MouseEvent<HTMLElement>,
  item: PortalUser | PortalGroup,
  resetAddstate: () => void,
  setCurrentUser: React.Dispatch<React.SetStateAction<string>>,
  setCurrentGroup: React.Dispatch<React.SetStateAction<string>>,
  setEditPortalUser: React.Dispatch<React.SetStateAction<boolean>>,
  setEditPortalGroup: React.Dispatch<React.SetStateAction<boolean>>,
  setGroupType: React.Dispatch<React.SetStateAction<string>>,
) => {
  const confirm = window.confirm(
    'Are you sure you want to leave the current operations?, if so your changes will be lost',
  );
  if (confirm) {
    resetAddstate();
    if (isPortalUser(item)) {
      setEditPortalGroup(false);
      setCurrentGroup('');
      setCurrentUser(item.Username);
      setEditPortalUser(true);
    } else {
      setEditPortalUser(false);
      setCurrentUser('');
      setCurrentGroup(item.GroupName);
      setGroupType(item.GroupType);
      setEditPortalGroup(true);
    }
  }
};

const addUserClickHandler = (
  event: React.MouseEvent<HTMLElement>,
  setAddPortalUser: React.Dispatch<React.SetStateAction<boolean>>,
  setEditPortalUser: React.Dispatch<React.SetStateAction<boolean>>,
  setAddPortalGroup: React.Dispatch<React.SetStateAction<boolean>>,
  setEditPortalGroup: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setAddPortalUser(true);
  setEditPortalUser(false);
  setAddPortalGroup(false);
  setEditPortalGroup(false);
};
const addGroupClickHandler = (
  event: React.MouseEvent<HTMLElement>,
  setAddPortalUser: React.Dispatch<React.SetStateAction<boolean>>,
  setEditPortalUser: React.Dispatch<React.SetStateAction<boolean>>,
  setAddPortalGroup: React.Dispatch<React.SetStateAction<boolean>>,
  setEditPortalGroup: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setAddPortalUser(false);
  setEditPortalUser(false);
  setAddPortalGroup(true);
  setEditPortalGroup(false);
};

const ListComponent: React.FC<ListComponentProps> = ({
  items,
  title,
  icon,
  setCurrentUser,
  setCurrentGroup,
  addIcon,
  addClickHandler,
  setAddPortalUser,
  setEditPortalUser,
  setAddPortalGroup,
  setEditPortalGroup,
  resetAddstate,
  setGroupType,
}) => (
  <Paper sx={{ margin: 2, padding: 2 }}>
    <Paper>
      <Typography variant="h5" padding={2}>
        {title}
        <IconButton
          sx={{ float: 'right' }}
          onClick={(e) =>
            addClickHandler(
              e,
              setAddPortalUser,
              setEditPortalUser,
              setAddPortalGroup,
              setEditPortalGroup,
            )
          }
        >
          {addIcon}
        </IconButton>
      </Typography>
    </Paper>

    <Box
      sx={{
        overflow: 'auto',
        paddingBottom: 4,
        maxHeight: 600,
        '&::-webkit-scrollbar': { display: 'none' },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
      }}
    >
      <List dense={false}>
        {items.map((item) => (
          <ListItem key={isPortalUser(item) ? item.Username : item.GroupName}>
            <ListItemButton
              onClick={(e) =>
                handleClick(
                  e,
                  item,
                  resetAddstate,
                  setCurrentUser,
                  setCurrentGroup,
                  setEditPortalUser,
                  setEditPortalGroup,
                  setGroupType,
                )
              }
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primary={isPortalUser(item) ? item.Username : item.GroupName}
                secondary={'Secondary text'}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  </Paper>
);

const AccessControlHome: React.FC = () => {
  const [portalUsers, setPortalUsers] = useState<PortalUser[]>([]);
  const [portalGroups, setPortalGroups] = useState<PortalGroup[]>([]);
  const [currentUser, setCurrentUser] = useState('');
  const [addPortalUser, setAddPortalUser] = useState(true);
  const [EditPortalUser, setEditPortalUser] = useState(false);
  const [addPortalGroup, setAddPortalGroup] = useState(false);
  const [EditPortalGroup, setEditPortalGroup] = useState(false);
  const [currentGroup, setCurrentGroup] = useState('');
  const [NewUserName, setNewUserName] = useState('');
  const [NewGroupName, setNewGroupName] = useState('');
  const [selectedGroups, setselectedUserGroups] = useState<string[]>([]);
  const [selectedPermissions, setselectedGroupPermissions] = useState<
    PortalPermission[]
  >([]);
  const [groupType, setGroupType] = useState('Internal');
  const [loading, setloading] = useState(false);
  const classes = useToolbarStyles();

  useEffect(() => {
    loadPortalUsers().catch((err) => console.log(err));
    loadGroups().catch((err) => console.log(err));
  }, []);

  const loadPortalUsers = async () => {
    const portaluses = await accessControlService.getPortalUsers();
    setPortalUsers(portaluses.Items);
  };

  const loadGroups = async () => {
    const portalgroups = await accessControlService.getPortalGroups();
    setPortalGroups(portalgroups.Items);
  };

  const resetAddstate = () => {
    setAddPortalUser(false);
    setAddPortalGroup(false);
  };

  const reInitialize = () => {
    setPortalUsers([]);
    setPortalGroups([]);
    loadPortalUsers().catch((err) => console.log(err));
    loadGroups().catch((err) => console.log(err));
    setCurrentUser('');
    setNewUserName('');
    setAddPortalUser(true);
    setAddPortalGroup(false);
    setEditPortalUser(false);
    setEditPortalGroup(false);
    setEditPortalGroup(false);
    setloading(false);
  };

  const handleSaveUser = async () => {
    try {
      setloading(true);
      if (addPortalUser) {
        if (NewUserName === '') throw 'User Name cannot be empty';

        await accessControlService.SaveUser(NewUserName, selectedGroups);
        toast.success(NewUserName + ' Saved successfully');
      } else if (currentUser) {
        await accessControlService.SaveUser(currentUser, selectedGroups);
        toast.success(currentUser + ' Saved successfully');
      }
      reInitialize();
    } catch (err) {
      toast.error(err as ToastContent);
      setloading(false);
    }
  };

  const handleSaveGroup = async () => {
    try {
      setloading(true);
      if (addPortalGroup) {
        if (NewGroupName === '') throw 'Group Name cannot be empty';
        const group: PortalGroup = {
          GroupName: NewGroupName,
          GroupType: groupType,
        };
        const portalgrouppermissions: PortalGroupPermission[] =
          selectedPermissions.map((permission) => ({
            PermissionName: permission.PermissionName,
            GroupName: NewGroupName,
          }));

        await accessControlService.SaveGroup(NewGroupName, group);
        await accessControlService.setGroupPermissions(
          NewGroupName,
          portalgrouppermissions,
        );
        toast.success(NewGroupName + ' Saved successfully');
      } else if (currentGroup) {
        const group: PortalGroup = {
          GroupName: currentGroup,
          GroupType: groupType,
        };
        const portalgrouppermissions: PortalGroupPermission[] =
          selectedPermissions.map((permission) => ({
            PermissionName: permission.PermissionName,
            GroupName: currentGroup,
          }));

        await accessControlService.SaveGroup(currentGroup, group);
        await accessControlService.setGroupPermissions(
          currentGroup,
          portalgrouppermissions,
        );
        toast.success(currentGroup + ' Saved successfully');
      }
      reInitialize();
    } catch (err) {
      toast.error(err as ToastContent);
      setloading(false);
    }
  };

  const handleDeleteUser = async () => {
    const confirm = window.confirm(
      'Are you sure you want to delete user : ' + currentUser,
    );
    if (confirm) {
      try {
        setloading(true);
        await accessControlService.DeleteUser(currentUser);
        toast.success(currentUser + ' deleted successfully');
      } catch (err) {
        toast.error(err as ToastContent);
      } finally {
        setloading(false);
        reInitialize();
      }
    }
  };

  const handleDeleteGroup = async () => {
    const confirm = window.confirm(
      'Are you sure you want to delete Group : ' + currentGroup,
    );
    if (confirm) {
      try {
        const group: PortalGroup = {
          GroupName: currentGroup,
          GroupType: groupType,
        };
        setloading(true);
        await accessControlService.DeleteGroup(currentGroup, group);
        toast.success(currentGroup + ' deleted successfully');
      } catch (err) {
        toast.error(err as ToastContent);
      } finally {
        setloading(false);
        reInitialize();
      }
    }
  };

  return (
    <>
      {' '}
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box display={'flex'} width={'100%'}>
          <ListComponent
            items={portalUsers}
            title="Portal Users"
            icon={<PersonIcon color="secondary" />}
            setCurrentUser={setCurrentUser}
            setCurrentGroup={setCurrentGroup}
            addIcon={<PersonAddAlt1Icon color="primary" />}
            addClickHandler={addUserClickHandler}
            setAddPortalUser={setAddPortalUser}
            setEditPortalUser={setEditPortalUser}
            setAddPortalGroup={setAddPortalGroup}
            setEditPortalGroup={setEditPortalGroup}
            resetAddstate={resetAddstate}
            setGroupType={setGroupType}
          />
          <ListComponent
            items={portalGroups}
            title="Groups"
            icon={<GroupIcon color="secondary" />}
            addIcon={<GroupAddIcon color="primary" />}
            setCurrentUser={setCurrentUser}
            setCurrentGroup={setCurrentGroup}
            addClickHandler={addGroupClickHandler}
            setAddPortalUser={setAddPortalUser}
            setEditPortalUser={setEditPortalUser}
            setAddPortalGroup={setAddPortalGroup}
            setEditPortalGroup={setEditPortalGroup}
            resetAddstate={resetAddstate}
            setGroupType={setGroupType}
          />
          <Paper sx={{ margin: 2, padding: 2 }}>
            {addPortalUser &&
              CreatePortalUser(
                NewUserName,
                setNewUserName,
                setselectedUserGroups,
              )}
            {EditPortalUser &&
              currentUser !== '' &&
              UpdatePortalUser(
                currentUser,
                handleDeleteUser,
                setselectedUserGroups,
              )}
            {addPortalGroup &&
              CreatePortalGroup(
                NewGroupName,
                setNewGroupName,
                setselectedGroupPermissions,
                setGroupType,
                groupType,
              )}
            {EditPortalGroup &&
              currentGroup !== '' &&
              UpdatePortalGroup(
                currentGroup,
                handleDeleteGroup,
                setselectedGroupPermissions,
                setGroupType,
                groupType,
              )}

            <Button
              variant="contained"
              startIcon={<Save />}
              onClick={
                addPortalUser || EditPortalUser
                  ? handleSaveUser
                  : handleSaveGroup
              }
              sx={{ float: 'right' }}
            >
              {addPortalUser || EditPortalUser ? 'Save User' : 'Save Group'}
            </Button>
          </Paper>
        </Box>
      )}
    </>
  );
};

export default AccessControlHome;

function UpdatePortalUser(
  currentUser: string,
  handleDeleteUser: () => Promise<void>,
  setselectedUserGroups: React.Dispatch<React.SetStateAction<string[]>>,
) {
  return (
    <>
      <Paper
        sx={{
          display: '-webkit-inline-box',
          width: '-webkit-fill-available',
        }}
      >
        <Typography variant="h5">
          <Box display="flex" alignItems="center" padding={2}>
            <PersonIcon fontSize="large" />
            {currentUser}
          </Box>
        </Typography>
        <Box alignItems="flex-start" padding={2}>
          <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            sx={{ float: 'right' }}
            onClick={handleDeleteUser}
          >
            Delete
          </Button>
        </Box>
      </Paper>

      <UserGroupDragable
        username={currentUser}
        setselectedUserGroups={setselectedUserGroups}
      />
    </>
  );
}

function CreatePortalUser(
  NewUserName: string,
  setNewUserName: React.Dispatch<React.SetStateAction<string>>,
  setselectedUserGroups: React.Dispatch<React.SetStateAction<string[]>>,
) {
  return (
    <>
      <Box
        display={'flex'}
        sx={{ alignContent: 'space-between', padding: 2 }}
        alignItems={'center'}
      >
        <Typography marginRight={1}>
          <PersonIcon fontSize="large" />
        </Typography>
        <TextField
          required
          placeholder="User Name"
          name="Username"
          value={NewUserName}
          onChange={(e) => setNewUserName(e.target.value)}
        />
      </Box>
      <UserGroupDragable
        username=""
        setselectedUserGroups={setselectedUserGroups}
      />
    </>
  );
}

function UpdatePortalGroup(
  currentGroup: string,
  handleDeleteGroup: () => Promise<void>,
  setselectedGroupPermissions: React.Dispatch<
    React.SetStateAction<PortalPermission[]>
  >,
  setGroupType: React.Dispatch<React.SetStateAction<string>>,
  groupType: string,
) {
  return (
    <>
      <Paper
        sx={{
          display: '-webkit-inline-box',
          width: '-webkit-fill-available',
        }}
      >
        <Typography variant="h5">
          <Box display="flex" alignItems="center" padding={2}>
            <GroupIcon fontSize="large" />
            {currentGroup}
          </Box>
        </Typography>
        <Box alignItems="flex-start" padding={2}>
          <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            onClick={handleDeleteGroup}
          >
            Delete
          </Button>
          <Select
            sx={{ float: 'right', marginLeft: 30, paddingRight: 2 }}
            labelId="GroupType"
            name="GroupType"
            value={groupType}
            variant="standard"
            label="GroupType"
            onChange={(e) => setGroupType(e.target.value)}
          >
            {['Internal', 'Partner'].map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Paper>
      <GroupPermissionsDragable
        groupname={currentGroup}
        setselectedGroupPermissions={setselectedGroupPermissions}
      />
    </>
  );
}

function CreatePortalGroup(
  NewGroupName: string,
  setNewGroupName: React.Dispatch<React.SetStateAction<string>>,
  setselectedGroupPermissions: React.Dispatch<
    React.SetStateAction<PortalPermission[]>
  >,
  setGroupType: React.Dispatch<React.SetStateAction<string>>,
  groupType: string,
) {
  return (
    <>
      <Box
        display={'flex'}
        sx={{ alignContent: 'space-between', padding: 2 }}
        alignItems={'center'}
      >
        <Typography marginRight={1}>
          <GroupIcon fontSize="large" />
        </Typography>
        <TextField
          required
          placeholder="Group Name"
          name="GroupName"
          value={NewGroupName}
          onChange={(e) => setNewGroupName(e.target.value)}
        />
        <Select
          sx={{ float: 'right', marginLeft: 30 }}
          labelId="GroupType"
          name="GroupType"
          value={groupType}
          variant="standard"
          label="GroupType"
          onChange={(e) => setGroupType(e.target.value)}
        >
          {['Internal', 'Partner'].map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <GroupPermissionsDragable
        groupname=""
        setselectedGroupPermissions={setselectedGroupPermissions}
      />
    </>
  );
}
