import React, { useState, useEffect, useContext } from 'react';

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { PermissionsContext } from '../../UIContainer/PermissionsWrapper';
import { Dictionary } from '../../../Common/CommonModel';
import { StorageAccountMenuProps } from '../Model/StorageAccountProps';
import { StorageAccountMenuItems } from '../Model/StorageAccountModel';

const useStyles = makeStyles(() =>
  createStyles({
    menu: {
      padding: 0,
    },
  }),
);

const menuItems: StorageAccountMenuItems[] = [
  {
    id: 'edit',
    label: 'Edit',
    linkTo: '/storageaccount/{0}/name/{1}',
    validationFunc: 'edit',
  },
  {
    id: 'delete',
    label: 'Delete',
    validationFunc: 'delete',
  },
];

export default function StorageAccountMenu(props: StorageAccountMenuProps) {
  const { Name, DeleteFunction } = props;
  const classes = useStyles();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [menuState, setMenuState] = useState(false);
  const permissions = useContext(PermissionsContext);
  useEffect(() => {}, []);

  const openStorageAccountMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setMenuState(true);
    setAnchor(event.currentTarget);
  };
  const closeStorageAccountMenu = () => {
    setMenuState(false);
    setAnchor(null);
  };

  const hasStorageAccountWriteAccess = () =>
    permissions['StorageAccountWriter'] ? true : false;

  function executeValidationMethod(type: string) {
    let fn;
    const validations: Dictionary<() => boolean> = {};
    validations['delete'] = () => hasStorageAccountWriteAccess();
    validations['edit'] = () => hasStorageAccountWriteAccess();
    validations['default'] = () => false;

    if (validations[type]) {
      fn = validations[type];
    } else {
      fn = validations['default'];
    }
    return fn();
  }

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns="long-menu"
        aria-haspopup="true"
        onClick={openStorageAccountMenu}
        className={classes.menu}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchor}
        open={menuState}
        onClose={closeStorageAccountMenu}
      >
        {menuItems
          .filter((menu) => executeValidationMethod(menu.validationFunc))
          .map((menu) => {
            if (menu.linkTo) {
              return (
                <MenuItem
                  key={menu.id}
                  component={Link}
                  to={menu.linkTo.format(menu.id, Name)}
                >
                  {menu.label}
                </MenuItem>
              );
            } else {
              return (
                <MenuItem key={menu.id} onClick={() => DeleteFunction(Name)}>
                  {menu.label}
                </MenuItem>
              );
            }
          })}
      </Menu>
    </div>
  );
}
