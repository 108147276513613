import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { TenantMetricsData } from './Model/MetricsModel';

class MetricsService {
  public getLatestTenantMetrics(
    tenantId: string,
  ): Promise<TenantMetricsData[]> {
    const tenantMetrics = callService('metrics', tenantId, RequestType.GET);
    return tenantMetrics;
  }

  public getTenantUserMetrics(
    farmId: number,
    tenantId: string,
  ): Promise<TenantMetricsData[]> {
    const tenantMetrics = callService(
      'metrics',
      `${farmId}/${tenantId}/usermetrics`,
      RequestType.GET,
    );
    return tenantMetrics;
  }
}

export const metricsService: MetricsService = new MetricsService();
