import { Component } from 'react';

export default class TriggerConstants extends Component {
  static readonly TriggerSavedMessage = 'Trigger saved successfully.';
  static readonly TriggerDeployedMessage =
    'Trigger deployment job has been queued, check logs for more information.';
  static readonly TriggerDroppedMessage =
    'Job to drop trigger has been queued, check logs for more information.';
  static readonly ErrorDeployNewTriggerMessage =
    'Trigger is not saved or there is nothing to deploy.';
  static readonly ErrorDropNewTriggerMessage =
    'Trigger is not saved or there is nothing to drop.';
  static readonly TriggerDropConfirmationMessage =
    'Trigger {0} will be dropped from the database, do you want to continue?';
  static readonly TriggerResetConfirmationMessage =
    'Are you sure you want to reset changes?';
  static readonly TriggersUploadedMessage =
    'Triggers uploaded successfully and are being processed.';
  static readonly UploadedTriggerSavedMessage =
    'Trigger {0} saved successfully.';
  static readonly TriggerContentChangesMessage =
    'Trigger editor has unsaved changes, are you sure you want to continue?';

  static readonly TriggerDropzoneText = 'drag and drop sql files here or click';
  static readonly GenericUploadErrorMessage =
    'Failed to upload trigger with status code {0}';

  static readonly SaveButtonTooltip = 'save trigger';
  static readonly ResetButtonTooltip = 'reset changes';
  static readonly UploadButtonTooltip = 'upload files';
  static readonly DeployButtonTooltip = 'deploy trigger';
}
