import {
  Backdrop,
  CircularProgress,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Theme,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ClientsSecreteTableHead from './ClientSecreteTableHead';
import { Order } from '../../../../Helpers/Literals';
import { ClientSecretMetaData } from '../Model/ClientSecretesModel';
import {
  getLocalDate,
  getSorting,
  stableSort,
} from '../../../../Helpers/UtilityMethods';
import ClientsSecreteHeaderToolbar from './ClientsSecreteHeaderToolbar';
import { customerService } from '../../Customer/CustomerService';
import { CustomerV2 } from '../../Customer/Model/CustomerModel';
import { clientsecretService } from '../ClientSecretService';
import { toast } from 'react-toastify';
import ClientsSecreteMenu from '../Components/ClientSecretMenu';
import ClientSecretContants from '../Model/ClientSecretConstants';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
      width: '100%',
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    subDomainWidth: {
      width: 421,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);
interface Param {
  customerId: string;
}
export default function ClientSecreteHome() {
  const initialState: CustomerV2 = {};
  const [customerData, getCustomer] = useState(initialState);
  const [update, setUpdate] = useState<boolean>();
  const [loading, setLoading] = useState(true);
  const [updateParent, setUpdateParent] = useState(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const params = useParams<Param>();
  const classes = useStyles();
  const [orderBy, setOrderBy] =
    React.useState<keyof ClientSecretMetaData>('ClientId');
  const initialStateTC: ClientSecretMetaData[] = [];
  const [ClientsSecretes, setFields] = useState(initialStateTC);
  const [ClientsSecretescopy, setFieldsCopy] = useState(initialStateTC);
  const rowsPerPage = 10;
  const [page, setPage] = React.useState(0);
  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, ClientsSecretes.length - page * rowsPerPage);

  useEffect(() => {
    loadInitData();
  }, [updateParent, update]);

  const loadInitData = async () => {
    await loadCustomerData();
    await loadClientSecretMetaData();
  };
  const loadCustomerData = async () => {
    try {
      const customerData = await customerService.getCustomer(params.customerId);

      getCustomer(customerData);
    } catch (error) {
      console.log(error);
    }
  };

  const updateClientActiveness = async (
    clientId: string,
    isActive: boolean,
  ) => {
    setLoading(true);
    try {
      await clientsecretService.UpdateClientActiveness(
        params.customerId,
        clientId,
        isActive,
      );
      setUpdate(!update);
      const activated = isActive ? 'Activated' : 'Deactivated';
      toast.success(`Api Key ${clientId} ${activated}`);
    } catch (error) {
      console.log(error);
    }
  };

  const loadClientSecretMetaData = async () => {
    try {
      const clientSecretmetadata =
        await clientsecretService.GetClientSecretMetadata(params.customerId);

      setFields(clientSecretmetadata.Items);
      setFieldsCopy(clientSecretmetadata.Items);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  function oncloseUpdateParent() {
    setUpdateParent(!updateParent);
  }

  const handleFilter = (searchFilterText: string) => {
    const filteredTC = ClientsSecretescopy.filter(function (TC) {
      return (
        TC.ClientId.toLowerCase().indexOf(searchFilterText.toLowerCase()) >= 0
      );
    });
    setFields(filteredTC);
  };

  function handleSearchFilter(event: React.ChangeEvent<HTMLInputElement>) {
    handleFilter(event.target.value);
  }

  const handleSwitch = async (
    event: React.ChangeEvent<HTMLInputElement>,
    row: ClientSecretMetaData,
  ) => {
    await updateClientActiveness(row.ClientId, event.target.checked);
  };

  function handleChangePage(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) {
    setPage(newPage);
  }

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof ClientSecretMetaData,
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <ClientsSecreteHeaderToolbar
          onFilterRequest={handleSearchFilter}
          customer={customerData || ''}
          oncloseUpdateParent={oncloseUpdateParent}
        />
        {loading ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <ClientsSecreteTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(ClientsSecretes, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((TC) => (
                    <TableRow key={TC.ClientId}>
                      <TableCell align="center">
                        {TC.ClientId.toUpperCase()}
                      </TableCell>
                      <TableCell align="center">{TC.Description}</TableCell>
                      <TableCell align="center">
                        {getLocalDate(TC.CreatedDate)}
                      </TableCell>
                      <TableCell align="center">
                        <Tooltip
                          title={
                            TC.IsActive
                              ? ClientSecretContants.DeactivateClientSecretToolTip
                              : ClientSecretContants.ActivateClientSecretToolTip
                          }
                        >
                          <Switch
                            checked={TC.IsActive}
                            onChange={(evt) => {
                              handleSwitch(evt, TC);
                            }}
                            name="IsActive"
                            color="primary"
                            inputProps={{ 'aria-label': 'checkbox' }}
                          />
                        </Tooltip>
                        {TC.IsActive ? 'Active' : 'Not Active'}
                      </TableCell>
                      <TableCell>
                        <ClientsSecreteMenu
                          clientId={TC.ClientId}
                          customerId={params.customerId}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    colSpan={7}
                    count={ClientsSecretes.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { 'aria-label': 'Rows per page' },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        )}
      </Paper>
    </div>
  );
}
