import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from 'react';
import { useToolbarStyles } from '../Home/CustomerHeaderToolbar';
import Cancel from '@mui/icons-material/Cancel';
import MuiTableCell from '@mui/material/TableCell';
import { useHistory, useParams } from 'react-router-dom';
import { customerService } from '../CustomerService';
import { toast } from 'react-toastify';
import { CustomerV2 } from '../Model/CustomerModel';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);

interface Param {
  customerId: string;
}

export default function RemoveCustomer() {
  const params = useParams<Param>();
  const initialState: CustomerV2 = {};
  const [customerData, getCustomer] = useState(initialState);
  const classes = useToolbarStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const loadCustomerData = async () => {
    try {
      const customerData = await customerService.getCustomer(params.customerId);
      getCustomer(customerData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    customerService.DeleteCustomer(params.customerId);
    toast.success('runbook queued for removing customer...!');
    setLoading(false);
    history.push('/customer');
  };

  const handleClose = () => {
    history.push('/customer');
  };

  useEffect(() => {
    loadCustomerData();
  }, []);
  return (
    <div>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Paper>
          <Toolbar className={classes.root}>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Delete Customer
              </Typography>
            </div>
          </Toolbar>
          <Card elevation={2} style={{ padding: 15, margin: 15 }}>
            <Typography variant="h6">Customer Operation</Typography>
            <Table>
              <TableRow>
                <TableCell>Customer ID</TableCell>
                <TableCell>{customerData.Id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Customer</TableCell>
                <TableCell>{customerData.Subdomain}</TableCell>
              </TableRow>
            </Table>
          </Card>
          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleDelete}
              startIcon={<DeleteIcon />}
            >
              Delete{' '}
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.button}
              startIcon={<Cancel />}
            >
              Cancel{' '}
            </Button>
          </Paper>
        </Paper>
      )}
    </div>
  );
}
