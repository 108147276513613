import React, { useState, useEffect, useContext } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import {
  CustomizationEditorProps,
  EditorOption,
} from '../../../Layout/Trigger/Model/EditorModel';
import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PermissionsContext } from '../../UIContainer/PermissionsWrapper';
import PermissionConstants from '../../../Common/Constants/PermissionConstants';
import { PreferredThemeContext } from '../../../../Theme/UseToggleDarkMode';
import { CustomerDispatchContext } from '../Reducer/CustomizationState';
import { toast } from 'react-toastify';

require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');
require('codemirror/theme/xq-light.css');
require('codemirror/theme/neat.css');
require('codemirror/mode/sql/sql.js');
require('codemirror/mode/powershell/powershell.js');

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  codeMirror: {
    height: '40vh',
  },
}));

export default function CustomizationEditor(
  props: Readonly<CustomizationEditorProps>,
) {
  const { content, editorContentChangedCallback, actiontype, mode } = props;
  const classes = useStyles();

  const [editorValue, setEditorValue] = useState('');

  const permissions = useContext(PermissionsContext);
  const prefersDarkMode = useContext(PreferredThemeContext);

  useEffect(() => {
    setEditorValue(content);
  }, [content]);

  const onEditorChange = (value: string) => {
    editorContentChangedCallback();
    if (lengthLimit(value)) {
      return toast.info('maximum char reached please remove some characters');
    }
  };

  const options: EditorOption = {
    mode: mode,
    theme: prefersDarkMode ? 'material' : 'xq-light',
    lineNumbers: true,
    readOnly: permissions[PermissionConstants.CustomerWriter]
      ? false
      : 'nocursor',
  };

  const lengthLimit = (value: string) => {
    if (value.length > 1070000000) {
      return true;
    }
  };

  return (
    <Paper className={classes.root}>
      <CustomerDispatchContext.Consumer>
        {(dispatch) => (
          <CodeMirror
            className={classes.codeMirror}
            value={editorValue}
            options={options}
            editorDidMount={(editor) => {
              editor.setSize('', '40vh');
            }}
            onBeforeChange={(_editor, _data, value) => {
              dispatch({ type: actiontype, payload: value });
              setEditorValue(value);
            }}
            onChange={(_editor, _data, value) => {
              onEditorChange(value);
            }}
          />
        )}
      </CustomerDispatchContext.Consumer>
    </Paper>
  );
}
