import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles({
  tableContainer: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    width: '100%',
    margin: 0,
  },
  HealthHeaderIcon: {
    verticalAlign: 'middle',
    marginRight: 10,
  },
  TableBody: {
    padding: 5,
  },
  TableRow: {
    height: '45px',
  },
});
export const useSelfStyles = makeStyles({
  WrapperPaper: {
    display: 'flex',
    marginBottom: 5,
  },
});
