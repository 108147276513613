import React, { useEffect, useState } from 'react';
import {
  Card,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TextField,
  Divider,
  Paper,
  Button,
  Toolbar,
  CircularProgress,
  Backdrop,
  Select,
  MenuItem,
} from '@mui/material';
import { Save } from '@mui/icons-material';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import JoditTemplateEditor from '../../../Common/JodiEditor/JodiEditor';
import { EmailTemplate } from '../Model/EmailAdminModel';
import { useStyles } from '../../../../Common/Styles/Theme';
import { TableCell } from '../../DashBoard/Common/CommonMethods';
import { createEmailTemplate } from '../Common/Common';
import { useHistory } from 'react-router-dom';
import { commonService } from '../../../Common/CommonService';

const CreateTemplateOps = (props: { products: string[] }) => {
  const classes = useToolbarStyles();
  const history = useHistory();
  const [loading, setLoadingState] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>({
    Name: '',
    Content: '',
    Product: '',
    Subject: '',
  });
  return (
    <>
      <Card elevation={2} style={{ padding: 15, margin: 15 }}>
        <Typography variant="h6">Basic Information</Typography>
        {loading ? (
          <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : (
          <>
            <Card sx={{ width: '50%', padding: 2, margin: 2 }}>
              <TableContainer>
                <Table>
                  <TableRow>
                    <TableCell>Template Name :</TableCell>
                    <TextField
                      className={classes.textFieldMedium}
                      value={emailTemplate?.Name}
                      onChange={(e) =>
                        setEmailTemplate({
                          ...emailTemplate,
                          Name: e.target.value,
                        })
                      }
                    />
                  </TableRow>

                  <TableRow>
                    <TableCell>E-mail Subject :</TableCell>
                    <TextField
                      className={classes.textFieldMedium}
                      value={emailTemplate?.Subject}
                      onChange={(e) =>
                        setEmailTemplate({
                          ...emailTemplate,
                          Subject: e.target.value,
                        })
                      }
                    />
                  </TableRow>
                  <TableRow>
                    <TableCell>Product :</TableCell>
                    <Select
                      className={classes.textFieldMedium}
                      value={emailTemplate?.Product}
                      variant="outlined"
                      onChange={(e) =>
                        setEmailTemplate({
                          ...emailTemplate,
                          Product: e.target.value,
                        })
                      }
                    >
                      {props.products.map((product) => (
                        <MenuItem key={product} value={product}>
                          {product}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableRow>
                </Table>
              </TableContainer>
            </Card>
            <Card sx={{ margin: 2 }}>
              <JoditTemplateEditor
                emailtemplate={emailTemplate}
                setEmailTemplate={
                  setEmailTemplate as React.Dispatch<
                    React.SetStateAction<EmailTemplate | undefined>
                  >
                }
              />
            </Card>
          </>
        )}
      </Card>
      <Divider className={classes.divider} />
      <Paper style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<Save />}
          onClick={() =>
            createEmailTemplate(
              emailTemplate,
              setLoadingState,
              history,
              setEmailTemplate,
            )
          }
        >
          Save
        </Button>
      </Paper>
    </>
  );
};

const CreateTemplate = () => {
  const [products, setProducts] = useState<string[]>([]);

  useEffect(() => {
    loadProducts();
  }, []);
  const loadProducts = async () => {
    const products = await commonService.getProducts();
    setProducts(products.Items.map((x) => x.Id));
  };
  const classes = useStyles();
  return (
    <div>
      <Paper>
        <Toolbar className={classes.Root}>
          <div className={classes.Title}>
            <Typography variant="h6" id="tableTitle">
              Create Tamplate
            </Typography>
          </div>
        </Toolbar>
        <CreateTemplateOps products={products} />
      </Paper>
    </div>
  );
};

export default CreateTemplate;
