import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { PagedData } from '../../Common/CommonModel';
import { Farm, Tenant } from './Model/FarmModel';

class FarmService {
  public getFarms(): Promise<PagedData<Farm>> {
    const farms = callService('farms', '', RequestType.GET);
    return farms;
  }
  public getFarm(farmId: string): Promise<Farm> {
    const farm = callService('farms', farmId, RequestType.GET);
    return farm;
  }

  public getTenantsOnFarm(farmId: number): Promise<PagedData<Tenant>> {
    const tenants = callService('farms', farmId + '/tenants', RequestType.GET);
    return tenants;
  }

  public getTenant(farmId: number, tenantId: string): Promise<Tenant> {
    const tenant = callService(
      'farms',
      `${farmId}/tenants/${tenantId}`,
      RequestType.GET,
    );
    return tenant;
  }

  public getDeployedTenantsOnFarm(farmId: number): Promise<Tenant[]> {
    const tenants = callService(
      'farms',
      farmId + '/deployedtenants',
      RequestType.GET,
    );
    return tenants;
  }
  public getMoniteredTenantsOnFarm(farmId: number): Promise<Tenant[]> {
    const tenants = callService(
      'farms',
      farmId + '/moniteredtenants',
      RequestType.GET,
    );
    return tenants;
  }

  public getFarmsByProduct(product: string): Promise<PagedData<Farm>> {
    return callService('farms', `product/${product}`, RequestType.GET);
  }
}

export const farmService: FarmService = new FarmService();
