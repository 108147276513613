import { Paper } from '@mui/material';
import { useStyles } from '../../../Common/Styles/Theme';
import React, { useState } from 'react';
import { Order } from '../../../Helpers/Literals';
import { Audit, PortalUser } from './Model/AuditModel';
import AuditHeaderToolbar from './Components/AuditHeaderToolbar';
import { auditService } from './AuditService';
import ShowKeyVaultData from './Components/RenderAuditData';

export default function AuditHome() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Audit>('DateAdded');
  const [page, setPage] = React.useState(1);
  const initialState: Audit[] = [];
  const [AuditList, setAuditLogs] = React.useState(initialState);
  const [loading, setLoadingState] = useState(false);
  const pageSize = 40;
  const [excludeAutomationEnties, setExcludeAutomationEnties] = useState(false);
  const [totalAuditsCount, setTotalAuditsCount] = useState(1);
  const initialselectedUsers: PortalUser[] = [];
  const [selectedUsers, setSelectedUsers] =
    React.useState(initialselectedUsers);
  const [Entity, setEntity] = React.useState('');
  React.useEffect(() => {
    loadAuditLogs();
  }, [excludeAutomationEnties, page]);

  const loadAuditLogs = async () => {
    let queryString = '';
    queryString = getQuerryString();
    try {
      setLoadingState(true);
      const auditlogsReceived = await auditService.GetAuditLogs(queryString);
      setTotalAuditsCount(auditlogsReceived.TotalCount);
      setAuditLogs(auditlogsReceived.Items);
      setLoadingState(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingState(false);
    }
  };

  const appendQuery = (input: string, query: string) => {
    if (input == null || input.length === 0) input += '?';
    else input += '&';

    return input + query;
  };

  const getQuerryString = () => {
    let query = '';
    if (page) {
      query = appendQuery(query, 'page=' + page);
    }
    if (pageSize) {
      query = appendQuery(query, 'pageSize=' + pageSize);
    }
    if (Entity) {
      query = appendQuery(query, 'entity=' + Entity);
    }
    if (selectedUsers.length > 0) {
      query = appendQuery(
        query,
        'performed=' + selectedUsers.map((p) => p.Username).join(';'),
      );
    }
    if (excludeAutomationEnties) {
      query = appendQuery(query, 'excludePerformedBy=automation');
    }
    return query;
  };

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof Audit,
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement>,
    value: number,
  ) => {
    setPage(value);
  };

  return (
    <div className={classes.Root}>
      <Paper className={classes.Paper}>
        <AuditHeaderToolbar
          loadAuditLogs={loadAuditLogs}
          excludeAutomationEnties={excludeAutomationEnties}
          setExcludeAutomationEnties={setExcludeAutomationEnties}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
          Entity={Entity}
          setEntity={setEntity}
        />
        <ShowKeyVaultData
          AuditList={AuditList}
          order={order}
          orderBy={orderBy}
          loading={loading}
          totalAuditsCount={totalAuditsCount}
          pageSize={pageSize}
          page={page}
          handleRequestSort={handleRequestSort}
          handlePageChange={handlePageChange}
        />
      </Paper>
    </div>
  );
}
