import React, { useEffect } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Theme,
  Typography,
  CircularProgress,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { farmService } from '../../Farm/FarmService';

import { Farm, Tenant, FarmState } from '../../Farm/Model/FarmModel';
import TenantContext from './TenantContext';
import { MetricEntity } from '../Model/MetricsModel';
import { getTenantSubDomain } from '../Helpers/MetricsHelpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
    progress: {
      marginLeft: '50%',
      marginTop: '15%',
      color: theme.palette.primary.light.toString(),
    },
    progressSmall: {
      marginLeft: '50%',
      color: theme.palette.primary.light.toString(),
    },
  }),
);

export default function TenantBlade() {
  const classes = useStyles();

  const [farms, setFarms] = React.useState<Farm[]>([]);
  const [tenants, setTenantsOnFarm] = React.useState<Tenant[]>([]);

  const [selectedFarm, setCurrentFarm] = React.useState(-1);
  const [selectedTenant, setCurrentTenant] = React.useState('');

  const [farmsLoading, setFarmsLoading] = React.useState(false);
  const [tenantsLoading, setTenantsLoading] = React.useState(false);

  useEffect(() => {
    loadFarms();
  }, []);

  const loadFarms = async () => {
    setFarmsLoading(true);
    const farms = await farmService.getFarms();
    const proarcOnlineFarms = farms.Items.filter(
      (farm) =>
        farm.Type === 'ProArc Online' && farm.State === FarmState.Production,
    );
    setFarms(proarcOnlineFarms);
    setFarmsLoading(false);
  };

  const loadTenants = async (farmId: number) => {
    setTenantsLoading(true);
    const deployedTenants = await farmService.getDeployedTenantsOnFarm(farmId);
    setTenantsOnFarm(deployedTenants);
    setTenantsLoading(false);
  };

  const handleFarmClick = (
    farmId: number,
    setSelectedFarm: (farmId: number) => void,
    setSelectedEntity: (entity: MetricEntity) => void,
  ) => {
    setCurrentFarm(farmId);
    setSelectedFarm(farmId);
    if (farmId !== selectedFarm) {
      loadTenants(farmId);
    }
    setCurrentTenant('');
    setSelectedEntity(MetricEntity.Farm);
  };

  const handleTenantClick = (
    tenantId: string,
    setSelectedTenant: (tenantId: string) => void,
    setSelectedEntity: (entity: MetricEntity) => void,
  ) => {
    setCurrentTenant(tenantId);
    setSelectedTenant(tenantId);
    setSelectedEntity(MetricEntity.Tenant);
  };

  return (
    <div>
      {farmsLoading ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <TenantContext.Consumer>
          {({ setSelectedTenant, setSelectedFarm, setSelectedEntity }) => (
            <List>
              {farms.map((farm) => (
                <div key={farm.Id + 'div'}>
                  <ListItem
                    button
                    onClick={() =>
                      handleFarmClick(
                        farm.Id,
                        setSelectedFarm,
                        setSelectedEntity,
                      )
                    }
                    selected={selectedFarm === farm.Id}
                  >
                    <ListItemText>
                      <Typography variant="button" gutterBottom>
                        {farm.DisplayName}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <Collapse
                    in={farm.Id === selectedFarm}
                    timeout="auto"
                    unmountOnExit
                  >
                    {tenantsLoading ? (
                      <CircularProgress
                        size={20}
                        className={classes.progressSmall}
                      />
                    ) : (
                      <div>
                        {tenants.map((tenant) => (
                          <List component="div" disablePadding key={tenant.Id}>
                            <ListItem
                              button
                              className={classes.nested}
                              selected={selectedTenant === tenant.Id}
                              onClick={() =>
                                handleTenantClick(
                                  tenant.Id,
                                  setSelectedTenant,
                                  setSelectedEntity,
                                )
                              }
                            >
                              <ListItemText>
                                <Typography variant="body2" gutterBottom>
                                  {getTenantSubDomain(tenant.ApplicationUrl)}
                                </Typography>
                              </ListItemText>
                            </ListItem>
                          </List>
                        ))}
                      </div>
                    )}
                  </Collapse>
                </div>
              ))}
            </List>
          )}
        </TenantContext.Consumer>
      )}
    </div>
  );
}
