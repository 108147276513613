import React, { useState } from 'react';
import TextField from '@mui/material/TextField';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function UriApp(props: any) {
  const { LUri } = props;
  const [fields, setFields] = useState<string[]>(props.LUri);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChange(i: any, event: any) {
    const values = [...fields];
    values[i] = event.target.value;
    setFields(values);
    props.onUpdate(values);
  }

  function handleAdd() {
    const values = [...fields];
    values.push('');
    setFields(values);
    props.onUpdate(values);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleRemove(i: any) {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
    props.onUpdate(values);
  }

  if (LUri !== fields) {
    setFields(LUri);
  }
  return (
    <div className="App">
      {fields.map((field: string, idx: number) => {
        return (
          <div key={`${idx}`}>
            <TextField
              placeholder="Redirect Uri"
              error={props.RedirectUrisE}
              required
              name="RedirectUri"
              value={field || ''}
              onChange={(e) => handleChange(idx, e)}
            />
            <button type="button" onClick={() => handleRemove(idx)}>
              X
            </button>
          </div>
        );
      })}
      <button type="button" onClick={() => handleAdd()}>
        +
      </button>
    </div>
  );
}
