import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  LicenseLogHeadRow,
  LicenseLog,
  EnhancedTableProps,
} from '../../Model/Log';
import {
  OperationHeaderLabel,
  StatusHeaderLabel,
  DateAddedHeaderLabel,
  MessageHeaderLabel,
  PerformedByHeaderLabel,
} from '../../../Dashboard/Model/LicenseConstants';

const LicenseLogheadRows: LicenseLogHeadRow[] = [
  { id: 'PerformedBy', label: PerformedByHeaderLabel, enableSorting: false },
  { id: 'Operation', label: OperationHeaderLabel, enableSorting: false },
  { id: 'Status', label: StatusHeaderLabel, enableSorting: false },
  { id: 'CreatedDate', label: DateAddedHeaderLabel, enableSorting: false },
  { id: 'Message', label: MessageHeaderLabel, enableSorting: false },
];

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      color: '#336699 !important',
    },
  }),
);

export default function LicenseLogTableHeader(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const classes = useStyles();

  const createSortHandler =
    (property: keyof LicenseLog) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {LicenseLogheadRows.map((row, index) => (
          <TableCell
            className="theadcell"
            key={row.id}
            sortDirection={orderBy === row.id ? order : false}
            align={index === 0 ? 'left' : 'center'}
          >
            {row.enableSorting ? (
              <TableSortLabel
                className={classes.root}
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            ) : (
              <div>{row.label}</div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
