import { Cancel, Save } from '@mui/icons-material';
import {
  Paper,
  Typography,
  Divider,
  Button,
  Stepper,
  Step,
  StepLabel,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NewCustomer, InputClaimTypes, Param } from '../Model/CustomerModel';
import CloneTenantForm from './CloneTenantForm';
import { customerService } from '../CustomerService';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TenantRegistrationForm from '../../Tenant/Components/TenantRegistrationForm';
import { StorageAccount } from '../../StorageAccount/Model/StorageAccountModel';
import {
  CloneTenantMetaData,
  DeployDetailsForm,
  RegisterTenant,
  TenantForm,
} from '../../Tenant/Model/TenantModels';
import { plans } from '../../../Common/Constants/ValueConstants';
import { storageAccountService } from '../../StorageAccount/StorageAccountService';
import { farmService } from '../../ManageFarms/FarmService';
import {
  RemoveDuplicateAppserviceplans,
  createNewAppServicePlan,
} from '../../../../Common/CommonMethods';
import { Loader } from '../../../../Common/Components/Loader';
import { tenantService } from '../../Tenant/TenantService';
import { ValidationErrors } from 'fluentvalidation-ts';
import { toast } from 'react-toastify';
import DeploymentForm from './DeploymentForm';
import { azureDevOpsService } from '../../AzureDevOps/AzureDevOpsService';
import { useStyles } from '../../../../Common/Styles/WraperStyle';
import { sqlServerService } from '../../SqlServer/SqlServerService';
import { SqlServer } from '../../SqlServer/Model/SqlServerModel';

const CloneTenant = () => {
  const classes = useStyles();
  const params = useParams<Param>();
  const history = useHistory();
  const [loading, setLoading] = useState<number>(0);

  //create customer
  const [groupNames, setGroupNames] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const initalCustomer: NewCustomer = {};
  const [customer, setCustomer] = useState<NewCustomer>(initalCustomer);
  const [FarmId, setFarmId] = useState<string>();
  const [registerTenant, setRegisterTenant] = useState<RegisterTenant>();
  const [openAppserviceDialogue, setOpenAppserviceDialogue] =
    React.useState(false);
  const [storageAccount, setStorageAccount] = React.useState('');
  const [sqlServers, setSqlServers] = useState<SqlServer[]>([]);
  const [replicaServers, setReplicaServers] = useState<SqlServer[]>([]);
  const [sqlServer, setSqlserver] = useState('');
  const [appserviceplans, setAppserviceplans] = React.useState<string[]>([]);
  const [replicas, setreplicas] = useState<string[]>([]);
  const [appserviceplan, setAppserviceplan] = React.useState('');
  const [selectedValue, setSelectedValue] = React.useState(plans[0]);
  const [storageAccounts, setStorageAccounts] = React.useState<
    StorageAccount[]
  >([]);
  const [deployDetailsForm, setDeployDetailsForm] =
    React.useState<DeployDetailsForm>({
      Branch: '',
    });
  const [branches, setBranches] = React.useState<string[]>([]);

  const [customerErrors, setCustomerErros] = useState<
    ValidationErrors<NewCustomer>
  >({});
  const [tenantErrors, setTenantErrors] = useState<
    ValidationErrors<TenantForm>
  >({});
  const [deploymentErrors, setDeploymentErrors] = useState<
    ValidationErrors<DeployDetailsForm>
  >({});

  const [enableExternalIdentity, SetEnableExternalIdentity] = useState(false);

  useEffect(() => {
    InitData();
  }, []);

  const loadGroupNames = async () => {
    try {
      setLoading(loading + 1);
      const groupNames = await customerService.getGroupNames();
      setGroupNames(groupNames.Items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  const loadSqlServers = async () => {
    try {
      setLoading(loading + 1);
      await sqlServerService.getSqlServers().then((sqlservers) => {
        setSqlServers(sqlservers.Items);
        setReplicaServers(sqlservers.Items);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  const loadBranches = async () => {
    try {
      setLoading(loading + 1);
      const branches = await azureDevOpsService.getBranches();
      setBranches(branches.Items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  const loadRegisterTenant = async () => {
    setLoading(loading + 1);
    await customerService.getRegisterTenant(params.customerId).then((data) => {
      setRegisterTenant(data);
      setLoading(loading - 1);
    });
  };

  const loadStorageAccounts = async () => {
    try {
      await storageAccountService
        .getstorageAccounts()
        .then((storageAccounts) => {
          setStorageAccounts(
            storageAccounts.Items.filter(
              (sa) => sa.Purpose.toLowerCase() === 'archive',
            ),
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  const InitData = async () => {
    await loadGroupNames().catch((err) => console.log(err));
    await loadDefaultValues().catch((err) => console.log(err));
    await loadRegisterTenant().catch((err) => console.log(err));
    await loadStorageAccounts().catch((err) => console.log(err));
    await loadSqlServers().catch((err) => console.log(err));
    await loadBranches().catch((err) => console.log(err));
  };

  const loadDefaultValues = async () => {
    try {
      setLoading(loading + 1);
      const sourceCustomer = await customerService.getEditCustomer(
        params.customerId,
      );
      if (sourceCustomer) {
        setCustomer({
          ...customer,
          Id: sourceCustomer.Id,
          Subdomain: sourceCustomer.Subdomain + '-clone',
          CustomerName: sourceCustomer.CustomerName + '-clone',
          EnterpriseNumber: sourceCustomer.EnterpriseNumber,
          SetupType: sourceCustomer.SetupType,
          GroupName: sourceCustomer.GroupName,
          AzureDBServiceObjective: sourceCustomer.AzureDBServiceObjective,
          IdentityProviders: sourceCustomer.IdentityProviders,
          AdminUser: sourceCustomer.AdminUser,
          AzureADAppClientID: '',
          WSFederationUrl: '',
          InputClaimType:
            InputClaimTypes[sourceCustomer.IdentityProviders ?? ''],
          EnableProArcLogin: sourceCustomer.EnableProArcLogin,
          AuthProviderName: sourceCustomer.AuthProviderName,
          ContactPersons: sourceCustomer.ContactPersons,
          AdminUserName: sourceCustomer.AdminUserName,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };
  const setEnableProarcLogin = (value: boolean) => {
    setCustomer({
      ...customer,
      EnableProArcLogin: value,
    });
  };

  const handleFieldValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    setCustomer({
      ...customer,
      [name]: value,
    });

    if (name === 'IdentityProviders') {
      setCustomer({
        ...customer,
        IdentityProviders: value,
        InputClaimType: InputClaimTypes[value],
      });
    }
  };

  const handleDeploymentFormChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = event.target;
    setDeployDetailsForm({
      ...deployDetailsForm,
      [name]: value,
    });
  };

  const loadAppserviceplans = (farmId: number) => {
    farmService.getAppservicePlans(farmId).then((appserviceplans) => {
      setAppserviceplans(appserviceplans);
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFarmId(value);
    const farmId = Number(value);
    loadAppserviceplans(farmId);
    setStorageAccounts(
      storageAccounts.filter(
        (sa) =>
          sa.AzureRegion ===
          registerTenant?.Farms.find((farm) => farm.Id === farmId)?.FarmRegion,
      ),
    );
    setStorageAccount('');
  };

  const handleReplicaChange = (values: string[]) => {
    let duplicatesRemoved: string[] = [];

    values.forEach((item) => {
      if (duplicatesRemoved.findIndex((o) => o === item) >= 0) {
        duplicatesRemoved = duplicatesRemoved.filter((x) => x === item);
      } else {
        duplicatesRemoved.push(item);
      }
    });

    setreplicas(duplicatesRemoved);
  };

  const addItemToState = (item: string[]) => {
    const newItem = createNewAppServicePlan(
      appserviceplans,
      item,
      Number(FarmId),
    );
    const appAppservicePlans = appserviceplans.concat(appserviceplans, newItem);
    setAppserviceplans(RemoveDuplicateAppserviceplans(appAppservicePlans));
  };

  const handleCloseAppservicePlan = (value: string) => {
    setOpenAppserviceDialogue(false);
    if ('Not Selected' !== value) {
      setSelectedValue(value);
      const appserviceplan: string[] = [];
      appserviceplan.push(value);
      addItemToState(appserviceplan);
    }
  };

  const handleClickOpen = () => {
    setOpenAppserviceDialogue(true);
  };

  const validateAuthenticationTypes = () => {
    if (!customer.EnableProArcLogin && !enableExternalIdentity) {
      toast.error(
        'You need to select at least one of the authentication types.',
      );
      return false;
    }
    return true;
  };

  const validateIdentityProviderSection = () => {
    if (
      enableExternalIdentity &&
      (customerErrors.AzureADAppClientID ||
        customerErrors.WSFederationUrl ||
        customerErrors.IdentityProviders ||
        customerErrors.InputClaimType ||
        customerErrors.AuthProviderName)
    ) {
      toast.error(
        'Some fields need to be filled in the create customer section',
      );
      return false;
    }
    return true;
  };

  const validateCustomerSection = () => {
    if (
      customerErrors.Subdomain ||
      customerErrors.CustomerName ||
      customerErrors.ContactPersons ||
      customerErrors.GroupName ||
      customerErrors.AdminUserName ||
      customerErrors.AdminUser
    ) {
      toast.error(
        'Some fields need to be filled in the create customer section',
      );
      return false;
    }
    return true;
  };

  const validateFarmTenantSection = () => {
    if (
      tenantErrors.FarmId ||
      tenantErrors.StorageAccount ||
      tenantErrors.AppservicePlan
    ) {
      toast.error('Some fields need to be filled in the farm/tenant section');
      return false;
    }
    return true;
  };

  const handleClone = async () => {
    if (
      validateAuthenticationTypes() &&
      validateIdentityProviderSection() &&
      validateCustomerSection() &&
      validateFarmTenantSection()
    ) {
      const tenantmetaData: CloneTenantMetaData = {
        Customer: customer,
        FarmId: Number(FarmId),
        StorageAccount: storageAccount,
        AppServicePlan: appserviceplan,
        SqlServer: sqlServer,
        Replicas: replicas,
        PlatformBranch: deployDetailsForm.Branch,
      };
      tenantService.cloneTenant(params.customerId, tenantmetaData);
      toast.success(`deployment started to clone ${customer.Id}`);
      setLoading(loading + 1);
      history.push('/customer');
    }
  };

  function handleClose(): void {
    throw new Error('Function not implemented.');
  }

  function GetSteps() {
    return ['Create Customer', 'Register Tenant to Farm', 'Deployment Details'];
  }

  const steps = GetSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handlePrev = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function GetStepsContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return (
          <CloneTenantForm
            handleFieldValueChange={handleFieldValueChange}
            groupNames={groupNames}
            customer={customer}
            errors={customerErrors}
            setErrors={setCustomerErros}
            enableExternalIdentity={enableExternalIdentity}
            SetEnableProArcLogin={setEnableProarcLogin}
            SetEnableExternalIdentity={SetEnableExternalIdentity}
          />
        );
      case 1:
        return (
          <TenantRegistrationForm
            FarmId={FarmId ?? ''}
            appserviceplan={appserviceplan}
            setAppserviceplan={setAppserviceplan}
            appserviceplans={appserviceplans}
            customerId={params.customerId}
            storageAccount={storageAccount}
            setStorageAccount={setStorageAccount}
            storageAccounts={storageAccounts}
            sqlServer={sqlServer}
            setSqlServer={setSqlserver}
            sqlServers={sqlServers}
            replicaServers={replicaServers}
            handleChange={handleChange}
            handleClickOpen={handleClickOpen}
            registerTenant={registerTenant}
            handleCloseAppservicePlan={handleCloseAppservicePlan}
            openAppserviceDialogue={openAppserviceDialogue}
            selectedValue={selectedValue}
            setOpenAppserviceDialogue={setOpenAppserviceDialogue}
            handleReplicaSelection={handleReplicaChange}
            replicas={replicas}
            errors={tenantErrors}
            setErrors={setTenantErrors}
          />
        );

      default:
        return (
          <DeploymentForm
            deployDetailsForm={deployDetailsForm}
            branches={branches}
            handleChange={handleDeploymentFormChange}
            errors={deploymentErrors}
            setErrors={setDeploymentErrors}
          />
        );
    }
  }
  return (
    <>
      <Loader loading={loading > 0} />
      <div>
        <Paper>
          <Stepper activeStep={activeStep} alternativeLabel sx={{ padding: 2 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Typography variant="h5" className={classes.title}>
            Clone Tenant
          </Typography>
          <Divider className={classes.divider} />
          {GetStepsContent(activeStep)}
          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            {activeStep !== 0 && (
              <Button
                variant="contained"
                onClick={handlePrev}
                className={classes.button}
                startIcon={<ArrowBackIcon />}
              >
                Prev
              </Button>
            )}

            {activeStep === steps.length - 1 ? (
              <Button
                variant="contained"
                onClick={handleClone}
                className={classes.button}
                startIcon={<Save />}
              >
                Clone{' '}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
                startIcon={<ArrowForwardIcon />}
                className={classes.button}
              >
                Next
              </Button>
            )}

            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.button}
              startIcon={<Cancel />}
            >
              Cancel{' '}
            </Button>
          </Paper>
        </Paper>
      </div>
    </>
  );
};

export default CloneTenant;
