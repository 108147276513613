import React, { useEffect, useState } from 'react';
import CustomerForm from './CustomerForm';
import { customerService } from '../CustomerService';
import { useParams, useHistory } from 'react-router-dom';
import { NewCustomer, InputClaimTypes } from '../Model/CustomerModel';
import { toast } from 'react-toastify';
import { Button, Divider, Paper, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import CustomerConstants from '../Model/CustomerConstants';
import { ValidationErrors } from 'fluentvalidation-ts';
import { Loader } from '../../../../Common/Components/Loader';
import { useStyles } from '../../../../Common/Styles/WraperStyle';

interface Param {
  customerId: string;
}

export default function EditCustomerPage() {
  const params = useParams<Param>();
  const initialgroupnames: string[] = [];
  const [groupNames, setGroupNames] = useState(initialgroupnames);
  const [customer, setCustomer] = useState<NewCustomer>({});
  const [loading, setLoading] = useState(0);
  const history = useHistory();
  const [customerErrors, setCustomerErros] = useState<
    ValidationErrors<NewCustomer>
  >({});

  const classes = useStyles();
  useEffect(() => {
    InitData();
  }, []);

  const InitData = async () => {
    await loadGroupNames().catch((err) => console.log(err));
    await loadDefaultValues().catch((err) => console.log(err));
  };
  const loadGroupNames = async () => {
    try {
      setLoading(loading + 1);
      const groupNames = await customerService.getGroupNames();
      setGroupNames(groupNames.Items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  const loadDefaultValues = async () => {
    try {
      setLoading(loading + 1);
      const updatedcustomer = await customerService.getEditCustomer(
        params.customerId,
      );
      setCustomer({
        ...customer,
        ...updatedcustomer,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };
  const UpdateCustomer = async () => {
    try {
      const updatedcustomer = await customerService.updateCustomer(customer);
      if (updatedcustomer) {
        handleClose();
        toast.success(CustomerConstants.CustomerUpdated);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const validateAuthenticationTypes = () => {
    if (!customer.EnableProArcLogin && !customer.ExternalIdentityProvider) {
      toast.error(
        'You need to select at least one of the authentication types.',
      );
      return false;
    }
    return true;
  };

  const validateAuthenticationSection = () => {
    if (
      customer.ExternalIdentityProvider &&
      (customerErrors.AzureADAppClientID ||
        customerErrors.WSFederationUrl ||
        customerErrors.IdentityProviders ||
        customerErrors.InputClaimType ||
        customerErrors.AuthProviderName)
    ) {
      toast.error(
        'Some fields need to be filled in the create authentication section',
      );
      return false;
    }
    return true;
  };

  const validateBasicDetailsSection = () => {
    if (
      customerErrors.CustomerName ||
      customerErrors.ContactPersons ||
      customerErrors.GroupName ||
      customerErrors.AdminUserName ||
      customerErrors.AdminUser
    ) {
      toast.error(
        'Some fields need to be filled in the create Basic Details section',
      );
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    if (
      validateAuthenticationTypes() &&
      validateAuthenticationSection() &&
      validateBasicDetailsSection()
    ) {
      setLoading(loading + 1);
      await UpdateCustomer();
      setLoading(loading - 1);
      handleClose();
    }
  };

  const handleClose = () => {
    history.push('/customer');
  };

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      setCustomer({
        ...customer,
        [name]: checked,
      });
    } else if (name === 'IdentityProviders') {
      setCustomer({
        ...customer,
        IdentityProviders: value,
        InputClaimType: InputClaimTypes[value],
      });
    } else {
      setCustomer({
        ...customer,
        [name]: value,
      });
    }
  }
  return (
    <>
      <Loader loading={loading > 0} />
      <div>
        <Paper>
          <Typography variant="h5" className={classes.title}>
            Edit Customer Details
          </Typography>
          <Divider className={classes.divider} />
          <CustomerForm
            groupNames={groupNames}
            onFieldChange={handleChange}
            customer={customer}
            errors={customerErrors}
            setErrors={setCustomerErros}
            ops="Edit"
          />
          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              onClick={handleSave}
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Save{' '}
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.button}
              startIcon={<Cancel />}
            >
              Cancel{' '}
            </Button>
          </Paper>
        </Paper>
      </div>
    </>
  );
}
