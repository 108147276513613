import React from 'react';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { keyvaultrService } from '../KeyVaultService';
import KeyVaultConstants from '../Common/Constants';
import { RevealProps } from '../Model/KeyVaultProps';
import { ThemedCopyIcon } from '../../../../Common/Components/Icons';

const Reveal: React.FC<RevealProps> = ({ KeyId }) => {
  const getSecretAsString = (value: string): string =>
    typeof value === 'object' ? JSON.stringify(value) : value;

  const toastforSecretCopy = () =>
    toast.success(KeyVaultConstants.SecretCoppied);

  const loadKeyValue = async () => {
    try {
      const KeyValue = await keyvaultrService.RevealKeyValue(KeyId);
      const value = getSecretAsString(KeyValue);
      await navigator.clipboard.writeText(value);
      toastforSecretCopy();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <IconButton onClick={loadKeyValue}>
      <ThemedCopyIcon fontSize="small" />
    </IconButton>
  );
};

export default Reveal;
