import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { Farm } from '../ManageFarms/Model/FarmModel';

class TopologyService {
  getAppservicePlans(farmId: number): Promise<Farm> {
    const farm = callService('farms', `${farmId}`, RequestType.GET);
    return farm;
  }
  UpdateAppservicePlans(farmId: number, editedfarm: Farm): Promise<Farm> {
    const farm = callService('farms', `${farmId}`, RequestType.PUT, editedfarm);
    return farm;
  }
}

export const topologyService: TopologyService = new TopologyService();
