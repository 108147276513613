import { Client } from '@microsoft/microsoft-graph-client';

function getAuthenticatedClient(accessToken: string) {
  const client = Client.init({
    authProvider: (done: (error: Error | null, token: string) => void) => {
      done(null, accessToken);
    },
  });

  return client;
}

export async function getUserDetails(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  const user = await client.api('/me').get();
  return user;
}

export async function getProfile(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  const user = await client.api('/me/photo/$value').get();
  return user;
}

export async function getEvents(accessToken: string) {
  const client = getAuthenticatedClient(accessToken);

  const events = await client
    .api('/me/events')
    .select('subject,organizer,start,end')
    .orderby('createdDateTime DESC')
    .get();

  return events;
}
