import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import { accessControlService } from '../AccessControlService';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import { DragableUI, GetDragEndList } from './BaseDragable';
import { GroupState } from '../Model/AccessControlModel';

interface props {
  username: string;
  setselectedUserGroups: React.Dispatch<React.SetStateAction<string[]>>;
}

const UserGroupDragable = ({ username, setselectedUserGroups }: props) => {
  const classes = useToolbarStyles();
  const [loading, setLoading] = useState(true);
  const [liststate, setListState] = useState<GroupState>({
    unselectedlist: [],
    selectedlist: [],
  });

  useEffect(() => {
    if (username === '') {
      loadAvailableGroups().catch((err) => console.log(err));
    } else {
      loadUserAndAvailableGroups().catch((err) => console.log(err));
    }
  }, [username]);

  const loadUserAndAvailableGroups = async () => {
    setLoading(true);
    const userandavailablegroups =
      await accessControlService.getUserAndAvailableGroups(username);
    setListState((prevState) => ({
      ...prevState,
      unselectedlist: userandavailablegroups.AvailableGroups.map(
        (x) => x.GroupName,
      ),
    }));
    setListState((prevState) => ({
      ...prevState,
      selectedlist: userandavailablegroups.UserGroups.map((x) => x.GroupName),
    }));
    setselectedUserGroups(
      userandavailablegroups.UserGroups.map((x) => x.GroupName),
    );
    setLoading(false);
  };

  const loadAvailableGroups = async () => {
    setLoading(true);
    const userandavailablegroups =
      await accessControlService.getAvailableGroups();
    setListState((prevState) => ({
      ...prevState,
      unselectedlist: userandavailablegroups.AvailableGroups.map(
        (x) => x.GroupName,
      ),
    }));
    setListState((prevState) => ({
      ...prevState,
      selectedlist: userandavailablegroups.UserGroups.map((x) => x.GroupName),
    }));
    setLoading(false);
  };

  const onDragEnd = GetDragEndList(
    liststate,
    setListState,
    setselectedUserGroups,
  );

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        DragableUI(onDragEnd, liststate, 'Available Groups', 'Selected Groups')
      )}
    </>
  );
};

export default UserGroupDragable;
