import { Dispatch, createContext } from 'react';
import { TriggerDetails } from '../Model/TriggerModel';

export const TriggerDispatchContext = createContext<
  Dispatch<TriggerReducerAction>
>({} as Dispatch<TriggerReducerAction>);

export const TriggerCustomerContext = createContext({
  CustomerId: '',
  ContentChanged: false,
});

export type TriggerReducerAction =
  | { type: 'triggerData'; payload: TriggerDetails }
  | { type: 'content'; payload: string }
  | { type: 'name'; payload: string }
  | { type: 'reset' };

export type TriggerDeployAction =
  | { type: 'save' }
  | { type: 'deploy' }
  | { type: 'drop' };

export const initialTriggerState: TriggerDetails = {
  Id: 0,
  CustomerId: '',
  Name: '',
  DeploymentState: 0,
  InsertedDate: new Date(),
  Query: '',
};

export enum TriggerDeploymentState {
  Created = 1,
  Failed = 2,
  NotDeployed = 3,
  Dropped = 4,
}
