import { Dictionary } from '../Components/Common/CommonModel';
import React from 'react';
import { accessCheckFunctions } from './permissionAccessHelper';
import { Permissions } from '../Components/Layout/UIContainer/Global/GlobalState';
import { pageFunctions } from './pagepermissionHandler';

export function hasAccessToModule(
  permissions: Dictionary<Permissions>,
  routeKey: string,
  isProduction: boolean,
): boolean {
  if (routeKey === 'default') {
    return true;
  }
  const accessCheckFunction = accessCheckFunctions[routeKey] || (() => false);
  const value = accessCheckFunction(permissions, isProduction);
  return value;
}

export function hasAccessToPage(
  permissions: Dictionary<Permissions>,
  routeKey: string,
  isProduction: boolean,
): React.ComponentType<React.JSX.Element> {
  const accessCheckFunction = pageFunctions[routeKey];
  const component = accessCheckFunction(permissions, isProduction);
  return component;
}
