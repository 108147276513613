import { Dictionary } from '../Components/Common/CommonModel';
import { Permissions } from '../Components/Layout/UIContainer/Global/GlobalState';
import RestartTenant from '../Components/Layout/PlatformEngineering/Components/RestartTenant';
import KeyValutTable from '../Components/Layout/KeyVault/Home';
import { NoAccess } from '../Components/Layout/Common/NoAccess';
import AuditHome from '../Components/Layout/Audit/AuditHomePage';
import ModulesHomePage from '../Components/Layout/Module/ModulesHomePage';
import StorageAccountOps from '../Components/Layout/StorageAccount/Components/StorageAccountOps';
import SecureKeyOps from '../Components/Layout/KeyVault/Components/SecureKeyOps';
import AccessControlHome from '../Components/Layout/AccessControl/AccessControlHome';
import StorageAccountHome from '../Components/Layout/StorageAccount/StorageAccountsHome';
import CreateTemplate from '../Components/Layout/EmailAdministration/Components/CreateTemplate';
import EditTemplate from '../Components/Layout/EmailAdministration/Components/EditTemplate';
import SendEmailPage from '../Components/Layout/EmailAdministration/Components/SendEmailPage';
import EmailAdmin from '../Components/Layout/EmailAdministration/EmailAdminHomePage';
import { accessCheckFunctions } from './permissionAccessHelper';
import SqlServerHome from '../Components/Layout/SqlServer/SqlServerHome';
import SqlServerCreate from '../Components/Layout/SqlServer/Components/SqlServerCreate';
import SqlServerDelete from '../Components/Layout/SqlServer/Components/SqlServerDelete';
import SqlServerEdit from '../Components/Layout/SqlServer/Components/SqlServerEdit';

export const pageFunctions: Record<
  string,
  (
    permissions: Dictionary<Permissions>,
    isProduction: boolean,
  ) => React.ComponentType<React.JSX.Element>
> = {
  TenantRestartPage: (permissions, _) =>
    accessCheckFunctions.hasTenantRestartAccess(permissions, _)
      ? RestartTenant
      : NoAccess,

  KeyVaultPage: (permissions, _) =>
    accessCheckFunctions.hasKeyVaultAccess(permissions, _)
      ? KeyValutTable
      : NoAccess,

  EmailTemplatePage: (permissions, _) =>
    accessCheckFunctions.hasEmailAccess(permissions, _) ? EmailAdmin : NoAccess,

  EmailSendPage: (permissions, _) =>
    accessCheckFunctions.hasEmailSendAccess(permissions, _)
      ? SendEmailPage
      : NoAccess,

  EditTemplatePage: (permissions, _) =>
    accessCheckFunctions.hasEmailAccess(permissions, _)
      ? EditTemplate
      : NoAccess,

  CreateTemplatePage: (permissions, _) =>
    accessCheckFunctions.hasEmailAccess(permissions, _)
      ? CreateTemplate
      : NoAccess,

  StorageAccountPage: (permissions, _) =>
    accessCheckFunctions.hasStorageAccountAccess(permissions, _)
      ? StorageAccountHome
      : NoAccess,

  SqlServerPage: (permissions, _) =>
    accessCheckFunctions.hasSqlServerAccess(permissions, _)
      ? SqlServerHome
      : NoAccess,

  SqlServerCreate: (permissions, _) =>
    accessCheckFunctions.hasSqlServerAccess(permissions, _)
      ? SqlServerCreate
      : NoAccess,

  SqlServerEdit: (permissions, _) =>
    accessCheckFunctions.hasSqlServerAccess(permissions, _)
      ? SqlServerEdit
      : NoAccess,

  SqlServerDelete: (permissions, _) =>
    accessCheckFunctions.hasSqlServerAccess(permissions, _)
      ? SqlServerDelete
      : NoAccess,

  AccessControlPage: (permissions, _) =>
    accessCheckFunctions.hasAccessControlAccess(permissions, _)
      ? AccessControlHome
      : NoAccess,

  SecureKeyOpsPage: (permissions, _) =>
    accessCheckFunctions.hasKeyVaultWriteAccess(permissions, _)
      ? SecureKeyOps
      : NoAccess,

  StorageAccountOpsPage: (permissions, _) =>
    accessCheckFunctions.hasStorageAccountWriteAccess(permissions, _)
      ? StorageAccountOps
      : NoAccess,

  ModulePage: (permissions, _) =>
    accessCheckFunctions.hasModuleWriteAccess(permissions, _)
      ? ModulesHomePage
      : NoAccess,

  AuditPage: (permissions, _) =>
    accessCheckFunctions.hasAuditAccess(permissions, _) ? AuditHome : NoAccess,
};
