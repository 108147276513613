import { useLocation } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { useEffect } from 'react';
import { PageStep } from './PageStepsProps';

export function usePageTour(pageSteps: PageStep[]) {
  const location = useLocation();
  const { setSteps, setIsOpen } = useTour();

  useEffect(() => {
    if (pageSteps.length > 0 && setSteps) {
      setSteps(pageSteps);
      setIsOpen(true);
    }
  }, [location, setSteps, setIsOpen, pageSteps]);
}
