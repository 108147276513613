import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Divider, Paper, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import { ValidationErrors } from 'fluentvalidation-ts';
import { Loader } from '../../../../Common/Components/Loader';
import { useStyles } from '../../../../Common/Styles/WraperStyle';
import { OperationType, SqlServer } from '../Model/SqlServerModel';
import { sqlServerService } from '../SqlServerService';
import SqlServerCreateForm from './SqlServerForm';

interface Param {
  sqlserver: string;
}

export default function SqlServerEdit() {
  const params = useParams<Param>();
  const [loading, setLoading] = useState(0);
  const classes = useStyles();
  const history = useHistory();
  const [errors, setErrors] = useState<ValidationErrors<SqlServer>>({});
  const [sqlServer, setSqlServer] = useState<SqlServer>({
    AdminPassword: '',
    UserName: '',
    ServerName: '',
    ResourceGroup: '',
    Subscription: '',
    ProductID: '',
    Role: 'primary',
    Region: 'northeurope',
    EnableElasticPool: true,
    TenantCount: 0,
    DataMaxSize: 50,
    EDtu: 50,
    PerDbDtu: 10,
    FailoverGroups: [],
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      setSqlServer({
        ...sqlServer,
        [name]: checked,
      });
    } else {
      setSqlServer({
        ...sqlServer,
        [name]: value,
      });
    }
  };

  const validate = () => {
    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([_, value]) => {
        toast.error(_ + ' ' + value?.toString());
      });
      return false;
    }
    return true;
  };

  const cancel = () => {
    history.push('/sqlserver');
  };

  const saveSqlServer = () => {
    setLoading((prevState) => prevState + 1);
    if (validate()) {
      sqlServerService
        .addSqlServer(sqlServer.ServerName, sqlServer)
        .then((result) => {
          toast.success(result.ServerName + ' updated successfully');
          cancel();
        })
        .catch((err) => {
          toast.error('Failed to add Sql Server');
          toast.error(err.message);
        });
    }
    setLoading((prevState) => prevState - 1);
  };

  const loadSqlServer = async () => {
    try {
      setLoading(loading + 1);
      const sqlserver = await sqlServerService.getSqlServer(params.sqlserver);
      setSqlServer(sqlserver);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  useEffect(() => {
    InitData();
  }, []);

  const InitData = async () => {
    await loadSqlServer().catch((err) => console.log(err));
  };

  return (
    <>
      <Loader loading={loading > 0} />
      <div>
        <Paper>
          <Typography variant="h5" className={classes.title}>
            Edit Customer Details
          </Typography>
          <Divider className={classes.divider} />
          <SqlServerCreateForm
            onFieldChange={handleChange}
            sqlserver={sqlServer}
            errors={errors}
            setErrors={setErrors}
            setSqlServer={setSqlServer}
            sqlServerOperation={OperationType.Edit}
          />
          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              onClick={saveSqlServer}
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Save{' '}
            </Button>
            <Button
              variant="contained"
              onClick={cancel}
              className={classes.button}
              startIcon={<Cancel />}
            >
              Cancel{' '}
            </Button>
          </Paper>
        </Paper>
      </div>
    </>
  );
}
