import React, { useContext } from 'react';
import { Theme, Toolbar, Typography, Button, Icon } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { PreferredThemeContext } from '../../../../Theme/UseToggleDarkMode';
import { SiteHeaderProps } from '../Model';

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    spacer: {
      flex: '1 1 100%',
    },
    title: {
      flex: '0 0 auto',
    },
    button: {
      margin: theme.spacing(2),
      width: 130,
    },
  }),
);

export default function SitesHeader(props: SiteHeaderProps) {
  const { open, customerName } = props;

  const classes = useToolbarStyles();

  const prefersDarkMode = useContext(PreferredThemeContext);

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Available dss sites for{' '}
          {customerName === undefined ? '' : customerName.toUpperCase()}
        </Typography>
      </div>

      <div className={classes.spacer} />

      <Button
        variant="contained"
        color={prefersDarkMode ? 'secondary' : 'primary'}
        startIcon={<Icon>add</Icon>}
        className={classes.button}
        onClick={() => open()}
      >
        Add
      </Button>
    </Toolbar>
  );
}
