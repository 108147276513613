import {
  Dialog,
  DialogTitle,
  Card,
  Button,
  DialogContentText,
  DialogContent,
  DialogActions,
  Divider,
} from '@mui/material';
import Tag from './Tag';
import React, { useEffect } from 'react';
import { globalService } from '../../Layout/UIContainer/Global/GlobalService';
import { CommonTagProps } from './TagProps';

const CommonTag = (props: CommonTagProps) => {
  const [selected, setSelected] = React.useState<string[]>([]);

  useEffect(() => {
    loadSelectedTags(props.objectId);
  }, []);

  const loadSelectedTags = async (objectId: string) => {
    await globalService
      .getTagDetailsByObjectId(objectId)
      .then((selectedTags) => {
        setSelected(selectedTags.Items.map((x) => x.TagName));
      });
  };
  return (
    <Dialog disableEscapeKeyDown open={true} fullWidth maxWidth="md">
      <DialogTitle id="form-dialog-title">{props.objectType} Tags</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Card sx={{ padding: 2 }}>
            <Tag
              initialOptions={props.options}
              setSelected={setSelected}
              initialSelected={selected}
            />
          </Card>
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            props.saveTags(selected);
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.setShowTags(false)}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommonTag;
