import React, { useState } from 'react';
import { Drawer, Theme, Divider, IconButton } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import TenantBlade from './Components/TenantBlade';
import TenantMetrics from './Components/TenantMetrics';

import TenantContext from '../Metrics/Components/TenantContext';
import { MetricEntity } from './Model/MetricsModel';
import AllTenantMetrics from './Components/AllTenantMetrics/AllTenantMetrics';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: -theme.spacing(11),
      marginLeft: -theme.spacing(3),
    },
    wrapper: {
      display: 'flex',
      height: '100vh',
      width: '100%',
      paddingTop: theme.spacing(8),
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    iconButton: {
      padding: '8px',
    },
    drawerPaper: {
      position: 'relative',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      position: 'relative',
      overflowX: 'hidden',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(5),
      },
    },
    paper: {
      flexGrow: 1,
      alignSelf: 'flexend',
      marginLeft: theme.spacing(1),
    },
    hidden: {
      display: 'none',
    },
    visible: {
      display: 'visible',
    },
  }),
);

export default function MetricsHome() {
  const classes = useStyles();
  const [isDrawerOpen, setDrawerState] = useState(true);
  const [selectedTenantId, setCurrentTenant] = useState('');
  const [selectedFarmId, setCurrentFarm] = useState(-1);
  const [selectedEntity, setCurrentEntityLevel] = useState(MetricEntity.Farm);

  const handleDrawerOpen = () => {
    setDrawerState(!isDrawerOpen);
  };

  const setSelectedTenant = (tenantId: string) => {
    setCurrentTenant(tenantId);
  };

  const setSelectedFarm = (farmId: number) => {
    setCurrentFarm(farmId);
  };

  const setSelectedEntity = (entity: MetricEntity) => {
    setCurrentEntityLevel(entity);
  };

  return (
    <TenantContext.Provider
      value={{ setSelectedTenant, setSelectedFarm, setSelectedEntity }}
    >
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Drawer
            variant="permanent"
            classes={{
              paper: !isDrawerOpen
                ? classes.drawerPaperClose
                : classes.drawerPaper,
            }}
            open={isDrawerOpen}
          >
            <div className={classes.toolbarIcon}>
              <IconButton
                onClick={handleDrawerOpen}
                className={classes.iconButton}
                size="large"
              >
                {isDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            <div className={!isDrawerOpen ? classes.hidden : classes.visible}>
              <TenantBlade />
            </div>
          </Drawer>
          <div className={classes.paper}>
            {selectedEntity === MetricEntity.Tenant &&
            selectedTenantId !== '' ? (
              <TenantMetrics
                farmId={selectedFarmId}
                tenantId={selectedTenantId}
              ></TenantMetrics>
            ) : selectedEntity === MetricEntity.Farm &&
              selectedFarmId !== -1 ? (
              <AllTenantMetrics farmId={selectedFarmId}></AllTenantMetrics>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
    </TenantContext.Provider>
  );
}
