import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { TableHeader } from '../../Common/CommonModel';
import { EmailAdminHearRow, EmailTemplate } from './Model/EmailAdminModel';
import { EnhancedTableProps } from './Model/EmailAdminProps';

const EmailAdminHeaderRows: TableHeader<EmailAdminHearRow>[] = [
  {
    id: 'Name',
    numeric: false,
    disablePadding: false,
    label: 'Template Name',
    enableSorting: true,
  },
  {
    id: 'Product',
    numeric: false,
    disablePadding: false,
    label: 'Product',
    enableSorting: true,
  },
  {
    id: 'Subject',
    numeric: false,
    disablePadding: false,
    label: 'Subject',
    enableSorting: true,
  },
  {
    id: 'EditTemplate',
    numeric: false,
    disablePadding: false,
    label: 'Edit Template',
    enableSorting: true,
  },
  {
    id: 'DeleteTemplate',
    numeric: false,
    disablePadding: false,
    label: 'Delete Template',
    enableSorting: true,
  },
];

const createSortHandler =
  (
    property: keyof EmailAdminHearRow,
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof EmailTemplate,
    ) => void,
  ) =>
  (event: React.MouseEvent<unknown>) => {
    if (property) onRequestSort(event, property as keyof EmailTemplate);
  };

const EmailAdminHeader = (props: EnhancedTableProps) => {
  return (
    <TableHead>
      <TableRow>
        {EmailAdminHeaderRows.map((emailTemplateRow) => (
          <TableCell
            key={emailTemplateRow.id}
            align={'left'}
            padding={emailTemplateRow.disablePadding ? 'none' : 'normal'}
            sortDirection={
              props.orderBy === emailTemplateRow.id ? props.order : false
            }
          >
            {emailTemplateRow.enableSorting ? (
              <TableSortLabel
                active={props.orderBy === emailTemplateRow.id}
                direction={props.order}
                onClick={createSortHandler(
                  emailTemplateRow.id,
                  props.onRequestSort,
                )}
              >
                {emailTemplateRow.label}
              </TableSortLabel>
            ) : (
              <div>{emailTemplateRow.label}</div>
            )}
          </TableCell>
        ))}
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
};

export default EmailAdminHeader;
