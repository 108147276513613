import { Paper, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { farmService } from '../FarmService';
import KeyVaultConstants from '../../KeyVault/Common/Constants';
import { useStyles as CommonStyle } from '../../../../Common/Styles/FarmStyle';
import { ThemedCopyIcon } from '../../../../Common/Components/Icons';

const useStyles = makeStyles(() => ({
  mainPaper: {
    padding: '20px',
  },
}));

const DetailsHolder: FC<{ farmId: number }> = ({ farmId }) => {
  const classes = useStyles();
  const commonClass = CommonStyle();
  const [loading, setLoading] = useState(0);
  const userName = `farm${farmId}Admin`;

  const toastforSecretCopy = () =>
    toast.success(KeyVaultConstants.SecretCoppied);

  const loadAdminPassword = async () => {
    try {
      setLoading(loading + 1);
      const password = await farmService.getPassword(farmId);
      await navigator.clipboard.writeText(password);
      toastforSecretCopy();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  const copyUserName = () => {
    navigator.clipboard.writeText(userName);
    toastforSecretCopy();
  };

  return (
    <Paper elevation={0}>
      <Paper className={classes.mainPaper} elevation={0}>
        <div className={commonClass.spanContainer}>
          <span className={commonClass.span}>Username</span>
          <span className={commonClass.span} style={{ alignItems: 'center' }}>
            {userName}
            <IconButton onClick={copyUserName}>
              <ThemedCopyIcon fontSize="small" />
            </IconButton>
          </span>
        </div>
        <div className={commonClass.spanContainer}>
          <span className={commonClass.span}>VM Password</span>
          <span className={commonClass.span} style={{ alignItems: 'center' }}>
            xxxxxxxxxxxxxx
            <IconButton onClick={loadAdminPassword}>
              <ThemedCopyIcon fontSize="small" />
            </IconButton>
          </span>
        </div>
      </Paper>
    </Paper>
  );
};

export default DetailsHolder;
