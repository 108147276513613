import {
  Checkbox,
  TextField,
  MenuItem,
  Divider,
  Button,
  Paper,
  Card,
} from '@mui/material';
import React, { FC } from 'react';
import { AssembleBuild } from '../Model/AzureDevOpsModel';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import { Cancel, CloudUpload } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../../../../Common/Styles/FarmStyle';
export function handleCommonCheckbox(
  event: React.ChangeEvent<HTMLInputElement>,
  setState: React.Dispatch<React.SetStateAction<boolean>> | undefined,
) {
  if (setState) setState(event.target.checked);
}

const GetCheckboxStateHandler = (
  e: React.ChangeEvent<HTMLInputElement>,
  commonCheckBox: boolean | undefined,
  setState: React.Dispatch<React.SetStateAction<boolean>> | undefined,
  handleCheckbox?: (event: React.ChangeEvent<HTMLInputElement>) => void,
) => {
  if (commonCheckBox === true) {
    handleCommonCheckbox(e, setState);
  } else if (handleCheckbox) handleCheckbox(e);
};

export const renderCheckbox = (
  name: string,
  checked: boolean,
  label: string,
  handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void,
  commonCheckbox?: boolean,
  setState?: React.Dispatch<React.SetStateAction<boolean>> | undefined,
) => {
  return (
    <div
      style={{
        display: 'block',
        marginRight: '10px',
      }}
    >
      <Checkbox
        checked={checked}
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        name={name}
        onChange={(e) => {
          GetCheckboxStateHandler(e, commonCheckbox, setState, handleCheckbox);
        }}
      />
      <label>{label}</label>
    </div>
  );
};

interface BaseDeploymentProps {
  assembleBuild: string;
  handleFieldChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  assemblebuilds: AssembleBuild[];
  selectedassembleBuild: string;
  branches: string[];
  selectedBranch: string;
}

export const BaseDeployment: FC<BaseDeploymentProps> = ({
  assembleBuild,
  handleFieldChange,
  assemblebuilds,
  selectedassembleBuild,
  branches,
  selectedBranch,
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.outerContainer}>
        <Card elevation={0} className={classes.card}>
          <h3>Build Details</h3>
          <div>
            <div className={classes.spanContainer}>
              <span className={classes.span}>Search Build</span>
              <TextField
                className={classes.textField}
                value={assembleBuild}
                placeholder="Search"
                name="AssembleBuild"
                onChange={(e) =>
                  handleFieldChange(e as React.ChangeEvent<HTMLInputElement>)
                }
              />
            </div>
            {assemblebuilds && (
              <div className={classes.spanContainer}>
                <span className={classes.span}>Assemble Build</span>
                <TextField
                  className={classes.textField}
                  value={selectedassembleBuild}
                  name="SelectedAssemble"
                  onChange={(e) =>
                    handleFieldChange(e as React.ChangeEvent<HTMLInputElement>)
                  }
                  select
                >
                  {assemblebuilds.map((assemble) => {
                    return (
                      <MenuItem
                        key={assemble.BuildNumber}
                        value={assemble.BuildNumber}
                      >
                        {assemble.BuildNumber}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            )}
            {branches && (
              <div className={classes.spanContainer}>
                <span className={classes.span}>Platform Branch</span>
                <TextField
                  className={classes.textField}
                  value={selectedBranch}
                  defaultValue={selectedBranch}
                  name="SelectedBranch"
                  onChange={(e) =>
                    handleFieldChange(e as React.ChangeEvent<HTMLInputElement>)
                  }
                  select
                >
                  {branches.map((branchname) => {
                    return (
                      <MenuItem key={branchname} value={branchname}>
                        {branchname.replace('refs/heads/', '')}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

interface DeploymentTypeProps {
  disasterrecovery: boolean;
  rollback: boolean;
  DeployOnly: boolean;
  handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setDeployOnly: React.Dispatch<React.SetStateAction<boolean>>;
  setRollback: React.Dispatch<React.SetStateAction<boolean>>;
  setDisasterrecovery: React.Dispatch<React.SetStateAction<boolean>>;
  displayOptions: {
    deployOnly: boolean;
    rollback: boolean;
    disasterRecovery: boolean;
  };
}

export const DeploymentTypeComponent: FC<DeploymentTypeProps> = ({
  disasterrecovery,
  rollback,
  DeployOnly,
  handleCheckbox,
  setDeployOnly,
  setRollback,
  setDisasterrecovery,
  displayOptions,
}) => {
  const checkboxes = [
    {
      id: 'DeployOnly',
      value: DeployOnly,
      label: 'Deploy Only',
      setter: setDeployOnly,
      disabled: disasterrecovery || rollback,
      display: displayOptions.deployOnly,
    },
    {
      id: 'rollback',
      value: rollback,
      label: 'Roll Back',
      setter: setRollback,
      disabled: disasterrecovery || DeployOnly,
      display: displayOptions.rollback,
    },
    {
      id: 'disasterrecovery',
      value: disasterrecovery,
      label: 'Disaster Recovery',
      setter: setDisasterrecovery,
      disabled: DeployOnly || rollback,
      display: displayOptions.disasterRecovery,
    },
  ];

  return (
    <>
      {checkboxes.map(
        (checkbox) =>
          checkbox.display &&
          !checkbox.disabled &&
          renderCheckbox(
            checkbox.id,
            checkbox.value,
            checkbox.label,
            handleCheckbox,
            true,
            checkbox.setter,
          ),
      )}
    </>
  );
};

interface GlobalDeploymentProps {
  commonresources: boolean;
  webviewer: boolean;
  appgateway: boolean;
  formatconverter: boolean;
  indexingsearch: boolean;
  monitoring: boolean;
  handleCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setCommonResources: React.Dispatch<React.SetStateAction<boolean>>;
  setFormatConverter: React.Dispatch<React.SetStateAction<boolean>>;
  setAppGateway: React.Dispatch<React.SetStateAction<boolean>>;
  setWebViewer: React.Dispatch<React.SetStateAction<boolean>>;
  setIndexingSearch: React.Dispatch<React.SetStateAction<boolean>>;
  setMonitoring: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GlobalDeploymentComponent: FC<GlobalDeploymentProps> = ({
  commonresources,
  webviewer,
  appgateway,
  formatconverter,
  indexingsearch,
  monitoring,
  handleCheckbox,
  setCommonResources,
  setFormatConverter,
  setAppGateway,
  setWebViewer,
  setIndexingSearch,
  setMonitoring,
}) => {
  const checkboxes = [
    {
      id: 'COMMON',
      value: commonresources,
      label: 'Common Resources',
      setter: setCommonResources,
      disabled:
        appgateway ||
        webviewer ||
        indexingsearch ||
        formatconverter ||
        monitoring,
    },
    {
      id: 'FORMATCONVERTER',
      value: formatconverter,
      label: 'Format Converter',
      setter: setFormatConverter,
      disabled:
        appgateway ||
        webviewer ||
        indexingsearch ||
        commonresources ||
        monitoring,
    },
    {
      id: 'WEBVIEW',
      value: webviewer,
      label: 'Webviewer',
      setter: setWebViewer,
      disabled:
        appgateway ||
        formatconverter ||
        indexingsearch ||
        commonresources ||
        monitoring,
    },
    {
      id: 'APPGATEWAY',
      value: appgateway,
      label: 'Application Gateway',
      setter: setAppGateway,
      disabled:
        webviewer ||
        formatconverter ||
        indexingsearch ||
        commonresources ||
        monitoring,
    },
    {
      id: 'INDEXINGSEARCH',
      value: indexingsearch,
      label: 'Indexing Search',
      setter: setIndexingSearch,
      disabled:
        webviewer ||
        formatconverter ||
        appgateway ||
        commonresources ||
        monitoring,
    },
    {
      id: 'MONITORING',
      value: monitoring,
      label: 'Dashboard and Alerts',
      setter: setMonitoring,
      disabled:
        webviewer ||
        formatconverter ||
        appgateway ||
        commonresources ||
        indexingsearch,
    },
  ];

  return (
    <>
      {checkboxes.map(
        (checkbox) =>
          !checkbox.disabled &&
          renderCheckbox(
            checkbox.id,
            checkbox.value,
            checkbox.label,
            handleCheckbox,
            true,
            checkbox.setter,
          ),
      )}
    </>
  );
};

export const DeploymentFooter = (props: {
  DeployAppServices: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const history = useHistory();
  const classes = useToolbarStyles();
  const handleCancel = () => {
    return history.push('/farm');
  };
  return (
    <>
      <Divider className={classes.divider} />
      <Paper style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          startIcon={<CloudUpload />}
          className={classes.button}
          onClick={props.DeployAppServices}
        >
          Deploy
        </Button>
        <Button
          variant="contained"
          startIcon={<Cancel />}
          className={classes.button}
          onClick={handleCancel}
        >
          Cancel
        </Button>
      </Paper>
    </>
  );
};
