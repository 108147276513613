import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import { CustomerDssSite } from './Model/DssModel';

class DssService {
  getDssSites(customerId: string): Promise<CustomerDssSite[]> {
    const sites = callService(
      'customers',
      customerId + '/dsssite',
      RequestType.GET,
    );
    return sites;
  }

  getDssSite(customerId: string, id: number): Promise<CustomerDssSite> {
    const site = callService(
      'customers',
      customerId + '/dsssite/' + id,
      RequestType.GET,
    );
    return site;
  }

  public createSite(
    customerId: string,
    site: CustomerDssSite,
  ): Promise<CustomerDssSite> {
    const dssSite = callService(
      'customers',
      customerId + '/dsssite',
      RequestType.POST,
      site,
    );
    return dssSite;
  }

  public editSite(
    customerId: string,
    site: CustomerDssSite,
  ): Promise<CustomerDssSite> {
    const dssSite = callService(
      'customers',
      customerId + '/dsssite',
      RequestType.PUT,
      site,
    );
    return dssSite;
  }
}

export const dssService: DssService = new DssService();
