import { toast } from 'react-toastify';
import ClientSecretContants from '../Model/ClientSecretConstants';
import { Theme, lighten, alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import MuiTableCell from '@mui/material/TableCell';

export const toastforClientIdCopy = () => {
  toast.success(ClientSecretContants.ClientIdCoppied);
};
export const toastforSecretCopy = () => {
  toast.success(ClientSecretContants.SecretCoppied);
};

export const TableCell = withStyles({
  root: {
    borderBottom: 'none',
    whiteSpace: 'nowrap',
  },
})(MuiTableCell);

export const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
      width: 200,
    },
    smalltextbox: {
      width: 120,
    },
    longtextbox: {
      width: 700,
    },
    medtextbox: {
      width: 400,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight: {
      paddingRight: 10,
      color: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: lighten(theme.palette.common.black, 0.95),
      height: 35,
    },
    spacer: {
      flex: '1 1 100%',
    },
    title: {
      flex: '0 0 auto',
    },
    searchOption: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.black, 0.1),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.05),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
      marginRight: 30,
    },
    IconSearch: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    RootInput: {
      color: 'inherit',
    },
    DialogContent: {
      overflow: 'hidden',
    },
    DataGridCell: {
      '& .MuiDataGrid-cell:focus-within': {
        outline: 'none !important',
      },
    },
    typo: {
      padding: '10px',
      fontSize: 12,
    },
    SmallButton: {
      margin: theme.spacing(1),
    },
    input: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
  }),
);
