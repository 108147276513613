import { Dictionary } from '../../../Common/CommonModel';
export interface Customer {
  Id: string;
  CustomerName: string;
  Subdomain: string;
  StateID: number;
  DeploymentStateID: number;
  DeploymentDate: Date;
  InsertDate: Date;
  Tags: string[];
}
export interface CustomScript {
  CustomerId: string;
  Sql: string;
  Powershell: string;
}

export interface CustomerV2 {
  Id?: string;
  EnterpriseNumber?: string;
  WSFederationUrl?: string;
  AdminUser?: string;
  AdminUserName?: string;
  Subdomain?: string;
  SetupType?: string;
  InputClaimType?: string;
  IdentityProviders?: string;
  DeploymentDate?: Date;
  DeploymentStateID?: number;
  StorageAccountName?: string;
  InsertDate?: Date;
  AzureDBServiceObjective?: string;
  AzureADAppClientID?: string;
  ExternalIdentityProvider?: boolean;
  EnableProArcLogin?: boolean;
  AuthProviderName?: string;
  GroupName?: string;
  TrustedClients?: string;
  CustomerName?: string;
}
export interface CustomerModule {
  CustomerID: string;
  ModuleID: string;
}
export interface CustomerModuleDTO {
  ModuleID: string;
  ModuleName: string;
  DisplayName: string;
  Description: string;
  Type: string;
  IsSubsetup: boolean;
}
export interface CollaborationRelayInfo {
  OwnerID: string;
  Username: string;
  EncryptedPassword: string;
  AzureRelayKey: string;
  HybridConnectionKey: string;
  Created: Date;
}
export interface CustomerState {
  stateId: number;
  stateDescription: string;
}
export interface CustomerMenuItems {
  id: string;
  label: string;
  linkTo: string;
  validationFunc: string;
}
export enum AvailableIdentityProviders {
  ADFS = 'ADFS',
  AzureB2C = 'Azure B2C',
  OKTA = 'OKTA',
  Google = 'Google',
}

export enum AvailableLanguages {
  Norwegian = 1044,
  English = 1033,
  Swedish = 1053,
  Danish = 1030,
  Finnish = 1035,
  Dutch = 1043,
}

export enum CustomerStateId {
  'Trial / Test' = 2,
  'Production' = 1,
  'Automatic test' = 3,
  'Demo' = 4,
}
export interface CreateCustomerConstants {
  AzureDBServiceObjective?: string;
  AdminUser?: string;
  Subdomain?: string;
  SubscriptionType?: string;
  State?: number;
}

export interface NewCustomer extends CustomerV2 {
  ContactPersons?: string | null;
}

export interface Param {
  customerId: string;
}

export const CustomerProperties = [
  'CustomerName',
  'GroupName',
  'ArchiveEncryptionAlgorithm',
  'IdentityProviders',
  'AdminUser',
  'AzureADAppClientID',
  'InputClaimType',
];
export const InputClaimTypes: Dictionary<string> = {};
InputClaimTypes['ADFS'] =
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/upn';
InputClaimTypes['Azure B2C'] = 'Emails';
InputClaimTypes['OKTA'] = 'Emails';
InputClaimTypes['Google'] = 'Emails';

export interface CustomizationOptionProps {
  saveCustomizationCallback: () => Promise<void>;
}
