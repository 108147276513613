import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Divider,
  MenuItem,
  Paper,
  Select,
  Table,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import { useParams, useHistory } from 'react-router-dom';
import { TableCell } from '../../DashBoard/Common/CommonMethods';
import { Cancel, Save } from '@mui/icons-material';
import { ToastContent, toast } from 'react-toastify';
import { StorageAccount } from '../Model/StorageAccountModel';
import { CreateOrEditProps, ParamProps } from '../Model/StorageAccountProps';
import { storageAccountService } from '../StorageAccountService';
import { commonService } from '../../../Common/CommonService';
import { Product, Subscription } from '../../../Common/CommonModel';
import StorageAccountConstants from '../Common/Constants';

const StorageAccountFarm: React.FC<CreateOrEditProps> = ({
  storageAccount,
  setStorageAccount,
  AddOrEditStorageAccount,
  showOperationbutton,
  showBackbutton,
  operation,
}) => {
  const classes = useToolbarStyles();
  const [AzureRegions, setRegions] = useState<string[]>([]);
  const [Products, setProducts] = useState<Product[]>([]);
  const [Subscriptions, setSuscriptions] = useState<Subscription[]>([]);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    InitData();
  }, []);

  const InitData = async () => {
    setloading(true);
    await loadAzureRegions().catch((err) => console.log(err));
    await loadProducts().catch((err) => console.log(err));
    await loadSubscriptions().catch((err) => console.log(err));
    setloading(false);
  };
  const loadAzureRegions = async () => {
    const azureRegions = await commonService.getAzureRegions();
    setRegions(azureRegions);
  };

  const loadSubscriptions = async () => {
    const subscriptions = await commonService.getSubscriptions();
    setSuscriptions(subscriptions.Items);
  };

  const loadProducts = async () => {
    const products = await commonService.getProducts();
    setProducts(products.Items);
  };

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Card elevation={2} style={{ padding: 15, margin: 15 }}>
            <Typography variant="h6">
              {operation.charAt(0).toUpperCase() + operation.slice(1)} Storage
              Account
            </Typography>
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell>Storage Account Name</TableCell>
                  <TextField
                    disabled={operation !== 'create'}
                    className={classes.textFieldMedium}
                    value={storageAccount.Name}
                    onChange={(e) =>
                      setStorageAccount({
                        ...storageAccount,
                        Name: e.target.value,
                      })
                    }
                  />
                </TableRow>
                <TableRow>
                  <TableCell>Resource Group</TableCell>
                  <TextField
                    className={classes.textFieldMedium}
                    value={storageAccount.ResourceGroup}
                    onChange={(e) =>
                      setStorageAccount({
                        ...storageAccount,
                        ResourceGroup: e.target.value,
                      })
                    }
                  />
                </TableRow>
                <TableRow>
                  <TableCell>Product Id</TableCell>
                  <Select
                    className={classes.textFieldMedium}
                    value={storageAccount.ProductID}
                    MenuProps={{
                      classes: {
                        paper: classes.menuPaper,
                      },
                    }}
                    onChange={(e) =>
                      setStorageAccount({
                        ...storageAccount,
                        ProductID: e.target.value,
                      })
                    }
                  >
                    {Products.map((product) => (
                      <MenuItem key={product.Id} value={product.Id}>
                        {product.Id}
                      </MenuItem>
                    ))}
                  </Select>
                </TableRow>
                <TableRow>
                  <TableCell>Subscription</TableCell>
                  <Select
                    className={classes.textFieldMedium}
                    value={storageAccount.Subscription}
                    MenuProps={{
                      classes: {
                        paper: classes.menuPaper,
                      },
                    }}
                    onChange={(e) =>
                      setStorageAccount({
                        ...storageAccount,
                        Subscription: e.target.value,
                      })
                    }
                  >
                    {Subscriptions.map((subscription) => (
                      <MenuItem
                        key={subscription.Name}
                        value={subscription.Name}
                      >
                        {subscription.Name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableRow>
                <TableRow>
                  <TableCell>Purpose</TableCell>
                  <TextField
                    className={classes.textFieldMedium}
                    disabled={true}
                    value={storageAccount.Purpose}
                    onChange={(e) =>
                      setStorageAccount({
                        ...storageAccount,
                        Purpose: e.target.value,
                      })
                    }
                  />
                </TableRow>
                <TableRow>
                  <TableCell>Azure Region</TableCell>
                  <Select
                    className={classes.textFieldMedium}
                    value={storageAccount.AzureRegion}
                    MenuProps={{
                      classes: {
                        paper: classes.menuPaper,
                      },
                    }}
                    onChange={(e) =>
                      setStorageAccount({
                        ...storageAccount,
                        AzureRegion: e.target.value,
                      })
                    }
                  >
                    {AzureRegions.map((region) => (
                      <MenuItem key={region} value={region}>
                        {region}
                      </MenuItem>
                    ))}
                  </Select>
                </TableRow>
              </Table>
            </TableContainer>
          </Card>

          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            {showOperationbutton(AddOrEditStorageAccount)}
            {showBackbutton()}
          </Paper>
        </>
      )}
    </>
  );
};

export default function StorageAccountOps() {
  const classes = useToolbarStyles();
  const [storageAccount, setStorageAccount] = useState<StorageAccount>({
    Name: '',
    ProductID: '',
    ResourceGroup: '',
    Subscription: '',
    Purpose: 'Archive',
    tStorageKey: 0,
    AzureRegion: '',
  });
  const history = useHistory();

  const param = useParams<ParamProps>();
  const Operation = param.Operation.toLocaleLowerCase();

  useEffect(() => {
    if (Operation === 'edit') {
      GetStorageAccount();
    }
  }, []);

  const validateStorageAccount = (storageAccount: StorageAccount) => {
    if (
      storageAccount.Name.trim() === '' ||
      storageAccount.AzureRegion.trim() === '' ||
      storageAccount.ProductID.trim() === '' ||
      storageAccount.Subscription.trim() === '' ||
      storageAccount.ResourceGroup.trim() === ''
    ) {
      toast.error('all field are mandatory');
      return false;
    }
    return true;
  };
  const handleCancel = async () => {
    history.push('/storageaccount');
  };

  const AddOrEditStorageAccount = async () => {
    if (validateStorageAccount(storageAccount)) {
      try {
        await storageAccountService.setstorageAccount(storageAccount);
        handleCancel();
        toast.success(StorageAccountConstants.StorageAccountSaveSuc);
      } catch (error) {
        console.log(error);
        toast.error(error as ToastContent);
      }
    }
  };

  const GetStorageAccount = async () => {
    try {
      await storageAccountService
        .getstorageAccount(param.Name)
        .then((storageAccount) => setStorageAccount(storageAccount));
    } catch {
      toast.error(StorageAccountConstants.StorageAccountSaveFailed);
    }
  };

  const showBackOption = () => {
    return (
      <Button
        variant="contained"
        className={classes.button}
        startIcon={<Cancel />}
        onClick={handleCancel}
      >
        Back{' '}
      </Button>
    );
  };

  const showOpsOption = (
    handleOperation: React.MouseEventHandler<HTMLButtonElement>,
  ) => {
    return (
      <Button
        variant="contained"
        className={classes.button}
        startIcon={<Save />}
        onClick={handleOperation}
      >
        Save{' '}
      </Button>
    );
  };

  return (
    <div>
      <Paper>
        <Toolbar className={classes.root}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              Storage Account Operations
            </Typography>
          </div>
        </Toolbar>
        <StorageAccountFarm
          storageAccount={storageAccount}
          setStorageAccount={setStorageAccount}
          AddOrEditStorageAccount={AddOrEditStorageAccount}
          showOperationbutton={showOpsOption}
          showBackbutton={showBackOption}
          operation={Operation}
        />
      </Paper>
    </div>
  );
}
