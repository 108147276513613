import { Button, InputBase, Toolbar } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import React, { ChangeEvent, useContext } from 'react';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import { PermissionsContext } from '../../UIContainer/PermissionsWrapper';
import PermissionConstants from '../../../Common/Constants/PermissionConstants';
import { Link } from 'react-router-dom';
import { SqlServerHeaderToolbarProps } from '../Model/SqlServerProps';

const SqlServerHeaderToolbar = (props: SqlServerHeaderToolbarProps) => {
  const permissions = useContext(PermissionsContext);

  const hasSqlServerWritePermission = () =>
    permissions[PermissionConstants.SqlServerWriter] ? true : false;
  const { onFilterRequest } = props;
  const createFilterHandler = () => (event: ChangeEvent<HTMLInputElement>) => {
    onFilterRequest(event);
  };

  const classes = useToolbarStyles();
  return (
    <Toolbar className={classes.root}>
      <div className={classes.spacer} />
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'Search' }}
          onChange={createFilterHandler()}
        />
      </div>
      {hasSqlServerWritePermission() && (
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<AddIcon />}
          component={Link}
          to={'/sqlserver/create'}
        >
          Add
        </Button>
      )}
    </Toolbar>
  );
};

export default SqlServerHeaderToolbar;
