import React from 'react';
import { EnhancedTableProps } from '../Model/CustomerProps';
import { Customer } from '../Model/CustomerModel';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { TableHeader } from '../../../Common/CommonModel';

const CustomerheadRows: TableHeader<Customer>[] = [
  {
    id: 'CustomerName',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    enableSorting: true,
  },
  {
    id: 'Id',
    numeric: false,
    disablePadding: false,
    label: 'Customer ID',
    enableSorting: true,
  },
  {
    id: 'Subdomain',
    numeric: false,
    disablePadding: false,
    label: 'Sub Domain',
    enableSorting: true,
  },
  {
    id: 'StateID',
    numeric: false,
    disablePadding: false,
    label: 'State',
    enableSorting: false,
  },
  {
    id: 'InsertDate',
    numeric: false,
    disablePadding: false,
    label: 'Tenant Onboarded',
    enableSorting: false,
  },
  {
    id: 'DeploymentDate',
    numeric: false,
    disablePadding: false,
    label: 'Last Deployed',
    enableSorting: false,
  },
  {
    id: 'InsertDate',
    numeric: false,
    disablePadding: false,
    label: 'Created On',
    enableSorting: true,
  },
  {
    id: 'Tags',
    numeric: false,
    disablePadding: false,
    label: 'Tags',
    enableSorting: true,
  },
];

//last empty table cell is header for menu

export default function CustomerTableHead(props: Readonly<EnhancedTableProps>) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Customer) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {CustomerheadRows.map((customerrow) => (
          <TableCell
            key={customerrow.id}
            align={customerrow.numeric ? 'right' : 'left'}
            padding={customerrow.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === customerrow.id ? order : false}
          >
            {customerrow.enableSorting ? (
              <TableSortLabel
                active={orderBy === customerrow.id}
                direction={order}
                onClick={createSortHandler(customerrow.id)}
              >
                {customerrow.label}
              </TableSortLabel>
            ) : (
              <div>{customerrow.label}</div>
            )}
          </TableCell>
        ))}
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
}
