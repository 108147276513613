import React, { useState, useContext } from 'react';
import { Icon, CircularProgress, Tooltip, Fab } from '@mui/material';

import UploadTriggers from './UploadTriggers';
import {
  TriggerOptionProps,
  TriggerConstants,
  TriggerCustomerContext,
} from '../Model';
import { ConfirmationDialog } from '../../../Common/Dialog';
import { useFabStyles } from '../../../../Common/Styles/FabStyle';

export default function TriggerOptions(props: Readonly<TriggerOptionProps>) {
  const classes = useFabStyles();
  const {
    saveTriggerCallback,
    deployTriggerCallback,
    reloadTriggersCallback,
    resetTriggerCallback,
  } = props;
  const [saving, setSaving] = useState(false);
  const [deploying, setDeploying] = useState(false);

  const [showUploadWindow, setShowUploadWindow] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const { ContentChanged } = useContext(TriggerCustomerContext);

  const saveTrigger = async () => {
    setSaving(true);
    await saveTriggerCallback();
    setSaving(false);
  };

  const deployTrigger = async () => {
    setDeploying(true);
    await deployTriggerCallback();
    setDeploying(false);
  };

  const handleReset = () => {
    setConfirmationMessage(TriggerConstants.TriggerResetConfirmationMessage);
    setShowConfirmation(true);
  };

  const setConfirmationValuesToDefault = () => {
    setShowConfirmation(false);
    setConfirmationMessage('');
  };

  const reset = () => {
    resetTriggerCallback();
    setConfirmationValuesToDefault();
  };

  const openUploadWindow = () => {
    setShowUploadWindow(true);
  };

  const closeUploadWindow = () => {
    setShowUploadWindow(false);
  };

  const onUploadComplete = async () => {
    setShowUploadWindow(false);
    await reloadTriggersCallback();
  };

  return (
    <div className={classes.flexRowContainer}>
      <Tooltip title={TriggerConstants.DeployButtonTooltip}>
        <Fab
          className={classes.firstFab}
          aria-label="deploy"
          disabled={saving || deploying}
          color="primary"
          onClick={deployTrigger}
        >
          <Icon>backup</Icon>
        </Fab>
      </Tooltip>
      {deploying && (
        <CircularProgress size={68} className={classes.firstProgress} />
      )}
      <Tooltip title={TriggerConstants.SaveButtonTooltip}>
        <Fab
          className={classes.secondFab}
          aria-label="save"
          disabled={!ContentChanged || saving || deploying}
          color="primary"
          onClick={saveTrigger}
        >
          <Icon>save</Icon>
        </Fab>
      </Tooltip>
      {saving && (
        <CircularProgress size={68} className={classes.secondProgress} />
      )}
      <Tooltip title={TriggerConstants.ResetButtonTooltip}>
        <Fab
          className={classes.fourthFab}
          aria-label="reset"
          disabled={saving || deploying}
          color="secondary"
          onClick={handleReset}
        >
          <Icon>autorenew</Icon>
        </Fab>
      </Tooltip>
      <Tooltip title={TriggerConstants.UploadButtonTooltip}>
        <Fab
          className={classes.thirdFab}
          disabled={saving || deploying}
          aria-label="upload"
          color="primary"
          onClick={openUploadWindow}
        >
          <Icon>publish</Icon>
        </Fab>
      </Tooltip>
      <ConfirmationDialog
        open={showConfirmation}
        message={confirmationMessage}
        onConfirmHandler={reset}
        onCancelHandler={setConfirmationValuesToDefault}
        onCloseHandler={setConfirmationValuesToDefault}
      />
      <UploadTriggers
        open={showUploadWindow}
        onClose={closeUploadWindow}
        onUploaded={onUploadComplete}
      />
    </div>
  );
}
