import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Theme,
  Tooltip,
  Typography,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TableFooter,
  CircularProgress,
  Backdrop,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
  FormControlLabel,
  Switch,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { toast } from 'react-toastify';
import Paper from '@mui/material/Paper';
import { licenseService } from '../../Api/LicenseService';
import { Order } from '../../../Helpers/Literals';
import { getSorting, stableSort } from '../../../Helpers/UtilityMethods';
import {
  LicenseWriter,
  SaveAndPublishButtonLabelConstant,
  RetryPublishButtonTooltip,
  PublishLicenseSuccessMessage,
  PublishLicenseErrorMessage,
  DeletePurchaseOrderWarningMessage,
  DeletePurchaseOrderSuccessMessage,
  DeletePurchaseOrderErrorMessage,
  LicenseUsageFooterConstant,
  HomeCurrentUsageTooltip,
  HomeTotalPurchasedTooltip,
  ExpiredPurchaseOrdersSwitchLabel,
  ExpiredPurchaseOrdersSwitchShowToolTip,
  ExpiredPurchaseOrdersSwitchHideToolTip,
  LicenseLogViewTooltip,
  LicenseLogLabel,
  PurchaseOrderTitle,
  PurchaseOrderDeleteTootltipDisabled,
  PurchaseOrderDeleteTootltip,
  ExpiredPurchaseOrderTooltip,
} from '../../Dashboard/Model/LicenseConstants';
import { LicenseRouterProps } from '../../Dashboard/Model/LicenseModel';
import {
  PurchaseOrder,
  LicenseUsage,
  LicenseType,
} from '../Model/PurchaseOrderModel';
import PurchaseTableHeader from '../Views/Components/PurchaseTableHeader';
import Delete from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import TableChartIcon from '@mui/icons-material/TableChart';
import { lighten } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
    },
    container: {
      maxHeight: '65vh !important',
    },
    title: {
      '& h6#tableTitle': {
        width: 'max-content',
      },
    },
    messageText: {
      color: 'gray',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%);',
    },
    tableRow: {
      '& .MuiTableCell-root': {
        padding: '12px',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.10) !important',
      },
    },
    tableFooterRow: {
      position: 'fixed',
      width: 'auto',
      zIndex: 1,
      cursor: 'pointer',
      '& td.MuiTableCell-footer': {
        fontWeight: 600,
        fontSize: '15px',
      },
    },
    progress: {
      marginLeft: '50%',
      marginTop: '15%',
    },
    zebra: {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
    hideCell: {
      display: 'none',
    },
    showCell: {
      display: 'table-cell',
    },
    spaceBetween: {
      flex: '1 1 100%',
    },
    refreshLicenseButton: {
      flex: '1',
      display: 'flex',
      width: 'max-content',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    highlight: {
      marginRight: 20,
      paddingRight: 10,
      color: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: lighten(theme.palette.common.black, 0.95),
      height: 35,
      width: 'max-content',
    },
    actionButtons: {
      padding: '5px',
      lineHeight: '16px',
      fontSize: '13px',
      height: '40px',
      margin: '-1px 5px',
      minWidth: '115px',
    },
    buttonIcon: {
      lineHeight: '14px',
      fontSize: '16px',
      marginRight: '3px',
    },
    dialogActionButton: {
      '& .MuiButton-root.MuiButton-textPrimary': {
        color: theme.palette.primary.light,
      },
    },
  }),
);

export default function PurchaseOrdersPage(props: LicenseRouterProps) {
  const { tenantId } = props.match.params;
  const classes = useStyles();
  const history = useHistory();
  const initialPurchaseState: PurchaseOrder[] = [];
  const initialLicenseUsage: LicenseUsage = {
    StandardUsersLicenses: 0,
    StandardUsersLicensesUsage: 0,
    AdminUsersLicenses: 0,
    AdminUsersLicensesUsage: 0,
    ReadUsersLicenses: 0,
    ReadUsersLicensesUsage: 0,
    StorageLicenses: 0,
    StorageLicensesUsage: 0,
  };

  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof PurchaseOrder>('IssuedOn');
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [allPurchaseOrders, setAllPurchaseOrders] =
    useState(initialPurchaseState);
  const [purchaseOrders, setPurchaseOrders] = useState(initialPurchaseState);
  const [loading, setLoadingState] = useState(true);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [hasWritePermission, setWritePermissions] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [customerId, setcustomerId] = useState('');
  const [purchaseOrderName, setpurchaseOrderName] = useState('');
  const [customerName, setCustomerName] = useState('');
  const [licenseusage, setLicenseusage] = useState(initialLicenseUsage);
  const [showExpiredOrders, setShowExpiredOrders] = useState(false);
  const [disablePublishButton, setDisablePublishButton] = useState(false);

  useEffect(() => {
    if (loading || !allPurchaseOrders || allPurchaseOrders.length === 0) return;
    const purchaseOrdersCollection =
      filterExpiredPurchaseOrders(allPurchaseOrders);
    setPurchaseOrders(purchaseOrdersCollection);
    setDisablePublishButton(
      purchaseOrdersCollection.every((order) => order.Published),
    );
  }, [showExpiredOrders]);

  useEffect(() => {
    if (loading) {
      licenseService
        .getPurchaseOrdersbyTenantId(tenantId)
        .then((purchaseReceived) => {
          licenseService
            .getLicenseUsageSummary(tenantId)
            .then((licenseUsageReceived) => {
              setLicenseusage(licenseUsageReceived);
            });

          if (purchaseReceived.TotalCount !== 0) {
            setAllPurchaseOrders(purchaseReceived.Items);
            const filteredPurchaseOrders = filterExpiredPurchaseOrders(
              purchaseReceived.Items,
            );
            setPurchaseOrders(filteredPurchaseOrders);
            setDisablePublishButton(
              filteredPurchaseOrders.every((order) => order.Published),
            );
          }

          purchaseReceived.Items.forEach((purchaseInfo) => {
            getLicenseInfo(purchaseInfo);
          });

          const userPermissions = JSON.parse(
            localStorage.getItem('userPermissions') ?? '{}',
          );

          for (const element of userPermissions) {
            if (element.PermissionName === LicenseWriter) {
              setWritePermissions(true);
              break;
            }
          }
          setLoadingState(false);
        });
    }
    fixedTableFooter();

    const customername = localStorage.getItem('customerName') || '';
    setCustomerName(JSON.parse(customername));
    window.addEventListener('resize', function () {
      fixedTableFooter();
    });
  }, [loading]);

  const filterExpiredPurchaseOrders = (
    purchaseOrdersCollection: PurchaseOrder[],
  ): PurchaseOrder[] => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return showExpiredOrders
      ? purchaseOrdersCollection
      : purchaseOrdersCollection.filter(
          (order) => new Date(order.ValidTo).valueOf() >= currentDate.valueOf(),
        );
  };

  const IsExpiredDateString = (validToDate: string): boolean => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    return new Date(validToDate).valueOf() < currentDate.valueOf();
  };

  const getLicenseInfo = (purchaseInfo: PurchaseOrder) => {
    purchaseInfo['AdminUsersLicenses'] = 0;
    purchaseInfo['StandardUsersLicenses'] = 0;
    purchaseInfo['ReadUsersLicenses'] = 0;
    purchaseInfo['StorageLicenses'] = 0;

    for (const licenseinfo of purchaseInfo.Licenses) {
      switch (licenseinfo.LicenseTypeId) {
        case LicenseType.StandardUsers:
          purchaseInfo['StandardUsersLicenses'] =
            licenseinfo.Count === null ? 0 : licenseinfo.Count;
          break;

        case LicenseType.AdministratorUsers:
          purchaseInfo['AdminUsersLicenses'] =
            licenseinfo.Count === null ? 0 : licenseinfo.Count;
          break;

        case LicenseType.ReadUsers:
          purchaseInfo['ReadUsersLicenses'] =
            licenseinfo.Count === null ? 0 : licenseinfo.Count;
          break;

        case LicenseType.FileStorage:
          purchaseInfo['StorageLicenses'] =
            licenseinfo.Count === null ? 0 : licenseinfo.Count;
          break;

        default:
          break;
      }
    }
  };

  function fixedTableFooter() {
    const elem = document.querySelector('.MuiTablePagination-root');
    const footerRow = document.getElementById('licenseUsageFooter');
    if (elem && footerRow) {
      const rect = elem.getBoundingClientRect();
      footerRow.style.top = rect.top - 10 + 'px';
    }

    const tableHeader = document.getElementsByClassName(
      'theadcell',
    ) as HTMLCollectionOf<HTMLElement>;
    const tableFooter = document.getElementsByClassName(
      'tfootcell',
    ) as HTMLCollectionOf<HTMLElement>;

    for (let index = 0; index < tableHeader.length; index++) {
      const tableHead = tableHeader[index];
      const tableFoot = tableFooter[index];

      if (typeof tableFoot === 'object') {
        tableFoot.style.width = tableHead.offsetWidth + 'px';
        tableFoot.style.borderBottom = 'none';
      }
    }
  }

  function handleRequestSort(
    event: React.MouseEvent<unknown>,
    property: keyof PurchaseOrder,
  ) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  const handleDeletePurchase = (
    tenantId: string,
    purchaseOrderName: string,
  ) => {
    setOpenDialog(true);
    setDialogMessage(purchaseOrderName + DeletePurchaseOrderWarningMessage);
    setcustomerId(tenantId);
    setpurchaseOrderName(purchaseOrderName);
  };

  const handleViewLogs = (tenantId: string) => {
    history.push(`/logs/${tenantId}`);
  };

  const deletePurchaseOrder = (tenantId: string, purchaseOrderName: string) => {
    setOpenBackDrop(true);
    licenseService
      .deletePurchaseOrder(tenantId, purchaseOrderName)
      .then(() => {
        setOpenBackDrop(false);
        toast.success(purchaseOrderName + DeletePurchaseOrderSuccessMessage);
        setLoadingState(true);

        handlePublish();
      })
      .catch(() => {
        setOpenBackDrop(false);
        toast.error(DeletePurchaseOrderErrorMessage + purchaseOrderName);
      });
  };

  const createTableFooter = (): ReactElement => {
    return (
      <TableRow>
        <TableCell className="tfootcell">
          {LicenseUsageFooterConstant}
        </TableCell>
        <TableCell
          align="center"
          className={`tfootcell ${
            licenseusage.StandardUsersLicensesUsage +
              licenseusage.AdminUsersLicensesUsage >
              licenseusage.StandardUsersLicenses && 'highlightCell'
          }`}
        >
          {createLicenseTableCellWithTooltip(
            licenseusage.StandardUsersLicensesUsage +
              licenseusage.AdminUsersLicensesUsage,
            licenseusage.StandardUsersLicenses,
          )}
        </TableCell>
        <TableCell
          align="center"
          className={`tfootcell ${
            licenseusage.ReadUsersLicensesUsage >
              licenseusage.ReadUsersLicenses && 'highlightCell'
          }`}
        >
          {createLicenseTableCellWithTooltip(
            licenseusage.ReadUsersLicensesUsage,
            licenseusage.ReadUsersLicenses,
          )}
        </TableCell>
        <TableCell
          align="center"
          className={`tfootcell ${
            licenseusage.StorageLicensesUsage > licenseusage.StorageLicenses &&
            'highlightCell'
          }`}
        >
          {createStorageLicenseTableCellWithTooltip(
            licenseusage.StorageLicensesUsage,
            licenseusage.StorageLicenses,
          )}
        </TableCell>
      </TableRow>
    );
  };

  const createLicenseTableCellWithTooltip = (
    usageCount: number,
    totalLicenseCount: number,
  ): ReactElement => {
    return (
      <Tooltip
        title={
          <div>
            <span>
              {HomeCurrentUsageTooltip}
              {usageCount}
            </span>
            <br />
            <span>
              {HomeTotalPurchasedTooltip}
              {totalLicenseCount}
            </span>
          </div>
        }
      >
        <span>
          {usageCount}/{totalLicenseCount}
        </span>
      </Tooltip>
    );
  };

  const createStorageLicenseTableCellWithTooltip = (
    usageCount: number,
    totalLicenseCount: number,
  ): ReactElement => {
    return (
      <Tooltip
        title={
          <div>
            <span>
              {HomeCurrentUsageTooltip}
              {usageCount}
              {' GB'}
            </span>
            <br />
            <span>
              {HomeTotalPurchasedTooltip}
              {totalLicenseCount}
              {' GB'}
            </span>
          </div>
        }
      >
        <span>
          {usageCount}/{totalLicenseCount}
        </span>
      </Tooltip>
    );
  };

  const handlePublish = () => {
    setOpenBackDrop(true);
    licenseService
      .publishLicense(tenantId)
      .then((response) => {
        setOpenBackDrop(false);
        if (response) {
          setLoadingState(true);
          toast.success(PublishLicenseSuccessMessage);
        } else toastPublishError();
      })
      .catch(() => {
        setOpenBackDrop(false);
        toastPublishError();
      });
  };

  const toastPublishError = () => {
    toast.error(PublishLicenseErrorMessage);
  };

  const handleDialogClose = (confirmed: boolean) => {
    setOpenDialog(false);
    setDialogMessage('');
    if (confirmed) {
      deletePurchaseOrder(customerId, purchaseOrderName);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    window.setTimeout(function () {
      fixedTableFooter();
    }, 0);
  };

  const handleExpiredOrders = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowExpiredOrders(event.target.checked);
    window.setTimeout(function () {
      fixedTableFooter();
    }, 0);
  };

  const createValidToTableCellWithTooltip = (validTo: string): ReactElement => {
    const isExpired = IsExpiredDateString(validTo);

    return (
      <Tooltip title={isExpired ? ExpiredPurchaseOrderTooltip : ''}>
        <TableCell align="center" className={isExpired ? 'highlightCell' : ''}>
          {validTo.slice(0, validTo.indexOf('T'))}
        </TableCell>
      </Tooltip>
    );
  };

  return (
    <div className={classes.root}>
      {loading ? (
        <CircularProgress className={classes.progress} />
      ) : (
        <>
          {allPurchaseOrders.length > 0 ? (
            <div>
              <Toolbar disableGutters={true}>
                <div className={classes.title}>
                  <Typography variant="h6" id="tableTitle">
                    {PurchaseOrderTitle}{' '}
                    {allPurchaseOrders[0].CustomerName.toUpperCase()}
                  </Typography>
                </div>
                <div className={classes.spaceBetween}></div>
                <div className={classes.refreshLicenseButton}>
                  <Tooltip
                    title={
                      showExpiredOrders
                        ? ExpiredPurchaseOrdersSwitchHideToolTip
                        : ExpiredPurchaseOrdersSwitchShowToolTip
                    }
                  >
                    <FormControlLabel
                      label={ExpiredPurchaseOrdersSwitchLabel}
                      className={`${classes.title} ${classes.highlight}`}
                      control={
                        <Switch
                          checked={showExpiredOrders}
                          onChange={handleExpiredOrders}
                          color="primary"
                        />
                      }
                    />
                  </Tooltip>
                  <div
                    className={
                      hasWritePermission ? classes.showCell : classes.hideCell
                    }
                  >
                    <Tooltip title={RetryPublishButtonTooltip}>
                      <Button
                        className={classes.actionButtons}
                        variant="contained"
                        color="primary"
                        disabled={disablePublishButton}
                        onClick={() => handlePublish()}
                      >
                        <PublishIcon className={classes.buttonIcon} />{' '}
                        {SaveAndPublishButtonLabelConstant}
                      </Button>
                    </Tooltip>
                  </div>
                  <Tooltip title={LicenseLogViewTooltip}>
                    <Button
                      className={classes.actionButtons}
                      variant="contained"
                      color="primary"
                      onClick={() => handleViewLogs(tenantId)}
                    >
                      <TableChartIcon className={classes.buttonIcon} />{' '}
                      {LicenseLogLabel}
                    </Button>
                  </Tooltip>
                </div>
              </Toolbar>
              <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <PurchaseTableHeader
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      hasWritePermission={hasWritePermission}
                    />
                    <TableBody>
                      {stableSort(purchaseOrders, getSorting(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((purchaseInfo, index) => {
                          return (
                            <TableRow
                              hover
                              key={purchaseInfo.Id.toString()}
                              className={`${classes.tableRow} ${
                                index % 2 === 0 && classes.zebra
                              }`}
                            >
                              <TableCell>
                                {purchaseInfo.Name.toLocaleString()}
                              </TableCell>
                              <TableCell align="center">
                                {purchaseInfo.StandardUsersLicenses.toLocaleString()}
                              </TableCell>
                              <TableCell align="center">
                                {purchaseInfo.ReadUsersLicenses.toString()}
                              </TableCell>
                              <TableCell align="center">
                                {purchaseInfo.StorageLicenses.toString()}
                              </TableCell>
                              <TableCell align="center">
                                {purchaseInfo.IssuedOn.toString().slice(
                                  0,
                                  purchaseInfo.IssuedOn.toString().indexOf('T'),
                                )}
                              </TableCell>
                              <TableCell align="center">
                                {purchaseInfo.ValidFrom.toString().slice(
                                  0,
                                  purchaseInfo.ValidFrom.toString().indexOf(
                                    'T',
                                  ),
                                )}
                              </TableCell>
                              {createValidToTableCellWithTooltip(
                                purchaseInfo.ValidTo.toString(),
                              )}
                              <TableCell align="center">
                                {purchaseInfo.Published ? 'Yes' : 'No'}
                              </TableCell>
                              <TableCell
                                align="center"
                                className={
                                  hasWritePermission
                                    ? classes.showCell
                                    : classes.hideCell
                                }
                              >
                                <Tooltip
                                  title={
                                    purchaseInfo.Voided
                                      ? PurchaseOrderDeleteTootltipDisabled
                                      : PurchaseOrderDeleteTootltip
                                  }
                                >
                                  <span>
                                    <IconButton
                                      color="inherit"
                                      disabled={
                                        purchaseInfo.Voided ? true : false
                                      }
                                      size="small"
                                      onClick={() =>
                                        handleDeletePurchase(
                                          purchaseInfo.TenantId.toString(),
                                          purchaseInfo.Name.toString(),
                                        )
                                      }
                                    >
                                      <Delete />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    <TableFooter
                      className={classes.tableFooterRow}
                      id="licenseUsageFooter"
                    >
                      {createTableFooter()}
                    </TableFooter>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={purchaseOrders.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                />

                <Dialog
                  disableEscapeKeyDown
                  maxWidth="xs"
                  open={openDialog}
                  onClose={() => handleDialogClose(false)}
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {dialogMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions className={classes.dialogActionButton}>
                    <Button
                      onClick={() => handleDialogClose(true)}
                      color="primary"
                    >
                      Confirm
                    </Button>
                    <Button
                      onClick={() => handleDialogClose(false)}
                      color="primary"
                      autoFocus
                    >
                      Cancel
                    </Button>
                  </DialogActions>
                </Dialog>

                <Backdrop className={classes.backdrop} open={openBackDrop}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </Paper>
            </div>
          ) : (
            <div>
              <Typography variant="h6" className={classes.messageText}>
                Customer {customerName} has no purchase orders to show!
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
}
