import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  Chip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import MuiTableCell from '@mui/material/TableCell';
import withStyles from '@mui/styles/withStyles';
import { CustomerV2 } from '../../Customer/Model/CustomerModel';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import { customerService } from '../../Customer/CustomerService';
import { Cancel, RestartAlt } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { tenantService } from '../Services/TenantService';
import { Tenant } from '../../Farm/Model/FarmModel';
import { TenantRestartMetaData } from '../Model/Model';
import { globalService } from '../../UIContainer/Global/GlobalService';
import { PortalConfig } from '../../UIContainer/Global/GlobalState';
import PlatformEngineeringConstants from '../Model/CommonConstants';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);
interface Param {
  customerId: string;
}
export default function RestartTenant() {
  const params = useParams<Param>();
  const initialCustomerState: CustomerV2 = {};
  const TenantRestartMetaData: TenantRestartMetaData = {
    ResourceGroupName: '',
    TenantName: '',
    Reason: '',
  };
  const [customerData, setCustomer] = useState(initialCustomerState);
  const initialTenantData: Tenant = {
    Id: '',
    FarmId: 0,
    ApplicationUrl: '',
    StatusID: 0,
    AppDBName: '',
    AppservicePlan: '',
  };
  const [reason, setReason] = useState('');
  const [portalConfig, setPortalConfig] = useState<PortalConfig>();
  const [tenant, setTenant] = useState(initialTenantData);
  const classes = useToolbarStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [tenantStatus, setTenantStatus] = useState('Fetching');
  const [CanCheckStatus, setCanCheckStatus] = useState(false);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReason(event.target.value);
  };

  const loadTenantData = async () => {
    try {
      customerService
        .getCustomer(params.customerId)
        .then((customerData) => setCustomer(customerData));
      globalService.getPortalConfig().then((config) => setPortalConfig(config));
      tenantService
        .GetTenant(params.customerId)
        .then((tenantData) => setTenant(tenantData));
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const GetTenantRunningStatus = async () => {
    try {
      setCanCheckStatus(false);
      setTenantStatus('Fetching');

      const PromiseTimeout = new Promise((resolve) =>
        setTimeout(() => resolve(false), 5000),
      );

      const response = await Promise.race([
        PromiseTimeout,
        tenantService.GetTenantRunningStatus(params.customerId),
      ]);

      if (!response) {
        setTenantStatus('Not Running');
      } else {
        setTenantStatus(response as string);
        setCanCheckStatus(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetResourceGroup = (
    portalConfig: PortalConfig | undefined,
    FarmId: number,
  ) => {
    return (portalConfig?.Environment === 'Dev' ? 'DevFarm' : 'Farm') + FarmId;
  };

  const handleRestart = () => {
    setCanCheckStatus(false);
    if (reason === '') {
      toast.error(PlatformEngineeringConstants.ReasonAlert);
    } else {
      TenantRestartMetaData.TenantName = customerData.Subdomain ?? '';
      TenantRestartMetaData.ResourceGroupName = GetResourceGroup(
        portalConfig,
        tenant.FarmId,
      );
      TenantRestartMetaData.Reason = reason;
      setLoading(true);
      tenantService
        .RestartTenant(params.customerId, TenantRestartMetaData)
        .then(() => {
          setLoading(false);
          toast.success(PlatformEngineeringConstants.RestartQueued);
          setCanCheckStatus(true);
        });
    }
  };

  const handleClose = () => {
    history.push('/customer');
  };

  useEffect(() => {
    loadTenantData();
    GetTenantRunningStatus();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (CanCheckStatus) {
        GetTenantRunningStatus();
      }
    }, 10000);

    return () => clearInterval(intervalId);
  }, [CanCheckStatus]);

  const showStatus = (Status: string) => {
    if (Status === 'Running') {
      return <Chip color="success" label="Running" size="small"></Chip>;
    } else if (Status === 'Fetching') {
      return <Chip color="warning" label="Fetching" size="small"></Chip>;
    } else {
      return <Chip color="error" label="Not Running" size="small"></Chip>;
    }
  };

  return (
    <div>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Paper>
          <Toolbar className={classes.root}>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Tenant Operations
              </Typography>
            </div>
          </Toolbar>
          <Card elevation={2} style={{ padding: 15, margin: 15 }}>
            <Typography variant="h6">Restart Tenant</Typography>
            <Table>
              <TableRow>
                <TableCell>Tenant ID</TableCell>
                <TableCell>{customerData.Id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Resource Group</TableCell>
                <TableCell>
                  {GetResourceGroup(portalConfig, tenant.FarmId)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Customer</TableCell>
                <TableCell>{customerData.Subdomain}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>{showStatus(tenantStatus)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reason for Restarting</TableCell>
                <TableCell>
                  <TextField
                    required={true}
                    id="Reason"
                    label="Reason"
                    variant="outlined"
                    onChange={onChange}
                    value={reason}
                  />
                </TableCell>
              </TableRow>
            </Table>
          </Card>
          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleRestart}
              startIcon={<RestartAlt />}
            >
              Restart{' '}
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.button}
              startIcon={<Cancel />}
            >
              Back{' '}
            </Button>
          </Paper>
        </Paper>
      )}
    </div>
  );
}
