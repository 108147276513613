import React from 'react';
import Typography from '@mui/material/Typography';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { getStandardDateFormat } from '../../../../Helpers/UtilityMethods';

const ResponsibilityCalendar = ({ userSelectionArray }) => {
  const getBackgroundColor = (date) => {
    for (const user of userSelectionArray) {
      const startDate = getStandardDateFormat(new DateObject(user.startDate));
      const endDate = getStandardDateFormat(new DateObject(user.endDate));
      const currentDate = getStandardDateFormat(date);
      if (startDate <= currentDate && currentDate <= endDate) {
        return { backgroundColor: user.color, title: user.name };
      }
    }
    return {};
  };
  return (
    <div>
      <Typography variant="subtitle2" gutterBottom>
        Responsibility calendar:
      </Typography>
      <Calendar
        numberOfMonths={2}
        multiple
        disabled
        weekStartDayIndex={1}
        mapDays={({ date }) => {
          const { backgroundColor, title } = getBackgroundColor(date);
          if (backgroundColor) {
            return {
              style: { backgroundColor },
              title,
            };
          }
        }}
      />
    </div>
  );
};
export default ResponsibilityCalendar;
