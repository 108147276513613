import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import {
  EnhancedTableProps,
  AllTenantMetricsData,
} from '../../Model/MetricsModel';

export default function MetricsTableHead(props: Readonly<EnhancedTableProps>) {
  const { order, orderBy, onRequestSort, headerRows, typeHeaderRows } = props;
  const createSortHandler =
    (property: keyof AllTenantMetricsData) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {typeHeaderRows.map((row) => (
          <TableCell align="left" colSpan={row.colSpan} key={row.id}>
            {row.label}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        {headerRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.numeric ? 'right' : 'left'}
            padding={row.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === row.id ? order : false}
          >
            {row.enableSorting ? (
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            ) : (
              <div>{row.label}</div>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
