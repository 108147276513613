import { Button, Divider, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import { useHistory, useParams } from 'react-router-dom';
import { RegisterTenant, TenantForm } from './Model/TenantModels';
import { customerService } from '../Customer/CustomerService';
import { toast } from 'react-toastify';
import { storageAccountService } from '../StorageAccount/StorageAccountService';
import { StorageAccount } from '../StorageAccount/Model/StorageAccountModel';
import { farmService } from '../ManageFarms/FarmService';
import {
  RemoveDuplicateAppserviceplans,
  createNewAppServicePlan,
} from '../../../Common/CommonMethods';
import { plans } from '../../Common/Constants/ValueConstants';
import TenantRegistrationForm from './Components/TenantRegistrationForm';
import { ValidationErrors } from 'fluentvalidation-ts';
import { Loader } from '../../../Common/Components/Loader';
import { useStyles } from '../../../Common/Styles/WraperStyle';
import { sqlServerService } from '../SqlServer/SqlServerService';
import { SqlServer } from '../SqlServer/Model/SqlServerModel';

interface Param {
  customerId: string;
}
export default function TenantRegistration() {
  const params = useParams<Param>();
  const classes = useStyles();
  const history = useHistory();
  const [registerTenant, setRegisterTenant] = useState<RegisterTenant>();
  const [FarmId, setFarmId] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [storageAccounts, setStorageAccounts] = useState<StorageAccount[]>([]);
  const [sqlServers, setSqlServers] = useState<SqlServer[]>([]);
  const [replicaServers, setReplicaServers] = useState<SqlServer[]>([]);
  const [sqlServer, setSqlserver] = useState('');
  const [openAppserviceDialogue, setOpenAppserviceDialogue] = useState(false);
  const [storageAccount, setStorageAccount] = useState('');
  const [appserviceplans, setAppserviceplans] = useState<string[]>([]);
  const [replicas, setreplicas] = useState<string[]>([]);
  const [appserviceplan, setAppserviceplan] = useState('');
  const [selectedValue, setSelectedValue] = useState(plans[0]);
  const [tenantErrors, setTenantErrors] = useState<
    ValidationErrors<TenantForm>
  >({});

  const loadRegisterTenant = async () => {
    await customerService.getRegisterTenant(params.customerId).then((data) => {
      setRegisterTenant(data);
      setLoading(false);
    });
  };

  const addItemToState = (item: string[]) => {
    const newItem = createNewAppServicePlan(
      appserviceplans,
      item,
      Number(FarmId),
    );
    const appAppservicePlans = appserviceplans.concat(appserviceplans, newItem);
    setAppserviceplans(RemoveDuplicateAppserviceplans(appAppservicePlans));
  };

  const handleCloseAppservicePlan = (value: string) => {
    setOpenAppserviceDialogue(false);
    if ('Not Selected' !== value) {
      setSelectedValue(value);
      const appserviceplan: string[] = [];
      appserviceplan.push(value);
      addItemToState(appserviceplan);
    }
  };

  const loadStorageAccounts = async () => {
    try {
      await storageAccountService
        .getstorageAccounts()
        .then((storageAccounts) => {
          setStorageAccounts(
            storageAccounts.Items.filter(
              (sa) => sa.Purpose.toLowerCase() === 'archive',
            ),
          );
        });
    } catch (error) {
      console.log(error);
    }
  };
  const loadSqlServers = async () => {
    try {
      await sqlServerService.getSqlServers().then((sqlservers) => {
        setSqlServers(sqlservers.Items);
        setReplicaServers(sqlservers.Items);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handlRegister = async () => {
    if (
      FarmId !== undefined &&
      storageAccount !== '' &&
      appserviceplan !== ''
    ) {
      setLoading(true);
      const baseTenant: TenantForm = {
        AppservicePlan: appserviceplan,
        CustomerId: params.customerId,
        FarmId: FarmId,
        Replicas: replicas,
        SqlServer: sqlServer,
        StorageAccount: storageAccount,
      };
      await customerService.RegisterTenant(baseTenant).then(() => {
        toast.success('Tenant Registered Successfully!');
        setLoading(false);
      });
      history.push('/customer');
    } else {
      toast.error('All fields are required');
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setFarmId(value);
    const farmId = Number(value);
    loadAppserviceplans(farmId);
    setStorageAccounts(
      storageAccounts.filter(
        (sa) =>
          sa.AzureRegion ===
          registerTenant?.Farms.find((farm) => farm.Id === farmId)?.FarmRegion,
      ),
    );
    setStorageAccount('');
    setSqlServers(
      sqlServers.filter(
        (server) =>
          server.Region ===
          registerTenant?.Farms.find((farm) => farm.Id === farmId)?.FarmRegion,
      ),
    );
    setSqlserver('');
  };
  const handleClose = () => {
    history.push('/customer');
  };

  const handleReplicaChange = (values: string[]) => {
    let duplicatesRemoved: string[] = [];

    values.forEach((item) => {
      if (duplicatesRemoved.findIndex((o) => o === item) >= 0) {
        duplicatesRemoved = duplicatesRemoved.filter((x) => x === item);
      } else {
        duplicatesRemoved.push(item);
      }
    });

    if (!(duplicatesRemoved.length > 3)) {
      setreplicas(duplicatesRemoved);
    } else {
      toast.error(
        'More than 3 replicas are not allowed. please unselect any other',
      );
    }
  };

  const loadAppserviceplans = (farmId: number) => {
    farmService.getAppservicePlans(farmId).then((appserviceplans) => {
      setAppserviceplans(appserviceplans);
    });
  };

  const handleClickOpen = () => {
    setOpenAppserviceDialogue(true);
  };

  useEffect(() => {
    loadRegisterTenant().catch((err) => console.log(err));
    loadStorageAccounts().catch((err) => console.log(err));
    loadSqlServers();
  }, []);
  return (
    <>
      <Loader loading={loading} />
      <div>
        <Paper>
          <Typography variant="h5" className={classes.title}>
            Register Tenant
          </Typography>
          <Divider className={classes.divider} />
          <TenantRegistrationForm
            FarmId={FarmId ?? ''}
            appserviceplan={appserviceplan}
            setAppserviceplan={setAppserviceplan}
            appserviceplans={appserviceplans}
            customerId={params.customerId}
            storageAccount={storageAccount}
            setStorageAccount={setStorageAccount}
            storageAccounts={storageAccounts}
            sqlServers={sqlServers}
            sqlServer={sqlServer}
            replicaServers={replicaServers}
            setSqlServer={setSqlserver}
            handleChange={handleChange}
            handleClickOpen={handleClickOpen}
            registerTenant={registerTenant}
            handleCloseAppservicePlan={handleCloseAppservicePlan}
            openAppserviceDialogue={openAppserviceDialogue}
            selectedValue={selectedValue}
            setOpenAppserviceDialogue={setOpenAppserviceDialogue}
            handleReplicaSelection={handleReplicaChange}
            replicas={replicas}
            errors={tenantErrors}
            setErrors={setTenantErrors}
          />
          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handlRegister}
              startIcon={<SaveIcon />}
            >
              Save{' '}
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.button}
              startIcon={<Cancel />}
            >
              Cancel{' '}
            </Button>
          </Paper>
        </Paper>
      </div>
    </>
  );
}
