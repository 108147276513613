import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import { healthService } from '../Services/HealthService';
import {
  TenantHealthStatus,
  Props,
  TenantHealth,
  FarmHealth,
} from '../Model/HealthModels';
import TenantInsights from './TenantInsights';
import { useSelfStyles, useStyles } from '../Common/CommonStyle';
import { Item, StatusIcon, TableCell } from '../Common/CommonMethods';
import { Cancel } from '@mui/icons-material';
import { Farm } from '../../Farm/Model/FarmModel';
import { useToolbarStyles } from '../../ClientSecrete/CommonMethods/ClientSecretCommonMethods';
import { farmService } from '../../Farm/FarmService';
import { FarmHealthSkeleton } from './Skeletons/TenantSkeleton';

const handleRowSelection = async (
  tenant: TenantHealth,
  setTenants: React.Dispatch<React.SetStateAction<TenantHealthStatus[]>>,
  showDialogue: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  showDialogue(true);
  healthService.GetTenantHealth(tenant.Id).then((data) => {
    setTenants(data.Components);
  });
};

const handleClose = (
  setTenants: React.Dispatch<React.SetStateAction<TenantHealthStatus[]>>,
  showDialogue: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  setTenants([]);
  showDialogue(false);
};

const TenantHealthPage = ({ Product, setDropDown }: Props) => {
  const [tenants, setTenants] = React.useState<TenantHealthStatus[]>([]);
  const [farms, setFarms] = React.useState<Farm[]>([]);
  const [farmbatches, setFarmBatches] = React.useState<FarmHealth[]>([]);
  const classes = useStyles();
  const styles = useSelfStyles();
  const [showDialogue, setShowDialogue] = useState(false);
  const theme = useToolbarStyles();

  React.useEffect(() => {
    Initialize();
    loadActiveTenantsAndTenantHealth();
    const intervalId = setInterval(() => {
      loadActiveTenantsAndTenantHealth();
    }, 120000);

    return () => clearInterval(intervalId);
  }, [Product]);

  const Initialize = () => {
    setTenants([]);
    setFarms([]);
    setFarmBatches([]);
  };

  const loadActiveTenantsAndTenantHealth = async () => {
    try {
      const farms = await farmService.getFarmsByProduct(Product);
      setFarms(farms.Items);
      setFarmBatches([]);
      for (const farm of farms.Items) {
        const tenants = await farmService.getMoniteredTenantsOnFarm(farm.Id);
        const tenantsHealth = await Promise.all(
          tenants.map((tenant) =>
            healthService.GetTenantHealthStatus(tenant.Id),
          ),
        );
        const farmHealth = {
          FarmName: farm.DisplayName,
          Id: farm.Id,
          TenantsHealth: tenantsHealth,
        };
        setFarmBatches((prevBatches) => [...prevBatches, farmHealth]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getNumberofSkeleton = () => {
    const remain = farms.length - farmbatches.length;
    if (remain === 0) {
      setDropDown(true);
    }
    return remain;
  };

  return (
    <Paper elevation={0}>
      {farmbatches.map(
        (farmHealth: FarmHealth, farmIndex: React.Key | null | undefined) => (
          <Paper
            className={styles.WrapperPaper}
            elevation={0}
            key={farmIndex?.toString()}
          >
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <TableHead>
                <TableRow className={classes.TableRow}>
                  <TableCell colSpan={2}>
                    <MonitorHeartIcon
                      color="primary"
                      fontSize="medium"
                      className={classes.HealthHeaderIcon}
                    />
                    FARM HEALTH
                  </TableCell>
                  <TableCell colSpan={1}>FARM: {farmHealth.FarmName}</TableCell>
                  <TableCell colSpan={1}>ID: {farmHealth.Id}</TableCell>
                </TableRow>
              </TableHead>
              <Divider />
              <Table>
                <TableBody className={classes.TableBody}>
                  <Box sx={{ width: '100%' }}>
                    <Grid container spacing={{ xs: 2, md: 3 }} padding={2}>
                      {farmHealth.TenantsHealth.map((tenant) => (
                        <Grid item xs={4} sm={12} md={2} key={tenant.Id}>
                          <Item>
                            <div>{tenant.Name}</div>
                            <div
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                handleRowSelection(
                                  tenant,
                                  setTenants,
                                  setShowDialogue,
                                );
                              }}
                            >
                              {StatusIcon(
                                tenant.Status,
                                tenant.ResponseTime + 's',
                              )}
                            </div>
                          </Item>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        ),
      )}
      {FarmHealthSkeleton(getNumberofSkeleton())}
      {showDialogue ? (
        <Dialog open={true} fullWidth maxWidth={'lg'}>
          <DialogTitle>Tenant Sites</DialogTitle>
          {tenants.length > 0 ? (
            <DialogContent>
              <TenantInsights Components={tenants} />
            </DialogContent>
          ) : (
            <Backdrop className={theme.backdrop} open={true}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          <Divider />
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => handleClose(setTenants, setShowDialogue)}
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        <></>
      )}
    </Paper>
  );
};
export default TenantHealthPage;
