import React, { useState } from 'react';
import { alpha } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Typography, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import { FileUploadDialogProps } from './DialogProps';

const useStyles = makeStyles((theme) => ({
  fileUpload: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '400px',
    height: '200px',
    maxHeight: '300px',
    cursor: 'pointer',
    border: 'dashed',
    borderColor: 'grey',
    borderWidth: 'thin',
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.1),
    },
  },
  text: {
    bottom: '100px',
    textAlign: 'center',
  },
  dropzone: {
    pointerEvents: 'none',
    margin: '0 auto',
    color: 'grey',
  },
  download: {
    bottom: '60px',
    width: '30px',
    height: '30px',
  },
}));

export default function FileUploadDialog(props: FileUploadDialogProps) {
  const classes = useStyles();
  const { dropzoneText, acceptedFileExtensions, onFilesAdded } = props;

  const [dragEventCounter, setDragEventCounter] = useState(0);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      updateFilesState(event.target.files);
      event.target.value = '';
    }
  };

  const dragEnterListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    setDragEventCounter(dragEventCounter + 1);
  };

  const dragExitListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    setDragEventCounter(dragEventCounter - 1);
  };

  const dropListener = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    setDragEventCounter(0);

    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      updateFilesState(event.dataTransfer.files);
    }
  };

  const overrideEventDefaults = (
    event: Event | React.DragEvent<HTMLDivElement>,
  ) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const updateFilesState = (filelist: FileList) => {
    const uploadedFiles: File[] = [];
    for (let index = 0; index < filelist.length; index++) {
      const file = filelist.item(index) as File;
      if (fileTypeAllowed(file)) {
        uploadedFiles.push(file);
      }
    }
    onFilesAdded(uploadedFiles);
  };

  const fileTypeAllowed = (file: File) => {
    const fileExtension = file.name.slice(
      ((file.name.lastIndexOf('.') - 1) >>> 0) + 2,
    );
    return (
      acceptedFileExtensions.findIndex(
        (extension) => extension === fileExtension,
      ) >= 0
    );
  };

  return (
    <div>
      <label htmlFor="upload-files">
        <div
          className={classes.fileUpload}
          onDrop={dropListener}
          onDragEnter={dragEnterListener}
          onDragLeave={dragExitListener}
        >
          <div>
            <input
              id="upload-files"
              name="upload-files"
              type="file"
              style={{ display: 'none' }}
              multiple
              onChange={onChangeHandler}
            />
          </div>
          <Typography
            variant="subtitle1"
            component="p"
            className={`${classes.text} ${classes.dropzone}`}
          >
            {dropzoneText}
          </Typography>
          {dragEventCounter ? (
            <CircularProgress
              size={30}
              className={`${classes.download} ${classes.dropzone}`}
            />
          ) : (
            <CloudUploadIcon
              className={`${classes.download} ${classes.dropzone}`}
            />
          )}
        </div>
      </label>
    </div>
  );
}
