import React, { Component } from 'react';
import OnCallOfTheWeek from '../Monitoring/Components/OnCallOfTheWeek';
import { oncalldrillService } from './OncalldrillService';
import { RouteComponentProps } from 'react-router';
import { callService } from '../../../Service/ServiceBase';
import { RequestType } from '../../../Service/RequestType';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import { Divider, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import { OnCallUser } from '../Monitoring/Model/MonitoringModel';
import { Customer } from '../Customer/Model/CustomerModel';
import { monitoringService } from '../Monitoring/MonitoringService';

export interface TriggerMatchParams {
  customerId: string;
}

export interface TriggerRouterProps
  extends RouteComponentProps<TriggerMatchParams> {}

interface OncalldrillHomeState {
  onCallUsers: OnCallUser[];
  Customer: Customer;
  loadingOnCallUser: boolean;
  onCallUserOfTheWeek: OnCallUser;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      flexGrow: 1,
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    userListWidth: {
      width: 550,
    },
    paper: {
      padding: theme.spacing(2),
      height: '100%',
      color: theme.palette.text.secondary,
    },
  });

class OncalldrillHome extends Component<
  TriggerRouterProps,
  OncalldrillHomeState
> {
  constructor(props: TriggerRouterProps) {
    super(props);
    this.state = {
      onCallUsers: [],
      loadingOnCallUser: true,
      Customer: {
        Id: '',
        CustomerName: '',
        Subdomain: '',
        StateID: 0,
        DeploymentStateID: 0,
        DeploymentDate: new Date(),
        InsertDate: new Date(),
        Tags: [],
      },
      onCallUserOfTheWeek: {
        Id: '',
        Name: '',
        EmailAddress: '',
        PhoneNumber: '',
        Sequence: 0,
      },
    };
  }

  componentDidMount() {
    this.loadattributes();
    this.getOnCallUser();
  }

  getOnCallUser = () => {
    this.setState({ loadingOnCallUser: true });
    monitoringService
      .getOnCallOfTheWeek()
      .then((user) => {
        this.setState({ onCallUserOfTheWeek: user, loadingOnCallUser: false });
      })
      .catch((err) => console.log(err.Message));
  };

  loadattributes = () => {
    try {
      const cust = this.getCustomer();
      this.setState({ Customer: cust as Customer });
    } catch (err: unknown) {
      console.log(err);
    }
    oncalldrillService
      .getUsers()
      .then((users) => {
        this.setState({ onCallUsers: users });
      })
      .catch((err) => console.log(err.message));
  };

  getCustomerSubDomain = () => {
    return this.state.Customer.Subdomain;
  };

  getCustomer() {
    return callService(
      'customers',
      this.props.match.params.customerId,
      RequestType.GET,
    );
  }

  postOncallDrillRequest() {
    return callService(
      'customers',
      this.props.match.params.customerId + '/oncalldrill',
      RequestType.PUT,
    );
  }

  postStopOncallDrill() {
    return callService(
      'customers',
      this.props.match.params.customerId + '/stoponcalldrill',
      RequestType.PUT,
    );
  }

  callOncallDrill = () => {
    try {
      this.postOncallDrillRequest();
    } catch (err) {
      console.log(err);
    }
  };

  StopOncallDrill = () => {
    try {
      this.postStopOncallDrill();
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <React.Fragment>
        <OnCallOfTheWeek
          loadingOnCallUser={this.state.loadingOnCallUser}
          onCallUserOfTheWeek={this.state.onCallUserOfTheWeek}
        />
        <br />
        <br />
        <Divider />
        <br />
        <div>
          <Typography variant="h5" component="h2">
            Customer Info:
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            TenantID: {this.props.match.params.customerId}
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            SubDomain: {this.state.Customer.Subdomain}
          </Typography>
        </div>
        <br />
        <Divider />
        <br />
        <Button color="primary" onClick={this.callOncallDrill}>
          Start On-Call Drill
        </Button>
        <br />
        <br />
        <Button color="primary" onClick={this.StopOncallDrill}>
          Stop On-Call Drill
        </Button>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(OncalldrillHome);
