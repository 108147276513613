import React, { useEffect, useState } from 'react';
import { ChartWrapperData } from '../../../../Charts/Wrapper/ChartWrapperProps';
import ChartWrapper from '../../../../Charts/Wrapper/ChartWrapper';
import makeStyles from '@mui/styles/makeStyles';
import {
  TenantMetricsProps,
  TenantMetricsData,
} from '../../Model/MetricsModel';
import { metricsService } from '../../MetricsService';
import { farmService } from '../../../Farm/FarmService';
import { Tenant } from '../../../Farm/Model/FarmModel';
import { getTenantSubDomain, convertValue } from '../../Helpers/MetricsHelpers';

const useStyles = makeStyles(() => ({
  root: {
    margin: '5px',
  },
}));

export default function OverallUserMetricsChart(props: TenantMetricsProps) {
  const { farmId, tenantId } = props;
  const classes = useStyles();

  const [chartData, setchartData] = useState<ChartWrapperData>({
    title: '',
    type: 'doughnut',
    labels: [],
    dataset: [],
    showOutsideLabel: false,
    showTooltip: true,
    showValueOnChart: true,
  });

  useEffect(() => {
    loadUserMetrics();
  }, [farmId, tenantId]);

  const loadUserMetrics = async () => {
    const tenantInfo = await farmService.getTenant(farmId, tenantId);
    const userMetrics = await metricsService.getTenantUserMetrics(
      farmId,
      tenantId,
    );
    BuildChartData(tenantInfo, userMetrics);
  };

  const BuildChartData = (
    tenantInfo: Tenant,
    userMetrics: TenantMetricsData[],
  ) => {
    const tenantSubdomain = getTenantSubDomain(tenantInfo.ApplicationUrl);
    const tenantUsersChartData: ChartWrapperData = {
      title: `Total users for tenant ${tenantSubdomain}`,
      type: 'doughnut',
      labels: [],
      dataset: [
        {
          label: tenantSubdomain,
          data: [],
          hoverOffset: 200,
        },
      ],
      showTooltip: true,
      showOutsideLabel: false,
      showValueOnChart: true,
    };
    userMetrics.forEach((metrics) => {
      tenantUsersChartData.labels.push(metrics.DisplayName);
      tenantUsersChartData.dataset[0].data.push(convertValue(metrics));
    });
    setchartData(tenantUsersChartData);
  };

  return (
    <div className={classes.root}>
      <ChartWrapper {...chartData}></ChartWrapper>
    </div>
  );
}
