import { callService } from '../../Service/ServiceBase';
import { RequestType } from '../../Service/RequestType';
import {
  LicenseSummary,
  CustomerV2,
  ModuleLicense,
  ModuleInfo,
  Order,
} from '../Dashboard/Model/LicenseModel';
import {
  PurchaseOrder,
  LicenseUsage,
} from '../PurchaseOrders/Model/PurchaseOrderModel';
import { PagedData } from '../../Components/Common/CommonModel';
import { LicenseLog } from '../LicenseLogs/Model/Log';

class LicenseService {
  getAllLicenseSummaries(): Promise<PagedData<LicenseSummary>> {
    const licenseResult = callService('licenses', 'summary', RequestType.GET);
    return licenseResult;
  }

  getPurchaseOrdersbyTenantId(
    tenantId: string,
  ): Promise<PagedData<PurchaseOrder>> {
    const licenseResult = callService(
      'purchaseorders',
      tenantId,
      RequestType.GET,
    );
    return licenseResult;
  }

  deletePurchaseOrder(
    tenantId: string,
    purchaseOrderName: string,
  ): Promise<Response> {
    const response = callService(
      'purchaseorders',
      tenantId + '/' + purchaseOrderName,
      RequestType.DELETE,
    );
    return response;
  }

  savePurchaseOrder(tenantId: string, purchaseOrder: Order): Promise<Response> {
    const response = callService(
      'purchaseorders',
      tenantId,
      RequestType.POST,
      purchaseOrder,
    );
    return response;
  }

  getLicenseUsageSummary(tenantId: string): Promise<LicenseUsage> {
    const response = callService(
      'licenses',
      tenantId + '/summary',
      RequestType.GET,
    );
    return response;
  }

  publishLicense(tenantId: string): Promise<boolean> {
    const response = callService(
      'licenses',
      `${tenantId}/publish`,
      RequestType.POST,
    );
    return response;
  }

  publishModuleLicenses(
    tenantId: string,
    moduleLicenses: ModuleLicense[],
  ): Promise<boolean> {
    const response = callService(
      'licenses',
      `${tenantId}/publish/modules`,
      RequestType.POST,
      moduleLicenses,
    );
    return response;
  }

  fetchLiveLicenseUsage(): Promise<PagedData<CustomerV2>> {
    const response = callService(
      'licenses',
      'livelicenseusage',
      RequestType.GET,
    );
    return response;
  }

  fetchLiveLicenseUsageForTenant(tenantId: string): Promise<LicenseSummary> {
    const response = callService(
      'licenses',
      `${tenantId}/livelicenseusage`,
      RequestType.GET,
    );
    return response;
  }

  getLicenseSummary(tenantId: string): Promise<LicenseSummary> {
    const licenseResult = callService(
      'licenses',
      `${tenantId}/summary`,
      RequestType.GET,
    );
    return licenseResult;
  }

  getLicenseLogs(
    page: number,
    rowsPerPage: number,
    tenantId: string,
    operation: string,
  ): Promise<PagedData<LicenseLog>> {
    const response = callService(
      'licenses',
      `logs?page=${page + 1}&pageSize=${rowsPerPage}&tenantId=${tenantId}&operation=${operation}`,
      RequestType.GET,
    );
    return response;
  }

  getModuleLicenses(tenantId: string): Promise<ModuleLicense[]> {
    const moduleLicenseResult = callService(
      'licenses',
      `${tenantId}/modules`,
      RequestType.GET,
    );
    return moduleLicenseResult;
  }

  getModuleInfo(): Promise<ModuleInfo[]> {
    const moduleInfo = callService('licenses', `moduleinfo`, RequestType.GET);
    return moduleInfo;
  }
}

export const licenseService: LicenseService = new LicenseService();
