import React, { useState, ReactElement } from 'react';

import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Menu,
  MenuItem,
} from '@mui/material';
import { AuditTrailMenuItems } from '../Model/AuditModel';
import { Dictionary } from '../../../Common/CommonModel';
import { useToolbarStyles } from '../../ClientSecrete/CommonMethods/ClientSecretCommonMethods';

interface Props {
  details: string;
  Id: number;
}

export default function AditMenu(props: Readonly<Props>) {
  const { details, Id } = props;
  const classes = useToolbarStyles();
  const [openDetailsDialogue, setOpenDetailsDialogue] = useState(false);
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [menuState, setMenuState] = useState(false);

  const handleDetailsView = () => {
    closeAuditMenu();
    setOpenDetailsDialogue(true);
  };

  const menuItems: AuditTrailMenuItems[] = [
    {
      id: 'Details',
      label: 'Details',
      ToolTip: 'Details',
      validationFunc: 'Details',
      handleFunc: handleDetailsView,
    },
  ];

  function executeValidationMethod(type: string) {
    let fn;
    const validations: Dictionary<() => boolean> = {};
    validations['Details'] = () => true;

    if (validations[type]) {
      fn = validations[type];
    } else {
      fn = validations['default'];
    }
    return fn();
  }

  const closeAuditMenu = () => {
    setMenuState(false);
    setAnchor(null);
  };

  const openAuditMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuState(true);
    setAnchor(event.currentTarget);
  };

  const Closed = () => {
    setOpenDetailsDialogue(false);
  };
  const showDetailsDialoguePage = (
    open: boolean,
    details: string,
  ): ReactElement | null => {
    if (open) {
      return (
        <Dialog disableEscapeKeyDown open={open} fullWidth maxWidth="lg">
          <DialogTitle>Audit Trail Information</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Card elevation={-2} sx={{ padding: 2 }}>
                {Object.entries(JSON.parse(details)).map(([key, value]) => (
                  <p key={key}>{`${key}=${value}`}</p>
                ))}
              </Card>
            </DialogContentText>
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              className={classes.SmallButton}
              onClick={Closed}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
    return null;
  };

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns="long-menu"
        aria-haspopup="true"
        onClick={openAuditMenu}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      {menuItems.some((menu) =>
        executeValidationMethod(menu.validationFunc),
      ) && (
        <Menu
          id="long-menu"
          anchorEl={anchor}
          open={menuState}
          onClose={closeAuditMenu}
        >
          {menuItems.map((menu) => (
            <MenuItem
              onClick={menu.handleFunc}
              id={Id.toString()}
              value={menu.label}
              key={menu.id}
            >
              {menu.label}
            </MenuItem>
          ))}
        </Menu>
      )}
      {showDetailsDialoguePage(openDetailsDialogue, details)}
    </div>
  );
}
