import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js';

import {
  ChartWrapperData,
  ChartWrapperDataSet,
  PluginsLabelData,
} from './ChartWrapperProps';
import { Card, CardContent } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

import 'chartjs-plugin-labels';

interface ChartWrapperInternalDataset extends ChartWrapperDataSet {
  borderColor: string[];
  backgroundColor: string[];
  fill: boolean;
}

const useStyles = makeStyles(() => ({
  papper: {
    minWidth: 500,
    width: 600,
  },
}));

const colors = [
  '#006E82',
  '#8214A0',
  '#FA78FA',
  '#FA7850',
  '#c1c11f',
  '#81c784',
  '#ffe082',
  '#81c784',
  '#ff8a65',
];

export default function ChartWrapper(props: ChartWrapperData) {
  const {
    title,
    type,
    showTooltip,
    showValueOnChart,
    showOutsideLabel,
    labels,
    dataset,
  } = props;

  const classes = useStyles();
  const chartContainer = useRef<HTMLCanvasElement>(null);

  const [imageData, setImageData] = useState('');

  useEffect(() => {
    if (!chartContainer.current) {
      return;
    }

    const canvas: HTMLCanvasElement = chartContainer.current;
    canvas.width = 650;
    canvas.height = 450;

    let chartData = buildInternalChartDataset();
    const labelsData = buildLabelsData();

    const chart = new Chart(canvas, {
      type: type,
      data: {
        labels: labels,
        datasets: chartData,
      },
      options: {
        tooltips: {
          enabled: showTooltip,
        },
        hover: {
          mode: 'index',
          intersect: true,
        },
        plugins: {
          labels: labelsData,
        },
        title: {
          display: true,
          text: title,
          fontSize: 20,
        },
        legend: {
          display: true,
        },
        responsive: true,
        animation: {
          onComplete: function (current) {
            setImageData(current.chart.toBase64Image());
          },
        },
        maintainAspectRatio: false, // Set to false to allow custom size
        layout: {
          padding: {
            top: 5,
            left: 15,
            right: 15,
            bottom: 15,
          },
        },
      },
    });

    return () => {
      chartData = [];
      chart.destroy();
    };
  }, [dataset]);

  const buildInternalChartDataset = (): ChartWrapperInternalDataset[] => {
    let itemCounter = 0;
    const chartDataset: ChartWrapperInternalDataset[] = [];

    const isLineChart = type === 'line';

    dataset.forEach((item) => {
      chartDataset.push({
        ...item,
        borderColor: isLineChart ? [colors[itemCounter]] : [],
        backgroundColor: isLineChart ? [] : colors.slice(labels.length),
        fill: !isLineChart,
      });
      itemCounter++;
    });
    return chartDataset;
  };

  const buildLabelsData = (): PluginsLabelData[] => {
    const labelData: PluginsLabelData[] = [];
    if (showValueOnChart) {
      labelData.push({
        fontColor: '#FFFFFF',
        fontSize: 12,
        fontStyle: 'bold',
        position: 'border',
        render: 'value',
      });
    }
    if (showOutsideLabel) {
      labelData.push({
        fontColor: '#0d98bd',
        fontSize: 12,
        fontStyle: 'normal',
        position: 'outside',
        render: 'label',
      });
    }
    return labelData;
  };

  const downloadPng = () => {
    if (imageData) {
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('href', imageData);
      downloadLink.setAttribute('id', 'download');
      downloadLink.setAttribute('download', 'chart.png');
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
  };

  return (
    <Card className={classes.papper}>
      <CardContent onDoubleClick={downloadPng}>
        <canvas id="myChart" ref={chartContainer} />
      </CardContent>
    </Card>
  );
}
