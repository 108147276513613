import { Toolbar, Button, InputBase } from '@mui/material';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import { KeyVaultHeaderToolbarProps } from '../Model/KeyVaultProps';
import React, { ChangeEvent, useContext } from 'react';
import { Link } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { PermissionsContext } from '../../UIContainer/PermissionsWrapper';

export default function KeyVaultHeaderToolbar(
  props: Readonly<KeyVaultHeaderToolbarProps>,
) {
  const { onFilterRequest } = props;
  const classes = useToolbarStyles();
  const permissions = useContext(PermissionsContext);
  const createFilterHandler = () => (event: ChangeEvent<HTMLInputElement>) => {
    onFilterRequest(event);
  };

  const haskeyVaultWritePermission = () =>
    permissions['SecureKeyWriter'] ? true : false;
  return (
    <Toolbar className={classes.root}>
      <div className={classes.spacer} />
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'Search' }}
          onChange={createFilterHandler()}
        />
      </div>
      {haskeyVaultWritePermission() ? (
        <Button
          variant="contained"
          component={Link}
          to={'/keyvault/create'}
          className={classes.button}
          startIcon={<AddIcon />}
        >
          Add
        </Button>
      ) : null}
    </Toolbar>
  );
}
