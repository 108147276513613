import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';
import { TableHeader } from '../../../Common/CommonModel';
import { StorageAccount } from '../Model/StorageAccountModel';
import { EnhancedTableProps } from '../Model/StorageAccountProps';

const StorageAccountheadRows: TableHeader<StorageAccount>[] = [
  {
    id: 'Name',
    numeric: false,
    disablePadding: false,
    label: 'Name',
    enableSorting: true,
  },
  {
    id: 'ResourceGroup',
    numeric: false,
    disablePadding: false,
    label: 'ResourceGroup',
    enableSorting: true,
  },
  {
    id: 'Subscription',
    numeric: false,
    disablePadding: false,
    label: 'Subscription',
    enableSorting: true,
  },
  {
    id: 'ProductID',
    numeric: false,
    disablePadding: false,
    label: 'Product ID',
    enableSorting: true,
  },
  {
    id: 'Purpose',
    numeric: false,
    disablePadding: false,
    label: 'Purpose',
    enableSorting: true,
  },
  {
    id: 'AzureRegion',
    numeric: false,
    disablePadding: false,
    label: 'Azure Region',
    enableSorting: true,
  },
];

export default function StorageAccountsTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof StorageAccount) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {StorageAccountheadRows.map((storageAccountrow) => (
          <TableCell
            key={storageAccountrow.id}
            align={storageAccountrow.numeric ? 'right' : 'left'}
            padding={storageAccountrow.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === storageAccountrow.id ? order : false}
          >
            {storageAccountrow.enableSorting ? (
              <TableSortLabel
                active={orderBy === storageAccountrow.id}
                direction={order}
                onClick={createSortHandler(storageAccountrow.id)}
              >
                {storageAccountrow.label}
              </TableSortLabel>
            ) : (
              <div>{storageAccountrow.label}</div>
            )}
          </TableCell>
        ))}
        <TableCell align="right"></TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
}
