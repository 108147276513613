import {
  Backdrop,
  Button,
  Card,
  CircularProgress,
  Divider,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { useEffect, useState } from 'react';
import { useToolbarStyles } from '../Customer/Home/CustomerHeaderToolbar';
import SaveIcon from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import MuiTableCell from '@mui/material/TableCell';
import { useHistory, useParams } from 'react-router-dom';
import { MoveTenant } from './Model/TenantModels';
import { customerService } from '../Customer/CustomerService';
import { tenantService } from './TenantService';
import { toast } from 'react-toastify';
import TenantConstants from './Model/TenantConstants';
import { StorageAccount } from '../StorageAccount/Model/StorageAccountModel';
import { storageAccountService } from '../StorageAccount/StorageAccountService';
import { renderAppservicePlanDialogue } from '../ManageFarms/Components/TenantTable';
import { plans } from '../../Common/Constants/ValueConstants';
import {
  RemoveDuplicateAppserviceplans,
  createNewAppServicePlan,
} from '../../../Common/CommonMethods';
import { farmService } from '../ManageFarms/FarmService';

const TableCell = withStyles({
  root: {
    borderBottom: 'none',
  },
})(MuiTableCell);
interface Param {
  customerId: string;
}
export default function TenantMove() {
  const params = useParams<Param>();
  const classes = useToolbarStyles();
  const history = useHistory();
  const [moveTenant, setMoveTenant] = useState<MoveTenant>();
  const [FarmId, setFarmId] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [appservicePlan, setAppservicePlan] = useState('');
  const initialStorageAccounts: StorageAccount[] = [];
  const [storageAccounts, setStorageAccounts] = React.useState(
    initialStorageAccounts,
  );
  const initialAppservicePlans: string[] = [];
  const [appserviceplans, setAppserviceplans] = React.useState(
    initialAppservicePlans,
  );
  const [storageAccount, setStorageAccountName] = React.useState('');

  const [openAppserviceDialogue, setOpenAppserviceDialogue] =
    React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(plans[0]);

  const loadRegisterTenant = async () => {
    await customerService.getMoveTenant(params.customerId).then((data) => {
      setMoveTenant(data);
      setLoading(false);
    });
  };

  const addItemToState = (item: string[]) => {
    const newItem = createNewAppServicePlan(
      appserviceplans,
      item,
      Number(FarmId),
    );
    const appAppservicePlans = appserviceplans.concat(appserviceplans, newItem);
    setAppserviceplans(RemoveDuplicateAppserviceplans(appAppservicePlans));
  };

  const handleCloseAppservicePlan = (value: string) => {
    setOpenAppserviceDialogue(false);
    if ('Not Selected' !== value) {
      setSelectedValue(value);
      const appserviceplan: string[] = [];
      appserviceplan.push(value);
      addItemToState(appserviceplan);
    }
  };

  const loadStorageAccounts = async () => {
    try {
      await storageAccountService
        .getstorageAccounts()
        .then((storageAccounts) => {
          setStorageAccounts(
            storageAccounts.Items.filter(
              (sa) => sa.Purpose.toLowerCase() === 'archive',
            ),
          );
        });
    } catch (error) {
      console.log(error);
    }
  };

  const validate = () => {
    if (appservicePlan === '') {
      toast.error(TenantConstants.AppserviceEmptyWarn);
      return false;
    } else if (FarmId === '' || FarmId === undefined) {
      toast.error(TenantConstants.TargetFarmIdEmptyWarn);
      return false;
    }
    return true;
  };

  const handleMove = async () => {
    if (validate() && moveTenant !== undefined) {
      const Tenant: MoveTenant = {
        SourceFarmID: moveTenant.SourceFarmID,
        FarmID: Number(FarmId),
        AppservicePlan: appservicePlan,
        TenantId: moveTenant.TenantId,
        Farms: [],
      };

      setLoading(true);
      await tenantService
        .MoveTenant(params.customerId, Tenant, storageAccount)
        .then(() => {
          toast.success(TenantConstants.TenantMoved);
          setLoading(false);
        });
      history.push('/customer');
    }
  };

  const handleAppserviceplanChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setAppservicePlan(value);
  };

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = (event.target as HTMLInputElement).value;
    setFarmId(value);
    const farmId = Number(value);
    setStorageAccounts(
      storageAccounts.filter(
        (sa) =>
          sa.AzureRegion ===
          moveTenant?.Farms.find((farm) => farm.Id === farmId)?.FarmRegion,
      ),
    );
    setStorageAccountName('');
    loadAppservicePlans(Number(value));
    setAppserviceplans(appserviceplans.filter((ap) => ap.includes(value)));
  };
  const handleClose = () => {
    history.push('/customer');
  };

  const loadAppservicePlans = async (FarmId: number) => {
    try {
      setLoading(true);
      await farmService.getAppservicePlans(FarmId).then((appserviceplans) => {
        setAppserviceplans(appserviceplans);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickOpen = () => {
    setOpenAppserviceDialogue(true);
  };

  useEffect(() => {
    loadRegisterTenant();
    loadStorageAccounts().catch((err) => console.log(err));
  }, []);
  return (
    <div>
      {loading && !moveTenant ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Paper>
          <Toolbar className={classes.root}>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Move Tenant for Customer
              </Typography>
            </div>
          </Toolbar>
          <Card elevation={2} style={{ padding: 15, margin: 15 }}>
            <Typography variant="h6">Tenant information</Typography>
            <Table>
              <TableRow>
                <TableCell>Tenant Id</TableCell>
                <TableCell>{params.customerId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Source Farm ID</TableCell>
                <TableCell>{moveTenant?.SourceFarmID}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Target Farm</TableCell>
                <TableCell>
                  <Select
                    className={classes.maxSelect}
                    name="FarmId"
                    value={FarmId ? FarmId.toString() : ''}
                    onChange={handleChange}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {moveTenant?.Farms.map((farm) => {
                      return (
                        <MenuItem key={farm.Id} value={farm.Id.toString()}>
                          {farm.DisplayName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Storage Account</TableCell>
                <TableCell>
                  <Select
                    disabled={undefined === FarmId ? true : false}
                    className={classes.maxSelect}
                    name="StorageAccount"
                    required
                    value={storageAccount}
                    onChange={(e) => setStorageAccountName(e.target.value)}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                  >
                    {storageAccounts.map((storageAccount) => {
                      return (
                        <MenuItem
                          key={storageAccount.Name}
                          value={storageAccount.Name}
                        >
                          {storageAccount.Name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Target Appservice Plan</TableCell>
                <TableCell>
                  <Select
                    className={classes.maxSelect}
                    disabled={FarmId === undefined ? true : false}
                    name={`Appserviceplan`}
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                    value={appservicePlan}
                    onChange={(e) => handleAppserviceplanChange(e)}
                  >
                    <MenuItem
                      value={'Create New Appservice Plan'}
                      onClick={handleClickOpen}
                    >
                      Create New Appservice Plan
                    </MenuItem>
                    {appserviceplans.map((plan) => {
                      return (
                        <MenuItem key={plan} value={plan}>
                          {plan}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {openAppserviceDialogue &&
                    renderAppservicePlanDialogue(
                      handleCloseAppservicePlan,
                      openAppserviceDialogue,
                      selectedValue,
                    )}
                </TableCell>
              </TableRow>
            </Table>
          </Card>
          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleMove}
              startIcon={<SaveIcon />}
            >
              Save{' '}
            </Button>
            <Button
              variant="contained"
              onClick={handleClose}
              className={classes.button}
              startIcon={<Cancel />}
            >
              Cancel{' '}
            </Button>
          </Paper>
        </Paper>
      )}
    </div>
  );
}
