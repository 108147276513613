import React, { useState, useMemo, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useImmerReducer } from 'use-immer';
import { CustomScript } from '../Model/CustomerModel';
import {
  CustomerContext,
  CustomerDispatchContext,
  CustomerReducerAction,
  initialCustomScriptState,
} from '../Reducer/CustomizationState';
import CustomizationEditor from './CustomizationEditor';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ClassNameMap,
} from '@mui/material';
import { Error, isValidError } from '../../../Common/CommonModel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast, ToastContent } from 'react-toastify';
import CustomerConstants from '../Model/CustomerConstants';
import { customerService } from '../CustomerService';
import CustomizationOptions from './CustomizationOptions';
import { CustomizationRouterProps } from '../Model/CustomerProps';

const useStyles = makeStyles((theme) => ({
  flexRowItem: {
    display: 'flex',
    flex: '3%',
    padding: theme.spacing(3, 0, 0, 2),
  },
  homeRoot: {
    width: '100%',
    height: '80vh',
    margin: theme.spacing(0, 2, 2, 0),
    padding: theme.spacing(0, 2, 0, 0),
  },
  comboEditorContainer: {
    justifyContent: 'space-between',
    justifyItems: 'space-between',
  },
}));

function customerReducer(
  scriptDraft: CustomScript,
  action: CustomerReducerAction,
) {
  switch (action.type) {
    case 'sql': {
      scriptDraft.Sql = action.payload;
      return scriptDraft;
    }
    case 'powershell': {
      scriptDraft.Powershell = action.payload;
      return scriptDraft;
    }
    case 'content': {
      scriptDraft = action.payload;
      return scriptDraft;
    }
    default:
      return scriptDraft;
  }
}

interface AccordianProps {
  customScript: CustomScript;
  editorContentChangedCallback: () => void;
  saveCustomScriptCallback: () => Promise<void>;
  classes: ClassNameMap<'flexRowItem' | 'homeRoot' | 'comboEditorContainer'>;
}
const CustomScriptAccordion = (props: AccordianProps) => {
  return (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Sql Script
        </AccordionSummary>
        <AccordionDetails>
          <CustomizationEditor
            content={props.customScript.Sql}
            editorContentChangedCallback={props.editorContentChangedCallback}
            actiontype="sql"
            mode="sql"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Powershell Script
        </AccordionSummary>
        <AccordionDetails>
          <CustomizationEditor
            content={props.customScript.Powershell}
            editorContentChangedCallback={props.editorContentChangedCallback}
            actiontype="powershell"
            mode="application/x-powershell"
          />
        </AccordionDetails>
      </Accordion>
      <div className={props.classes.flexRowItem}>
        <CustomizationOptions
          saveCustomizationCallback={props.saveCustomScriptCallback}
        />
      </div>
    </>
  );
};

export default function CustomizationHome(
  props: Readonly<CustomizationRouterProps>,
) {
  const { customerId } = props.match.params;
  const classes = useStyles();

  const [editorContentChanged, setEditorContentChanged] = useState(false);
  const [initialScript, setInitialScript] = useState<CustomScript>();

  useEffect(() => {
    const fetchScript = async () => {
      try {
        const script = await customerService.getCustomizationScript(customerId);
        if (script) {
          setInitialScript(script);
          updateScriptState(script);
        } else {
          setInitialScript(initialCustomScriptState);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchScript();
  }, [customerId]);

  const [customScript, dispatch] = useImmerReducer(
    customerReducer,
    initialScript ?? initialCustomScriptState,
  );

  const editorContentChangedCallback = () => {
    setEditorContentChanged(
      JSON.stringify(customScript) !== JSON.stringify(initialScript),
    );
  };

  const updateScriptState = (customScript: CustomScript) => {
    dispatch({ type: 'content', payload: customScript });
    dispatch({ type: 'sql', payload: customScript.Sql });
    dispatch({ type: 'powershell', payload: customScript.Powershell });
  };

  function reInitialize(savedScript: CustomScript) {
    setInitialScript(savedScript);
    updateScriptState(savedScript);
    setEditorContentChanged(false);
  }

  const saveCustomScriptCallback = async (): Promise<void> => {
    console.log(customScript);
    try {
      const savedScript = await customerService.createCustomization(
        customerId,
        {
          ...customScript,
          CustomerId: customerId,
        },
      );
      reInitialize(savedScript);

      toast.success(CustomerConstants.CustomizationAdded);
    } catch (error) {
      if (isValidError(error as Error)) {
        toast.error(error as ToastContent);
      } else {
        console.log(error);
      }
    }
  };

  const CustomizationScriptValue = useMemo(
    () => ({
      CustomerId: customerId,
      ContentChanged: editorContentChanged,
    }),
    [customerId, editorContentChanged],
  );

  return (
    <div className={classes.homeRoot}>
      <CustomerDispatchContext.Provider value={dispatch}>
        <CustomerContext.Provider value={CustomizationScriptValue}>
          <CustomScriptAccordion
            customScript={customScript}
            editorContentChangedCallback={editorContentChangedCallback}
            saveCustomScriptCallback={saveCustomScriptCallback}
            classes={classes}
          />
        </CustomerContext.Provider>
      </CustomerDispatchContext.Provider>
    </div>
  );
}
