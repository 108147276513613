import { Component } from 'react';

export default class CustomerConstants extends Component {
  static readonly CanNotViewCustomers =
    'You do not have permission to view customers.';
  static readonly CustomerCreated = 'Customer Created Successfully ..!';
  static readonly AllFieldsAreRequired = 'All fields are required ..!';
  static readonly CustomerFieldsAreRequired =
    'Customer fields are required ..!';
  static readonly CustomerUpdated = 'Customer Updated Successfully ..!';
  static readonly CustomizationAdded = 'Customization Added Successfully ..!';
}
