import * as React from 'react';
import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { TagsProps } from './TagProps';

export default function Tags(props: TagsProps): JSX.Element {
  const [options, setOptions] = useState<string[]>(props.initialOptions);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const newInputValue = (event.target as HTMLInputElement).value;
      if (newInputValue && !options.includes(newInputValue)) {
        setOptions([...options, newInputValue]);
      }
      if (newInputValue) {
        props.setSelected([...props.initialSelected, newInputValue]);
      }
    }
  };

  return (
    <div>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={options}
        getOptionLabel={(option: string) => option}
        value={props.initialSelected}
        onChange={(event: React.SyntheticEvent, newValue: string[]) => {
          props.setSelected(newValue);
        }}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tags"
            placeholder="Select Tags"
            onKeyDown={handleKeyDown}
          />
        )}
      />
    </div>
  );
}
