import React from 'react';
import { Link } from 'react-router-dom';

export default function NotFoundPage() {
  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Page not found</h1>
      <br />
      <p style={{ textAlign: 'center' }}>
        The page that you are looking for is not found.{' '}
        <Link to="/">Go Home </Link>
      </p>
    </div>
  );
}
