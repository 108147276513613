import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Button, InputBase, Toolbar } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import SearchIcon from '@mui/icons-material/Search';
import { useToolbarStyles } from '../Customer/Home/CustomerHeaderToolbar';
import { EmailAdminHeaderToolbarProps } from './Model/EmailAdminProps';

const EmailAdminToolbar = (props: EmailAdminHeaderToolbarProps) => {
  const classes = useToolbarStyles();
  const [, setSearchFilterText] = React.useState('');

  const createFilterHandler = () => (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(event.target.value);
    props.onFilterRequest(event);
  };

  return (
    <Toolbar className={classes.root}>
      <div className={classes.spacer} />
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'Search' }}
          onChange={createFilterHandler()}
        />
      </div>
      <Button
        variant="contained"
        component={Link}
        to={'emailadministration/sendemail'}
        className={classes.button}
        startIcon={<EmailIcon />}
      >
        Send Email
      </Button>
      <Button
        variant="contained"
        component={Link}
        to={'emailadministration/emailtemplate/create'}
        className={classes.button}
        startIcon={<AddIcon />}
      >
        Add
      </Button>
    </Toolbar>
  );
};

export default EmailAdminToolbar;
