import React, { useState, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from 'react-router-dom';
import { Dictionary } from '../../../Common/CommonModel';
import { PermissionsContext } from '../../UIContainer/PermissionsWrapper';
import PermissionConstants from '../../../Common/Constants/PermissionConstants';
import { SqlServerMenuItems } from '../Model/SqlServerModel';
import { SqlServerMenuProps } from '../Model/SqlServerProps';

const useStyles = makeStyles(() =>
  createStyles({
    menu: {
      padding: 0,
    },
  }),
);

const menuItems: SqlServerMenuItems[] = [
  {
    id: 'edit',
    label: 'Edit',
    linkTo: '/sqlserver/{0}/edit',
    validationFunc: 'edit',
  },
  {
    id: 'delete',
    label: 'Delete',
    linkTo: '/sqlserver/{0}/delete',
    validationFunc: 'delete',
  },
];

export default function SqlServerMenu({
  server,
}: Readonly<SqlServerMenuProps>) {
  const classes = useStyles();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [menuState, setMenuState] = useState(false);
  const permissions = useContext(PermissionsContext);

  const openFarmMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuState(true);
    setAnchor(event.currentTarget);
  };
  const closeFarmMenu = () => {
    setMenuState(false);
    setAnchor(null);
  };

  const hasSqlServerDeleterPermission = () =>
    !!permissions[PermissionConstants.SqlServerWriter];
  const hasFarmWriterPermission = () =>
    !!permissions[PermissionConstants.SqlServerWriter];

  function executeValidationFunction(type: string) {
    let fn;
    const validations: Dictionary<() => boolean> = {};
    validations['delete'] = () => hasSqlServerDeleterPermission();
    validations['edit'] = () => hasFarmWriterPermission();
    validations['default'] = () => false;

    if (validations[type]) {
      fn = validations[type];
    } else {
      fn = validations['default'];
    }
    return fn();
  }

  return (
    <div>
      <IconButton
        aria-label="More"
        aria-owns="long-menu"
        aria-haspopup="true"
        onClick={openFarmMenu}
        className={classes.menu}
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchor}
        open={menuState}
        onClose={closeFarmMenu}
      >
        {menuItems
          .filter((menu) => executeValidationFunction(menu.validationFunc))
          .map((menu) => (
            <MenuItem
              key={menu.id}
              component={Link}
              to={menu.linkTo.format(server)}
            >
              {menu.label}
            </MenuItem>
          ))}
      </Menu>
    </div>
  );
}
