/* eslint-disable  @typescript-eslint/no-explicit-any */
import { RouteComponentProps } from 'react-router';

export interface CustomerDssSite extends Record<string, any> {
  Id: number;
  CustomerId: string;
  AzureRegion: string;
  SecondaryRegion: string;
  SiteId: string;
  SiteDescription: string;
  SiteUrl?: string;
  SiteCount: number;
  IsActive: boolean;
}

export const initialSiteData: CustomerDssSite = {
  Id: 0,
  CustomerId: '',
  AzureRegion: '',
  SecondaryRegion: '',
  SiteId: '',
  SiteCount: 0,
  SiteDescription: '',
  SiteUrl: '',
  IsActive: true,
};

export type SiteAction =
  | { type: 'value'; property: string; payload: string | boolean }
  | { type: 'set'; payload: CustomerDssSite }
  | { type: 'reset' };

export interface SiteValidation extends Record<string, any> {
  SiteIdTouched: boolean;
  AzureRegionTouched: boolean;
  SecondaryRegionTouched: boolean;
}

interface DssSitesMatchParams {
  customerId: string;
}

export interface DssSitesRouterProps
  extends RouteComponentProps<DssSitesMatchParams> {}
