export const validDTUs = [
  {
    value: 10,
  },
  {
    value: 20,
  },
  {
    value: 50,
  },
  {
    value: 100,
  },
  {
    value: 200,
  },
  {
    value: 300,
  },
  {
    value: 400,
  },
  {
    value: 800,
  },
  {
    value: 1200,
  },
  {
    value: 1600,
  },
  {
    value: 2000,
  },
  {
    value: 2500,
  },
  {
    value: 3000,
  },
];

export const validDMS = [
  {
    value: 50,
  },
  {
    value: 100,
  },
  {
    value: 150,
  },
  {
    value: 200,
  },
  {
    value: 250,
  },
  {
    value: 300,
  },
  {
    value: 400,
  },
  {
    value: 500,
  },
  {
    value: 750,
  },
  {
    value: 800,
  },
  {
    value: 1024,
  },
  {
    value: 1200,
  },
  {
    value: 1280,
  },
  {
    value: 1536,
  },
  {
    value: 1600,
  },
  {
    value: 1792,
  },
  {
    value: 2000,
  },
  {
    value: 2048,
  },
  {
    value: 2304,
  },
  {
    value: 2500,
  },
  {
    value: 2560,
  },
  {
    value: 2816,
  },
  {
    value: 3000,
  },
  {
    value: 3072,
  },
  {
    value: 3328,
  },
  {
    value: 3584,
  },
  {
    value: 3840,
  },
  {
    value: 4096,
  },
];
