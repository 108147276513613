/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { MetricEntity } from '../Model/MetricsModel';

const TenantContext = React.createContext({
  setSelectedTenant: (_: string) => {},
  setSelectedFarm: (_: number) => {},
  setSelectedEntity: (_: MetricEntity) => {},
});
export default TenantContext;
