import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

import { ConfirmationDialogProps } from './DialogProps';

export default function ConfirmationDialog(props: ConfirmationDialogProps) {
  const { message, open, onCancelHandler, onCloseHandler, onConfirmHandler } =
    props;

  const closed = () => {
    onCloseHandler();
  };

  const canceled = () => {
    onCancelHandler();
  };

  const confirmed = () => {
    onConfirmHandler();
  };

  return (
    <Dialog
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
      onClose={closed}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmed} autoFocus>
          Confirm
        </Button>
        <Button onClick={canceled}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}
