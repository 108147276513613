import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import userHelper from '../../../Helpers/UserHelper';
import ProfileCard from './Global/ProfileCard';
import { capitalizeWords } from '../../../Helpers/UtilityMethods';

function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [profileUrl, setprofileUrl] = React.useState<string>('');
  const user = userHelper.getUser();
  const initials = userHelper.getUserInitials();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    loadInitData();
  }, []);

  const loadInitData = async () => {
    await loadProfile();
  };

  const loadProfile = async () => {
    setprofileUrl(URL.createObjectURL(await userHelper.getUserPfile()));
  };

  const logOut = () => {
    userHelper.logOut();
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title={`Account settings for ${capitalizeWords(user.name)}`}>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar src={profileUrl} sx={{ width: 32, height: 32 }}>
              {initials}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box>
          <ProfileCard
            username={capitalizeWords(user.name)}
            email={user.userName}
            profileimageurl={profileUrl}
            accounturl={`https://myaccount.microsoft.com/?ref=MeControl&login_hint=${user.userName}`}
            logOut={logOut}
          />
        </Box>
      </Menu>
    </React.Fragment>
  );
}

export default AccountMenu;
