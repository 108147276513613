import * as React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { TenantSkeletonProps } from '../../Model/HealthModels';
import {
  Box,
  Grid,
  Paper,
  Table,
  TableRow,
  TableContainer,
  TableHead,
  Divider,
} from '@mui/material';
import { Item, TableCell } from '../../Common/CommonMethods';
import { useSelfStyles, useStyles } from '../../Common/CommonStyle';
import Avatar from '@mui/material/Avatar';

export const renderSkeleton = (number: number) => {
  const elements = [];
  for (let i = 0; i < number; i++) {
    elements.push(
      <Grid item xs={4} sm={12} md={2} key={i}>
        <Item>{renderItem()}</Item>
      </Grid>,
    );
  }
  return elements;
};

export const renderItem = () => {
  return (
    <>
      <Skeleton
        variant="rounded"
        width={'50%'}
        height={30}
        sx={{ margin: 1, float: 'left' }}
      />
      <Skeleton
        variant="rounded"
        width={'30%'}
        height={30}
        sx={{ margin: 1, marginLeft: 2, float: 'right' }}
      />
    </>
  );
};

export default function TenantSkeleton({ numberofTime }: TenantSkeletonProps) {
  return <Box sx={{ width: '100%' }}>{GridCard(numberofTime)}</Box>;
}

export const GridCard = (numberofTime: number) => {
  return (
    <Grid container spacing={{ xs: 2, md: 3 }} padding={2}>
      {renderSkeleton(numberofTime)}
    </Grid>
  );
};

export const FarmHealthSkeleton = (numberofTimes: number) => {
  const classes = useStyles();
  const styles = useSelfStyles();
  return Array.from({ length: numberofTimes }).map((_, index) => {
    return (
      <Paper
        className={styles.WrapperPaper}
        elevation={0}
        key={index.toString()}
      >
        <TableContainer component={Paper} className={classes.tableContainer}>
          <TableHead>
            <TableRow className={classes.TableRow}>
              <TableCell>
                <Skeleton variant="circular">
                  <Avatar sizes="small" />
                </Skeleton>
              </TableCell>
              <TableCell>
                FARM: <Skeleton variant="rectangular" width={'100px'} />
              </TableCell>
              <TableCell>
                ID: <Skeleton variant="rectangular" width={'100px'} />
              </TableCell>
            </TableRow>
          </TableHead>
          <Divider />
          <Table>
            <TenantSkeleton key={index.toString()} numberofTime={6} />
          </Table>
        </TableContainer>
      </Paper>
    );
  });
};
