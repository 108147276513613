import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Theme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';

import { toast, ToastContent } from 'react-toastify';
import { useImmerReducer } from 'use-immer';

import { Error, isValidError } from '../../../Common/CommonModel';

import { dssService } from '../DssService';
import DssSiteConstants from '../Model/DssSiteConstants';
import {
  CustomerDssSite,
  initialSiteData,
  SiteAction,
  SiteEditorProps,
  SiteValidation,
} from '../Model';
import { commonService } from '../../../Common/CommonService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  }),
);

function siteReducer(draft: CustomerDssSite, action: SiteAction) {
  switch (action.type) {
    case 'value':
      draft[action.property] = action.payload;
      return;
    case 'set':
      draft.Id = action.payload.Id;
      draft.SiteId = action.payload.SiteId;
      draft.SiteUrl = action.payload.SiteUrl;
      draft.SiteCount = action.payload.SiteCount;
      draft.AzureRegion = action.payload.AzureRegion;
      draft.SecondaryRegion = action.payload.SecondaryRegion;
      draft.SiteDescription = action.payload.SiteDescription;
      draft.IsActive = action.payload.IsActive;
      draft.CustomerId = action.payload.CustomerId;
      return;
    case 'reset':
      draft = initialSiteData;
      return;
    default:
      break;
  }
}

export default function SiteEditor(props: SiteEditorProps) {
  const {
    SiteCount,
    subDomain,
    subscription,
    customerId,
    openEditor,
    setDrawerClose,
    id,
  } = props;

  const classes = useStyles();
  const [saving, setSaving] = useState(false);
  const [Count, setcount] = useState(SiteCount);
  const [regions, setRegions] = useState<string[]>([]);
  const [validation, setValidation] = useState<SiteValidation>({
    SiteURLTouched: false,
    SiteIdTouched: false,
    AzureRegionTouched: false,
    SecondaryRegionTouched: false,
  });
  const [siteData, dispatch] = useImmerReducer(siteReducer, {
    Id: 0,
    CustomerId: '',
    AzureRegion: '',
    SecondaryRegion: '',
    SiteId: '',
    SiteCount: SiteCount,
    SiteDescription: '',
    SiteUrl:
      'https://' +
      subDomain +
      '-dss' +
      SiteCount +
      (subscription === 'Dev' ? '.dev' : '') +
      '.proarconline.com',
    IsActive: true,
  });

  useEffect(() => {
    loadData();
    return () => {
      setValidation({
        SiteURLTouched: false,
        SiteIdTouched: false,
        AzureRegionTouched: false,
        SecondaryRegionTouched: false,
      });
      setSaving(false);
    };
  }, [id]);

  const loadData = async () => {
    await loadAzureRegions();
    await loadSelectedSite();
  };

  const loadAzureRegions = async () => {
    const azureRegions = await commonService.getAzureRegions();
    setRegions(azureRegions.sort());
  };

  const loadSelectedSite = async () => {
    if (id > 0) {
      const siteData = await dssService.getDssSite(customerId, id);
      dispatch({ type: 'set', payload: siteData });
    }
  };

  const isNewSite = (): boolean => {
    return siteData.Id === 0;
  };

  const handleOnChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>,
  ) => {
    const { name, value } = event.target;
    dispatch({
      type: 'value',
      payload: value as string,
      property: name as string,
    });
  };

  const handleSelectOnChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    dispatch({
      type: 'value',
      payload: value,
      property: name,
    });
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    dispatch({ type: 'value', payload: checked, property: name });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name } = event.target;
    const propName = `${name}Touched`;
    validation[propName] = true;
    setValidation({ ...validation });
  };

  const handleSubmit = async () => {
    setSaving(true);
    let validated = true;
    if (siteData.SiteId === '') {
      validation['SiteIdTouched'] = true;
      validated = false;
    }
    if (siteData.SiteUrl === '') {
      validation['SiteURLTouched'] = true;
      validated = false;
    }
    if (siteData.AzureRegion === '') {
      validation['AzureRegionTouched'] = true;
      validated = false;
    }
    if (siteData.SecondaryRegion === '') {
      validation['SecondaryRegionTouched'] = true;
      validated = false;
    }
    setValidation({ ...validation });

    if (validated) {
      const savedSite = await saveSiteDate();
      let localCount;
      if (siteData.SiteCount === Count) {
        localCount = Count + 1;
        setcount(localCount);
      } else {
        localCount = Count;
      }

      dispatch({
        type: 'set',
        payload: {
          Id: 0,
          CustomerId: '',
          AzureRegion: '',
          SecondaryRegion: '',
          SiteId: '',
          SiteCount: localCount,
          SiteDescription: '',
          SiteUrl:
            'https://' +
            subDomain +
            '-dss' +
            localCount +
            (subscription === 'Dev' ? '.dev' : '') +
            '.proarconline.com',
          IsActive: true,
        },
      });
      setDrawerClose(isNewSite(), savedSite);
    }
    setSaving(false);
  };

  const handleClose = () => {
    dispatch({
      type: 'set',
      payload: {
        Id: 0,
        CustomerId: '',
        AzureRegion: '',
        SecondaryRegion: '',
        SiteId: '',
        SiteCount: Count,
        SiteDescription: '',
        SiteUrl:
          'https://' +
          subDomain +
          '-dss' +
          Count +
          (subscription === 'Dev' ? '.dev' : '') +
          '.proarconline.com',
        IsActive: true,
      },
    });

    setDrawerClose(false, initialSiteData);
  };

  const saveSiteDate = async (): Promise<CustomerDssSite> => {
    let savedSite: CustomerDssSite = {} as CustomerDssSite;
    try {
      if (isNewSite()) {
        savedSite = await dssService.createSite(customerId, {
          ...siteData,
          CustomerId: customerId,
        });
      } else {
        savedSite = await dssService.editSite(customerId, {
          ...siteData,
          CustomerId: customerId,
        });
      }
      toast.success(DssSiteConstants.SiteSavedMessage);
    } catch (error) {
      if (isValidError(error as Error)) {
        toast.error(error as ToastContent);
      } else {
        console.log(error);
      }
    }
    return savedSite;
  };

  return (
    <div>
      <Dialog
        disableEscapeKeyDown
        maxWidth="md"
        open={openEditor}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="dialog-description">
            {DssSiteConstants.SiteEditorTitle}
          </DialogContentText>
          <TextField
            margin="dense"
            name="SiteId"
            label="Site Id"
            fullWidth
            error={validation.SiteIdTouched && siteData.SiteId === ''}
            helperText={
              validation.SiteIdTouched && siteData.SiteId === ''
                ? DssSiteConstants.SiteIdRequiredMessage
                : ''
            }
            value={siteData.SiteId}
            disabled={saving || (siteData.SiteId !== '' && !isNewSite())}
            onBlur={handleBlur}
            onChange={handleOnChange}
          />

          <TextField
            margin="dense"
            name="SiteUrl"
            label="Site Url"
            fullWidth
            error={siteData.SiteUrl.length >= 69}
            helperText={
              siteData.SiteUrl === '' || siteData.SiteUrl.length >= 69
                ? DssSiteConstants.SiteURLRequiredMessage
                : ''
            }
            value={siteData.SiteUrl}
            disabled={saving || (siteData.SiteUrl !== '' && !isNewSite())}
            onBlur={handleBlur}
            onChange={handleOnChange}
          />

          <TextField
            className={classes.selectEmpty}
            disabled
            margin="dense"
            name="SiteCount"
            label="Site Count"
            fullWidth
            value={siteData.SiteCount}
          />

          <FormControl
            fullWidth
            className={classes.selectEmpty}
            error={validation.AzureRegionTouched && siteData.AzureRegion === ''}
          >
            <InputLabel id="AzureRegion">Primary region</InputLabel>
            <Select
              labelId="AzureRgionLabel"
              name="AzureRegion"
              value={siteData.AzureRegion}
              onBlur={handleBlur}
              onChange={handleSelectOnChange}
              disabled={saving}
              label="Primary region"
            >
              {regions.map((region) => (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {DssSiteConstants.PrimaryRegionHelperText}
            </FormHelperText>
          </FormControl>

          <FormControl
            fullWidth
            className={classes.selectEmpty}
            error={
              validation.SecondaryRegionTouched &&
              siteData.SecondaryRegion === ''
            }
          >
            <InputLabel id="SecondaryRegion">Secondary region</InputLabel>
            <Select
              labelId="SecondaryRgionLabel"
              name="SecondaryRegion"
              value={siteData.SecondaryRegion}
              onBlur={handleBlur}
              onChange={handleSelectOnChange}
              disabled={saving}
              label="Secondary Region"
            >
              {regions.map((region) => (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {DssSiteConstants.SecondaryRegionHelperText}
            </FormHelperText>
          </FormControl>

          <TextField
            className={classes.selectEmpty}
            id="SiteDescription"
            name="SiteDescription"
            label="Site description"
            multiline
            maxRows={3}
            fullWidth
            value={siteData.SiteDescription}
            onChange={handleOnChange}
          />

          <FormControlLabel
            className={classes.selectEmpty}
            control={
              <Switch
                color="primary"
                checked={siteData.IsActive || false}
                onChange={handleSwitchChange}
                name="IsActive"
                inputProps={{ 'aria-label': 'primary checkbox' }}
                disabled={saving}
              />
            }
            label="Is Active"
          />
        </DialogContent>
        <DialogActions>
          {saving ? (
            <CircularProgress size={20} />
          ) : (
            <div>
              <Button onClick={handleSubmit} autoFocus type="submit">
                Confirm
              </Button>
              <Button onClick={handleClose}>Cancel</Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
