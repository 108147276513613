import React from 'react';
import ContentLoader from 'react-content-loader';

export const TriggerBladeSkeleton = () => (
  <ContentLoader
    speed={1}
    width={350}
    height={'100%'}
    backgroundColor="#d9d9d9"
    foregroundColor="#ecebeb"
    backgroundOpacity={0}
    viewBox="0 0 400 460"
  >
    <circle cx="31" cy="31" r="15" />
    <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
    <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
    <rect x="0" y="60" rx="2" ry="2" width="400" height="400" />
  </ContentLoader>
);

const Loader = (props: { key: number; style: { opacity: string } }) => {
  const { style } = props;
  const random = Math.random() * (1 - 0.7) + 0.7;
  return (
    <ContentLoader
      height={40}
      width={500}
      speed={1}
      backgroundColor="#d9d9d9"
      foregroundColor="#ecebeb"
      opacity={style.opacity}
    >
      <rect x="30" y="15" rx="4" ry="4" width="6" height="6.4" />
      <rect x="64" y="13" rx="6" ry="6" width={350 * random} height="12" />
    </ContentLoader>
  );
};

export const TriggerEditorSkeleton = () => (
  <React.Fragment>
    {Array(15)
      .fill('')
      .map((e, i) => (
        <Loader key={i} style={{ opacity: Number(2 / i).toFixed(1) }} />
      ))}
  </React.Fragment>
);
