import { UserAgentApplication } from 'msal';

import { GRAPH_REQUESTS, getUserDetails, getProfile } from '.';

declare global {
  interface Window {
    config: {
      appid: string;
      tenantid: string;
      apibaseurl: string;
      redirectUri: string;
    };
  }
}

interface AuthProviderState {
  error: unknown;
  isAuthenticated: boolean;
  user: unknown;
}

interface MsalConfig {
  clientId: string;
  redirectUri: string;
  cacheLocation: 'localStorage' | 'sessionStorage';
  authority: string;
  storeAuthStateInCookie: boolean;
}

export class AuthProvider {
  private msalConfig: MsalConfig;
  private userAgentApplication: UserAgentApplication;
  private authProviderState: AuthProviderState;

  constructor() {
    this.authProviderState = {
      error: null,
      isAuthenticated: false,
      user: {},
    };

    this.msalConfig = this.getMsalConfiguration();

    // Initialize the MSAL application object
    this.userAgentApplication = new UserAgentApplication({
      auth: {
        authority: this.msalConfig.authority,
        clientId: this.msalConfig.clientId,
        redirectUri: this.msalConfig.redirectUri,
      },
      cache: {
        cacheLocation: this.msalConfig.cacheLocation,
        storeAuthStateInCookie: this.msalConfig.storeAuthStateInCookie,
      },
    });
  }

  private getMsalConfiguration = () => {
    const win: Window = window;
    const appid: string = win.config.appid;
    const authurl: string =
      'https://login.microsoftonline.com/' + win.config.tenantid;

    const msalConfig: MsalConfig = {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: true,
      authority: authurl,
      clientId: appid,
      redirectUri: win.config.redirectUri,
    };
    return msalConfig;
  };

  public getAccount() {
    const userAccount = this.userAgentApplication.getAccount();
    return userAccount;
  }

  public async login() {
    try {
      this.userAgentApplication.loginRedirect({
        scopes: GRAPH_REQUESTS.LOGIN.scopes,
        prompt: 'select_account',
      });
      await this.getUserProfile();
    } catch (err) {
      this.authProviderState = {
        isAuthenticated: false,
        user: {},
        error: this.normalizeError(err as Error),
      };
    }
  }

  public logout() {
    this.userAgentApplication.logout();
  }

  public async getWebApiToken(): Promise<string> {
    let retryCount = 3;
    let hasAccessToken = false;
    let accessToken = '';
    do {
      accessToken = await this.getGraphAccessToken(GRAPH_REQUESTS.API.scopes);
      if (accessToken) {
        hasAccessToken = true;
      }
      retryCount--;
    } while (!hasAccessToken && retryCount > 0);

    return accessToken;
  }

  public async getGraphAccessToken(scopes: string[]): Promise<string> {
    try {
      const silentResult = await this.userAgentApplication.acquireTokenSilent({
        scopes: scopes,
      });

      return silentResult.accessToken;
    } catch (err) {
      if (this.isInteractionRequired(err as Error)) {
        this.userAgentApplication.acquireTokenRedirect({
          scopes: scopes,
        });
        throw new Error('Redirecting for new token');
      } else {
        throw err;
      }
    }
  }

  public async getProfile(): Promise<Blob> {
    const accessToken = this.getGraphAccessToken(GRAPH_REQUESTS.LOGIN.scopes);

    if (accessToken) {
      const profile = await getProfile(await accessToken);
      return profile;
    }
    throw new Error();
  }

  public async getUserProfile() {
    try {
      const accessToken = await this.getGraphAccessToken(
        GRAPH_REQUESTS.LOGIN.scopes,
      );

      if (accessToken) {
        // Get the user's profile from Graph
        const user = await getUserDetails(accessToken);
        this.authProviderState = {
          isAuthenticated: true,
          user: {
            displayName: user.displayName,
            email: user.mail || user.userPrincipalName,
          },
          error: null,
        };
      }
    } catch (err) {
      this.authProviderState = {
        isAuthenticated: false,
        user: {},
        error: this.normalizeError(err as Error),
      };
    }
  }

  setErrorMessage(message: string, debug: string) {
    this.authProviderState.error = { message: message, debug: debug };
  }

  normalizeError(error: string | Error): unknown {
    let normalizedError = {};
    if (typeof error === 'string') {
      const errParts = error.split('|');
      normalizedError =
        errParts.length > 1
          ? { message: errParts[1], debug: errParts[0] }
          : { message: error };
    } else {
      normalizedError = {
        message: error.message,
        debug: JSON.stringify(error),
      };
    }
    return normalizedError;
  }

  isInteractionRequired(error: Error): boolean {
    if (!error.message || error.message.length <= 0) {
      return false;
    }

    return (
      error.message.indexOf('consent_required') > -1 ||
      error.message.indexOf('interaction_required') > -1 ||
      error.message.indexOf('login_required') > -1 ||
      error.message.indexOf('not consented') > -1
    );
  }
}

const authProviderInstance: AuthProvider = new AuthProvider();
export default authProviderInstance;
