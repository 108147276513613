import {
  Button,
  Card,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableContainer,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import { useParams, useHistory } from 'react-router-dom';
import { SecureKey } from '../Model/KeyVaultModel';
import { TableCell } from '../../DashBoard/Common/CommonMethods';
import { Cancel, Delete, Save } from '@mui/icons-material';
import { keyvaultrService } from '../KeyVaultService';
import { ToastContent, toast } from 'react-toastify';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
  EditOperationProps,
  DeleteOperationProps,
  CreateOperationProps,
  ParamProps,
} from '../Model/KeyVaultProps';
import KeyVaultConstants from '../Common/Constants';

const EditOperation: React.FC<EditOperationProps> = ({
  param,
  Value,
  handleEditSave,
  showOperationbutton,
  showBackbutton,
  setValue,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useToolbarStyles();

  return (
    <>
      <Card elevation={2} style={{ padding: 15, margin: 15 }}>
        <Typography variant="h6">Edit Secure Key</Typography>
        <TableContainer>
          <Table>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{param.KeyName}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Key</TableCell>
              <TextField
                value={Value}
                className={classes.hideIcons}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => setValue(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </TableRow>
          </Table>
        </TableContainer>
      </Card>
      <Divider className={classes.divider} />
      <Paper style={{ textAlign: 'right' }}>
        {showOperationbutton(handleEditSave)}
        {showBackbutton()}
      </Paper>
    </>
  );
};

const DeleteOperation: React.FC<DeleteOperationProps> = ({
  param,
  DeleteSecureKey,
  showBackbutton,
}) => {
  const classes = useToolbarStyles();
  return (
    <>
      {' '}
      <Card elevation={2} style={{ padding: 15, margin: 15 }}>
        <Typography variant="h6">Delete Secure Key</Typography>
        <TableContainer>
          <Table>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>{param.KeyName}</TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Card>
      <Divider className={classes.divider} />
      <Paper style={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<Delete />}
          onClick={DeleteSecureKey}
        >
          Delete{' '}
        </Button>
        {showBackbutton()}
      </Paper>
    </>
  );
};

const CreateOperation: React.FC<CreateOperationProps> = ({
  secureKey,
  setSecureKey,
  AddSecureKey,
  showOperationbutton,
  showBackbutton,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const classes = useToolbarStyles();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      const isValid = /^[A-Za-z0-9-]*$/.test(inputValue);
      setError(!isValid);
      if (isValid) {
        setSecureKey({ ...secureKey, KeyName: inputValue });
      }
    }, 500);

    return () => clearTimeout(timerId);
  }, [inputValue]);

  return (
    <>
      <Card elevation={2} style={{ padding: 15, margin: 15 }}>
        <Typography variant="h6">Create Secure Key</Typography>
        <TableContainer>
          <Table>
            <TableRow>
              <TableCell>Key</TableCell>
              <TextField
                className={classes.textfieldlong}
                value={inputValue}
                onChange={handleInputChange}
                error={error}
                helperText={error ? KeyVaultConstants.KeyNameHelperText : ''}
              />
            </TableRow>
            <TableRow>
              <TableCell>Value</TableCell>
              <TextField
                className={classes.hideIcons}
                type={showPassword ? 'text' : 'password'}
                value={secureKey.KeyValue}
                onChange={(e) =>
                  setSecureKey({ ...secureKey, KeyValue: e.target.value })
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </TableRow>
          </Table>
        </TableContainer>
      </Card>
      <Divider className={classes.divider} />
      <Paper style={{ textAlign: 'right' }}>
        {showOperationbutton(AddSecureKey)}
        {showBackbutton()}
      </Paper>
    </>
  );
};

export default function SecureKeyOps() {
  const classes = useToolbarStyles();
  const [secureKey, setSecureKey] = useState<SecureKey>({
    KeyName: '',
    KeyValue: '',
  });
  const history = useHistory();
  const [Value, setValue] = useState('');
  const param = useParams<ParamProps>();
  const Operation = param.Operation;

  const validateSecureKey = (key: SecureKey) => {
    if (key.KeyName === '') {
      toast.error(KeyVaultConstants.KeyNameError);
      return false;
    }
    return true;
  };

  const handleEditSave = async () => {
    try {
      const secureKey: SecureKey = {
        KeyName: param.KeyName,
        KeyValue: Value,
      };
      const key = await keyvaultrService.EditKeyValue(secureKey);
      setValue(key.KeyValue);
      handleCancel();
      toast.success(KeyVaultConstants.KeyEditSuccess);
    } catch (error) {
      console.error(error);
      toast.error(error as ToastContent);
    }
  };

  const DeleteSecureKey = async () => {
    try {
      const key = await keyvaultrService.DeleteSecureKey(param.KeyName);
      setValue(key);
      handleCancel();
      toast.success(KeyVaultConstants.KeyDelSuccess);
    } catch (error) {
      console.error(error);
      toast.error(error as ToastContent);
    }
  };
  const handleCancel = async () => {
    history.push('/keyvault');
  };

  const AddSecureKey = async () => {
    if (validateSecureKey(secureKey)) {
      try {
        await keyvaultrService.CreateSecureKey(secureKey);
        handleCancel();
        toast.success(KeyVaultConstants.KeySaveSuccess);
      } catch (error) {
        console.log(error);
        toast.error(error as ToastContent);
      }
    }
  };

  const showBackbutton = () => {
    return (
      <Button
        variant="contained"
        className={classes.button}
        startIcon={<Cancel />}
        onClick={handleCancel}
      >
        Back{' '}
      </Button>
    );
  };

  const showOperationbutton = (
    handleOperation: (event: React.MouseEvent<HTMLButtonElement>) => void,
  ) => {
    return (
      <Button
        variant="contained"
        className={classes.button}
        startIcon={<Save />}
        onClick={handleOperation}
      >
        Save{' '}
      </Button>
    );
  };

  return (
    <div>
      <Paper>
        <Toolbar className={classes.root}>
          <div className={classes.title}>
            <Typography variant="h6" id="tableTitle">
              Key Vault Operations
            </Typography>
          </div>
        </Toolbar>
        {Operation === 'edit' && (
          <EditOperation
            param={param}
            Value={Value}
            handleEditSave={handleEditSave}
            showOperationbutton={showOperationbutton}
            showBackbutton={showBackbutton}
            setValue={setValue}
          />
        )}
        {Operation === 'create' && (
          <CreateOperation
            secureKey={secureKey}
            setSecureKey={setSecureKey}
            AddSecureKey={AddSecureKey}
            showOperationbutton={showOperationbutton}
            showBackbutton={showBackbutton}
          />
        )}
        {Operation === 'delete' && (
          <DeleteOperation
            param={param}
            DeleteSecureKey={DeleteSecureKey}
            showBackbutton={showBackbutton}
          />
        )}
      </Paper>
    </div>
  );
}
