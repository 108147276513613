import { Component } from 'react';

export default class ClientConstants extends Component {
  static readonly PackageSavedMessage =
    'Client package {0} saved successfully.';
  static readonly PackageDeletedMessage = 'Client package deleted successfully';
  static readonly PackageDeleteConfirmationMessage =
    'Client package {0} will be deleted, do you want to continue?';
  static readonly PackageDropzoneText = 'drag and drop zip files here or click';
  static readonly GenericUploadErrorMessage =
    'Failed to upload client package with status code {0}';
  static readonly UploadButtonTooltip = 'upload Packages';
}
