import { Dictionary } from '../../../Common/CommonModel';

export const farmStateMapping: Dictionary<string> = {};
farmStateMapping[1] = 'Inactive';
farmStateMapping[2] = 'Production';
farmStateMapping[3] = 'Staging';
farmStateMapping[4] = 'Staging - Passed Autotests';
farmStateMapping[5] = 'Provisioning';
farmStateMapping[6] = 'Maintenance';
farmStateMapping[7] = 'New';
farmStateMapping[8] = 'Test environment';
farmStateMapping[9] = 'Delete';

export const tenantStateMapping: Dictionary<string> = {};
tenantStateMapping[1] = 'Active';
tenantStateMapping[2] = 'New';
tenantStateMapping[3] = 'Moved';
tenantStateMapping[4] = 'Deactivated';
tenantStateMapping[5] = 'Reactivated';
tenantStateMapping[6] = 'Maintenance';
