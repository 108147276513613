import * as Icons from '@mui/icons-material';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface BaseMenuOptions {
  key: string;
  name: string;
  tooltip: string;
  link?: string;
  icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>> & {
    muiName: string;
  };
  validationFunc: string;
}
export interface MenuOptions extends BaseMenuOptions {
  SubMenu?: BaseMenuOptions[];
}

export const Menus: MenuOptions[] = [
  {
    key: 'Home',
    name: 'Home',
    tooltip: 'home page',
    link: '/',
    icon: Icons.Home,
    validationFunc: 'default',
  },
  {
    key: 'License',
    name: 'License',
    tooltip: 'license management',
    link: '/licenses',
    icon: Icons.Description,
    validationFunc: 'hasLicenseModuleAccess',
  },
  {
    key: 'Administration',
    name: 'Administration',
    tooltip: 'Administration',
    icon: Icons.AdminPanelSettings,
    validationFunc: 'default',
    SubMenu: [
      {
        key: 'EmailAdmin',
        name: 'Email',
        tooltip: 'Email',
        link: '/emailadministration',
        icon: Icons.Email,
        validationFunc: 'hasEmailAccess',
      },
      {
        key: 'KeyVault',
        name: 'Key Vault',
        tooltip: 'Manage Secure Keys',
        link: '/keyvault',
        icon: Icons.Key,
        validationFunc: 'hasKeyVaultAccess',
      },
      {
        key: 'accesscontrol',
        name: 'Access Control',
        tooltip: 'Access Control',
        link: '/accesscontrol',
        icon: Icons.PersonAdd,
        validationFunc: 'hasAccessControlAccess',
      },
      {
        key: 'Audit',
        name: 'Audit Trail',
        tooltip: 'Audit Trail',
        link: '/audits',
        icon: Icons.AutoStories,
        validationFunc: 'hasAuditAccess',
      },
    ],
  },
  {
    key: 'Monitoring',
    name: 'Monitoring',
    tooltip: 'Monitoring',
    icon: Icons.MonitorHeartOutlined,
    validationFunc: 'default',
    SubMenu: [
      {
        key: 'Oncallsupport',
        name: 'On call support',
        tooltip: '24/7 On call support & notification',
        link: '/monitoring',
        icon: Icons.NotificationsActive,
        validationFunc: 'default',
      },
      {
        key: 'Metrics',
        name: 'Metrics',
        tooltip: 'view tenant metrics',
        link: '/metrics',
        icon: Icons.Assessment,
        validationFunc: 'default',
      },
    ],
  },
  {
    key: 'infrastructure',
    name: 'Infrastructure',
    tooltip: 'Infrastructure',
    icon: Icons.CloudCircle,
    validationFunc: 'default',
    SubMenu: [
      {
        key: 'farm',
        name: 'Manage Farms',
        tooltip: 'Manage Farms',
        link: '/farm',
        icon: Icons.Diversity2,
        validationFunc: 'default',
      },
      {
        key: 'Customer',
        name: 'Customers',
        tooltip: 'manage customers',
        link: '/customer',
        icon: Icons.People,
        validationFunc: 'default',
      },
      {
        key: 'SequalServer',
        name: 'Sql Server',
        tooltip: 'Sql Server',
        link: '/sqlserver',
        icon: Icons.Storage,
        validationFunc: 'hasStorageAccountAccess',
      },
      {
        key: 'StorageAccount',
        name: 'Storage Account',
        tooltip: 'Storage Account',
        link: '/storageaccount',
        icon: Icons.Folder,
        validationFunc: 'hasStorageAccountAccess',
      },
      {
        key: 'AddOn',
        name: 'AddOn Services',
        tooltip: 'AddOn Services',
        link: '/services',
        icon: Icons.SettingsApplications,
        validationFunc: 'default',
      },
    ],
  },
  {
    key: 'Clients',
    name: 'Clients',
    tooltip: 'Clients',
    icon: Icons.DownloadForOfflineRounded,
    validationFunc: 'default',
    link: '/clients',
  },
];

export const publicMenus: MenuOptions[] = [
  {
    key: 'Clients',
    name: 'Clients',
    tooltip: 'Clients',
    icon: Icons.DownloadForOfflineRounded,
    validationFunc: 'default',
    link: '/clients',
  },
];
export default Menus;
