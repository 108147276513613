export const plans = [
  'Not Selected',
  'S1',
  'S2',
  'S3',
  'P1V2',
  'P2V2',
  'P3V2',
  'P1V3',
  'P2V3',
  'P3V3',
  'P0V3',
  'P1V3',
  'P1MV3',
  'P2MV3',
  'P3MV3',
  'P4MV3',
  'P5MV3',
];
