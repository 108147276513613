import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Snackbar,
  TextField,
} from '@mui/material';
import { createStyles } from '@mui/styles';
import withStyles, { WithStyles } from '@mui/styles/withStyles';
import { Component } from 'react';
import {
  DialogOptions,
  OnCallSubstituteUser,
  OnCallUser,
} from '../Model/MonitoringModel';
import { monitoringService } from '../MonitoringService';
import React from 'react';

const styles = createStyles({
  pos: {
    marginBottom: 12,
    marginRight: 20,
  },
});

interface Props extends WithStyles<typeof styles> {
  open: boolean;
  onCallUsers: OnCallUser[];
  weeks: number[];
  onClose: (options: DialogOptions) => void;
}

interface SubstituteUserState extends OnCallSubstituteUser {
  loading: boolean;
  showSnackbar: boolean;
  snackbarMessage: string;
}

class SubstituteUser extends Component<
  Props,
  SubstituteUserState,
  OnCallUser[]
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      Id: 0,
      ActualUserId: '',
      SubstituteUserId: '',
      WeekNumber: 0,
      Valid: false,
      loading: false,
      showSnackbar: false,
      snackbarMessage: '',
    };
  }

  handleWeekSelection = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const week = Number(event.target.value);

    this.setState({
      WeekNumber: week,
      loading: true,
      SubstituteUserId: '',
    });

    const oncalluser = this.props.onCallUsers[week - this.props.weeks[0]];

    monitoringService.getUserByWeek(week, oncalluser).then((user) => {
      this.setState({
        ActualUserId: user.Id,
        loading: false,
      });
    });
  };

  getCurrentUser = () => {
    return this.props.onCallUsers.find(
      (user) => user.Id === this.state.ActualUserId,
    )?.Name;
  };

  handleInputChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    let name = event.target.id;
    if (name == undefined) {
      name = event.target.name;
    }
    this.setState({
      [name]: event.target.value,
    } as unknown as Pick<OnCallSubstituteUser, keyof OnCallSubstituteUser>);
  };

  getEmptySubsUser = () => {
    return {
      Id: 0,
      ActualUserId: '',
      SubstituteUserId: '',
      WeekNumber: 0,
      Valid: false,
    };
  };

  handleOk = () => {
    this.setState({ loading: true });

    const oncalluser =
      this.props.onCallUsers[this.state.WeekNumber - this.props.weeks[0]];

    const onCallSubstituteUser: OnCallSubstituteUser = {
      Id: this.state.Id,
      ActualUserId: oncalluser.Id,
      SubstituteUserId: this.state.SubstituteUserId,
      WeekNumber: this.state.WeekNumber,
      Valid: true,
    };

    monitoringService
      .substituteOnCallUser(onCallSubstituteUser)
      .then((user) => {
        this.setState(this.getEmptySubsUser());
        const actualUser = this.props.onCallUsers.find(
          (u: OnCallUser) => u.Id == user.ActualUserId,
        );
        const substitutedUser = this.props.onCallUsers.find(
          (u: OnCallUser) => u.Id == user.SubstituteUserId,
        );

        this.setState({
          snackbarMessage:
            actualUser?.Name +
            ' is substituted with ' +
            substitutedUser?.Name +
            ' for the week ' +
            user.WeekNumber.toString(),
        });
        this.setState({ showSnackbar: true, loading: false });
        this.props.onClose(DialogOptions.Ok);
      })
      .catch((err) => {
        console.log(err.message);
        this.setState({
          snackbarMessage: 'Failed to substitute user',
        });
        this.setState({ showSnackbar: true, loading: false });
        this.props.onClose(DialogOptions.Cancel);
      });
  };

  handleCancel = () => {
    this.setState(this.getEmptySubsUser());
    this.props.onClose(DialogOptions.Cancel);
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };

  render() {
    const { open, classes, onClose, onCallUsers, weeks, ...other } = this.props;
    return (
      <div>
        <Dialog
          disableEscapeKeyDown
          open={open}
          aria-labelledby="form-dialog-title"
          {...other}
          onClose={() => onClose(DialogOptions.Cancel)}
        >
          <DialogTitle id="form-dialog-title">Substitute user</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter user details to substitute user.
            </DialogContentText>
            <br />
            <TextField
              fullWidth
              margin="dense"
              name="WeekNumber"
              label="Week No"
              value={this.state.WeekNumber}
              onChange={this.handleWeekSelection()}
              select
            >
              {weeks.map((week) => (
                <MenuItem key={week} value={week}>
                  {week}
                </MenuItem>
              ))}
            </TextField>
            <br />
            {this.state.ActualUserId && (
              <>
                <div>{'On call for the week is ' + this.getCurrentUser()}</div>
                <>
                  <br />
                  <TextField
                    fullWidth
                    autoFocus
                    margin="dense"
                    name="SubstituteUserId"
                    value={this.state.SubstituteUserId}
                    variant="outlined"
                    label="Select Substitute User"
                    onChange={this.handleInputChange()}
                    select
                  >
                    {onCallUsers
                      .filter((user) => user.Id != this.state.ActualUserId)
                      .map((user) => (
                        <MenuItem key={user.Id} value={user.Id}>
                          {user.Name}
                        </MenuItem>
                      ))}
                  </TextField>
                  <br />
                </>
              </>
            )}
          </DialogContent>
          <DialogActions>
            {this.state.loading ? (
              <CircularProgress size={20} className={classes.pos} />
            ) : (
              <div>
                <Button onClick={this.handleCancel} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.handleOk} color="primary">
                  Substitute
                </Button>
              </div>
            )}
          </DialogActions>
        </Dialog>
        <Snackbar
          open={this.state.showSnackbar}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackbarMessage}</span>}
        />
      </div>
    );
  }
}

export default withStyles(styles)(SubstituteUser);
