import { Theme, alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    Root: {
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    Paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    Space: {
      flex: '1 1 100%',
    },
    fab: {
      position: 'absolute',
      top: theme.spacing(9),
      right: theme.spacing(1),
    },

    SearchOptions: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.black, 0.1),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.05),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
      marginRight: 30,
    },
    SearchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    Title: {
      flex: '0 0 auto',
    },
    Container: {
      maxHeight: '65vh !important',
    },
    TableWrapper: {
      overflowX: 'auto',
    },
    TableRows: {
      '& .MuiTableCell-root': {
        padding: '12px',
      },
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.10) !important',
        cursor: 'pointer',
      },
    },
    Progressbar: {
      marginLeft: '50%',
      marginTop: '15%',
    },
    Zebra: {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
    Cellhighlight: {
      color: '#f44336',
      fontWeight: 'bold',
    },
    HideCell: {
      display: 'none',
    },
    ShowCell: {
      display: 'table-cell',
    },
    SpaceBetween: {
      flex: '1 1 100%',
    },
    PageTitle: {
      '& h6#tableTitle': {
        width: 'max-content',
      },
    },
    ButtonProgress: {
      marginTop: '6px',
      marginLeft: '-110px',
      position: 'absolute',
    },
    RefreshLicenseButton: {
      width: '200px',
      marginRight: '5px',

      '& svg.MuiSvgIcon-root': {
        marginRight: '2px',
      },

      '& button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary':
        {
          width: '180px',
        },
    },
    Backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    dialogActionButton: {
      '& .MuiButton-root.MuiButton-textPrimary': {
        color: theme.palette.primary.light,
      },
    },
    RootInput: {
      color: 'inherit',
    },
    input: {
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 120,
        '&:focus': {
          width: 200,
        },
      },
    },
  }),
);
