import React, { useState } from 'react';
import { Icon, Tooltip, Fab } from '@mui/material';
import { PackageOptionProps } from '../Model/ClientsModel';
import { TriggerConstants } from '../../Trigger/Model';
import UploadClientPackage from './UploadClientPackage';
import { useFabStyles } from '../../../../Common/Styles/FabStyle';

export default function ClientOptions(props: Readonly<PackageOptionProps>) {
  const classes = useFabStyles();
  const [showUploadWindow, setShowUploadWindow] = useState(false);

  const openUploadWindow = () => {
    setShowUploadWindow(true);
  };

  const closeUploadWindow = () => {
    setShowUploadWindow(false);
  };

  const onUploadComplete = async () => {
    setShowUploadWindow(false);
    await props.loadClientsData();
  };

  return (
    <div className={classes.flexRowContainer}>
      <Tooltip title={TriggerConstants.UploadButtonTooltip}>
        <Fab
          className={classes.firstFab}
          aria-label="upload"
          color="primary"
          onClick={openUploadWindow}
        >
          <Icon>publish</Icon>
        </Fab>
      </Tooltip>
      <UploadClientPackage
        open={showUploadWindow}
        onClose={closeUploadWindow}
        onUploaded={onUploadComplete}
      />
    </div>
  );
}
