import React from 'react';
import { IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';
import { clientsService } from '../ClientsService';
import { toast } from 'react-toastify';

const DeleteClientPackage = (props: {
  container: string;
  filename: string;
  loadClientsData: () => Promise<void>;
}) => {
  const handleClick = () => {
    const confirm = window.confirm(
      'Are you sure!, you want to delete the the package ' + props.filename,
    );
    if (confirm) {
      clientsService
        .deleteClientfile(props.container, props.filename)
        .then(() => {
          props.loadClientsData();
          toast.success(props.filename + ' deleted successfuly');
        });
    }
  };

  return (
    <IconButton onClick={handleClick}>
      <Delete color="primary" />
    </IconButton>
  );
};

export default DeleteClientPackage;
