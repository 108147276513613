import { toast } from 'react-toastify';
import { EmailTemplate } from '../Model/EmailAdminModel';
import { emailadminService } from '../EmailAdministrationService';
import { Farm } from '../../Farm/Model/FarmModel';
import { CheckBoxItem, Tag } from '../../../Common/CommonModel';
import { Customer } from '../../Customer/Model/CustomerModel';
import { History } from 'history';

export const saveEmailTemplate = async (
  history: History<History.PoorMansUnknown>,
  emailTemplate: EmailTemplate | undefined,
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>,
  setEmailTemplate:
    | React.Dispatch<React.SetStateAction<EmailTemplate>>
    | React.Dispatch<React.SetStateAction<EmailTemplate | undefined>>,
) => {
  if (emailTemplate && validateTemplate(emailTemplate)) {
    setLoadingState(true);
    await emailadminService
      .saveEmailTemplate(emailTemplate.Name, emailTemplate)
      .then((template) => {
        setEmailTemplate(template);
        toast.success(emailTemplate.Name + ' saved successfuly');
        setLoadingState(false);
        history.push('/emailadministration');
      });
  }
};

export const createEmailTemplate = async (
  emailTemplate: EmailTemplate | undefined,
  setLoadingState: React.Dispatch<React.SetStateAction<boolean>>,
  history: History<History.PoorMansUnknown>,
  setEmailTemplate: React.Dispatch<React.SetStateAction<EmailTemplate>>,
) => {
  if (emailTemplate && validateTemplate(emailTemplate)) {
    setLoadingState(true);
    await emailadminService
      .createEmailTemplate(emailTemplate.Name, emailTemplate)
      .then((template) => {
        setEmailTemplate(template);
        toast.success(emailTemplate.Name + ' saved successfuly');
        setLoadingState(false);
        history.push('/emailadministration');
      });
  }
};

const validateTemplate = (emailTemplate: EmailTemplate) => {
  if (emailTemplate.Name === '') {
    toast.error('Template Name should not be empty');
    return false;
  } else if (emailTemplate.Subject === '') {
    toast.error('Subject should not be empty');
    return false;
  } else if (emailTemplate.Product === '') {
    toast.error('Product must be selected');
    return false;
  } else {
    return true;
  }
};

export const GetFarmCheckBoxList = (farms: Farm[]) => {
  const farmsList: CheckBoxItem[] = [];
  farms.forEach((farm) => {
    const farmCheckBox: CheckBoxItem = {
      Check: false,
      Text: farm.DisplayName + ' (ID: ' + farm.Id + ')',
      Value: farm.Id.toString(),
    };
    farmsList.push(farmCheckBox);
  });
  return farmsList;
};

export const GetCustomerCheckBoxList = (customers: Customer[]) => {
  const customersList: CheckBoxItem[] = [];
  customers.forEach((customer) => {
    const farmCheckBox: CheckBoxItem = {
      Check: false,
      Text: customer.Subdomain,
      Value: customer.Id,
    };
    customersList.push(farmCheckBox);
  });
  return customersList;
};

export const GetTagCheckBoxList = (tags: Tag[]) => {
  const tagsList: CheckBoxItem[] = [];
  tags.forEach((tag) => {
    const tagCheckBox: CheckBoxItem = {
      Check: false,
      Text: tag.TagName,
      Value: tag.TagName,
    };
    tagsList.push(tagCheckBox);
  });
  return tagsList;
};

export const IsValidEmail = (email: string) => {
  const regexp = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );
  return regexp.test(email);
};

export const ValidateEmails = (value: unknown) => {
  if (typeof value === 'string') {
    const emails = value.split(',').map((email) => email.trim());
    for (const email of emails) {
      if (!IsValidEmail(email)) {
        return false;
      }
    }
    return true;
  }
  return false;
};
