import {
  Button,
  Card,
  Checkbox,
  FormControl,
  LinearProgress,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect } from 'react';
import { farmService } from '../FarmService';
import { Farm, Tenant } from '../Model/FarmModel';
import {
  RemoveDuplicateAppserviceplans,
  createNewAppServicePlan,
} from '../../../../Common/CommonMethods';
import { plans } from '../../../Common/Constants/ValueConstants';
import { SimpleDialog } from '../../../Common/Dialog/SimpleDialog';
import { tenantStateMapping } from '../StateMapper/StateMapper';
import { Save } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { StorageAccount } from '../../StorageAccount/Model/StorageAccountModel';
import StorageAccountConstants from '../../StorageAccount/Common/Constants';
import { GetCustomerEditActionLink } from '../../../../Common/CommonComponents';
import { CustomerV2 } from '../../Customer/Model/CustomerModel';
import { customerService } from '../../Customer/CustomerService';
import { SqlServer } from '../../SqlServer/Model/SqlServerModel';
import {
  ThemedDoneIcon,
  ThemedWarningIcon,
} from '../../../../Common/Components/Icons';
import { MenuProps } from '../../DashBoard/Common/CommonConstants';

const TenantTable = (props: {
  farm: Farm;
  storageaccounts: StorageAccount[];
  sqlServers: SqlServer[];
  replicaServers: SqlServer[];
}) => {
  const { farm, storageaccounts, sqlServers, replicaServers } = props;
  const [loading, setLoading] = React.useState(0);
  const [originalTenants, setOriginalTenants] = React.useState<Tenant[]>([]);
  const [tenants, setTenants] = React.useState<Tenant[]>([]);

  const [appserviceplans, setAppserviceplans] = React.useState<string[]>([]);
  const [openAppserviceDialogue, setOpenAppserviceDialogue] =
    React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(plans[0]);
  const [customers, setCustomers] = React.useState<CustomerV2[]>();

  useEffect(() => {
    Init();
  }, []);

  const Init = () => {
    loadAppservicePlans(farm.Id);
    loadTenants(farm.Id);
    loadCustomers();
  };

  const loadTenants = async (FarmId: number) => {
    try {
      setLoading(loading + 1);
      const response = await farmService.getTenantsOnFarm(FarmId);
      setTenants(response.Items);
      setOriginalTenants(JSON.parse(JSON.stringify(response.Items))); // Clone the original data
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  const loadCustomers = async () => {
    try {
      setLoading(loading + 1);
      await customerService.getCustomers().then((customers) => {
        setCustomers(customers.Items);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  const loadAppservicePlans = async (FarmId: number) => {
    try {
      setLoading(loading + 1);
      await farmService.getAppservicePlans(FarmId).then((appserviceplans) => {
        setAppserviceplans(appserviceplans);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(loading - 1);
    }
  };

  const addItemToState = (item: string[]) => {
    const newItem = createNewAppServicePlan(appserviceplans, item, farm.Id);
    const appAppservicePlans = appserviceplans.concat(appserviceplans, newItem);
    setAppserviceplans(RemoveDuplicateAppserviceplans(appAppservicePlans));
  };

  const handleClickOpen = () => {
    setOpenAppserviceDialogue(true);
  };

  const SaveTenants = () => {
    const confirm = window.confirm('Are you sure you want to update tenants?');
    if (confirm) {
      tenants.forEach((tenant, index) => {
        const originalTenant = originalTenants[index];
        if (!areTenantsEqual(tenant, originalTenant)) {
          const updatedTenant = { ...tenant };
          farmService
            .setTenant(updatedTenant.FarmId, tenant.Id, updatedTenant)
            .then((response) =>
              toast.success(response.Id + ' updated successfully'),
            )
            .catch((error) => toast.error('failed to save ' + error));
        }
      });
      Init();
    }
  };

  const areTenantsEqual = (tenant1: Tenant, tenant2: Tenant) => {
    return JSON.stringify(tenant1) === JSON.stringify(tenant2);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (name.includes('Appserviceplan-')) {
      const tenantId = name.replace('Appserviceplan-', '');
      setTenants((tenants) =>
        tenants.map((tenant) =>
          tenant.Id === tenantId
            ? { ...tenant, AppservicePlan: value }
            : tenant,
        ),
      );
    }

    if (name.includes('SqlServer-')) {
      const tenantId = name.replace('SqlServer-', '');
      setTenants((tenants) =>
        tenants.map((tenant) =>
          tenant.Id === tenantId
            ? { ...tenant, AzureSQLServerName: value, Replicas: [] }
            : tenant,
        ),
      );
    }

    if (name.includes('StorageAccountName-')) {
      if (window.confirm(StorageAccountConstants.StorageAccoutChangeWarn)) {
        const tenantId = name.replace('StorageAccountName-', '');
        setTenants((tenants) =>
          tenants.map((tenant) =>
            tenant.Id === tenantId
              ? { ...tenant, StorageAccountName: value }
              : tenant,
          ),
        );
      }
    }
  };

  const handleClose = (value: string) => {
    setOpenAppserviceDialogue(false);
    if ('Not Selected' !== value) {
      setSelectedValue(value);
      const appserviceplan: string[] = [];
      appserviceplan.push(value);
      addItemToState(appserviceplan);
    }
  };

  const getTenantSubDomain = (tenantId: string) => {
    return customers?.find(
      (x) => x.Id?.toLocaleLowerCase() === tenantId.toLocaleLowerCase(),
    )?.Subdomain;
  };

  const handleReplicaChange = (
    event: SelectChangeEvent<string[]>,
    tenant: Tenant,
  ) => {
    const values = event.target.value as string[];

    let duplicatesRemoved: string[] = [];

    values.forEach((item) => {
      if (duplicatesRemoved.findIndex((o) => o === item) >= 0) {
        duplicatesRemoved = duplicatesRemoved.filter((x) => x === item);
      } else {
        duplicatesRemoved.push(item);
      }
    });

    if (!(duplicatesRemoved.length > 3)) {
      setTenants((tenants) =>
        tenants.map((item) =>
          item.Id === tenant.Id
            ? { ...item, Replicas: duplicatesRemoved }
            : item,
        ),
      );
    } else {
      toast.error(
        'More than 3 replicas are not allowed. please unselect any other',
      );
    }
  };

  return (
    <Card elevation={2} sx={{ margin: 2 }}>
      <TableContainer style={{ maxHeight: 450 }}>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Customer Id</TableCell>
              <TableCell>Subdomain</TableCell>
              <TableCell>State</TableCell>
              <TableCell>Appservice Plan</TableCell>
              <TableCell>Storage Account</TableCell>
              <TableCell>Sql Server</TableCell>
              <TableCell>Replicas</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading > 0 ? (
              <TableRow>
                <TableCell colSpan={8}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            ) : (
              tenants.map((tenant, index) => {
                const hasChanged = !areTenantsEqual(
                  tenant,
                  originalTenants[index],
                );
                return (
                  <TableRow key={tenant.Id}>
                    <TableCell>
                      {hasChanged ? (
                        <ThemedWarningIcon titleAccess="Edited" />
                      ) : (
                        <ThemedDoneIcon titleAccess="Latest" />
                      )}
                    </TableCell>
                    <TableCell>
                      <GetCustomerEditActionLink id={tenant.Id} />
                    </TableCell>
                    <TableCell>{getTenantSubDomain(tenant.Id)}</TableCell>
                    <TableCell>{tenantStateMapping[tenant.StatusID]}</TableCell>
                    <TableCell>
                      <Select
                        name={`Appserviceplan-${tenant.Id}`}
                        style={{ width: '250px' }}
                        value={tenant.AppservicePlan}
                        onChange={(e) =>
                          handleChange(e as React.ChangeEvent<HTMLInputElement>)
                        }
                        MenuProps={MenuProps}
                      >
                        <MenuItem
                          value={'Create New Appservice Plan'}
                          onClick={handleClickOpen}
                        >
                          Create New Appservice Plan
                        </MenuItem>
                        {appserviceplans.map((plan) => (
                          <MenuItem key={plan} value={plan}>
                            {plan}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Select
                        name={`StorageAccountName-${tenant.Id}`}
                        style={{ width: '250px' }}
                        value={tenant.StorageAccountName}
                        onChange={(e) =>
                          handleChange(e as React.ChangeEvent<HTMLInputElement>)
                        }
                        MenuProps={MenuProps}
                      >
                        {storageaccounts.map((item) => (
                          <MenuItem key={item.Name} value={item.Name}>
                            {item.Name}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <Select
                        name={`SqlServer-${tenant.Id}`}
                        style={{ width: '250px' }}
                        value={tenant.AzureSQLServerName}
                        onChange={(e) =>
                          handleChange(e as React.ChangeEvent<HTMLInputElement>)
                        }
                        MenuProps={MenuProps}
                      >
                        {sqlServers.map((item) => (
                          <MenuItem
                            key={item.ServerName}
                            value={item.ServerName}
                          >
                            {item.ServerName}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    {tenant.AzureSQLServerName &&
                      tenant.AzureSQLServerName != '' && (
                        <TableCell>
                          <FormControl style={{ width: '250px' }}>
                            <Select
                              multiple
                              value={tenant.Replicas || []}
                              onChange={(event) =>
                                handleReplicaChange(event, tenant)
                              }
                              inputProps={{ 'aria-label': 'Without label' }}
                              renderValue={(selected) => selected.join(', ')}
                              MenuProps={MenuProps}
                            >
                              {replicaServers
                                .filter(
                                  (server) =>
                                    server.ServerName !=
                                    tenant.AzureSQLServerName,
                                )
                                .map((server) => (
                                  <MenuItem
                                    key={server.ServerName}
                                    value={server.ServerName}
                                  >
                                    <Checkbox
                                      checked={
                                        (tenant.Replicas || []).indexOf(
                                          server.ServerName,
                                        ) > -1
                                          ? true
                                          : false
                                      }
                                    />
                                    <ListItemText primary={server.ServerName} />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                      )}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        color="primary"
        startIcon={<Save />}
        onClick={SaveTenants}
      >
        Save Tenants
      </Button>

      <SimpleDialog
        selectedValue={selectedValue}
        open={openAppserviceDialogue}
        onClose={handleClose}
      />
    </Card>
  );
};

export default TenantTable;
export function renderAppservicePlanDialogue(
  handleClose: (value: string) => void,
  openAppserviceDialogue: boolean,
  selectedValue: string,
): React.ReactNode {
  return (
    <SimpleDialog
      onClose={handleClose}
      open={openAppserviceDialogue}
      selectedValue={selectedValue}
    />
  );
}
