import {
  Card,
  Checkbox,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import { StorageAccount } from '../../StorageAccount/Model/StorageAccountModel';
import { RegisterTenant, TenantForm } from '../Model/TenantModels';
import { renderAppservicePlanDialogue } from '../../ManageFarms/Components/TenantTable';
import { ValidationErrors, Validator } from 'fluentvalidation-ts';
import { useStyles } from '../../../../Common/Styles/FarmStyle';
import { SqlServer } from '../../SqlServer/Model/SqlServerModel';
import { MenuProps } from '../../DashBoard/Common/CommonConstants';

interface TenantRegistrationFormProps {
  customerId: string;
  FarmId: string;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  registerTenant: RegisterTenant | undefined;
  storageAccounts: StorageAccount[];
  sqlServers: SqlServer[];
  replicaServers: SqlServer[];
  appserviceplans: string[];
  storageAccount: string;
  sqlServer: string;
  replicas: string[];
  handleReplicaSelection: (values: string[]) => void;
  setStorageAccount: React.Dispatch<React.SetStateAction<string>>;
  setSqlServer: React.Dispatch<React.SetStateAction<string>>;
  appserviceplan: string;
  setAppserviceplan: React.Dispatch<React.SetStateAction<string>>;
  handleClickOpen: () => void;
  setOpenAppserviceDialogue: React.Dispatch<React.SetStateAction<boolean>>;
  openAppserviceDialogue: boolean;
  selectedValue: string;
  handleCloseAppservicePlan: (value: string) => void;
  errors: ValidationErrors<TenantForm>;
  setErrors: React.Dispatch<React.SetStateAction<ValidationErrors<TenantForm>>>;
}

class FormValidator extends Validator<TenantForm> {
  constructor() {
    super();
    this.ruleFor('CustomerId')
      .notEmpty()
      .withMessage('customr Id cannot be empty');

    this.ruleFor('FarmId').notEmpty().withMessage('Farm Id cannot be empty');

    this.ruleFor('StorageAccount')
      .notEmpty()
      .withMessage('Storage Account cannot be empty');

    this.ruleFor('AppservicePlan')
      .notEmpty()
      .withMessage('Appservice Plan cannot be empty');

    this.ruleFor('SqlServer')
      .notEmpty()
      .withMessage('Sql Server Cannot be empty');
  }
}

const TenantRegistrationForm = (props: TenantRegistrationFormProps) => {
  const {
    customerId,
    FarmId,
    handleChange,
    registerTenant,
    storageAccounts,
    appserviceplan,
    storageAccount,
    sqlServer,
    replicas,
    setSqlServer,
    handleReplicaSelection,
    sqlServers,
    replicaServers,
    setStorageAccount,
    appserviceplans,
    setAppserviceplan,
    handleClickOpen,
    openAppserviceDialogue,
    selectedValue,
    handleCloseAppservicePlan,
    errors,
    setErrors,
  } = props;
  const classes = useStyles();
  const form: TenantForm = {
    CustomerId: customerId,
    FarmId: FarmId,
    StorageAccount: storageAccount,
    AppservicePlan: appserviceplan,
    SqlServer: sqlServer,
    Replicas: replicas,
  };

  const formValidator = new FormValidator();

  const validate = () => {
    const formErrors = formValidator.validate(form);
    setErrors(formErrors);
  };

  useEffect(() => {
    validate();
  }, []);

  return (
    <>
      <div className={classes.outerContainer}>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card elevation={0} className={classes.card}>
              <h3>Farm information</h3>
              <div>
                <div className={classes.spanContainer}>
                  <span className={classes.span}>Tenant Id</span>
                  <TextField
                    disabled
                    className={classes.textField}
                    value={form.CustomerId}
                  />
                </div>
              </div>
              <div>
                <div className={classes.spanContainer}>
                  <span className={classes.span}>Farm</span>
                  <TextField
                    className={classes.textField}
                    error={!!errors.FarmId}
                    helperText={errors.FarmId}
                    value={form.FarmId}
                    placeholder="FarmId"
                    name="FarmId"
                    onBlur={validate}
                    onChange={handleChange}
                    select
                  >
                    {registerTenant?.Farms.map((farm) => {
                      return (
                        <MenuItem
                          key={farm.Id.toString()}
                          value={farm.Id.toString()}
                        >
                          {farm.DisplayName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div>
                <div className={classes.spanContainer}>
                  <span className={classes.span}>Storage Account</span>
                  <TextField
                    disabled={FarmId === undefined ? true : false}
                    error={!!errors.StorageAccount}
                    helperText={errors.StorageAccount}
                    className={classes.textField}
                    value={form.StorageAccount}
                    placeholder="StorageAccount"
                    name="StorageAccount"
                    onBlur={validate}
                    onChange={(e) => setStorageAccount(e.target.value)}
                    select
                  >
                    {storageAccounts.map((sa) => {
                      return (
                        <MenuItem key={sa.Name} value={sa.Name}>
                          {sa.Name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              <div>
                <div className={classes.spanContainer}>
                  <span className={classes.span}>Sql Server</span>
                  <TextField
                    disabled={FarmId === undefined ? true : false}
                    error={!!errors.SqlServer}
                    helperText={errors.SqlServer}
                    className={classes.textField}
                    value={form.SqlServer}
                    placeholder="SqlServer"
                    name="SqlServer"
                    onBlur={validate}
                    onChange={(e) => {
                      setSqlServer(e.target.value);
                      handleReplicaSelection([]);
                    }}
                    select
                  >
                    {sqlServers.map((server) => {
                      return (
                        <MenuItem
                          key={server.ServerName}
                          value={server.ServerName}
                        >
                          {server.ServerName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </div>
              </div>
              {replicaServers && (
                <div>
                  <div className={classes.spanContainer}>
                    <span className={classes.span}>Replicas</span>
                    <FormControl className={classes.textField}>
                      <Select
                        multiple
                        value={replicas || []}
                        onChange={(event) =>
                          handleReplicaSelection(event.target.value as string[])
                        }
                        inputProps={{ 'aria-label': 'Without label' }}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                        onBlur={validate}
                      >
                        {replicaServers
                          .filter(
                            (server) => server.ServerName != form.SqlServer,
                          )
                          .map((server) => (
                            <MenuItem
                              key={server.ServerName}
                              value={server.ServerName}
                            >
                              <Checkbox
                                checked={
                                  (replicas || []).indexOf(server.ServerName) >
                                  -1
                                    ? true
                                    : false
                                }
                              />
                              <ListItemText primary={server.ServerName} />
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}
              <div>
                <div className={classes.spanContainer}>
                  <span className={classes.span}>Appservice Plan</span>
                  <TextField
                    disabled={FarmId === undefined ? true : false}
                    error={!!errors.AppservicePlan}
                    helperText={errors.AppservicePlan}
                    className={classes.textField}
                    value={form.AppservicePlan}
                    placeholder="appserviceplan"
                    name="appserviceplan"
                    onBlur={validate}
                    onChange={(e) => setAppserviceplan(e.target.value)}
                    select
                  >
                    <MenuItem
                      value={'Create New Appservice Plan'}
                      onClick={handleClickOpen}
                    >
                      Create New Appservice Plan
                    </MenuItem>
                    {appserviceplans.map((ap) => {
                      return (
                        <MenuItem key={ap} value={ap}>
                          {ap}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                  {openAppserviceDialogue &&
                    renderAppservicePlanDialogue(
                      handleCloseAppservicePlan,
                      openAppserviceDialogue,
                      selectedValue,
                    )}
                </div>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TenantRegistrationForm;
