import React, { ChangeEvent } from 'react';
import { Toolbar, Typography, InputBase, Divider } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import CloudUpload from '@mui/icons-material/CloudUpload';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import DialogTitle from '@mui/material/DialogTitle';
import UriApp from './TrustedClientsRedirectUri';
import SaveIcon from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import { style } from '../../TrustedClients/CommonMethods/Theme';
import isURL from 'is-url';
import { toast } from 'react-toastify';
import { TrustedClients } from '../Model';
import { CustomerV2 } from '../../Customer/Model/CustomerModel';

interface TrustedClientsToolbarProps {
  handleDeployClients: () => void;
  onFilterRequest: (event: React.ChangeEvent<HTMLInputElement>) => void;
  OnAdd: (val: TrustedClients) => void;
  customer: CustomerV2;
}

export default function TrustedClientsHeaderToolbar(
  props: Readonly<TrustedClientsToolbarProps>,
) {
  const { onFilterRequest, customer } = props;
  const [, setSearchFilterText] = React.useState('');
  const [TrustedClient] = React.useState({
    ClientID: '',
    ClientUri: '',
    RedirectUris: [''],
    LogoutUri: '',
    AllowedScopes: [''],
    AllowedGrantTypes: [''],
  });
  const [ClientID, setFieldsClientID] = React.useState('');
  const [ClientUri, setFieldsClientUri] = React.useState('');
  const [LogoutUri, setFieldsLogoutUri] = React.useState('');
  const [RedirectUris, setFieldsRedirectUris] = React.useState(['']);
  const [AllowedScopes, setFieldsAllowedScopes] = React.useState(['']);
  const [AllowedGrantTypes, setFieldsAllowedGrantTypes] = React.useState(['']);

  const [ClientIDE, setFieldsClientIDE] = React.useState(false);
  const [ClientUriE, setFieldsClientUriE] = React.useState(false);
  const [LogoutUriE, setFieldsLogoutUriE] = React.useState(false);
  const [, setFieldsAllowedScopesE] = React.useState(false);
  const [, setFieldsAllowedGrantTypesE] = React.useState(false);
  const [RedirectUrisE, setFieldsRedirectUrisE] = React.useState(false);

  const classes = style();
  const createFilterHandler = () => (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFilterText(event.target.value);
    onFilterRequest(event);
  };

  const [isopen, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  function handleDeployClients() {
    props.handleDeployClients();
  }

  const handleDialogueClose = () => {
    setOpen(false);
  };

  const validRadio = (str: string[]) => {
    if (str.length >= 2) {
      return true;
    }
    return false;
  };

  const validateClientID = (str: string) => {
    if (str === '') {
      toast.error('Invalid Client Id');
      return false;
    }
    return true;
  };

  const validateRedirectUris = (str: string[]) => {
    if (str.length === 0 || RedirectUris.filter((i) => i === '').length) {
      return false;
    } else {
      let res = true;

      for (const i of str) {
        res = res && isURL(i);
      }
      return res;
    }
  };

  const IsClientValid = (res: boolean) => {
    if (validateClientID(ClientID) && res) {
      setFieldsClientIDE(true);
      return true;
    }
    return false;
  };

  const IsRedirectUriValid = (res: boolean) => {
    if (validateRedirectUris(RedirectUris) && res) {
      setFieldsRedirectUrisE(true);
      return true;
    }
    toast.error('Invalid Redirect Uri');
    return false;
  };
  const IsUriValidC = (res: boolean) => {
    if (isURL(ClientUri) && res) {
      setFieldsClientUriE(true);
      return true;
    }
    toast.error('Invalid Client Uri');
    return false;
  };
  const IsUriValid = (res: boolean) => {
    if (isURL(LogoutUri) && res) {
      setFieldsLogoutUriE(true);
      return true;
    }
    toast.error('Invalid Client Logout Uri');
    return false;
  };

  const IsRadioValid = (res: boolean) => {
    if (validRadio(AllowedScopes) && res) {
      setFieldsAllowedScopesE(true);
      return true;
    }
    toast.error('Select proper value for allowed scopes');
    return false;
  };

  const IsRadioValidGT = (res: boolean) => {
    if (validRadio(AllowedGrantTypes) && res) {
      setFieldsAllowedGrantTypesE(true);
      return true;
    }
    toast.error('Select proper value for allowed grant types');
    return false;
  };

  const Validate = () => {
    let res = true;
    res = IsClientValid(res);
    res = IsRedirectUriValid(res);
    res = IsUriValid(res);
    res = IsUriValidC(res);
    res = IsRadioValid(res);
    res = IsRadioValidGT(res);

    return res;
  };

  function handleSave() {
    if (Validate() === true) {
      TrustedClient.ClientID = ClientID;
      TrustedClient.ClientUri = ClientUri;
      TrustedClient.LogoutUri = LogoutUri;
      TrustedClient.RedirectUris = RedirectUris;
      TrustedClient.AllowedScopes = AllowedScopes;
      TrustedClient.AllowedGrantTypes = AllowedGrantTypes;
      props.OnAdd(TrustedClient);
      setFieldsClientID('');
      setFieldsClientUri('');
      setFieldsLogoutUri('');
      setFieldsRedirectUris(['']);
      setFieldsAllowedScopes(['']);
      setFieldsAllowedGrantTypes(['']);
      setFieldsClientIDE(false);
      setFieldsClientUriE(false);
      setFieldsLogoutUriE(false);
      setFieldsAllowedScopesE(false);
      setFieldsAllowedGrantTypesE(false);
      setFieldsRedirectUrisE(false);
      handleDialogueClose();
    }
  }

  function handleDialogueFieldChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ) {
    switch (event.target.name) {
      case 'ClientID':
        setFieldsClientID(event.target.value);
        break;

      case 'ClientUri':
        setFieldsClientUri(event.target.value);
        break;
      case 'LogoutUri':
        setFieldsLogoutUri(event.target.value);
        break;
      case 'RedirectUris': {
        const values = RedirectUris.filter((item: string) => item !== '');
        if (RedirectUris.indexOf(event.target.value) === -1) {
          if (event.target.checked) {
            RedirectUris.push(event.target.value);
          } else {
            const redirectUris = RedirectUris.filter(
              (item: string) => item !== event.target.value,
            );
            setFieldsRedirectUris(redirectUris);
          }
        }
        setFieldsRedirectUris(values);
        break;
      }

      case 'AllowedScopes': {
        const valuess = [...AllowedScopes];
        if (event.target.checked) {
          valuess.push(event.target.value);
          setFieldsAllowedScopes(valuess);
        } else {
          setFieldsAllowedScopes(
            valuess.filter((item: string) => item !== event.target.value),
          );
        }
        break;
      }

      case 'AllowedGrantTypes': {
        const val = [...AllowedGrantTypes];
        if (event.target.checked) {
          val.push(event.target.value);
          setFieldsAllowedGrantTypes(val);
        } else {
          setFieldsAllowedGrantTypes(
            val.filter((item: string) => item !== event.target.value),
          );
        }

        break;
      }
    }
  }

  function handleCheckAS(Name: string) {
    if (AllowedScopes.indexOf(Name) === -1) {
      return false;
    } else {
      return true;
    }
  }

  function handleCheck(Name: string) {
    if (AllowedGrantTypes.indexOf(Name) === -1) {
      return false;
    } else {
      return true;
    }
  }

  function onUpdate(val: string[]) {
    const arr = [];
    for (const j in val) {
      arr.push(val[j]);
    }
    setFieldsRedirectUris(arr);
  }

  return (
    <Toolbar className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6" id="tableTitle">
          Trusted Clients for customer: {customer.Subdomain}
        </Typography>
      </div>
      <div className={classes.spacer} />
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'Search' }}
          onChange={createFilterHandler()}
        />
      </div>
      <Button
        variant="contained"
        onClick={handleDeployClients}
        style={{ width: 600 }}
        className={classes.button}
        startIcon={<CloudUpload />}
      >
        Deploy Clients
      </Button>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        className={classes.button}
        startIcon={<AddIcon />}
      >
        Add
      </Button>
      <Dialog
        open={isopen}
        onClose={handleDialogueClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Trusted Client</DialogTitle>
        <DialogContent>
          <Table style={{ width: 550, textAlign: 'left' }}>
            <tr>
              <th>Client ID\Name:</th>
              <td>
                <TextField
                  error={ClientIDE}
                  required
                  placeholder="Client ID"
                  name="ClientID"
                  value={ClientID}
                  onChange={handleDialogueFieldChange}
                />
              </td>
            </tr>
            <tr>
              <th>Client Uri: </th>
              <td>
                {' '}
                <TextField
                  required
                  error={ClientUriE}
                  placeholder="Client Uri"
                  name="ClientUri"
                  value={ClientUri}
                  onChange={handleDialogueFieldChange}
                />
              </td>
            </tr>
            <tr>
              <th>{"Redirect Uri's: "}</th>
              <td>
                <UriApp
                  onUpdate={(e: string[]) => onUpdate(e)}
                  LUri={RedirectUris}
                  RedirectUrisE={RedirectUrisE}
                />
              </td>
            </tr>
            <tr>
              <th>Logout Uri: </th>
              <td>
                <TextField
                  error={LogoutUriE}
                  required
                  placeholder="Logout Uri"
                  name="LogoutUri"
                  value={LogoutUri}
                  onChange={handleDialogueFieldChange}
                />
              </td>
            </tr>
            <tr>
              <th>Scopes: </th>
              <td>
                <tr>
                  <td>
                    <input
                      name="AllowedScopes"
                      value="openid"
                      type="checkbox"
                      checked={handleCheckAS('openid')}
                      onChange={handleDialogueFieldChange}
                    />
                    openid
                  </td>
                  <td>
                    {' '}
                    <input
                      name="AllowedScopes"
                      value="profile"
                      type="checkbox"
                      checked={handleCheckAS('profile')}
                      onChange={handleDialogueFieldChange}
                    />
                    profile
                  </td>
                  <td>
                    <input
                      name="AllowedScopes"
                      value="Email"
                      type="checkbox"
                      checked={handleCheckAS('Email')}
                      onChange={handleDialogueFieldChange}
                    />
                    Email
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      name="AllowedScopes"
                      value="Address"
                      type="checkbox"
                      checked={handleCheckAS('Address')}
                      onChange={handleDialogueFieldChange}
                    />
                    Address{' '}
                  </td>
                  <td>
                    <input
                      name="AllowedScopes"
                      value="Phone"
                      type="checkbox"
                      checked={handleCheckAS('Phone')}
                      onChange={handleDialogueFieldChange}
                    />
                    Phone
                  </td>
                  <td>
                    <input
                      name="AllowedScopes"
                      value="OfflineAccess"
                      type="checkbox"
                      checked={handleCheckAS('OfflineAccess')}
                      onChange={handleDialogueFieldChange}
                    />
                    OfflineAccess
                  </td>
                </tr>
                <Divider />
                <br />
              </td>
            </tr>
            <tr>
              <th>Grant Types: </th>
              <td>
                <tr>
                  <td>
                    <input
                      name="AllowedGrantTypes"
                      value="hybrid"
                      type="checkbox"
                      checked={handleCheck('hybrid')}
                      onChange={handleDialogueFieldChange}
                    />
                    Hybrid
                  </td>
                  <td>
                    <input
                      name="AllowedGrantTypes"
                      value="client_credentials"
                      type="checkbox"
                      checked={handleCheck('client_credentials')}
                      onChange={handleDialogueFieldChange}
                    />
                    ClientCredentials
                  </td>
                  <td>
                    <input
                      name="AllowedGrantTypes"
                      value="urn:ietf:params:oauth:grant-type:device_code"
                      type="checkbox"
                      checked={handleCheck(
                        'urn:ietf:params:oauth:grant-type:device_code',
                      )}
                      onChange={handleDialogueFieldChange}
                    />
                    DeviceFlow
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      name="AllowedGrantTypes"
                      value="implicit"
                      type="checkbox"
                      checked={handleCheck('implicit')}
                      onChange={handleDialogueFieldChange}
                    />
                    Implicit
                  </td>
                  <td>
                    <input
                      name="AllowedGrantTypes"
                      value="authorization_code"
                      type="checkbox"
                      checked={handleCheck('authorization_code')}
                      onChange={handleDialogueFieldChange}
                    />
                    AuthorizationCode
                  </td>
                  <td>
                    <input
                      name="AllowedGrantTypes"
                      value="password"
                      type="checkbox"
                      checked={handleCheck('password')}
                      onChange={handleDialogueFieldChange}
                    />
                    ResourceOwnerPassword
                  </td>
                </tr>
              </td>
            </tr>
          </Table>
          <Divider />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleSave}
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            Save{' '}
          </Button>
          <Button
            variant="contained"
            onClick={handleDialogueClose}
            className={classes.button}
            startIcon={<Cancel />}
          >
            Cancel{' '}
          </Button>
        </DialogActions>
      </Dialog>
    </Toolbar>
  );
}
