import {
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material';
import { SimpleDialogProps } from '../Commonprops';
import React from 'react';
import { plans } from '../Constants/ValueConstants';

export function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      sx={{ maxHeight: '450px' }}
      scroll="paper"
    >
      <DialogTitle id="simple-dialog-title">Select Appservice Plan</DialogTitle>
      <Paper style={{ maxHeight: 350, overflow: 'auto' }}>
        <List>
          {plans
            .filter((x, y) => y !== 0)
            .map((plan) => (
              <ListItem
                button
                onClick={() => handleListItemClick(plan)}
                key={plan}
              >
                <ListItemText primary={plan} />
              </ListItem>
            ))}
        </List>
      </Paper>
    </Dialog>
  );
}
