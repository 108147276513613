import { Link } from '@mui/material';
import React from 'react';

type ActionLinkPorps = {
  id: string;
};

export const GetCustomerEditActionLink = (props: ActionLinkPorps) => {
  return <Link href={`customer/${props.id}/edit`}>{props.id}</Link>;
};
