import React, { Component } from 'react';
import { monitoringService } from '../MonitoringService';
import { OnCallUser } from '../Model/MonitoringModel';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Menu,
  MenuItem,
  Snackbar,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

interface UserMenuState {
  openMenu: boolean;
  anchorEl: HTMLButtonElement | null;
  showSnackbar: boolean;
  snackbarMessage: string;
  openDialog: boolean;
  dialogMessage: string;
  dialogAction: (() => void) | null;
}

interface UserMenuProps {
  onCallUser: OnCallUser;
  removeUser: (userID: string) => void;
}

class UserMenu extends Component<UserMenuProps, UserMenuState> {
  constructor(props: UserMenuProps) {
    super(props);
    this.state = {
      openMenu: false,
      anchorEl: null,
      showSnackbar: false,
      snackbarMessage: '',
      openDialog: false,
      dialogMessage: '',
      dialogAction: null,
    };
  }

  openUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openMenu: true, anchorEl: event.currentTarget });
  };

  handleUserMenuClose = () => {
    this.setState({ openMenu: false, anchorEl: null });
  };

  handleSnackbarClose = () => {
    this.setState({ showSnackbar: false });
  };

  handleDialogClose = (confirmed: boolean) => {
    this.setState({ openDialog: false, dialogMessage: '' });
    if (confirmed) {
      if (this.state.dialogAction) this.state.dialogAction();
    }
    this.handleUserMenuClose();
  };

  handleDeleteUser = (userId: string, name: string) => {
    const deleteMessage =
      name + ' will be deleted from the system, do you want to continue?';
    this.setState({ openDialog: true, dialogMessage: deleteMessage });
    this.setState({ dialogAction: () => this.deleteUser(userId, name) });
  };

  deleteUser = (userId: string, name: string) => {
    this.setState({
      snackbarMessage:
        'aha... we are removing ' + name + ' from on call users list',
    });
    this.setState({ showSnackbar: true });
    monitoringService
      .deleteOnCallUser(userId)
      .then(() => {
        this.props.removeUser(userId);
      })
      .catch((err) => {
        console.log(err.message);
        this.setState({
          snackbarMessage:
            'dang... failed to remove ' + name + ' from on call users list',
        });
        this.setState({ showSnackbar: true });
      });
  };

  render() {
    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns="long-menu"
          aria-haspopup="true"
          onClick={this.openUserMenu}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.openMenu}
          onClose={this.handleUserMenuClose}
        >
          <MenuItem
            onClick={() =>
              this.handleDeleteUser(
                this.props.onCallUser.Id,
                this.props.onCallUser.Name,
              )
            }
          >
            Delete
          </MenuItem>
        </Menu>
        <Snackbar
          open={this.state.showSnackbar}
          onClose={this.handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.snackbarMessage}</span>}
        />
        <Dialog
          disableEscapeKeyDown
          maxWidth="xs"
          open={this.state.openDialog}
          onClose={() => this.handleDialogClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.state.dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleDialogClose(true)}
              color="primary"
            >
              Confirm
            </Button>
            <Button
              onClick={() => this.handleDialogClose(false)}
              color="primary"
              autoFocus
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default UserMenu;
