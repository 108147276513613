import React from 'react';
import { ShowKeyVaultDataProps } from '../Model/AuditProps';
import { useStyles } from '../../../../Common/Styles/Theme';
import {
  Backdrop,
  CircularProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
  Typography,
} from '@mui/material';
import AuditTableHead from './AuditTableHeader';
import { getSorting, stableSort } from '../../../../Helpers/UtilityMethods';
import AditMenu from './AuditMenu';

const ShowKeyVaultData: React.FC<ShowKeyVaultDataProps> = ({
  AuditList,
  order,
  orderBy,
  loading,
  totalAuditsCount,
  pageSize,
  page,
  handleRequestSort,
  handlePageChange,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.TableWrapper}>
      <Table>
        <Backdrop className={classes.Backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <AuditTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(AuditList, getSorting(order, orderBy)).map((audit) => (
            <TableRow key={audit.Id.toString()}>
              <TableCell component="th" scope="row">
                <Typography variant="subtitle2">{audit.DateAdded}</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography variant="subtitle2">{audit.Entity}</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography variant="subtitle2">{audit.Action}</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <Typography variant="subtitle2">{audit.PerformedBy}</Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                <AditMenu details={audit.Details} Id={audit.Id} />
              </TableCell>
              <TableCell style={{ width: 100 }} align="left"></TableCell>
            </TableRow>
          ))}
          <TableRow></TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination
              count={Math.ceil(totalAuditsCount / pageSize)}
              page={page}
              onChange={(event, number) =>
                handlePageChange(
                  event as React.MouseEvent<HTMLButtonElement>,
                  number,
                )
              }
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default ShowKeyVaultData;
