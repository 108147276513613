import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useToolbarStyles } from '../Customer/Home/CustomerHeaderToolbar';
import {
  Backdrop,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Paper,
  Toolbar,
  Typography,
  TextField,
} from '@mui/material';
import { moduleService } from './ModuleService';
import {
  CustomerModuleMetaData,
  ModuleVariable,
  ModuleVariablesWrapper,
} from './Model/ModulesModel';
import { toast } from 'react-toastify';

interface Param {
  customerId: string;
}

const ModulesHomePage = () => {
  const params = useParams<Param>();
  const classes = useToolbarStyles();
  const history = useHistory();
  const [customermodulemetadata, setCustomerModuleMetaData] =
    useState<CustomerModuleMetaData>();
  const [loading, setLoading] = useState(true);
  const initialModuleVariableWrapperList: ModuleVariablesWrapper[] = [];
  const [moduleVariablesWrapperList, setModuleVariablesWrapperList] =
    React.useState(initialModuleVariableWrapperList);

  const loadRegisterTenant = async () => {
    await moduleService.GetCustomerModules(params.customerId).then((data) => {
      setCustomerModuleMetaData(data);
      if (data) {
        data.AvailableModules.map((am) =>
          am.Checked ? GetModuleVariables(am.Value, params.customerId) : null,
        );
      }
      setLoading(false);
    });
  };

  const GetModuleVariables = async (module: string, customerId: string) => {
    await moduleService.GetModuleVariables(module, customerId).then((data) => {
      const modulevariableswrapper = {} as ModuleVariablesWrapper;
      modulevariableswrapper.ModuleId = module;
      modulevariableswrapper.ModuleVariables = data;
      setModuleVariablesWrapperList((prevlist) => [
        ...prevlist,
        modulevariableswrapper,
      ]);
    });
  };

  const getsubText = (downtimeScope: string) => {
    if (downtimeScope === 'None') {
      return '(can be installed immediately)';
    } else if (downtimeScope === 'Tenant') {
      return '(can be installed immediately with downtime)';
    }
  };

  const updateModuleVariable = async (
    event: React.ChangeEvent<HTMLInputElement>,
    mv: ModuleVariable,
  ) => {
    const newValue = event.target.value;
    setModuleVariablesWrapperList((prevList) =>
      prevList.map((wrapper) => {
        if (wrapper.ModuleId === mv.ModuleID) {
          return {
            ...wrapper,
            ModuleVariables: wrapper.ModuleVariables.map((variable) =>
              variable.VariableName === mv.VariableName
                ? { ...variable, Value: newValue }
                : variable,
            ),
          };
        } else {
          return wrapper;
        }
      }),
    );
  };

  const mouseOutOnSave = async (mv: ModuleVariable) => {
    setLoading(true);
    await moduleService
      .SaveModuleVariable(
        mv.ModuleID,
        params.customerId,
        mv.VariableName,
        mv.Value,
      )
      .then((data) => {
        toast.success(data.VariableName + ' Saved Successfully');
        setLoading(false);
      });
  };

  const handleSave = async () => {
    setLoading(true);
    const moduleIds = moduleVariablesWrapperList.map((mv) => mv.ModuleId);
    await moduleService
      .SaveModulesForCustomer(params.customerId, moduleIds)
      .then(() => {
        toast.success('Modules Saved Successfully');
        setLoading(false);
      });
  };

  const handleCancel = async () => {
    history.push('/customer');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    setLoading(true);
    setCustomerModuleMetaData((prevState) => {
      if (!prevState) {
        prevState = {
          CustomerSubdomain: '',
          CustomerID: '',
          SelectedModules: [],
          AvailableModules: [],
        };
      }

      const index = prevState.AvailableModules.findIndex(
        (module) => module.Text === name,
      );
      const module = prevState.AvailableModules[index].Value;
      if (checked) {
        GetModuleVariables(module, params.customerId);
      } else {
        const latestmoduleVariablesWrapperList =
          moduleVariablesWrapperList.filter((mvwl) => mvwl.ModuleId !== module);
        setModuleVariablesWrapperList(latestmoduleVariablesWrapperList);
      }

      if (index !== -1) {
        const newAvailableModules = [...prevState.AvailableModules];
        newAvailableModules[index] = {
          ...newAvailableModules[index],
          Checked: checked,
        };

        return { ...prevState, AvailableModules: newAvailableModules };
      }
      return prevState;
    });
    setLoading(false);
  };

  useEffect(() => {
    loadRegisterTenant().catch((err) => console.log(err));
  }, []);
  return (
    <div>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Paper>
          <Toolbar className={classes.root}>
            <div className={classes.title}>
              <Typography variant="h6" id="tableTitle">
                Register Modules for Customer :{' '}
                {customermodulemetadata?.CustomerSubdomain}
              </Typography>
            </div>
          </Toolbar>
          <Paper sx={{ display: 'flex' }}>
            <Card elevation={2} style={{ padding: 15, margin: 15 }}>
              {' '}
              <FormGroup>
                {customermodulemetadata?.AvailableModules.map(
                  (availableModule) => (
                    <FormControlLabel
                      key={availableModule.Text}
                      control={
                        <Checkbox
                          checked={availableModule.Checked}
                          name={availableModule.Text}
                          onChange={handleChange}
                        />
                      }
                      label={
                        availableModule.Text +
                        ' ' +
                        getsubText(
                          availableModule.UnderlyingObject.DowntimeScope,
                        )
                      }
                    />
                  ),
                )}
              </FormGroup>
            </Card>
            <Card elevation={2} style={{ padding: 15, margin: 15 }}>
              {moduleVariablesWrapperList.length > 0 ? (
                <FormGroup>
                  {moduleVariablesWrapperList.map((moduleVariablesWrapper) => (
                    <Card
                      key={moduleVariablesWrapper.ModuleId}
                      elevation={2}
                      style={{
                        padding: 10,
                        margin: 5,
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="h6" width={150}>
                        {moduleVariablesWrapper.ModuleId}
                      </Typography>
                      <Typography sx={{ display: 'flex' }}>
                        {moduleVariablesWrapper.ModuleVariables.map((mv) => (
                          <Card
                            style={{ padding: 10, margin: 5 }}
                            key={mv.ModuleID}
                          >
                            <Typography textAlign={'center'}>
                              {mv.DisplayName}
                            </Typography>
                            <TextField
                              id={mv.VariableName}
                              value={mv.Value}
                              onChange={(e) =>
                                updateModuleVariable(
                                  e as React.ChangeEvent<HTMLInputElement>,
                                  mv,
                                )
                              }
                              onBlur={() => mouseOutOnSave(mv)}
                            />
                          </Card>
                        ))}
                      </Typography>
                    </Card>
                  ))}
                </FormGroup>
              ) : (
                <Typography>No Module Variables for Customer</Typography>
              )}
            </Card>
          </Paper>
          <Divider className={classes.divider} />
          <Paper style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleSave}
            >
              Save{' '}
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              onClick={handleCancel}
            >
              Back{' '}
            </Button>
          </Paper>
        </Paper>
      )}
    </div>
  );
};

export default ModulesHomePage;
