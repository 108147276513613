import { AuthProvider } from '.';

export function runWithMsal(authprovider: AuthProvider, app: () => void) {
  if (window.location.origin + '/clients' === window.location.href) {
    app();
  } else {
    const account = authprovider.getAccount();
    if (account) {
      app();
    } else {
      authprovider.login().then(() => app());
    }
  }
}
