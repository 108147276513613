import React from 'react';
import { IconButton } from '@mui/material';
import { GetActionLink } from '../../../../Common/CommonMethods';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemedEditNoteIcon } from '../../../../Common/Components/Icons';

const EditActionLinkButton = (props: { routeComponents: string[] }) => {
  const history = useHistory();
  const location = useLocation();
  const link = GetActionLink(props.routeComponents);

  const handleClick = () => {
    const newPath = `${location.pathname}${link}`;
    history.push(newPath);
  };

  return (
    <IconButton onClick={handleClick}>
      <ThemedEditNoteIcon />
    </IconButton>
  );
};

export default EditActionLinkButton;
