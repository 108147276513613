import React from 'react';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { ClientSecretMetaData, HeadRow } from '../Model/ClientSecretesModel';
import { EnhancedTableProps } from '../Model/ClientsSecretesProps';

const ApiKeyheadRows: HeadRow[] = [
  {
    id: 'ClientId',
    numeric: false,
    disablePadding: false,
    label: 'Api Key',
    enableSorting: true,
  },
  {
    id: 'Description',
    numeric: false,
    disablePadding: false,
    label: 'Description',
    enableSorting: true,
  },
  {
    id: 'CreatedDate',
    numeric: false,
    disablePadding: false,
    label: 'Created Date',
    enableSorting: true,
  },
  {
    id: 'IsActive',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    enableSorting: true,
  },
];

export default function ClientsSecreteTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof ClientSecretMetaData) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {ApiKeyheadRows.map((ApiKeyrow) => (
          <TableCell
            key={ApiKeyrow.id}
            align={ApiKeyrow.numeric ? 'left' : 'center'}
            padding={ApiKeyrow.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === ApiKeyrow.id ? order : false}
          >
            {ApiKeyrow.enableSorting ? (
              <TableSortLabel
                active={orderBy === ApiKeyrow.id}
                direction={order}
                onClick={createSortHandler(ApiKeyrow.id)}
              >
                {ApiKeyrow.label}
              </TableSortLabel>
            ) : (
              <div>{ApiKeyrow.label}</div>
            )}
          </TableCell>
        ))}
        <TableCell align="left"></TableCell>
      </TableRow>
    </TableHead>
  );
}
