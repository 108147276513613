export default class FarmConstants {
  static readonly UpdateSslBindings = 'Update Ssl Bindings';
  static readonly QueuedSSLRunbook = 'Runbook Queued to update SSL Bindings';
  static readonly NewFarm = 'New Farm';
  static readonly CreateFarm = 'Create New Farm';
  static readonly SelectSubscription = 'Select Product';
  static readonly AppGatewayWarning =
    'either select application gateway or tenants else gateway with one tenant';
  static readonly EmptyTenantInfo = 'Please select atleast one tenant';
  static readonly EmptyBuildInfo = 'Please select the assemble build';
  static readonly EmptyBranchInfo = 'Please select the platform branch';
  static readonly EmptyComponentInfo = 'Please select atleast one Component';
  static readonly DeploymentQueued = 'BAZINGA! deployment is queued';
  static readonly DeploymentQueueFailed = 'Failed to Queue the Deployment';
  static readonly EmptyFarmInfo = 'Atlease one farm must be selected ';
  static readonly Query1 =
    'SELECT * FROM sys.table_types WHERE NAME = @param1 AND COLUMN_NAME = @param2 ';
  static readonly Query2 = 'SELECT * FROM sys.table_types WHERE NAME = @param1';
  static readonly param1 = 'paramvalue1';
  static readonly param2 = 'paramvalue2';
  static readonly FindChanges = 'Find Changes';
  static readonly StorageAccount = 'Storage Account';
}
