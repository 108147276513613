import React, { useEffect, useState } from 'react';
import { Backdrop, Checkbox, CircularProgress, Paper } from '@mui/material';
import { accessControlService } from '../AccessControlService';
import { useToolbarStyles } from '../../Customer/Home/CustomerHeaderToolbar';
import { DragableUI, GetDragEndList } from './BasePermissionDragable';
import { PermissionState, PortalPermission } from '../Model/AccessControlModel';

interface props {
  groupname: string;
  setselectedGroupPermissions: React.Dispatch<
    React.SetStateAction<PortalPermission[]>
  >;
}

const GroupPermissionsDragable = ({
  groupname,
  setselectedGroupPermissions,
}: props) => {
  const classes = useToolbarStyles();
  const [loading, setLoading] = useState(true);
  const [liststate, setListState] = useState<PermissionState>({
    unselectedlist: [],
    selectedlist: [],
  });
  const [partnetgroupdefaultpermisssions, setPartnetgroupDefaultPermisssions] =
    useState<PortalPermission[]>([]);
  const [applyPartnerGroupPermissions, setApplyPartnerGroupPermissions] =
    useState(false);

  useEffect(() => {
    loadpartnetgroupdefaultpermisssions().catch((err) => console.log(err));
    if (groupname === '') {
      loadAvailableGroups().catch((err) => console.log(err));
    } else {
      loadGroupAndAvailablePermissions().catch((err) => console.log(err));
    }
  }, [groupname]);

  const loadpartnetgroupdefaultpermisssions = async () => {
    const partnergrouppermissions =
      await accessControlService.GetPartnerGroupDefaultPermissions();
    setPartnetgroupDefaultPermisssions(partnergrouppermissions.Items);
  };

  const loadGroupAndAvailablePermissions = async () => {
    setLoading(true);
    const GroupAndAvailablePermissions =
      await accessControlService.getGroupAndAvailablePermissions(groupname);

    setListState((prevState) => ({
      ...prevState,
      unselectedlist: GroupAndAvailablePermissions.AvailablePermissions,
    }));
    setListState((prevState) => ({
      ...prevState,
      selectedlist: GroupAndAvailablePermissions.GroupPermissions,
    }));
    setselectedGroupPermissions(GroupAndAvailablePermissions.GroupPermissions);
    setLoading(false);
  };

  const handleAddPartnerGroupDefaultPermissions = (checked: boolean) => {
    setApplyPartnerGroupPermissions(checked);
    if (checked) {
      partnetgroupdefaultpermisssions.forEach((permission) => {
        setListState((prevState) => ({
          ...prevState,
          selectedlist: [...prevState.selectedlist, permission],
        }));
      });

      partnetgroupdefaultpermisssions.forEach((permission) => {
        setselectedGroupPermissions((prevState) => [...prevState, permission]);
      });

      partnetgroupdefaultpermisssions.forEach((permission) => {
        setListState((prevState) => ({
          ...prevState,
          unselectedlist: prevState.unselectedlist.filter(
            (item) => item.PermissionName !== permission.PermissionName,
          ),
        }));
      });
    } else {
      partnetgroupdefaultpermisssions.forEach((permission) => {
        setListState((prevState) => ({
          ...prevState,
          unselectedlist: [...prevState.unselectedlist, permission],
        }));
      });
      partnetgroupdefaultpermisssions.forEach((permission) => {
        setListState((prevState) => ({
          ...prevState,
          selectedlist: prevState.selectedlist.filter(
            (item) => item.PermissionName !== permission.PermissionName,
          ),
        }));
      });
      partnetgroupdefaultpermisssions.forEach((permission) => {
        setselectedGroupPermissions((prevState) =>
          prevState.filter(
            (item) => item.PermissionName !== permission.PermissionName,
          ),
        );
      });
    }
  };

  const loadAvailableGroups = async () => {
    setLoading(true);
    const GroupAndAvailablePermissions =
      await accessControlService.getAvailablePermissions();

    setListState((prevState) => ({
      ...prevState,
      unselectedlist: GroupAndAvailablePermissions.AvailablePermissions,
    }));
    setListState((prevState) => ({
      ...prevState,
      selectedlist: GroupAndAvailablePermissions.GroupPermissions,
    }));

    setLoading(false);
  };

  const onDragEnd = GetDragEndList(
    liststate,
    setListState,
    setselectedGroupPermissions,
  );

  return (
    <>
      {loading ? (
        <Backdrop className={classes.backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Paper sx={{ padding: 1 }}>
            {' '}
            <Checkbox
              id={'partnetgrouppermisssion'}
              value={applyPartnerGroupPermissions}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                handleAddPartnerGroupDefaultPermissions(
                  (e.target as HTMLInputElement).checked,
                )
              }
              checked={applyPartnerGroupPermissions}
            />
            <label>Add Partner Group Permissions</label>
          </Paper>
          {DragableUI(
            onDragEnd,
            liststate,
            'Available Permissions',
            'Selected Permissions',
          )}
        </>
      )}
    </>
  );
};

export default GroupPermissionsDragable;
