import React from 'react';
import { EnhancedTableProps } from '../Model/TrustedClientsProps';
import { TrustedClients, HeadRow } from '../Model/TrustedClientsModel';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';

const TrustedClientheadRows: HeadRow[] = [
  {
    id: 'ClientID',
    numeric: false,
    disablePadding: false,
    label: 'Client ID',
    enableSorting: true,
  },
  {
    id: 'ClientUri',
    numeric: false,
    disablePadding: false,
    label: 'Client Uri',
    enableSorting: false,
  },
  {
    id: 'RedirectUris',
    numeric: false,
    disablePadding: false,
    label: 'Redirect Uris',
    enableSorting: false,
  },
  {
    id: 'LogoutUri',
    numeric: false,
    disablePadding: false,
    label: 'Logout Uri',
    enableSorting: false,
  },
  {
    id: 'AllowedScopes',
    numeric: false,
    disablePadding: false,
    label: 'Allowed Scopes',
    enableSorting: false,
  },
  {
    id: 'AllowedGrantTypes',
    numeric: false,
    disablePadding: false,
    label: 'Allowed GrantTypes',
    enableSorting: false,
  },
];

//last empty table cell is header for menu

export default function TrustedClientsTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof TrustedClients) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {TrustedClientheadRows.map((TCrow) => (
          <TableCell
            key={TCrow.id}
            align={TCrow.numeric ? 'right' : 'left'}
            padding={TCrow.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === TCrow.id ? order : false}
          >
            {TCrow.enableSorting ? (
              <TableSortLabel
                active={orderBy === TCrow.id}
                direction={order}
                onClick={createSortHandler(TCrow.id)}
              >
                {TCrow.label}
              </TableSortLabel>
            ) : (
              <div>{TCrow.label}</div>
            )}
          </TableCell>
        ))}
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
}
