import React, { useState } from 'react';
import { Route, BrowserRouter as Router, Link, Switch } from 'react-router-dom';
import { publicRoutes } from '../../../Routes/PublicRoutes';
import { TourProvider } from '@reactour/tour';
import { ToastContainer } from 'react-toastify';
import {
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useWrapperStyle } from '../../../Common/Styles/WraperStyle';
import PortalBar from './PortalBar';
import { publicMenus } from '../../../Routes/Routes';

const PublicPageWrapper = () => {
  const theme = useTheme();
  const classes = useWrapperStyle();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] =
    useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleNotificationDrawer = () => {
    setIsNotificationDrawerOpen(!isNotificationDrawerOpen);
  };

  return (
    <div>
      <Router>
        <TourProvider
          steps={[]}
          disableKeyboardNavigation={['esc']}
          defaultOpen={false}
          position={'center'}
          styles={{
            popover: (base) => ({
              ...base,
              borderRadius: 10,
              background: theme.palette.mode === 'dark' ? '#121415' : '#fff',
              color: theme.palette.mode === 'dark' ? '#fafafa' : '#212B36',
              fontSize: '1rem',
            }),
          }}
        >
          <div className={classes.root}>
            <CssBaseline />
            <PortalBar
              notificationClick={handleNotificationDrawer}
              drawerOpen={isDrawerOpen}
              clickOpen={handleDrawerOpen}
              routes={publicRoutes}
              showProfile={false}
            />
            <Drawer
              variant="permanent"
              classes={{
                paper: !isDrawerOpen
                  ? classes.drawerPaperClose
                  : classes.drawerPaper,
              }}
              open={isDrawerOpen}
            >
              <div className={classes.toolbarIcon}>
                <IconButton onClick={handleDrawerClose} size="large">
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <Divider />
              <List>
                {publicMenus.map((menu) => (
                  <div key={menu.key}>
                    {menu.link ? (
                      <ListItemButton
                        key={menu.key}
                        component={Link}
                        to={menu.link}
                      >
                        <ListItemIcon>
                          <menu.icon sx={{ color: '#0078d4' }} />
                        </ListItemIcon>
                        <ListItemText primary={menu.name} />
                      </ListItemButton>
                    ) : null}
                  </div>
                ))}
              </List>
            </Drawer>
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <div>
                <Switch>
                  {publicRoutes.map((route) => (
                    <Route
                      key={route.path}
                      path={route.path}
                      component={route.component}
                      exact={route.exact || false}
                    />
                  ))}
                </Switch>
              </div>
            </main>
            <ToastContainer pauseOnFocusLoss={false} position="bottom-left" />
          </div>
        </TourProvider>
      </Router>
    </div>
  );
};

export default PublicPageWrapper;
