import {
  Backdrop,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useStyles } from '../../../Common/Styles/Theme';
import React, { useState } from 'react';
import KeyVaultHeaderToolbar from './Components/KeyVaultHeaderToolbar';
import KeyVaultTableHead from './Components/KeyVaultTableHead';
import { Order } from '../../../Helpers/Literals';
import { KeyValuePairs } from './Model/KeyVaultModel';
import { getSorting, stableSort } from '../../../Helpers/UtilityMethods';
import { keyvaultrService } from './KeyVaultService';
import Reveal from './Components/Reveal';
import KeyVaultMenu from './Components/KeyVaultMenu';
import { ShowKeyVaultDataProps } from './Model/KeyVaultProps';

const ShowKeyVaultData: React.FC<ShowKeyVaultDataProps> = ({
  secureKeys,
  loading,
  order,
  orderBy,
  page,
  rowsPerPage,
  handleRequestSort,
  handleChangePage,
}) => {
  const classes = useStyles();
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, secureKeys.length - page * rowsPerPage);

  return (
    <div className={classes.TableWrapper}>
      <Table>
        <Backdrop className={classes.Backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <KeyVaultTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={(e, keyvalpairs) => handleRequestSort(keyvalpairs)}
        />
        <TableBody>
          {stableSort(secureKeys, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((secureKey) => (
              <TableRow key={secureKey.Key}>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2">{secureKey.Key}</Typography>
                </TableCell>
                <TableCell>
                  <Reveal KeyId={secureKey.Key} />
                </TableCell>
                <TableCell style={{ width: 100 }} align="left">
                  <KeyVaultMenu KeyName={secureKey.Key} />
                </TableCell>
              </TableRow>
            ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              colSpan={6}
              count={secureKeys.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'Rows per page' },
                native: true,
              }}
              onPageChange={(event, newPage) => handleChangePage(newPage)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

export default function KeyValutTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof KeyValuePairs>('Key');
  const rowsPerPage = 10;
  const [page, setPage] = React.useState(0);
  const initialState: KeyValuePairs[] = [];
  const [SecureKeys, setSecureKeys] = React.useState(initialState);
  const [SecureKeysCopy, setSecureKeyCopy] = React.useState(initialState);
  const [loading, setLoadingState] = useState(true);
  React.useEffect(() => {
    loadSecureKeys();
  }, []);
  const loadSecureKeys = async () => {
    try {
      setLoadingState(true);
      const securekeysReceived = await keyvaultrService.getSecureKeys();
      setSecureKeys(securekeysReceived.Items);
      setSecureKeyCopy(securekeysReceived.Items);
      setLoadingState(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingState(false);
    }
  };

  function handleSearchFilter(event: React.ChangeEvent<HTMLInputElement>) {
    handleFilter(event.target.value);
  }
  function handleRequestSort(property: keyof KeyValuePairs) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(newPage: number) {
    setPage(newPage);
  }

  const handleFilter = (searchFilterText: string) => {
    const filteredSecureKeys = SecureKeysCopy.filter(function (securekey) {
      return (
        securekey.Key.toLowerCase().indexOf(searchFilterText.toLowerCase()) >= 0
      );
    });
    setSecureKeys(filteredSecureKeys);
  };
  return (
    <div className={classes.Root}>
      <Paper className={classes.Paper}>
        <KeyVaultHeaderToolbar onFilterRequest={handleSearchFilter} />
        <ShowKeyVaultData
          secureKeys={SecureKeys}
          loading={loading}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          handleRequestSort={handleRequestSort}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </div>
  );
}
