import React, { useState } from 'react';
import { useStyles } from '../../../Common/Styles/Theme';
import {
  Backdrop,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import EmailAdminToolbar from './EmailAdminHeaderToolbar';
import EmailAdminHeader from './EmailAdminHeader';
import { EmailTemplateDataProps } from './Model/EmailAdminProps';
import { getSorting, stableSort } from '../../../Helpers/UtilityMethods';
import { EmailTemplate } from './Model/EmailAdminModel';
import { Order } from '../../../Helpers/Literals';
import { emailadminService } from './EmailAdministrationService';
import EditActionLinkButton from './Components/EditActionLinkButton';
import DeleteTemplate from './Components/DeleteTemplateButton';

const DisplayEmailTemplate = (props: EmailTemplateDataProps) => {
  const classes = useStyles();
  const {
    emailTemplates,
    loading,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleRequestSort,
    handleChangePage,
    loadEmailTemplates,
  } = props;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, emailTemplates.length - page * rowsPerPage);

  return (
    <div className={classes.TableWrapper}>
      <Table>
        <Backdrop className={classes.Backdrop} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <EmailAdminHeader
          order={order}
          orderBy={orderBy}
          onRequestSort={(e, emailtemplates) =>
            handleRequestSort(emailtemplates)
          }
        />
        <TableBody>
          {stableSort(emailTemplates, getSorting(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((emailTemplate) => (
              <TableRow key={emailTemplate.Name}>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2">
                    {emailTemplate.Name}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2">
                    {emailTemplate.Product}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2">
                    {emailTemplate.Subject}
                  </Typography>
                </TableCell>
                <TableCell component="th" scope="row">
                  <EditActionLinkButton
                    routeComponents={[
                      emailTemplate.Name,
                      emailTemplate.Product,
                    ]}
                  />
                </TableCell>
                <TableCell>
                  <DeleteTemplate
                    templateName={emailTemplate.Name}
                    loadEmailTemplates={loadEmailTemplates}
                  />
                </TableCell>
                <TableCell style={{ width: 100 }} align="left"></TableCell>
              </TableRow>
            ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10]}
              colSpan={6}
              count={emailTemplates.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { 'aria-label': 'Rows per page' },
                native: true,
              }}
              onPageChange={(event, newPage) => handleChangePage(newPage)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
};

const EmailAdmin = () => {
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof EmailTemplate>('Name');
  const rowsPerPage = 10;
  const [page, setPage] = React.useState(0);
  const initialState: EmailTemplate[] = [];
  const [emailTemplates, setEmailTemplates] = React.useState(initialState);
  const [emailTemplatesCopy, setEmailTemplatesCopy] =
    React.useState(initialState);
  const [loading, setLoadingState] = useState(true);

  React.useEffect(() => {
    loadEmailTemplates();
  }, []);

  const loadEmailTemplates = async () => {
    try {
      setLoadingState(true);
      const emailTemplatesRecieved =
        await emailadminService.getEmailTemplates();
      setEmailTemplates(emailTemplatesRecieved.Items);
      setEmailTemplatesCopy(emailTemplatesRecieved.Items);
      setLoadingState(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingState(false);
    }
  };

  function handleSearchFilter(event: React.ChangeEvent<HTMLInputElement>) {
    handleFilter(event.target.value);
  }
  function handleRequestSort(property: keyof EmailTemplate) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(newPage: number) {
    setPage(newPage);
  }

  const handleFilter = (searchFilterText: string) => {
    const filteredEmailTemplates = emailTemplatesCopy.filter(
      function (emailTemplate) {
        return (
          emailTemplate.Name.toLowerCase().indexOf(
            searchFilterText.toLowerCase(),
          ) >= 0
        );
      },
    );
    setEmailTemplates(filteredEmailTemplates);
  };
  return (
    <div className={classes.Root}>
      <Paper className={classes.Paper}>
        <EmailAdminToolbar onFilterRequest={handleSearchFilter} />
        <DisplayEmailTemplate
          emailTemplates={emailTemplates}
          loading={loading}
          order={order}
          orderBy={orderBy}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleRequestSort={handleRequestSort}
          loadEmailTemplates={loadEmailTemplates}
        />
      </Paper>
    </div>
  );
};

export default EmailAdmin;
