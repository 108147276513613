import {
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { farmService } from '../FarmService';
import { Farm, FarmTopologies, Resources } from '../Model/FarmModel';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Cancel, Save } from '@mui/icons-material';
import { Loader } from '../../../../Common/Components/Loader';
import { useStyles } from '../../../../Common/Styles/FarmStyle';
import { useStyles as useWraperStyle } from '../../../../Common/Styles/WraperStyle';

const FarmForm = () => {
  const initialGroupState: string[] = [];
  const initialTopologyState: FarmTopologies[] = [];
  const initialRegionState: string[] = [];
  const initialResourceState: Resources[] = [];
  const initialFarmsState: Farm[] = [];
  const history = useHistory();
  const commonStyle = useStyles();
  const wrapperStyle = useWraperStyle();
  const [groups, setGroups] = useState(initialGroupState);
  const [topologies, setFarmTopologies] = useState(initialTopologyState);
  const [regions, setFarmRegions] = useState(initialRegionState);
  const [resources, setFarmResources] = useState(initialResourceState);
  const [multiinstancefarms, setFarms] = useState(initialFarmsState);
  const [ownership, setOwnership] = useState<string>();
  const [datadiskname, setDataDiskName] = useState<string>();
  const [disksize, setDiskSize] = useState<number>(1024);
  const [diskSKU, setDiskSKU] = useState<string>('Standard_LRS');
  const [isloading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    Id: 0,
    DisplayName: '',
    GroupName: '',
    Location: '',
    Type: '',
    State: 7,
    DeploymentState: -1,
    SetupFilename: '',
    FarmRegion: 'northeurope',
    SecondaryRegion: 'westeurope',
    Properties: '',
    Subscription: '',
    IsHighAvailability: false,
  });

  useEffect(() => {
    loadFarmData().catch((err) => console.log(err));
    loadGroupsData().catch((err) => console.log(err));
    loadFarmTopologiesData().catch((err) => console.log(err));
    loadFarmRegionsData().catch((err) => console.log(err));
    loadFarmResourceData().catch((err) => console.log(err));
  }, []);

  const loadFarmData = async () => {
    const farmsReceived = await farmService.getFarms();
    setFarms(farmsReceived.Items);
  };

  const loadGroupsData = async () => {
    const groupsReceived = await farmService.getAvailableGroups();
    setGroups(groupsReceived);
  };
  const loadFarmTopologiesData = async () => {
    const farmsTopologiesReceived = await farmService.getFarmTopologies();
    setFarmTopologies(farmsTopologiesReceived.Items);
  };
  const loadFarmRegionsData = async () => {
    const farmsTopologiesReceived = await farmService.getFarmRegions();
    setFarmRegions(farmsTopologiesReceived);
  };
  const loadFarmResourceData = async () => {
    const farmsTopologiesReceived = await farmService.getResources();
    setFarmResources(farmsTopologiesReceived);
    setIsLoading(false);
  };

  const handleOwenerData = (e: {
    target: { value: React.SetStateAction<string | undefined> };
  }) => {
    setOwnership(e.target.value);
  };

  const [inputFields, setInputFields] = useState<
    { name: string; value: string | number }[]
  >([{ name: '', value: '' }]);

  const handleChangeInput = (
    index: number,
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const values = [...inputFields];
    values[index][event.target.name as keyof (typeof inputFields)[0]] =
      event.target.value;
    setInputFields(values);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const handleAddFields = () => {
    setInputFields([...inputFields, { name: '', value: '' }]);
  };

  const handleRemoveFields = (index: number) => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const Validate = () => {
    let res = true;

    if (formData.DisplayName === '') {
      toast.error('Display Name is required');
      res = false;
    }

    if (formData.GroupName === '') {
      toast.error('Group Name is required');
      res = false;
    }

    if (formData.Location === '') {
      toast.error('Location is required');
      res = false;
    }

    if (formData.SetupFilename === '') {
      toast.error('Setup Filename is required');
      res = false;
    }

    return res;
  };

  const handleCancel = () => {
    return history.push('/farm');
  };

  const getFarmId = () => {
    if (multiinstancefarms.length > 0) {
      return multiinstancefarms[multiinstancefarms.length - 1].Id + 1;
    }
    return 1;
  };

  const handleSave = async () => {
    if (Validate() === true) {
      setIsLoading(true);
      const properties: { [key: string]: string | number } = {};
      if (ownership !== undefined) {
        inputFields.push({ name: 'Ownership', value: ownership });
      }
      if (disksize !== undefined && diskSKU !== undefined) {
        inputFields.push({
          name: 'DataDiskName',
          value: datadiskname ?? 'Farm' + getFarmId() + 'IS-Data-IS-0',
        });
        inputFields.push({ name: 'DiskSizeGB', value: disksize });
        inputFields.push({ name: 'DiskSKU', value: diskSKU });
      }
      for (const element of inputFields) {
        const prop = element;
        if (!prop.name) continue;

        properties[prop.name] = prop.value;
      }
      const propertyString = JSON.stringify(properties);

      formData.Properties = propertyString;
      // Set Properties to an empty object if there are no properties
      formData.Properties =
        Object.keys(properties).length > 0 ? propertyString : '{}';

      formData.Id = getFarmId();

      if (formData.Location.includes('@')) {
        const [farmType, Location] = formData.Location.split('@');
        formData.Location = Location;
        formData.Type = farmType;
        const topo = topologies.find((x) => x.FarmType === farmType);
        let subscription = '';
        if (topo === undefined) {
          subscription = farmType;
        } else {
          subscription = topo.DefaultSubscription;
        }
        formData.Subscription = subscription;
      }

      await farmService.postFarm(formData.Id, formData as Farm);
      setIsLoading(false);
      history.push('/farm');
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = event.target;
    if (checked !== undefined) {
      setFormData({
        ...formData,
        [name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const updateFormData = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) =>
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });

  const {
    DisplayName,
    GroupName,
    Location,
    SetupFilename,
    FarmRegion,
    SecondaryRegion,
    IsHighAvailability,
  } = formData;

  return (
    <>
      <Loader loading={isloading} />
      <div>
        <Paper>
          <Typography variant="h5" className={wrapperStyle.title}>
            Create New Farm
          </Typography>
          <Divider className={wrapperStyle.divider} />
          <Grid container>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className={commonStyle.outerContainer}>
                <Card elevation={0} className={commonStyle.card}>
                  <h3>Basic information</h3>
                  <div>
                    <div className={commonStyle.spanContainer}>
                      <span className={commonStyle.span}>Display Name</span>
                      <TextField
                        className={commonStyle.textField}
                        value={DisplayName}
                        placeholder="DisplayName"
                        name="DisplayName"
                        onChange={(e) => updateFormData(e)}
                      />
                    </div>
                    <div className={commonStyle.spanContainer}>
                      <span className={commonStyle.span}>Group Name</span>
                      <TextField
                        className={commonStyle.textField}
                        value={GroupName}
                        placeholder="Group Name"
                        name="GroupName"
                        onChange={handleChange}
                        select
                      >
                        {groups.map((group) => {
                          return (
                            <MenuItem key={group} value={group}>
                              {group}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                    <div className={commonStyle.spanContainer}>
                      <span className={commonStyle.span}>
                        Location & farm type
                      </span>
                      <TextField
                        className={commonStyle.textField}
                        name="Location"
                        value={Location}
                        onChange={handleChange}
                        select
                      >
                        {topologies.map((topology) => {
                          return (
                            <MenuItem
                              key={topology.FarmType}
                              value={
                                topology.FarmType + '@' + topology.Location
                              }
                            >
                              {topology.FarmType + '@' + topology.DisplayName}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                    <div className={commonStyle.spanContainer}>
                      <span className={commonStyle.span}>Assemble Build</span>
                      <TextField
                        className={commonStyle.textField}
                        name="SetupFilename"
                        value={SetupFilename}
                        onChange={handleChange}
                        select
                      >
                        {resources.map((resource) => {
                          return (
                            <MenuItem
                              key={resource.Filename}
                              value={resource.Filename}
                            >
                              {resource.Filename}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                    <div className={commonStyle.spanContainer}>
                      <span className={commonStyle.span}>Primary Region</span>
                      <TextField
                        className={commonStyle.textField}
                        name="FarmRegion"
                        value={FarmRegion}
                        onChange={handleChange}
                        select
                      >
                        {regions.map((region) => {
                          return (
                            <MenuItem key={region} value={region}>
                              {region}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                    <div className={commonStyle.spanContainer}>
                      <span className={commonStyle.span}>Secondary Region</span>
                      <TextField
                        className={commonStyle.textField}
                        name="SecondaryRegion"
                        value={SecondaryRegion}
                        onChange={handleChange}
                        select
                      >
                        {regions.map((region) => {
                          return (
                            <MenuItem key={region} value={region}>
                              {region}
                            </MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                    <div className={commonStyle.spanContainer}>
                      <span className={commonStyle.span}>Ownership</span>
                      <TextField
                        className={commonStyle.textField}
                        value={ownership}
                        onChange={handleOwenerData}
                        type="text"
                        name="ownership"
                        required
                      />
                    </div>
                    <div className={commonStyle.spanContainer}>
                      <span className={commonStyle.span}>
                        High Availability
                      </span>
                      <div className={commonStyle.textField}>
                        <Switch
                          sx={{ float: 'left' }}
                          checked={IsHighAvailability}
                          onChange={handleChange}
                          name={'IsHighAvailability'}
                        />
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <div className={commonStyle.outerContainer}>
                <Card elevation={0} className={commonStyle.card}>
                  <h3>Indexing Search</h3>
                  <div className={commonStyle.spanContainer}>
                    <span className={commonStyle.span}>Data Disk Name</span>
                    <TextField
                      className={commonStyle.textField}
                      value={
                        datadiskname ?? 'Farm' + getFarmId() + 'IS-Data-IS-0'
                      }
                      onChange={(e) => {
                        setDataDiskName(e.target.value);
                      }}
                      type="text"
                      name="DataDiskName"
                      required
                    />
                  </div>
                  <div className={commonStyle.spanContainer}>
                    <span className={commonStyle.span}>
                      Disk SKU (StorageType)
                    </span>
                    <TextField
                      className={commonStyle.textField}
                      value={diskSKU}
                      onChange={(e) => {
                        setDiskSKU(e.target.value);
                      }}
                      type="text"
                      name="DiskSKU"
                      required
                    />
                  </div>
                  <div className={commonStyle.spanContainer}>
                    <span className={commonStyle.span}>Size (GiB)</span>
                    <TextField
                      className={commonStyle.textField}
                      value={disksize}
                      onChange={(e) => {
                        setDiskSize(Number(e.target.value));
                      }}
                      type="number"
                      name="DiskSize"
                      required
                      InputProps={{ inputProps: { min: 4, max: 32767 } }}
                    />
                  </div>
                </Card>
              </div>
              <div className={commonStyle.outerContainer}>
                <Card elevation={0} className={commonStyle.card}>
                  <h3>Farm Properties</h3>
                  <div className={commonStyle.spanContainer}>
                    <span className={commonStyle.span}>Properties</span>
                    <form onSubmit={handleSubmit}>
                      {inputFields.map((inputField, index) => (
                        <div key={inputField.name}>
                          <TextField
                            name="name"
                            label="name"
                            variant="standard"
                            value={inputField.name}
                            onChange={(event) =>
                              handleChangeInput(index, event)
                            }
                          />
                          <TextField
                            name="value"
                            label="value"
                            variant="standard"
                            value={inputField.value}
                            onChange={(event) =>
                              handleChangeInput(index, event)
                            }
                          />
                          <IconButton
                            onClick={() => handleRemoveFields(index)}
                            size="large"
                          >
                            <RemoveIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => handleAddFields()}
                            size="large"
                          >
                            <AddIcon />
                          </IconButton>
                        </div>
                      ))}
                    </form>
                  </div>
                </Card>
              </div>
            </Grid>
          </Grid>
          <Divider className={wrapperStyle.divider} />
          <Paper style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              onClick={handleSave}
              className={wrapperStyle.button}
              startIcon={<Save />}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={handleCancel}
              className={wrapperStyle.button}
              startIcon={<Cancel />}
            >
              Cancel
            </Button>
          </Paper>
        </Paper>
      </div>
    </>
  );
};

export default FarmForm;
